import React, { useEffect, useState } from "react";
import {
  Form,
  FormRenderProps,
  FormElement,
  Field,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { roleEn2swe } from "./userList";

// Redux Stuff
import {
  removeUser,
  setUserHasChanged,
  reduxSetSelectedUser,
  reduxDeleteUser,
} from "../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { appState } from "../../store";
import { Popup } from "@progress/kendo-react-popup";
import { roleType, userType } from "../../store/user/types";

import { getHighestRoleTypeFromServerStruct } from "../../helpers/genericHelpers";
import { UserForm } from "../../components/manageUsers/userForm";
import { useTranslation } from "react-i18next";

interface userListItemProps {
  user: userType;
  edit: boolean;
  selectedUser: userType["id"];
  setNewUserAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UserListItem: React.FC<userListItemProps> = ({
  user,
  edit,
  selectedUser,
  setNewUserAdded,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [trash, setTrash] = useState(false);
  const [show, setShow] = useState(false);

  const allCompanies = useSelector(
    (state: appState) => state.company.allCompanies
  );

  const authenticationSuccess = useSelector(
    (state: appState) => state.user.authenticationSuccess
  );

  const currentUser = useSelector((state: appState) => state.user.currentUser);
  useEffect(() => {
    if (!user?.sentToServer) {
      edit = true;
    }
  }, [selectedUser]);

  const renderDeleteUserForm = (formRenderProps: FormRenderProps) => {
    return (
      <FormElement>
        <div className="deletePlanPwField">
          <Field
            name={"radera"}
            placeholder={t("common.delete")}
            component={Input}
            label={t("measurementListItem.confirmDelete")}
          />
          <div style={{ color: "red" }}>
            {authenticationSuccess ? "" : t("manageUsers.wrongPassword")}
          </div>
        </div>
        <div className="DeletePlanButtonContainer">
          <button
            className="PopupButton BUTTON"
            onClick={(e) => {
              e.preventDefault();
              setShow(!show);
            }}
          >
            {t("common.cancel")}
          </button>{" "}
          <button
            type={"submit"}
            className="PopupButton BUTTON"
            disabled={
              formRenderProps.valueGetter("radera") !== t("common.delete")
            }
            style={{
              opacity:
                formRenderProps.valueGetter("radera") !== t("common.delete")
                  ? 0.5
                  : 1,
            }}
          >
            {t("common.delete")}
          </button>
        </div>
      </FormElement>
    );
  };

  // NOT DISMOUNTED PROPERLY
  const renderUserContent = () => {
    return edit && !trash ? (
      <UserForm setNewUserAdded={setNewUserAdded} />
    ) : (
      <div className="userCardUser">
        <div className="userCardColumnL">
          <h5 className="userCardRow">
            {user?.firstName !== ""
              ? user?.firstName + " " + user?.lastName
              : "-"}
          </h5>

          <div className="userCardRow">
            {user?.email !== "" ? user?.email : "-"}
          </div>
          <div className="userCardRow">
            {user?.roles
              ? roleEn2swe(t, getHighestRoleTypeFromServerStruct(user?.roles))
              : t("manageUsers.operator")}
          </div>
          <div className="userCardRow">{user?.phone ? user?.phone : "-"}</div>
          <div className="userCardRow">
            {currentUser?.roles.some((role) => role.name === roleType.admin)
              ? allCompanies.map((company) => {
                  if (user?.companyID === company.id) return company.name;
                })
              : null}
          </div>
        </div>

        <div className="userCardColumnR">
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span
              className="k-icon k-i-change-manually removeChart"
              style={{
                fontSize: "24px",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(setUserHasChanged(false));
                dispatch(reduxSetSelectedUser(user.id));
                setTrash(false);
              }}
            />
            <div
              style={{
                marginRight: "10px",
              }}
            />
            {!user.sentToServer && user ? (
              <span
                className="k-icon k-i-delete removeChart"
                style={{
                  fontSize: "24px",
                }}
                onClick={() => {
                  dispatch(reduxSetSelectedUser(user.id));
                  setTrash(true);
                  setTimeout(() => {
                    dispatch(reduxDeleteUser(user.id));
                  }, 200);
                  setNewUserAdded(true);
                }}
              />
            ) : (
              <span
                className="k-icon k-i-delete removeChart"
                style={{
                  fontSize: "24px",
                }}
                onClick={() => {
                  dispatch(reduxSetSelectedUser(user.id));
                  setTrash(true);
                  setShow(!show);
                }}
              />
            )}
            <Popup
              show={show}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(120, 120, 120, 0.5)",
              }}
            >
              <div className="PopupContentShare">
                <p style={{ fontWeight: "bold" }}>
                  {t("manageUsers.deleteUser")}
                </p>
                <Form
                  initialValues={{ deletePlan: "" }}
                  onSubmit={(dataItem) => {
                    if (selectedUser === user?.id) {
                      dispatch(removeUser(t, user));
                      setShow(!show);
                    }
                  }}
                  render={(formRenderProps) =>
                    renderDeleteUserForm(formRenderProps)
                  }
                />
              </div>
            </Popup>
            <div
              style={{
                marginRight: "10px",
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        border:
          selectedUser === user?.id ? "solid var(--primary-color,#782441)" : "",
      }}
    >
      {renderUserContent()}
    </div>
  );
};
