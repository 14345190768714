import { companyAction, companyActionType, companyState } from "./types";

const initialState: companyState = {
  currentCompany: undefined,
  allCompanies: [],
  selectedCompany: "",
  companyHasChanged: false,
};

export function companyReducer(
  state: companyState = initialState,
  action: companyAction
): companyState {
  switch (action.type) {
    case companyActionType.setAllCompanies:
      return {
        ...state,
        allCompanies: action.payload.map((company) => {
          return { ...company, sentToServer: true };
        }),
        selectedCompany: "",
        companyHasChanged: false,
      };
    case companyActionType.deleteCompany:
      return {
        ...state,
        allCompanies: state.allCompanies.filter(
          (company) => company.id !== action.payload
        ),
        selectedCompany: "",
      };
    case companyActionType.setSelectedCompany:
      return {
        ...state,
        selectedCompany: action.payload,
      };
    case companyActionType.addNewReduxCompany:
      return {
        ...state,
        allCompanies: [...state.allCompanies, { ...action.payload }],
        selectedCompany: action.payload.id,
        companyHasChanged: true,
      };

    case companyActionType.updateCompanyProperty:
      return {
        ...state,
        allCompanies: state.allCompanies.map((company) => {
          return company.id === state.selectedCompany
            ? { ...company, ...action.payload }
            : company;
        }),
      };

    case companyActionType.setCompanyHasChanged:
      return {
        ...state,
        companyHasChanged: action.payload,
      };
    case companyActionType.setCurrentCompany:
      return {
        ...state,
        currentCompany: action.payload,
      };

    default:
      return state;
  }
}
