import {
  SchedulerAction,
  schedulerActionType,
  schedulerState,
  planSubpages,
} from "./types";
import { Loop, LoopReducer } from "redux-loop";

const initialState: schedulerState = {
  plans: [],
  totalPlansOnServer: 0,
  planSorterType: "-createdAt",
  selectedPlan: "-1",
  selectedSubpage: planSubpages.plan,
  planHasChanged: false,
  selectedMeasurement: "-1",
  expandMenu: false,
  externalAccessToken: "",
  screenWidth: window.innerWidth,
  screenHeight: window.innerHeight,
  newPlanSelected: false,
};

export const schedulerReducer: LoopReducer<schedulerState, SchedulerAction> = (
  state: schedulerState = initialState,
  action: SchedulerAction
): schedulerState | Loop<schedulerState, SchedulerAction> => {
  switch (action.type) {
    case schedulerActionType.setPlanSorterType:
      return {
        ...state,
        planSorterType: action.payload,
      };
    case schedulerActionType.addNewReduxPlan:
      return {
        ...state,
        plans: [...state.plans, { ...action.payload }],
        selectedPlan: action.payload.id,
        planHasChanged: true,
      };
    case schedulerActionType.setAllPlans:
      return {
        ...state,
        plans: action.payload.map((plan) => {
          return { ...plan, sentToServer: true };
        }),
        planHasChanged: false,
        totalPlansOnServer: action.payload.length,
      };
    case schedulerActionType.setSelectedPlan:
      return {
        ...state,
        selectedPlan: action.payload,
      };
    case schedulerActionType.setSelectedSubPage:
      return {
        ...state,
        selectedSubpage: action.payload,
      };
    // TODO:: BAD
    case schedulerActionType.updatePlanParameter:
      return {
        ...state,
        plans: state.plans.map((plan) => {
          return plan.id === state.selectedPlan
            ? { ...plan, parameters: { ...plan, ...action.payload } }
            : plan;
        }),
      };
    case schedulerActionType.updatePlanProperty:
      return {
        ...state,
        plans: state.plans.map((plan) => {
          return plan.id === state.selectedPlan
            ? { ...plan, ...action.payload }
            : plan;
        }),
      };

    case schedulerActionType.updateMeasurementProperty: {
      const resp = {
        ...state,
        plans: state.plans.map((plan) => {
          if (plan.id === state.selectedPlan) {
            plan.measurements = plan.measurements.map((meas) => {
              return meas.id === state.selectedMeasurement
                ? { ...meas, ...action.payload }
                : meas;
            });

            return plan;
          } else {
            return plan;
          }
        }),
      };

      // Validate
      const plan = resp.plans.find((plan) => plan.id === state.selectedPlan);
      const meas = plan?.measurements.find(
        (meas) => meas.id === state.selectedMeasurement
      );

      return resp;
    }
    case schedulerActionType.updateMeasurementStatus: {
      const respRefresh = {
        ...state,
        plans: state.plans.map((plan) => {
          if (plan.id === state.selectedPlan) {
            plan.measurements = plan.measurements.map((meas) => {
              return meas.id === action.payload.id
                ? { ...meas, ...action.payload.properties }
                : meas;
            });

            return plan;
          } else {
            return plan;
          }
        }),
      };

      return respRefresh;
    }
    case schedulerActionType.setPlanHasChanged:
      return {
        ...state,
        planHasChanged: action.payload,
      };
    case schedulerActionType.deletePlan:
      return {
        ...state,
        plans: state.plans.filter((plan) => plan.id !== action.payload),
        totalPlansOnServer: state.totalPlansOnServer - 1,
      };
    case schedulerActionType.setTotalPlansOnServer:
      return {
        ...state,
        totalPlansOnServer: action.payload,
      };
    case schedulerActionType.deleteMeasurement:
      return {
        ...state,
        plans: state.plans.map((plan) => {
          return {
            ...plan,
            measurements: plan.measurements.filter(
              (measurement) => measurement.id !== action.payload
            ),
          };
        }),
      };
    case schedulerActionType.setReduxProjectedTrack:
      return {
        ...state,
        plans: state.plans.map((plan) => {
          return plan.id === action.payload.planID
            ? { ...plan, projectedTrackFile: action.payload.file }
            : plan;
        }),
      };
    case schedulerActionType.setAllMeasurements: // Not in use
      return {
        ...state,
        plans: state.plans.map((plan) => {
          return plan.id === action.payload.plan.id
            ? { ...plan, measurements: [...action.payload.measurements] }
            : plan;
        }),
      };
    case schedulerActionType.addOpearatorToMeasurement:
      return {
        ...state,
        plans: state.plans.map((plan) => {
          return {
            ...plan,
            measurements: plan.measurements.map((measurement) => {
              return {
                ...measurement,
                operatorID: action.payload,
              };
            }),
          };
        }),
      };
    case schedulerActionType.setSelectedMeasurement:
      return {
        ...state,
        selectedMeasurement: action.payload,
      };
    case schedulerActionType.setExpandedMenu:
      return {
        ...state,
        expandMenu: action.payload,
      };
    case schedulerActionType.setExternalAccessToken:
      return {
        ...state,
        externalAccessToken: action.payload,
      };
    case schedulerActionType.setScreenWidth:
      return {
        ...state,
        screenWidth: action.payload,
      };
    case schedulerActionType.setScreenHeight:
      return {
        ...state,
        screenHeight: action.payload,
      };
    case schedulerActionType.setNewPlanSelected:
      return {
        ...state,
        newPlanSelected: action.payload,
      };
    // case schedulerActionType.setMeasurementPipelines:
    //   return {
    //     ...state,
    //     plans: state.plans.map((plan) => {
    //       return plan.id === action.payload.planID
    //         ? {
    //             ...plan,
    //             measurements: plan.measurements.map((measurement) => {
    //               return measurement.id === action.payload.measurementID
    //                 ? { ...measurement, pipelines: action.payload.pipelines }
    //                 : measurement;
    //             }),
    //           }
    //         : plan;
    //     }),
    //   };
    default:
      return state;
  }
};
