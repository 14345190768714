import Auth from "@aws-amplify/auth";
import axios from "axios";
import store from "./store";

/**UNCOMMENT BELOW FOR DEV API */
// const l = document.location;
// Replace node.js development server port 3000 with 8080. Otherwise, just keep the port as is.

const baseURL = process.env.REACT_APP_API_URL; //"http://localhost:8080/api/"; //

const instance = axios.create({
  baseURL: baseURL,
});
instance.interceptors.request.use(async function (config) {
  try {
    const state: any = store.getState();
    const externalAccessToken = state.scheduler.externalAccessToken;
    if (externalAccessToken !== "") {
      config.headers.Authorization = externalAccessToken;
    } else {
      const session = await Auth.currentSession();
      config.headers.Authorization = session.getAccessToken().getJwtToken();
    }
  } catch (reason) {
    console.error(reason);
  }

  return config;
});

export default instance;
