const mapStyle = [
  {
    featureType: "water",
    elementType: "geometry",
    stylers: [
      {
        color: "#71c8d4",
      },
    ],
  },
  {
    featureType: "landscape",
    elementType: "geometry",
    stylers: [
      {
        hue: "#b7caaa",
      },
      {
        saturation: -14,
      },
      {
        lightness: -18,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "landscape.man_made",
    elementType: "all",
    stylers: [
      {
        hue: "#cbdac1",
      },
      {
        saturation: -6,
      },
      {
        lightness: -9,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road",
    elementType: "geometry",
    stylers: [
      {
        hue: "#8d9b83",
      },
      {
        saturation: -89,
      },
      {
        lightness: -12,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "road.highway",
    elementType: "geometry",
    stylers: [
      {
        hue: "#d4dad0",
      },
      {
        saturation: -88,
      },
      {
        lightness: 54,
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.arterial",
    elementType: "geometry",
    stylers: [
      {
        hue: "#bdc5b6",
      },
      {
        saturation: -89,
      },
      {
        lightness: -3,
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "road.local",
    elementType: "geometry",
    stylers: [
      {
        hue: "#bdc5b6",
      },
      {
        saturation: -89,
      },
      {
        lightness: -26,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi",
    elementType: "geometry",
    stylers: [
      {
        hue: "#c17118",
      },
      {
        saturation: 61,
      },
      {
        lightness: -45,
      },
      {
        visibility: "on",
      },
    ],
  },
  {
    featureType: "poi.park",
    elementType: "all",
    stylers: [
      {
        hue: "#8ba975",
      },
      {
        saturation: -46,
      },
      {
        lightness: -28,
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "transit",
    elementType: "geometry",
    stylers: [
      {
        hue: "#a43218",
      },
      {
        saturation: 74,
      },
      {
        lightness: -51,
      },
      {
        visibility: "simplified",
      },
    ],
  },
  {
    featureType: "administrative.province",
    elementType: "all",
    stylers: [
      {
        hue: "#ffffff",
      },
      {
        saturation: 0,
      },
      {
        lightness: 0,
      },
      {
        visibility: "simplified",
      },
    ],
  },

  {
    featureType: "administrative.neighborhood",
    elementType: "all",
    stylers: [
      {
        hue: "#ffffff",
      },
      {
        saturation: 0,
      },
      {
        lightness: 100,
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.locality",
    elementType: "labels",
    stylers: [
      {
        hue: "#ffffff",
      },
      {
        saturation: 0,
      },
      {
        lightness: 0,
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative.land_parcel",
    elementType: "all",
    stylers: [
      {
        hue: "#ffffff",
      },
      {
        saturation: 0,
      },
      {
        lightness: 100,
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "administrative",
    elementType: "all",
    stylers: [
      {
        hue: "#3a3935",
      },
      {
        saturation: 0,
      },
      {
        lightness: 0,
      },
      {
        visibility: "on",
      },
      {
        color: "black",
      },
    ],
  },
  {
    featureType: "administrative.neighborhood",
    elementType: "labels.text.fill",
    stylers: [
      {
        color: "#000000",
      },
    ],
  },
  {
    featureType: "poi.business",
    elementType: "all",
    stylers: [
      {
        hue: "#cba923",
      },
      {
        saturation: 50,
      },
      {
        lightness: -46,
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.medical",
    elementType: "all",
    stylers: [
      {
        hue: "#cba923",
      },
      {
        saturation: 50,
      },
      {
        lightness: -46,
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.attraction",
    elementType: "all",
    stylers: [
      {
        hue: "#cba923",
      },
      {
        saturation: 50,
      },
      {
        lightness: -46,
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.government",
    elementType: "all",
    stylers: [
      {
        hue: "#cba923",
      },
      {
        saturation: 50,
      },
      {
        lightness: -46,
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.place_of_worship",
    elementType: "all",
    stylers: [
      {
        hue: "#cba923",
      },
      {
        saturation: 50,
      },
      {
        lightness: -46,
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.school",
    elementType: "all",
    stylers: [
      {
        hue: "#cba923",
      },
      {
        saturation: 50,
      },
      {
        lightness: -46,
      },
      {
        visibility: "off",
      },
    ],
  },
  {
    featureType: "poi.sports_complex",
    elementType: "all",
    stylers: [
      {
        hue: "#cba923",
      },
      {
        saturation: 50,
      },
      {
        lightness: -46,
      },
      {
        visibility: "off",
      },
    ],
  },
];

export default mapStyle;
