import React, { useState, useEffect } from "react";
import { TrashCanIcon, MinimizeIcon, ConfirmIcon, CancelIcon, ExpandCaretIcon, ExpandedCaretIcon, AddCommentIcon } from "./supportHelpers";
import { t } from "i18next";
import { patchSupportTicket } from "../../api/netRail/support";
import { getUser } from "../../api/netRail/users";
import { useTranslation } from "react-i18next";

interface ToolbarProps {
    collapse: () => void;
    deleteTicket: () => void;
    sizeAdjustButton?: () => void;
}

export const Toolbar: React.FC<ToolbarProps> = ({ collapse, deleteTicket, sizeAdjustButton }) => {
    const [confirmDelete, setConfirmDelete] = useState(false);

    const handleCollapseButtonClick = () => {
        collapse();
    };

    const handleDeleteButtonClick = () => {
        setConfirmDelete(true);
    };

    const handleConfirmDelete = () => {
        deleteTicket();
    };

    const handleCancelDelete = () => {
        setConfirmDelete(false);
    };

    return (
        <div className="toolbar" style={{ display: "flex", justifyContent: "space-between", borderBottom: "1px solid darkgrey", paddingBottom: "10px", marginBottom: "15px" }}>
            <div style={{ width: "70px", justifyContent: "space-between", display: "flex" }}>
                <button className="collapseButton toolbarButton" onClick={handleCollapseButtonClick}><MinimizeIcon /></button>
                {sizeAdjustButton ? sizeAdjustButton() : null}
            </div>
            <div>
                {confirmDelete ? (
                    <div className="buttonContainer expanded">
                        <button className="confirmButton toolbarButton fadeIn" onClick={handleConfirmDelete}><ConfirmIcon /></button>
                        <button className="cancelButton toolbarButton fadeIn" onClick={handleCancelDelete}><CancelIcon /></button>
                    </div>
                ) : (
                    <button className="deleteButton toolbarButton" onClick={handleDeleteButtonClick}><TrashCanIcon /></button>
                )}
            </div>
        </div>
    );
};

export const SupportField: React.FC<any> = ({ label, value }) => (
    <div style={{ position: "relative", marginBottom: "5px" }}>
        <span style={{
            position: "absolute",
            transform: "translate(6px, -2px)",
            fontSize: "12px",
            backgroundColor: "white",
            color: "#6f6f6f",
            padding: "0 4px"
        }}>
            {label}
        </span>
        <span style={{
            margin: "8px 2px",
            border: "1px solid darkgrey",
            borderRadius: "5px",
            padding: "4px",
            width: "100%",
            display: "inline-block",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
        }}>
            {value}
        </span>
    </div>
);


const statuses = ["Created", "Open", "Closed", "In Progress", "On Hold"];

interface TicketStatusProps {
    status: string;
    supportTicket: any;
    handleChangeStatus: (updatedTicket: any) => Promise<void>;
}

export const TicketStatus: React.FC<TicketStatusProps> = ({ status, supportTicket, handleChangeStatus }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [currentStatus, setCurrentStatus] = useState(status);

    const handleStatusClick = async (newStatus: string) => {
        const date = new Date().toISOString().split("T")[0];
        const userId = supportTicket.creatorID;
        const newCommentEntry = `${date} - event (${userId}): status${newStatus.replace(" ", "")}`;

        const updatedComments = [...supportTicket.comments, newCommentEntry];
        const updatedTicket = {
            id: supportTicket.id,
            status: newStatus,
            comments: updatedComments,
        };
        console.log("Updated ticket", updatedTicket)

        try {
            await handleChangeStatus(updatedTicket);
            setCurrentStatus(newStatus);
        } catch (err) {
            console.error("Error changing status", err);
        }

        setIsOpen(false);
    };

    let backgroundColor = "#fff5a6";
    let color = "#ff8300";
    let displayStatus = currentStatus;

    switch (currentStatus) {
        case "Created":
            backgroundColor = "#ffd5dc";
            color = "red";
            break;
        case "Open":
            backgroundColor = "rgb(179, 184, 255)";
            color = "blue";
            break;
        case "Closed":
            backgroundColor = "rgb(210, 210, 210)";
            color = "#3e3e3e";
            break;
        case "In Progress":
            backgroundColor = "rgb(153, 255, 217)";
            color = "#006a42";
            break;
        case "On Hold":
            backgroundColor = "rgb(255, 178, 250)";
            color = "purple";
            break;
        default:
            displayStatus = "Unknown";
    }

    return (
        <div className="statusContainer" style={{ position: 'relative', display: 'inline-block', marginBottom: "7px" }}>
            <span
                className="statusTag"
                style={{ backgroundColor: backgroundColor, color: color, cursor: 'pointer', padding: '4px 8px', borderRadius: '4px', whiteSpace: 'nowrap' }}
                onClick={() => setIsOpen(!isOpen)}
            >
                {displayStatus}
                {isOpen ? <ExpandedCaretIcon /> : <ExpandCaretIcon />}
            </span>
            {isOpen && (
                <div className="dropdown" style={{ position: 'absolute', top: '100%', left: 0, backgroundColor: 'white', boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', borderRadius: '4px', zIndex: 1 }}>
                    {statuses.map((status) => (
                        <div
                            key={status}
                            onClick={() => handleStatusClick(status)}
                            style={{ padding: '8px', cursor: 'pointer', backgroundColor: "white" }}
                            onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = '#f0f0f0')}
                            onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = 'white')}
                        >
                            {status}
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

interface CommentSectionProps {
    supportTicket: any;
    updateSupportTicket: (supportTicket: any) => void;
}

interface ParsedCommentType {
    date: string;
    type: string;
    userId: string;
    payload: string;
}

export const CommentSection: React.FC<CommentSectionProps> = ({ supportTicket, updateSupportTicket }) => {
    const { t } = useTranslation();
    const [newComment, setNewComment] = useState("");
    const [comments, setComments] = useState<ParsedCommentType[]>([]);
    const [users, setUsers] = useState<{ [key: string]: string }>({});
    const [isAddingComment, setIsAddingComment] = useState(false);
    const [comment, setComment] = useState("");
    const [error, setError] = useState("");

    useEffect(() => {
        const parseComment = (comment: any): ParsedCommentType | null => {
            const regex = /^(.*?) - (.*?) \((.*?)\): (.*?)$/;
            const matches = comment.match(regex);

            if (matches) {
                const [, date, type, userId, payload] = matches;
                return { date, type, userId, payload };
            }

            return null;
        };

        const fetchUsers = async (comments: ParsedCommentType[]) => {
            const userIds = comments.map((comment) => comment.userId);
            const uniqueUserIds = Array.from(new Set(userIds));

            const userPromises = uniqueUserIds.map(async (id) => {
                const user = await getUser(id);
                return { id, name: user.firstName + " " + user.lastName };
            });

            const userResults = await Promise.all(userPromises);
            const userMap = userResults.reduce((acc, user) => {
                acc[user.id] = user.name;
                return acc;
            }, {} as { [key: string]: string });

            setUsers(userMap);
        };

        const parsedComments = supportTicket.comments.map(parseComment).filter(Boolean) as ParsedCommentType[];
        setComments(parsedComments);
        fetchUsers(parsedComments);
    }, [supportTicket.comments]);

    const handleCommentChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
        setComment(e.target.value);
        if (e.target.value) {
            setError("");
        }
    };

    const handleCancelComment = () => {
        setIsAddingComment(false);
        setComment("");
    };

    const handleSubmitComment = async () => {
        if (!comment.trim()) {
            setError(t("support.emptyCommentError") as string);
            return;
        }

        try {
            const date = new Date().toISOString().split("T")[0];
            const userId = supportTicket.creatorID;
            const newCommentEntry = `${date} - comment (${userId}): ${comment}`;
            const updatedComments = [...supportTicket.comments, newCommentEntry];

            const updatedTicket = {
                id: supportTicket.id,
                status: supportTicket.status, 
                comments: updatedComments,
            };

            const updatedTicketResponse = await patchSupportTicket(updatedTicket.id, {
                status: updatedTicket.status,
                comments: updatedTicket.comments,
            });

            updateSupportTicket(updatedTicketResponse);
            setIsAddingComment(false);
            setComment("");
        } catch (err) {
            console.error("Error submitting comment", err);
        }
    };

    const formatPayload = (type: string, payload: string): string => {
        const payloadMap: { [key: string]: string } = {
            "ticketCreated": "Ticket created.",
            "statusOpen": "Status changed to Open.",
            "statusClosed": "Status changed to Closed.",
            "statusInProgress": "Status changed to In Progress.",
            "statusOnHold": "Status changed to On Hold.",
        };

        return type === "event" ? payloadMap[payload] || payload : payload;
    };

    return (
        <div className="commentSectionContainer" style={{ textAlign: 'center' }}>
            {isAddingComment ? (
                <div className="addingCommentSection" style={{ marginBottom: '20px' }}>
                    <textarea
                        value={comment}
                        onChange={handleCommentChange}
                        placeholder={t("support.addCommentPlaceholder") as string}
                        style={{ width: '100%', padding: '10px', borderRadius: '4px', border: '1px solid #ccc' }}
                    />
                    {error && <div style={{ color: 'red', marginTop: '5px' }}>{error}</div>}
                    <div className="commentButtons" style={{ marginTop: '10px' }}>
                        <button onClick={handleSubmitComment} style={{ marginRight: '10px' }} disabled={!comment.trim()}>
                            {t("support.submit")}
                        </button>
                        <button onClick={handleCancelComment}>{t("support.cancel")}</button>
                    </div>
                </div>
            ) : (
                <button onClick={() => setIsAddingComment(true)} style={{ marginBottom: '20px', width: "45px", height: "45px", borderRadius: "5px" }}>
                    <AddCommentIcon />
                </button>
            )}

            <div className="commentSection">
                {comments.slice().reverse().map((comment, index) => (
                    <div key={index} className="comment" style={{ display: 'flex', marginBottom: '10px' }}>
                        <div className="commentLeft" style={{ flex: '1', textAlign: 'left' }}>
                            <div className="commentDate" style={{ fontWeight: 'bold' }}>{comment.date}</div>
                            <div className="commentUser" style={{ color: '#555' }}>{users[comment.userId]}</div>
                        </div>
                        <div className="commentRight" style={{ flex: '3', textAlign: 'left', paddingLeft: '10px' }}>
                            <div className="commentBody">{formatPayload(comment.type, comment.payload)}</div>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};