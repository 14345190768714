import { userType } from "./../../store/user/types";
import rest from "./../../rest";
import { getCompany } from "./companies";

export const getUsers = () => {
  return new Promise<userType[]>(async (resolve, reject) => {
    rest
      .get("/users")
      .then(async (response) => {
        try {
          const users: userType[] = response.data.list;
          resolve(users);
        } catch (reason) {
          const err = `Failed to fetch users from server: ${reason}`;
          console.error(err);
          reject(err);
        }
      })
      .catch((error) => {
        const err = `Failed to fetch users from server: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};
export const getUser = (userID: string) => {
  return new Promise<userType>(async (resolve, reject) => {
    rest
      .get(`users/${userID}`)
      .then(async (response) => {
        try {
          const user: any = response.data;
          const company = await getCompany(response.data.companyID);

          resolve({
            companyID: company.id,
            email: user.email,
            firstName: user.firstName,
            lastName: user.lastName,
            roles: user.roles, //TODO:: Temp sol. Implement role type and policies.
            syncedAt: user.synced,
            updatedAt: user.updated,
            createdAt: user.createdAt,
            phone: user.phone,
            sentToServer: true,
            id: user.id,
          });
        } catch (reason) {
          const err = `Failed to fetch user from server: ${reason}`;
          console.error(err);
          reject(err);
        }
      })
      .catch((error) => {
        const err = `Failed to fetch user from server: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};

export const patchUser = (
  userID: string,
  dataToPatch: {
    firstName?: string;
    lastName?: string;
    email?: string;
    phone?: string;
  }
) => {
  return new Promise<userType>(async (resolve, reject) => {
    rest
      .patch(
        "/users/" + userID,
        {
          email: dataToPatch.email,
          firstName: dataToPatch.firstName,
          lastName: dataToPatch.lastName,
          phone: dataToPatch.phone,
        },
        { withCredentials: true }
      )
      .then((result) => {
        const userUpdate: userType = result.data;

        resolve(userUpdate);
        return userUpdate;
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const resendUserInvite = (userID: string) => {
  return new Promise<userType>(async (resolve, reject) => {
    rest
      .post("/users/" + userID + "/resendInvite", { withCredentials: true })
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
