import React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSpinner} from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

export const Spinner = () => (
    <div className="loading-container">
        <FontAwesomeIcon icon={faSpinner} size="6x" spin/>
    </div>
);
