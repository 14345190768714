import React, { useState, useEffect } from "react";
import "./notificationComponent.css";
import { reduxSetNotification } from "../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { appState } from "../../store";
import {
  Notification,
  NotificationGroup,
} from "@progress/kendo-react-notification";
import { Fade } from "@progress/kendo-react-animation";
import { notificationType } from "../../store/user/types";

export const NotificationComponent: React.FunctionComponent = () => {
  const notification = useSelector(
    (state: appState) => state.user.notification
  );

  const [timer, setTimerState] = useState<ReturnType<typeof setTimeout>>();
  const [newMessage, setNewMessage] = useState(false);
  const [notificationQuee, setNotificationQuee] = useState<notificationType[]>(
    []
  );
  const [currentNotification, setCurrentNotification] = useState<
    notificationType | undefined
  >();

  useEffect(() => {
    // New notification. Add to queue.
    if (notification.message !== "" && newMessage) {
      setNewMessage(false);
      setNotificationQuee([...notificationQuee, notification]);
    }
  }, [notification, notificationQuee, newMessage]);

  useEffect(() => {
    if (notification.open) setNewMessage(true);
  }, [notification]);

  useEffect(() => {
    if (currentNotification === undefined && notificationQuee.length > 0) {
      const newNotification = notificationQuee[0];
      setCurrentNotification(newNotification);
      if (notificationQuee.length > 1) {
        setNotificationQuee([...notificationQuee.slice(1)]);
      } else {
        setNotificationQuee([]);
        dispatch(
          reduxSetNotification({ message: "", open: false, style: "success" })
        );
      }
      setTimerState(
        setTimeout(
          () => {
            setCurrentNotification(undefined);
          },
          notification.style === "error" ? 5000 : 3000
        )
      );
    }
  }, [notificationQuee, currentNotification]);

  const dispatch = useDispatch();

  return (
    <div
      style={{
        width: "100vw",
        top: 0,
        right: 10,
        display: "flex",
        position: "absolute",
        justifyContent: "flex-end",
        pointerEvents: "none",
        maxWidth: "var(--max-width)",
        zIndex: 1000000,
      }}
    >
      <NotificationGroup
        style={{
          display: "flex",
          alignItems: "flex-end",
          flexWrap: "wrap-reverse",
          pointerEvents: "auto",
        }}
      >
        <Fade enter={true} exit={true}>
          {currentNotification && (
            <Notification
              type={{ style: currentNotification.style, icon: true }}
              closable={true}
              onClose={() => {
                if (timer) clearTimeout(timer);
                setCurrentNotification(undefined);
              }}
            >
              <span>
                {currentNotification.message +
                  ` (${notificationQuee.length + 1})`}
              </span>
            </Notification>
          )}
        </Fade>
      </NotificationGroup>
    </div>
  );
};
