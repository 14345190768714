import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cPhoneWidthLim } from "../../app";
import i18n from "../../i18n";
import { appState } from "../../store";
import { reduxSetExpandedMenu } from "../../store/scheduler/actions";
import { reduxSetSelectedLanguage } from "../../store/user/actions";
import { languageType } from "../../store/user/types";
import "./style.scss";

interface ToolbarProps {
  children?: React.ReactNode;
}

export const Toolbar: React.FunctionComponent<ToolbarProps> = ({
  children,
}) => {
  const [menuExtended, setMenuExtended] = useState(false);
  const dispatch = useDispatch();

  const onMenuClick = () => {
    setMenuExtended(!menuExtended);
  };

  const width = useSelector((state: appState) => state.scheduler.screenWidth);
  const height = useSelector((state: appState) => state.scheduler.screenHeight);
  const announcementsCount = useSelector(
    (state: appState) => state.user.announcementsCount
  );

  const expandMenu = useSelector(
    (state: appState) => state.scheduler.expandMenu
  );

  return (
    <div className="toolbar-container">
      {(width <= cPhoneWidthLim || height <= cPhoneWidthLim) && !expandMenu ? (
        <>
          <img
            src={"/icons/menu.svg"}
            alt=""
            onClick={() => {
              onMenuClick();
              dispatch(reduxSetExpandedMenu(!expandMenu));
            }}
          />
          <div style={{ position: "relative" }}>
            {announcementsCount > 0 ? (
              <div className="numberCircleAnnouncements">
                {announcementsCount}
              </div>
            ) : null}
          </div>
        </>
      ) : (
        <div></div>
      )}
      <div className="toolbar-body">{children}</div>
    </div>
  );
};
