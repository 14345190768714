import {
  companyActionType,
  setAllCompanies,
  deleteCompany,
  setSelectedCompany,
  companyType,
  setCurrentCompany,
} from "./types";
import { getNewCompany } from "./../../development/initializer";
import rest from "../../rest";
import { appState } from "..";
import {
  reduxSetAuthenticationSuccess,
  reduxSetNotification,
} from "../user/actions";
import { TFunction } from "i18next";

// action creators
export function reduxSetAllCompanies(
  allCompanies: companyType[]
): setAllCompanies {
  return {
    type: companyActionType.setAllCompanies,
    payload: allCompanies,
  };
}

export function reduxSetCurrentCompany(
  currentCompany: companyType
): setCurrentCompany {
  return {
    type: companyActionType.setCurrentCompany,
    payload: currentCompany,
  };
}

export function reduxSetSelectedCompany(companyID: string): setSelectedCompany {
  return {
    type: companyActionType.setSelectedCompany,
    payload: companyID,
  };
}

export function reduxDeleteCompany(companyID: string): deleteCompany {
  return {
    type: companyActionType.deleteCompany,
    payload: companyID,
  };
}

export function reduxUpdateCompanyProperty<T extends keyof companyType>(
  companyProperty: Pick<companyType, T>
) {
  return {
    type: companyActionType.updateCompanyProperty,
    payload: companyProperty,
  };
}

export function setCompanyHasChanged(companyHasChanged: boolean) {
  return {
    type: companyActionType.setCompanyHasChanged,
    payload: companyHasChanged,
  };
}

export function addNewReduxCompany(company: companyType) {
  return {
    type: companyActionType.addNewReduxCompany,
    payload: company,
  };
}

export const removeCompany = (
  t: TFunction<"translation", undefined, "translation">,
  companyID: string
) => {
  return (dispatch: any) => {
    rest
      .delete("/companies/" + companyID, { withCredentials: true })
      .then((response) => {
        dispatch(reduxDeleteCompany(companyID));

        dispatch(
          reduxSetNotification({
            style: "success",
            message: t("store.company.deletedCompany"),
            open: true,
          })
        );
      })
      .catch(() => {
        console.error("Failed to delete company with companyID ", companyID);
        dispatch(
          reduxSetNotification({
            style: "error",
            message: t("store.company.deletedCompanyError"),
            open: true,
          })
        );
      });
  };
};

export function addNewCompany() {
  return (dispatch: any, getState: () => appState) => {
    const { user } = getState();
    const userID = user.currentUser ? user.currentUser.id : "";
    const newCompany = getNewCompany(userID);
    dispatch(addNewReduxCompany(newCompany));
  };
}

export const saveCompanyToServer = (
  t: TFunction<"translation", undefined, "translation">,
  name: string,
  type: string,
  description: string,
  companyID?: string,
  sentToServer?: boolean
) => {
  return (dispatch: any, getState: () => appState) => {
    if (!sentToServer) {
      rest
        .post(
          "/companies",
          {
            name: name,
            description: description,
            type: type,
          },
          { withCredentials: true }
        )
        .then((response) => {
          const companyID = response.data.id;
          if (companyID.length !== 0) {
            rest
              .get("/companies", { withCredentials: true })
              .then((response) => {
                const allFetchedCompanies = response.data.list.map(
                  (element: companyType) => {
                    return { ...element, sentToServer: true };
                  }
                );
                dispatch(reduxSetAllCompanies(allFetchedCompanies));
              })
              .catch((error) => {
                console.log(error);
                dispatch(setCompanyHasChanged(true));
              });
            dispatch(reduxSetSelectedCompany(companyID));
          }
          dispatch(setCompanyHasChanged(false));
          dispatch(reduxSetAuthenticationSuccess(true));
          dispatch(
            reduxSetNotification({
              style: "success",
              message: t("store.company.companyAdded"),
              open: true,
            })
          );
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            alert(t("store.networkError"));
          } else if (error.message === "Request failed with status code 500") {
            dispatch(
              reduxSetNotification({
                style: "error",
                message: t("store.company.companyExists"),
                open: true,
              })
            );
          } else {
            alert(t("statusProgress.unexpectedError"));
          }
          dispatch(setCompanyHasChanged(true));
          dispatch(reduxSetAuthenticationSuccess(false));
        });
    } else if (sentToServer && companyID) {
      rest
        .patch(
          "/companies/" + companyID,
          {
            name: name,
            description: description,
            type: type,
          },
          { withCredentials: true }
        )
        .then((response) => {
          dispatch(
            reduxUpdateCompanyProperty({
              id: companyID,
              name: name,
              description: description,
              type: type,
            })
          );
          dispatch(setCompanyHasChanged(false));
          dispatch(
            reduxSetNotification({
              style: "success",
              message: t("store.company.companyUpdated"),
              open: true,
            })
          );
        })
        .catch((error) => {
          dispatch(setCompanyHasChanged(true));
          if (error.message === "Network Error") {
            alert(t("store.networkError"));
          } else {
            dispatch(
              reduxSetNotification({
                style: "warning",
                message: t("store.company.companyExists"),
                open: true,
              })
            );
          }
          console.log("error", error);
        });
    }
  };
};
