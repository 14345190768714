import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getMeasurementMetaData } from "../../api/netRail/measurements";
import { appState } from "../../store";
import {
  reduxSetDataPoints,
  reduxSetDataPointsLongitudinalLevel,
  reduxSetInitialWindowPosition,
  reduxSetLongitudinalLevelWindow,
  reduxSetMainXScale,
  reduxSetSelectedChart,
  reduxSetWindowXPosition,
} from "../../store/plot/actions";
import { dataPointType, GroupChartDataProps } from "../../store/plot/types";
import {
  ChartContainer,
  ChartDimensionProps,
} from "../generic/chartContainer/chartContainer";
import { fetchInitialData } from "../generic/toogleMeasurements/toogleMeasurements";
import { ToogleMeasurementsLongitudinalLevel } from "../generic/toogleMeasurements/toogleMeasurementsLongitudinalLevel";
import { ToogleTolerances } from "../generic/toogleTolerances/toggleTolerances";
import { Card } from "./../generic/card/card";
import "./groupChartContainer.css";
import { GroupChartLongitudinalLevel } from "./groupChartLongitudinalLevel";
import { cPhoneWidthLim } from "../../app";
import { leftAxisWidth } from "./groupChart";
import { scaleLinear } from "d3";

interface GroupChartContainerLongitudinalLevelProps {
  chartID: string;
  mainView: React.MutableRefObject<boolean>;
  finishedLoading: boolean;
  setFinishedLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const emptyDataSet: GroupChartDataProps = {
  trackGauge: [] as dataPointType[],
  crossLevelBIS: [] as dataPointType[],
  crossLevel: [] as dataPointType[],
  crossLevelUnevenness: [] as dataPointType[],
  twist3m: [] as dataPointType[],
  twist6m: [] as dataPointType[],
  alignment: [] as dataPointType[],
  longitudinalLevel: [] as dataPointType[],
  alignmentLeft: [] as dataPointType[],
  longitudinalLevelLeft: [] as dataPointType[],
  alignmentRight: [] as dataPointType[],
  longitudinalLevelRight: [] as dataPointType[],
  longitudinalAngleHP: [] as dataPointType[],
};

export const GroupChartContainerLongitudinalLevel: React.FunctionComponent<
  GroupChartContainerLongitudinalLevelProps
> = ({ chartID, mainView, finishedLoading, setFinishedLoading }) => {
  const [chartDimensions, setChartDimensions] = useState<
    ChartDimensionProps | undefined
  >(undefined);

  const data = useSelector((state: appState) =>
    state.plot.specialCharts.specialChartArray.find(
      (chart: any) => chart.chartID === chartID
    )
  )?.plotData;
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const allChartsSpecial = useSelector(
    (state: appState) => state.plot.specialCharts.specialChartArray
  );

  const maxWindowSize = useSelector(
    (state: appState) => state.plot.specialCharts.maxWindowSize
  );

  const allCharts = useSelector((state: appState) => state.plot.charts);

  const screenWidth = useSelector(
    (state: appState) => state.scheduler.screenWidth
  );
  const height = useSelector((state: appState) => state.scheduler.screenHeight);

  // const height = useSelector((state: appState) => state.scheduler.screenHeight);

  const triggerRerender = height >= 450 ? true : false;

  const longitudinalAngleChartID = useSelector(
    (state: appState) => state.plot.specialCharts.longitudinalAngleChartID
  );
  const longitudinalLevelChartID = useSelector(
    (state: appState) => state.plot.specialCharts.longitudinalLevelChartID
  );

  const signalInterval = useSelector(
    (state: appState) => state.plot.globalSignalInterval
  );

  const selectedMeasurement = useSelector(
    (state: appState) => state.scheduler.selectedMeasurement
  );

  const graphCharts = allCharts.filter((chart) => chart.chartID !== "999999");
  const allChartsArray = [allChartsSpecial, graphCharts];
  return (
    <Card>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          maxHeight: "40px",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          overflow: "visible",
        }}
      >
        {chartID === longitudinalAngleChartID ? null : (
          <ToogleTolerances chartID={chartID} />
        )}
        <div style={{ display: "flex", flexDirection: "row" }}>
          {chartID === longitudinalAngleChartID ? (
            <h5
              style={{
                paddingBottom: "5px",
                // paddingLeft: screenWidth >= cPhoneWidthLim ? "40px" : "10px",
              }}
            >
              {t("currentLongitudinalLevel.bodyAngle")}
            </h5>
          ) : (
            <div />
          )}
          <span
            className="k-icon k-i-home removeChart"
            style={
              chartID === longitudinalAngleChartID
                ? {
                    fontSize: "24px",
                    marginRight: "20px",
                    marginLeft: chartDimensions
                      ? chartDimensions?.width - 90
                      : 30,
                  }
                : { fontSize: "24px", marginRight: "20px" }
            }
            onClick={async () => {
              const setsToPlot: GroupChartDataProps = {
                trackGauge: [] as dataPointType[],
                crossLevelBIS: [] as dataPointType[],
                crossLevel: [] as dataPointType[],
                crossLevelUnevenness: [] as dataPointType[],
                twist3m: [] as dataPointType[],
                twist6m: [] as dataPointType[],
                alignment: [] as dataPointType[],
                longitudinalLevel: [] as dataPointType[],
                alignmentLeft: [] as dataPointType[],
                longitudinalLevelLeft: [] as dataPointType[],
                alignmentRight: [] as dataPointType[],
                longitudinalLevelRight: [] as dataPointType[],
                longitudinalAngleHP: [] as dataPointType[],
              };

              setFinishedLoading(false);
              for (const i in allChartsArray) {
                // Set for every chart
                const charts = allChartsArray[i];
                for (const i in charts) {
                  const chart = charts[i];
                  // To update one chart set the selected chart to the current looped one, this will be overwritten later when user do any action.
                  dispatch(reduxSetSelectedChart(chart.chartID)); // Make sure that the chart we want to use is activated

                  for (const i in chart.measurementToDisplay) {
                    const key = chart.measurementToDisplay[
                      i
                    ] as keyof GroupChartDataProps;

                    const initialData = await fetchInitialData(
                      dispatch,
                      chart.measurementToDisplay,
                      screenWidth,
                      key,
                      signalInterval,
                      selectedMeasurement,
                      true // reset
                    );
                    setsToPlot[key] = initialData;
                  }
                  if (
                    chart.chartID === longitudinalLevelChartID ||
                    chart.chartID === longitudinalAngleChartID
                  ) {
                    dispatch(reduxSetDataPointsLongitudinalLevel(setsToPlot));
                  } else {
                    dispatch(reduxSetDataPoints(setsToPlot));
                  }
                }
              }

              const plotMetaData = await getMeasurementMetaData(
                "longitudinalLevel",
                selectedMeasurement
              );
              const xMin = plotMetaData.startKm * 1000 + plotMetaData.startMeter;
              const xMax = plotMetaData.endKm * 1000 + plotMetaData.endMeter;

              if (xMax - xMin <= 30) {
                dispatch(
                  reduxSetLongitudinalLevelWindow(
                    Number(((xMax - xMin) * 0.5).toPrecision(3))
                  )
                );
              } else {
                dispatch(reduxSetLongitudinalLevelWindow(maxWindowSize));
              }
              dispatch(reduxSetInitialWindowPosition(true));
              dispatch(reduxSetWindowXPosition(70));

              if (chartDimensions) {
                const xScale = scaleLinear()
                  .domain([signalInterval.xMinOrig, signalInterval.xMaxOrig])
                  .range([leftAxisWidth, chartDimensions.width]);

                dispatch(reduxSetMainXScale(xScale));
              }
              mainView.current = true;

              setFinishedLoading(true);
            }}
          />
        </div>
      </div>

      <div className="GroupChartContainer">
        <ChartContainer
          chartDimensions={chartDimensions}
          setChartDimensions={setChartDimensions}
          rerenderEvent={triggerRerender}
        >
          <GroupChartLongitudinalLevel
            chartDimensions={chartDimensions}
            data={data ? data : emptyDataSet}
            chartID={chartID}
            mainView={mainView}
            finishedLoading={finishedLoading}
            setFinishedLoading={setFinishedLoading}
          />
        </ChartContainer>
      </div>
      {chartID === longitudinalAngleChartID ? null : (
        <ToogleMeasurementsLongitudinalLevel
          chartID={chartID}
          chartDimensions={chartDimensions}
        />
      )}
    </Card>
  );
};
