import { machineType } from "../machine/types";

export enum companyActionType {
  setAllCompanies = "SET_ALL_COMPANIES",
  setSelectedCompany = "SET_SELECTED_COMPANIES",
  setCompanyHasChanged = "SET_COMPANY_HAS_CHANGED",
  deleteCompany = "REMOVE_COMPANY",
  addNewReduxCompany = "ADD_NEW_REDUX_COMPANY",
  updateCompanyProperty = "UPDATE_COMPANY_PROPERTY",
  setCurrentCompany = "SET_CURRENT_COMPANY",
}

export interface addNewReduxCompany {
  type: typeof companyActionType.addNewReduxCompany;
  payload: companyType;
}

export interface setAllCompanies {
  type: typeof companyActionType.setAllCompanies;
  payload: companyType[];
}

export interface setCurrentCompany {
  type: typeof companyActionType.setCurrentCompany;
  payload: companyType;
}

export interface deleteCompany {
  type: typeof companyActionType.deleteCompany;
  payload: string;
}

export interface setSelectedCompany {
  type: typeof companyActionType.setSelectedCompany;
  payload: companyType["id"];
}

export interface setCompanyHasChanged {
  type: typeof companyActionType.setCompanyHasChanged;
  payload: boolean;
}

export interface updateCompanyProperty<T extends keyof companyType> {
  type: typeof companyActionType.updateCompanyProperty;
  payload: Pick<companyType, T>;
}

export type companyAction =
  | addNewReduxCompany
  | setAllCompanies
  | deleteCompany
  | setSelectedCompany
  | setCompanyHasChanged
  | updateCompanyProperty<keyof companyType>
  | setCurrentCompany;

export interface companyState {
  currentCompany?: companyType;
  allCompanies: companyType[];
  selectedCompany: companyType["id"];
  companyHasChanged: boolean;
}

export interface companyType {
  id: string;
  name: string;
  description: string;
  type: string;
  sentToServer?: boolean;
  createdAt: string;
  machines: machineType[];
  updatedAt: string;
}
