import * as React from "react";
const SvgAnalysisGraph = (props: any) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    id="AnalysisGraph_svg__Layer_1"
    data-name="Layer 1"
    viewBox="0 0 31.76 24.11"
    {...props}
  >
    <defs>
      <style>
        {
          ".AnalysisGraph_svg__cls-1{fill:none;stroke:var(--primary-color);stroke-miterlimit:10;stroke-width:3px}"
        }
      </style>
    </defs>
    <path
      d="M0 16.75h6.65l4.94 5.86h8.37l4.42-2h7.38M15.88 12.82V0"
      className="AnalysisGraph_svg__cls-1"
    />
    <path
      d="m19.47 11.77-3.59 6.22-3.59-6.22h7.18z"
      style={{
        fill: "var(--primary-color)",
        strokeWidth: 0,
      }}
    />
  </svg>
);
export default SvgAnalysisGraph;
