import { companyType } from "./../../store/company/types";
import rest from "./../../rest";

export const getCompanies = () => {
  return new Promise<companyType[]>(async (resolve, reject) => {
    rest
      .get("/companies")
      .then(async (response) => {
        const companies: any = response.data.list.map((company: any) => {
          const ret: companyType = {
            id: company.id,
            name: company.name,
            type: company.type,
            description: company.description,
            createdAt: company.createdAt,
            machines: company.machines,
            updatedAt: company.updatedAt,
          };
          return ret;
        });
        getCompany(companies[0].companyID);
        resolve(companies);
      })
      .catch((error) => {
        const err = `Failed to fetch companies from server: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};

export const getCompany = (companyID: string) => {
  return new Promise<companyType>(async (resolve, reject) => {
    rest
      .get(`/companies/${companyID}`)
      .then(async (response) => {
        const company: companyType = {
          id: response.data.id,
          name: response.data.name,
          type: response.data.type,
          description: response.data.description,
          createdAt: response.data.createdAt,
          machines: response.data.machines,
          updatedAt: response.data.updatedAt,
        };
        resolve(company);
      })
      .catch((error) => {
        const err = `Failed to fetch company from server: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};
