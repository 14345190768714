import React, { useEffect, useState } from "react";
import {
  Form,
  FormRenderProps,
  FormElement,
  Field,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
// Redux Stuff
import { reduxSetAuthenticationSuccess } from "../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { appState } from "../../store";
import { Popup } from "@progress/kendo-react-popup";

import { CompanyForm } from "./companyForm";
import { companyType } from "../../store/company/types";
import {
  reduxSetSelectedCompany,
  removeCompany,
  setCompanyHasChanged,
} from "../../store/company/actions";
import { useTranslation } from "react-i18next";

interface companyListItemProps {
  company: companyType;
  edit: boolean;
  selectedCompany: companyType["id"];
  setNewCompanyAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CompanyListItem: React.FC<companyListItemProps> = ({
  company,
  edit,
  selectedCompany,
  setNewCompanyAdded,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [trash, setTrash] = useState(false);
  const [show, setShow] = useState(false);

  const authenticationSuccess = useSelector(
    (state: appState) => state.user.authenticationSuccess
  );

  useEffect(() => {
    if (!company?.sentToServer) {
      edit = true;
    }
  }, [selectedCompany]);

  const renderDeleteCompanyForm = (formRenderProps: FormRenderProps) => {
    return (
      <FormElement>
        <div className="deletePlanPwField">
          <Field
            name={"radera"}
            placeholder={t("common.delete")}
            component={Input}
            label={t("measurementListItem.confirmDelete")}
          />
          <div style={{ color: "red" }}>
            {authenticationSuccess ? "" : t("common.deleteValidator")}
          </div>
        </div>
        <div className="DeletePlanButtonContainer">
          <button
            className="PopupButton BUTTON"
            onClick={(e) => {
              e.preventDefault();
              setShow(!show);
            }}
          >
            {t("common.cancel")}
          </button>{" "}
          <button
            type={"submit"}
            className="PopupButton BUTTON"
            disabled={formRenderProps.valueGetter("radera") !== "radera"}
            style={{
              opacity:
                formRenderProps.valueGetter("radera") !== "radera" ? 0.5 : 1,
            }}
          >
            {t("common.delete")}
          </button>
        </div>
      </FormElement>
    );
  };

  // NOT DISMOUNTED PROPERLY
  const renderCompanyContent = () => {
    return edit && !trash ? (
      <CompanyForm setNewCompanyAdded={setNewCompanyAdded} />
    ) : (
      <div className="userCardUser">
        <div className="userCardColumnL">
          <h5 className="userCardRow">
            {company.name !== "" ? company.name : "-"}
          </h5>
          <div className="userCardRow">
            {company.description !== "" ? company.description : "-"}
          </div>
        </div>

        <div className="userCardColumnR">
          <div
            style={{
              marginLeft: "auto",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <span
              className="k-icon k-i-change-manually removeChart"
              style={{
                fontSize: "24px",
              }}
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                dispatch(setCompanyHasChanged(false));
                dispatch(reduxSetSelectedCompany(company.id));
                setTrash(false);
              }}
            />
            <div
              style={{
                marginRight: "10px",
              }}
            />

            <Popup
              show={show}
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "rgba(120, 120, 120, 0.5)",
              }}
            >
              <div className="PopupContentShare">
                <p style={{ fontWeight: "bold" }}>
                  {t("manageCompanies.deleteCompany")}
                </p>
                <Form
                  initialValues={{ deletePlan: "" }}
                  onSubmit={(dataItem) => {
                    if (company && dataItem.radera === "radera") {
                      dispatch(reduxSetAuthenticationSuccess(true));
                      if (selectedCompany === company?.id) {
                        dispatch(removeCompany(t, company.id));

                        setShow(!show);
                      }
                    } else {
                      dispatch(reduxSetAuthenticationSuccess(false));
                    }
                  }}
                  render={(formRenderProps) =>
                    renderDeleteCompanyForm(formRenderProps)
                  }
                />
              </div>
            </Popup>
            <div
              style={{
                marginRight: "10px",
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        border:
          selectedCompany === company?.id
            ? "solid var(--primary-color,#782441)"
            : "",
      }}
    >
      {renderCompanyContent()}
    </div>
  );
};
