import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./style.scss";

import { AdminMessageCard } from "../../components/adminMessage/adminMessageCard";
import { AdminMessageFilter } from "../../components/adminMessage/adminMessageFilter";

import { Toolbar } from "../../components/toolbar";
import { appState } from "../../store";
import { adminMessageType } from "../../store/user/types";
import { addNewMessage } from "../../store/user/actions";
import { getNewMessage } from "../../development/initializer";
import { cPhoneWidthLim } from "../../app";
import { useTranslation } from "react-i18next";

export const Announcements: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const messages = useSelector((state: appState) => state.user.messages);
  const width = useSelector((state: appState) => state.scheduler.screenWidth);
  const height = useSelector((state: appState) => state.scheduler.screenHeight);

  return (
    <div
      className="AnnouncementsContainer"
      style={
        width <= cPhoneWidthLim || height <= cPhoneWidthLim
          ? {}
          : { position: "relative", left: "44px", paddingRight: "44px" }
      }
    >
      <div>
        <Toolbar>
          <h5>{t("menu.adminMessagePageName")}</h5>
        </Toolbar>
      </div>
      <div className="AnnouncementsWorkSpace">
        <AdminMessageFilter />
        <img
          style={{ padding: "10px" }}
          src="./icons/plus.svg"
          alt=""
          onClick={() => {
            const newMessage = getNewMessage();
            dispatch(addNewMessage(newMessage));
          }}
        />
        {messages.map((message: adminMessageType) => (
          <AdminMessageCard message={message} key={message.id} />
        ))}
      </div>
    </div>
  );
};
