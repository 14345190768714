import React, { useState, useEffect } from "react";
import "./style.scss";

import { Toolbar } from "../../components/toolbar";

import { CompanyList } from "../../components/manageCompanies/companyList";

// Redux Stuff
import {
  addNewCompany,
  reduxSetSelectedCompany,
} from "./../../store/company/actions";
import { useDispatch, useSelector } from "react-redux";
import { appState } from "../../store";

import { Input } from "@progress/kendo-react-inputs";
import { cPhoneWidthLim } from "../../app";
import { useTranslation } from "react-i18next";

export const ManageCompanies: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allCompanies = useSelector(
    (state: appState) => state.company.allCompanies
  );
  const width = useSelector((state: appState) => state.scheduler.screenWidth);
  const height = useSelector((state: appState) => state.scheduler.screenHeight);

  const [companyListEmpty, setCompanyListEmpty] = useState<boolean>(false);

  const [filteredCompaniesArray, setFilteredList] = useState(allCompanies);
  const [filter, setFilter] = useState<string>("");
  const [newCompany, setNewCompany] = useState(false);
  const [newCompanyAdded, setNewCompanyAdded] = useState<boolean>(true);

  useEffect(() => {
    if (allCompanies.length !== 0) {
      setCompanyListEmpty(true);
      setFilteredList(
        filter !== ""
          ? allCompanies.filter((company) => {
              if (
                company.name
                  .toLocaleLowerCase()
                  .includes(filter.toLocaleLowerCase())
              ) {
                return true;
              }
              return false;
            })
          : allCompanies
      );
    }
  }, [allCompanies, filter, setFilteredList]);

  useEffect(() => {
    dispatch(reduxSetSelectedCompany(""));
  }, [dispatch]);

  return (
    <div
      className="ManageUsersContainer"
      style={
        width <= cPhoneWidthLim || height <= cPhoneWidthLim
          ? {}
          : { position: "relative", left: "44px", paddingRight: "44px" }
      }
    >
      <header className="ManageUsersToolbar">
        <Toolbar>
          <h5>{t("menu.companyPageName")}</h5>
        </Toolbar>
      </header>
      <div className="ManageUsersMain">
        <div className="manageUserList">
          <div className="centerCard">
            <div
              className="userListCardHeader"
              style={{ paddingBottom: "10px" }}
            >
              <Input
                className="autoComplete"
                label={t("manageCompanies.searchCompany").toString()}
                style={{ width: "100%" }}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              />
            </div>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                paddingTop: "10px",
                paddingBottom: "10px",
                justifyContent: "center",
              }}
            >
              <button
                className="addUserHeader"
                onClick={(e) => {
                  e.preventDefault();
                  dispatch(addNewCompany()); // Initialize company
                  setNewCompany(!newCompany);
                  setNewCompanyAdded(false);
                }}
                disabled={!newCompanyAdded}
                style={
                  !newCompanyAdded
                    ? {
                        opacity: 0.3,
                      }
                    : {}
                }
              >
                <img src="./icons/plus.svg" alt="" className="AddPlanImage" />
              </button>
            </div>
          </div>
          <div className="userList">
            {companyListEmpty ? (
              <CompanyList
                filteredCompanyArray={filteredCompaniesArray}
                newCompany={newCompany}
                setNewCompanyAdded={setNewCompanyAdded}
              />
            ) : (
              <div className="userListWrapper">
                <h5 style={{ paddingTop: "50px" }}>
                  {t("manageCompanies.createCompany")}
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
