import React from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { cPhoneWidthLim } from "../../../app";
import { appState } from "../../../store";
import {
  addLongitudinalLevelChart,
  addNewChart,
  reduxSetDataPoints,
  reduxSetDataPointsLongitudinalLevel,
  reduxSetInitialWindowPosition,
  reduxSetLongitudinalLevelToDisplay,
  reduxSetSelectedChart,
  reduxSetViewSpecialCharts,
  reduxSetWindowXPosition,
} from "../../../store/plot/actions";
import { dataPointType, GroupChartDataProps } from "../../../store/plot/types";
import { reduxSetNotification } from "../../../store/user/actions";
import { fetchInitialData } from "../toogleMeasurements/toogleMeasurements";

interface AddNewChartProps {
  mainView: React.MutableRefObject<boolean>;
}

export const AddNewChart: React.FunctionComponent<AddNewChartProps> = ({
  mainView,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const width = useSelector((state: appState) => state.scheduler.screenWidth);

  return (
    <div
      style={{
        display: "flex",
        position: "relative",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        paddingBottom: "20px",
        paddingTop: "20px",
      }}
    >
      <img
        src="./icons/plus.svg"
        alt=""
        onClick={() => {
          const newChart = uuidv4();
          dispatch(addNewChart(newChart));
          dispatch(reduxSetSelectedChart(newChart)); // Set the new chart as the selected one
        }}
      />
    </div>
  );
};
