import React, { useRef } from "react";
import Scrollbars from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { cPhoneWidthLim } from "../../app";
import {
  convertToHmiStatus,
  setLastVisitedPlanAndMeasurement,
} from "../../helpers/genericHelpers";
import { appState } from "../../store";
import {
  setSelectedMeasurement,
  setSelectedPlan,
} from "../../store/scheduler/actions";
import {
  measurementProps,
  measurementStatus,
  planProps,
} from "../../store/scheduler/types";
import { customThumb } from "../manageUsers/userList";
import MeasurementListItem from "../scheduler/plan/measurementList/measurementListItem";
import { measurementStatusHMI } from "../scheduler/plan/measurementList/statusProgress";
import "./filteringContent.css";
import SideBarListHeader from "./sideBarHeader";
import PlanList from "../scheduler/planList/planList";
import { reduxSetObjectsToDisplay } from "../../store/plot/actions";
import { objectTypes } from "../../store/plot/types";

interface FilteringContentProps {
  height: number;
  width: number;
  setShow: React.Dispatch<React.SetStateAction<boolean>>;
}

export const FilteringContent: React.FunctionComponent<
  FilteringContentProps
> = ({ height, width, setShow }) => {
  const dispatch = useDispatch();

  const parentWidthRef = useRef<any>();
  const allPlans = useSelector((state: appState) => state.scheduler.plans);
  const selectedMeasurement = useSelector(
    (state: appState) => state.scheduler.selectedMeasurement
  );
  const selectedPlan = useSelector(
    (state: appState) => state.scheduler.selectedPlan
  );

  const locallySelectedMeasurement = useRef(selectedMeasurement);
  const locallySelectedPlan = useRef(selectedPlan);

  let noPlanSelected = false;
  if (selectedPlan === "-1") {
    noPlanSelected = true;
  }

  const currPlan = allPlans.find((plan) => plan.id === selectedPlan);

  const selectPlanContent = () => {
    return (
      <div
        className={
          width <= cPhoneWidthLim || height <= cPhoneWidthLim
            ? "FilteringContentMobile"
            : "FilteringContent"
        }
        style={{
          flexDirection: height >= 450 ? "column" : "row",
          paddingTop: "60px",
          paddingBottom: "120px",
        }}
      >
        <PlanList onlySelectPlan={true} />
      </div>
    );
  };

  const selectMeasurementContent = () => {
    return (
      <div
        className={
          width <= cPhoneWidthLim || height <= cPhoneWidthLim
            ? "FilteringContentMobile"
            : "FilteringContent"
        }
        style={{
          flexDirection: height >= 450 ? "column" : "row",
          paddingTop: "60px",
        }}
      >
        {currPlan ? <SideBarListHeader plan={currPlan} /> : null}
        <Scrollbars
          thumbSize={60}
          renderThumbVertical={customThumb}
          autoHide={true}
          autoHideTimeout={1000}
          autoHideDuration={500}
        >
          <div
            className="MeasurmentList"
            style={{
              overflowY: "auto",
              height: "stretch",
              paddingTop: "30px",
              paddingBottom: "150px",
              // marginRight: "5px", // 17px compensates for <Scrollbars>
              fontSize: "small",
              fontWeight: "bold",
            }}
          >
            {currPlan?.measurements.map((meas) => measurementRow(meas))}
          </div>
        </Scrollbars>
      </div>
    );
  };

  const measurementRow = (measurement: measurementProps) => {
    const measurementNumber = currPlan
      ? currPlan?.measurements.length -
        currPlan?.measurements.findIndex((m) => m.id === measurement.id)
      : 0;

    return (
      <div
        onClick={(e) => {
          locallySelectedMeasurement.current = measurement.id;
          locallySelectedPlan.current = measurement.planID;
        }}
        onDoubleClick={() => {
          // check if measurement is done, if so allow selection by
          if (
            measurement.status === measurementStatus.WritingToDynamodbDone ||
            measurement.status === measurementStatus.CreatingExcel ||
            measurement.status === measurementStatus.CreatingExcelDone ||
            measurement.status === measurementStatus.SendingEmail ||
            measurement.status === measurementStatus.SendingEmailDone
          ) {
            dispatch(setSelectedPlan(locallySelectedPlan.current));
            dispatch(setSelectedMeasurement(measurement.id));
            setLastVisitedPlanAndMeasurement(
              locallySelectedPlan.current,
              locallySelectedMeasurement.current
            );
            setShow(false);

            dispatch(
              reduxSetObjectsToDisplay([
                objectTypes.switch,
                objectTypes.levelCrossing,
                objectTypes.marker,
                objectTypes.notes,
                objectTypes.trackBarrier,
                objectTypes.trackCross,
                objectTypes.culvert,
                objectTypes.contactPole,
              ])
            );
          }
        }}
        style={{
          opacity:
            measurement.status === measurementStatus.WritingToDynamodbDone ||
            measurement.status === measurementStatus.CreatingExcel ||
            measurement.status === measurementStatus.CreatingExcelDone ||
            measurement.status === measurementStatus.SendingEmail ||
            measurement.status === measurementStatus.SendingEmailDone
              ? 1
              : 0.5,
        }}
        ref={parentWidthRef}
      >
        <MeasurementListItem
          measurement={measurement}
          measurementNumber={measurementNumber}
          plan={currPlan}
          isSelected={
            measurement.id === selectedMeasurement &&
            convertToHmiStatus(measurement.status) >
              measurementStatusHMI.plotDone
              ? {
                  borderStyle: "solid",
                  borderColor: "var(--primary-color)",
                  borderWidth: "3px",
                }
              : undefined
          }
          selectedMeasurement={
            measurement.id === selectedMeasurement ? true : false
          }
        />
      </div>
    );
  };

  return (
    <div>
      {noPlanSelected ? selectPlanContent() : selectMeasurementContent()}
    </div>
  );
};

interface MeasurementPlanDetailsWrapperProps {
  currentPlan: planProps | undefined;
  measurement: measurementProps | undefined;
}

export const MeasurementPlanDetailsWrapper: React.FunctionComponent<
  MeasurementPlanDetailsWrapperProps
> = ({ children, currentPlan, measurement }) => {
  return (
    <div className="MeasurementPlanDetailsWrapperContainer">
      <div className="PlanDetails">
        {currentPlan?.name} -{" "}
        {measurement?.trackNumber ? measurement?.trackNumber : "-"} -{" "}
        {currentPlan?.trackPart}
      </div>
      {children}
    </div>
  );
};
