import React from "react";
import "./sideBar.css";
import { Slide } from "@progress/kendo-react-animation";
import { appState } from "../../store";
import { useSelector } from "react-redux";
import { FilteringContent } from "./filteringContent";

export const MeasurementSelectionBarRight: React.FunctionComponent = () => {
  const [show, setShow] = React.useState<boolean>(false);

  const height = useSelector((state: appState) => state.scheduler.screenHeight);

  const width = useSelector((state: appState) => state.scheduler.screenWidth);

  const doubleArrow = () => {
    return (
      <div style={{ marginLeft: "-5px" }}>
        <span
          className={"k-icon k-i-arrow-chevron-right"}
          style={{ marginLeft: "10px" }}
        />
        <span
          className={"k-icon k-i-arrow-chevron-right"}
          style={{ marginLeft: "-10px" }}
        />
      </div>
    );
  };

  const children = show ? (
    <div style={{ width: "fit-content", height: "100%" }}>
      <div className="Content">
        <FilteringContent height={height} width={width} setShow={setShow} />
        <div
          className="ArrowContainerRightExpand"
          onClick={() => {
            setShow(!show);
          }}
        >
          <div
            style={{
              paddingRight: "5px",
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "5px",
              width: "28px",
              height: "33px",
            }}
          >
            <span
              className={"k-icon k-i-arrow-chevron-left"}
              style={{ marginLeft: "10px" }}
            />
            <span
              className={"k-icon k-i-arrow-chevron-left"}
              style={{ marginLeft: "-10px" }}
            />
          </div>
        </div>
      </div>
    </div>
  ) : null;

  const animation = (
    <Slide className="AnimationRight" direction={"right"}>
      {children}
    </Slide>
  );

  return (
    <div className="ContentAlignerRight">
      {show ? null : (
        <div
          className="MeasurementSelectionBarRightContainer"
          onClick={() => {
            setShow(!show);
          }}
        >
          <div className="ArrowContainerRightRetract">{doubleArrow()}</div>
        </div>
      )}
      {animation}
    </div>
  );
};
