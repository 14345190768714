import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// import { getMeasurementMetaData } from "../../../api/netRail/measurements";
import {
  cPhoneHeightLim,
  cPhoneWidthLim,
  cPhoneWidthLimStandard,
} from "../../../app";
import { color, setEmptySignal } from "../../../helpers/genericHelpers";
import { appState } from "../../../store";
import {
  reduxSetPlotMetaData,
  reduxSetSelectedChart,
  reduxSetTolerancesToDisplay,
} from "../../../store/plot/actions";
import { SelectedTolerancesType } from "../../../store/plot/types";
import { measurementMetaData } from "../../../store/scheduler/types";
import "./toogleTolerances.css";

interface ToogleTolerancesProps {
  chartID: string;
}

export const emptyTolerances = {
  plan: false,
  uh1: false,
  uh2: false,
  krit: false,
  stopp: false,
};

export const ToogleTolerances: React.FunctionComponent<
  ToogleTolerancesProps
> = ({ chartID }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  let selectedChart: any;

  const selectedChartCharts = useSelector((state: appState) =>
    state.plot.charts.find((chart) => chart.chartID === chartID)
  );

  const selectedChartSpecialCharts = useSelector((state: appState) =>
    state.plot.specialCharts.specialChartArray.find(
      (chart) => chart.chartID === chartID
    )
  );

  if (selectedChartCharts === undefined) {
    selectedChart = selectedChartSpecialCharts;
  } else {
    selectedChart = selectedChartCharts;
  }

  const tolerancesToDisplay: SelectedTolerancesType =
    selectedChart?.tolerancesToDisplay
      ? selectedChart?.tolerancesToDisplay
      : emptyTolerances;

  const measurementToDisplay = selectedChart?.measurementToDisplay
    ? selectedChart?.measurementToDisplay
    : [];

  const width = useSelector((state: appState) => state.scheduler.screenWidth);
  const height = useSelector((state: appState) => state.scheduler.screenHeight);

  const scaleFactor =
    width >= cPhoneWidthLim
      ? 1
      : 1 - (cPhoneWidthLim - width) / cPhoneWidthLim > 0.75
      ? 1 - (cPhoneWidthLim - width) / cPhoneWidthLim
      : 0.75;

  useEffect(() => {
    return () => {
      dispatch(
        reduxSetTolerancesToDisplay({
          plan: true,
          uh1: true,
          uh2: true,
          krit: true,
          stopp: true,
        })
      );
    };
  }, []);

  const toleranceButton = (
    currentTolerance: keyof typeof tolerancesToDisplay
  ) => {
    let numberOfErrors = 0;

    const allowedSignals = Object.keys(
      selectedChart.plotMetaData as measurementMetaData
    ); // necessary to safe guard that the user accidentally doesn't have specialcharts measurements selected.

    if (
      measurementToDisplay.length === 1 &&
      measurementToDisplay.some((signal: string) =>
        Object.keys(allowedSignals).includes(signal)
      )
    ) {
      if (currentTolerance === "plan") {
        numberOfErrors = selectedChart
          ? selectedChart.plotMetaData[measurementToDisplay[0]].totalPlan
          : 0;
      } else if (currentTolerance === "uh1") {
        numberOfErrors = selectedChart
          ? selectedChart.plotMetaData[measurementToDisplay[0]].totalUh1
          : 0;
      } else if (currentTolerance === "uh2") {
        numberOfErrors = selectedChart
          ? selectedChart.plotMetaData[measurementToDisplay[0]].totalUh2
          : 0;
      } else if (currentTolerance === "krit") {
        numberOfErrors = selectedChart
          ? selectedChart.plotMetaData[measurementToDisplay[0]].totalKrit
          : 0;
      } else if (currentTolerance === "stopp") {
        numberOfErrors = selectedChart
          ? selectedChart.plotMetaData[measurementToDisplay[0]].totalStop
          : 0;
      }
    }

    let opacity;

    const measToDisplayNoBIS = measurementToDisplay.filter(
      (filt: any) => filt !== "crossLevelBIS"
    );

    if (
      !tolerancesToDisplay[currentTolerance] || // Does the selected one have the curr tolerance?
      measToDisplayNoBIS.length > 1 || // More than one selected
      measToDisplayNoBIS.length === 0 // none selected
    ) {
      opacity = 0.5;
    } else {
      opacity = 1;
    }

    return (
      <div
        key={currentTolerance}
        className={
          measToDisplayNoBIS.length > 1 || measToDisplayNoBIS.length === 0
            ? "ToleranceSelectorButtonDisabled caption"
            : "ToleranceSelectorButton caption"
        }
        style={{ opacity: opacity }}
        onClick={async () => {
          dispatch(reduxSetSelectedChart(chartID)); // Set the selected chart
          dispatch(
            reduxSetTolerancesToDisplay({
              ...tolerancesToDisplay,
              [currentTolerance]: !tolerancesToDisplay[currentTolerance],
            })
          );
        }}
      >
        <div
          className="ToleranceSelectorLine"
          style={{ backgroundColor: color[currentTolerance] }}
        ></div>
        <div
          style={{
            fontSize:
              width < cPhoneWidthLimStandard || height < cPhoneHeightLim
                ? "10px"
                : "14px",
            fontWeight: "bold",
            marginLeft: "5px",
          }}
        >
          {" "}
          {currentTolerance.toUpperCase()}
          {numberOfErrors > 0 ? " (" + numberOfErrors + ")" : ""}
        </div>
      </div>
    );
  };

  const metaData = useSelector((state: appState) => state.plot.charts).find(
    (chart) => chart.chartID === selectedChart
  )?.plotMetaData;

  // useEffect(() => {
  //   //set metadata
  //   dispatch(
  //     reduxSetPlotMetaData({
  //       ...metaData,
  //       crossLevelBIS: {
  //         endKm: metaData["crossLevel"].endKm,
  //         endMeter: metaData["crossLevel"].endMeter,
  //         endTick: metaData["crossLevel"].endTick,
  //         startKm: metaData["crossLevel"].startKm,
  //         startMeter: metaData["crossLevel"].startMeter,
  //         startTick: metaData["crossLevel"].startTick,
  //         totalKrit: 0,
  //         totalPlan: 0,
  //         totalStop: 0,
  //         totalUh1: 0,
  //         totalUh2: 0,
  //       },
  //     })
  //   );

  // }, [selectedChart?.measurementToDisplay]);

  return (
    <div
      className="ToleranceSelection"
      style={{
        transform: `scale(${scaleFactor})`,
        minWidth: "200px",
        transformOrigin: `center`,
        paddingRight:
          width >= cPhoneWidthLim && height >= cPhoneHeightLim
            ? "100px"
            : width >= cPhoneWidthLim
            ? "45px"
            : "0px",
      }}
    >
      {[
        t("tolerances.plan"),
        t("tolerances.uh1"),
        t("tolerances.uh2"),
        t("tolerances.crit"),
        t("tolerances.stop"),
      ].map((tol) => {
        return toleranceButton(tol as keyof typeof tolerancesToDisplay);
      })}
    </div>
  );
};
