import { adminMessageType } from "../../store/user/types";
import rest from "./../../rest";

export const getAnnouncements = (
  expiresAt?: Date | null,
  validAt?: Date | null,
  type?: string,
  companies?: string[]
) => {
  return new Promise<adminMessageType[]>(async (resolve, reject) => {
    const myObject: any = {};

    const filterArray: any = [];

    if (expiresAt && expiresAt !== null) {
      filterArray.push({
        expiresAt: {
          $lte: new Date(expiresAt.setHours(23, 59, 59, 59)).toISOString(),
        },
      });
    }

    if (validAt && validAt !== null) {
      filterArray.push({
        validAt: {
          $gte: new Date(validAt.setHours(0, 0, 0, 0)).toISOString(),
        },
      });
    }

    if (companies && companies.length > 0) {
      const compArray: any = [];
      companies.map((company) => {
        compArray.push({ companies: { $elemMatch: [company] } });
      });

      myObject.$or = compArray;
    }

    if (filterArray.length > 0) myObject.$and = filterArray;

    if (type) {
      myObject.type = type;
    }

    rest
      .get(`/announcements`, {
        params: {
          filter: myObject,
        },
      })
      .then(async (response) => {
        const announcements: adminMessageType[] = response.data.list;
        resolve(
          announcements.map((msg) => {
            return { ...msg, sentToServer: true };
          })
        );
      })
      .catch((error) => {
        const err = `Failed to fetch announcements from server: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};

export const postAnnouncement = (announcement: adminMessageType) => {
  return new Promise<adminMessageType>(async (resolve, reject) => {
    // convert to our timezone.
    const expiringDateUTC = new Date(announcement.expiresAt);
    const validDateUTC = new Date(announcement.validAt);

    announcement = {
      ...announcement,
      expiresAt: new Date(
        expiringDateUTC.setHours(23, 59, 59, 59)
      ).toISOString(), // TO UTC TIME AND ROOF TO LATEST HOUR
      validAt: new Date(validDateUTC.setHours(0, 0, 0, 0)).toISOString(), // TO UTC TIME AND FLLOR TO earliest day time, // TO UTC TIME
    };

    const { id, sentToServer, ...announcementWithoutID } = announcement;

    rest
      .post(`/announcements`, announcementWithoutID, { withCredentials: true })
      .then(async (response) => {
        const announcement: adminMessageType = response.data;
        resolve(announcement);
      })
      .catch((error) => {
        const err = `Failed to post announcement to server: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};

export const patchAnnouncement = (
  messageID: string,
  announcement: adminMessageType
) => {
  return new Promise<adminMessageType>(async (resolve, reject) => {
    const {
      creatorID,
      createdAt,
      updatedAt,
      id,
      sentToServer,
      ...destructedValues
    } = announcement; // These values we shouldn't set on the frontend

    rest
      .patch(`/announcements/${messageID}`, destructedValues, {
        withCredentials: true,
      })
      .then(async (response) => {
        const announcement: adminMessageType = response.data;
        resolve(announcement);
      })
      .catch((error) => {
        const err = `Failed to post announcement to server: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};

export const deleteAnnouncement = (messageID: string) => {
  return new Promise<any>(async (resolve, reject) => {
    rest
      .delete(`/announcements/${messageID}`)
      .then(async (response) => {
        resolve(response);
      })
      .catch((error) => {
        const err = `Failed to post announcement to server: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};

export const sendAnnouncementEmail = (messageID: string) => {
  return new Promise<any>(async (resolve, reject) => {
    rest
      .post(`/announcements/${messageID}/email`)
      .then(async (response) => {
        resolve(response);
      })
      .catch((error) => {
        const err = `Failed to post announcement email: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};
