import React from "react";
import { useSelector } from "react-redux";
import { cPhoneWidthLim } from "../../app";
import { appState } from "../../store";
import { GroupChartContainer } from "./groupChartContainer";
import "./visualizationContainer.css";
interface VisualizationProps {
  chartID: string;
  mainView: React.MutableRefObject<boolean>;
  finishedLoading: boolean;
  setFinishedLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Visualization: React.FunctionComponent<VisualizationProps> = ({
  chartID,
  mainView,
  finishedLoading,
  setFinishedLoading,
}) => {
  return (
    <div className="VisualizationContainer">
      {finishedLoading ? null : (
        <div
          style={{
            position: "absolute",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 10000,
            height: "100%",
            width: "100%",
            backgroundColor: "transparent",
          }}
        ></div>
      )}
      {chartID !== "999999" ? (
        <GroupChartContainer
          chartID={chartID}
          mainView={mainView}
          finishedLoading={finishedLoading}
          setFinishedLoading={setFinishedLoading}
        />
      ) : null}
    </div>
  );
};
