import {
  schedulerActionType,
  planSubpages,
  parametersProps,
  deletePlan,
  deleteMeasurement,
  planProps,
  measurementProps,
  planStatus,
} from "./types";
import { planSorterTypes } from "./types";
import { appState } from "..";
import { getNewPlan } from "../../development/initializer";
import rest from "../../../src/rest";
import { reduxSetNotification } from "../user/actions";
import axios from "axios";
import { setLastVisitedPlanAndMeasurement } from "../../helpers/genericHelpers";
import { TFunction } from "i18next";

export function setPlanSorterType(planSorterType: planSorterTypes) {
  return {
    type: schedulerActionType.setPlanSorterType,
    payload: planSorterType,
  };
}

function addNewReduxPlan(plan: planProps) {
  return {
    type: schedulerActionType.addNewReduxPlan,
    payload: plan,
  };
}

export function setAllReduxPlans(plans: planProps[]) {
  return {
    type: schedulerActionType.setAllPlans,
    payload: plans,
  };
}

export function setReduxProjectedTrack(planID: string, file: File) {
  return {
    type: schedulerActionType.setReduxProjectedTrack,
    payload: { planID: planID, file: file },
  };
}

export function setSelectedPlan(selectedPlan: planProps["id"]) {
  return {
    type: schedulerActionType.setSelectedPlan,
    payload: selectedPlan,
  };
}

export function setSelectedMeasurement(
  selectedMeasurement: measurementProps["id"]
) {
  return {
    type: schedulerActionType.setSelectedMeasurement,
    payload: selectedMeasurement,
  };
}

export function setExternalAccessToken(accessToken: string) {
  return {
    type: schedulerActionType.setExternalAccessToken,
    payload: accessToken,
  };
}

export function setSelectedSubpage(selectedSubpage: planSubpages) {
  return {
    type: schedulerActionType.setSelectedSubPage,
    payload: selectedSubpage,
  };
}

export function updatePlanParameter<T extends keyof parametersProps>(
  planParameter: Pick<parametersProps, T>
) {
  return {
    type: schedulerActionType.updatePlanParameter,
    payload: planParameter,
  };
}

export function updatePlanProperty<T extends keyof planProps>(
  planProperty: Pick<planProps, T>
) {
  return {
    type: schedulerActionType.updatePlanProperty,
    payload: planProperty,
  };
}

export function updateMeasurementProperty<T extends keyof measurementProps>(
  measurementProperty: Pick<measurementProps, T>
) {
  return {
    type: schedulerActionType.updateMeasurementProperty,
    payload: measurementProperty,
  };
}

export function updateMeasurementStatus<T extends keyof measurementProps>(
  id: string,
  properties: Pick<measurementProps, T>
) {
  return {
    type: schedulerActionType.updateMeasurementStatus,
    payload: { id, properties },
  };
}

export function setPlanHasChanged(planHasChanged: boolean) {
  return {
    type: schedulerActionType.setPlanHasChanged,
    payload: planHasChanged,
  };
}

export function reduxDeletePlan(planID: string): deletePlan {
  return {
    type: schedulerActionType.deletePlan,
    payload: planID,
  };
}

export function reduxDeleteMeasurementFromPlan(
  measurementID: string
): deleteMeasurement {
  return {
    type: schedulerActionType.deleteMeasurement,
    payload: measurementID,
  };
}

export function setAllReduxMeasurements(
  measurements: measurementProps[],
  plan: planProps
) {
  return {
    type: schedulerActionType.setAllMeasurements,
    payload: { measurements: measurements, plan: plan },
  };
}

export function setNewReduxPlanSelected(newPlanSelected: boolean) {
  return {
    type: schedulerActionType.setNewPlanSelected,
    payload: newPlanSelected,
  };
}

export function addNewPlan(prefilledPlan?: planProps) {
  return (dispatch: any, getState: () => appState) => {
    if (prefilledPlan !== undefined) {
      // Insert data from copied plan
      const { user } = getState();
      const userID = user.currentUser ? user.currentUser.id : "";
      const prefilledPlanID = getNewPlan(1, userID).id;
      dispatch(
        addNewReduxPlan({
          ...prefilledPlan,
          id: prefilledPlanID,
          sentToServer: false,
          measurements: [],
          status: planStatus.awaiting,
          scheduledAt: new Date().toISOString(),
          createdAt: new Date().toISOString(),
          doneAt: new Date().toISOString(),
          updatedAt: new Date().toISOString(),
        })
      );
      dispatch(setSelectedPlan(prefilledPlanID));
      setLastVisitedPlanAndMeasurement(prefilledPlanID, undefined);
      dispatch(setNewReduxPlanSelected(true));
    } else {
      // empty plan
      const { user } = getState();
      const userID = user.currentUser ? user.currentUser.id : "";
      const plan = getNewPlan(1, userID);
      dispatch(addNewReduxPlan(plan));
      dispatch(setSelectedPlan(plan.id));
      setLastVisitedPlanAndMeasurement(plan.id, undefined);
      dispatch(setNewReduxPlanSelected(true));
    }
  };
}

export function uplaodProjectedTrack(file: File, planID: string) {
  const fsize = file.size;
  const fileSize = Math.round(fsize / 1024);

  const postPresigned = {
    name: file.name,
    type: "projected_track",
    size: fileSize,
    ownerType: "plan",
    ownerID: planID,
    partType: "complete",
    part: 0,
  };
  const fileReader = new FileReader();
  const binaryFile = fileReader.readAsBinaryString(file);
  rest
    .post("/files/uploadURL", postPresigned)
    .then((response) => {
      const presignedURL: string = response.data.presignedURL;

      const path = response.data.fileKey;

      axios
        .put(
          presignedURL,
          {
            binaryFile,
          },
          {
            headers: {
              "X-Amz-meta-name": postPresigned.name.toString(),
              "X-Amz-meta-part": postPresigned.part.toString(),
              "X-Amz-meta-part_type": postPresigned.partType.toString(),
              "X-Amz-meta-type": postPresigned.type.toString(),
              "X-Amz-meta-size": postPresigned.size.toString(),
              "X-Amz-meta-path": path.toString(),
              "X-Amz-meta-owner_id": postPresigned.ownerID.toString(),
              "X-Amz-meta-owner_type": postPresigned.ownerType.toString(),
            },
          }
        )
        .then((response) => void 0)
        .catch((error) => void 0);
    })
    .catch((error) => {
      console.log("uploadURL error", error);
    });
}

export function savePlanToServer(
  t: TFunction<"translation", undefined, "translation">,
  plan: planProps
) {
  return (dispatch: any, getState: () => appState) => {
    if (plan.sentToServer) {
      const {
        id,
        creatorID,
        createdAt,
        measurements,
        updatedAt,
        sentToServer,
        doneAt,
        files,
        projectedTrackFile,
        ...planToPatch
      } = plan;

      rest
        .patch("/plans/" + plan.id, planToPatch, { withCredentials: true })
        .then((fulfill) => {
          const file = plan.projectedTrackFile;
          if (file) {
            uplaodProjectedTrack(file, plan.id);
          }
          dispatch(setPlanHasChanged(false));
          dispatch(updatePlanProperty(planToPatch));
          dispatch(
            reduxSetNotification({
              style: "success",
              message: t("store.plan.planUpdated"),
              open: true,
            })
          );
          setLastVisitedPlanAndMeasurement(plan.id, undefined);
        })
        .catch((reason) => {
          console.error("fail to upload plan: ", reason);
          dispatch(
            reduxSetNotification({
              style: "error",
              message: t("store.plan.savePlanError"),
              open: true,
            })
          );
        });
    } else {
      const {
        id,
        creatorID,
        createdAt,
        measurements,
        updatedAt,
        doneAt,
        files,
        sentToServer,
        projectedTrackFile,
        ...planToPost
      } = plan;

      rest
        .post("/plans", planToPost, { withCredentials: true })
        .then((fulfill) => {
          const planReponse = fulfill.data;

          dispatch(
            updatePlanProperty({
              id: planReponse.id,
              sentToServer: true,
              creatorID: planReponse.creatorID,
              createdAt: planReponse.createdAt,
              updatedAt: planReponse.updatedAt,
            })
          );
          dispatch(setSelectedPlan(planReponse.id));
          const file = plan.projectedTrackFile;

          if (file) {
            uplaodProjectedTrack(file, planReponse.id);
          }
          dispatch(setPlanHasChanged(false));
          dispatch(
            reduxSetNotification({
              style: "success",
              message: t("store.plan.planSaved"),
              open: true,
            })
          );
          setLastVisitedPlanAndMeasurement(planReponse.id, undefined);
        })
        .catch((reason) => {
          console.error("fail to upload plan: ", reason);
          if (reason.message === "Network Error") {
            dispatch(
              reduxSetNotification({
                style: "error",
                message: t("store.plan.savePlanError"),
                open: true,
              })
            );
          } else {
            dispatch(
              reduxSetNotification({
                style: "error",
                message: t("statusProgress.unexpectedError"),
                open: true,
              })
            );
          }
        });
    }
  };
}

export function onManualMeasurementMode() {
  return (dispatch: any, getState: () => appState) => {
    const measurementSelection = getState().scheduler.plans.find(
      (plan) => plan.id === getState().scheduler.selectedPlan
    )?.measurementsSelection;
    if (measurementSelection)
      dispatch(
        updatePlanProperty({
          measurementsSelection: [
            measurementSelection[0],
            measurementSelection[1],
            measurementSelection[2],
            false,
            false,
            false,
          ],
        })
      );
  };
}

export function saveProjectedTrackToServer(
  projectedTrack: File,
  planID: string
) {
  return (dispatch: any, getState: () => appState) => {
    const date = new Date();
    const formData = new FormData();
    formData.append("file", projectedTrack, "projected_track_" + date.toJSON());
    formData.append("FileProps", "projected_track");
    formData.append("planID", planID);
    formData.append("measurementID", "");
    formData.append("machineID", "");

    rest
      .put("/files/projected_track_" + date.toJSON(), formData, {
        withCredentials: true,
      })
      .then((fulfill) => void 0)
      .catch((reason) => {
        console.error("failed to upload plan to server: ", reason);
        alert("Kunde inte ladda upp projekteradmätdata till servern.");
      });
  };
}

export function getProjectedTrack(planID: string) {
  return (dispatch: any, getState: () => appState) => {
    rest
      .get("/files", { withCredentials: true })
      .then((result) => {
        const files = result.data.list;

        const foundFile = files.find(
          (file: any) =>
            file.type === "projected_track" && file.ownerID === planID
        );

        if (foundFile)
          rest
            .get("/files/" + foundFile.id + "/downloadURL", {
              withCredentials: true,
            })
            .then(async (resultFile) => {
              const response = await fetch(resultFile.data.presignedUR);
              const data = await response.blob();
              const metadata = {
                type: "xlsx",
              };
              const projectedTrackFile = new File(
                [data],
                foundFile.name,
                metadata
              );
              dispatch(setReduxProjectedTrack(planID, projectedTrackFile));
            })
            .catch((err) => {
              console.log(err);
            });
      })
      .catch((err) => {
        console.log(err);
      });
  };
}

export const removeMeasurement = (
  t: TFunction<"translation", undefined, "translation">,
  measurementID: string
) => {
  return (dispatch: any) => {
    rest
      .delete("/measurements/" + measurementID, { withCredentials: true })
      .then((response) => {
        dispatch(reduxDeleteMeasurementFromPlan(measurementID));
      })
      .catch(() => {
        console.error(
          "Failed to delete measurement with measurementID ",
          measurementID
        );
        dispatch(
          reduxSetNotification({
            style: "error",
            message: t("store.plan.deleteMeasurementError"),
            open: true,
          })
        );
      });
  };
};

export const setOperatorToMeasurement = (operatorID: string) => {
  return {
    type: schedulerActionType.addOpearatorToMeasurement,
    payload: operatorID,
  };
};

export const reduxSetExpandedMenu = (expandedMenu: boolean) => {
  return {
    type: schedulerActionType.setExpandedMenu,
    payload: expandedMenu,
  };
};

export const reduxSetScreenWidth = (width: number) => {
  return {
    type: schedulerActionType.setScreenWidth,
    payload: width,
  };
};

export const reduxSetScreenHeight = (height: number) => {
  return {
    type: schedulerActionType.setScreenHeight,
    payload: height,
  };
};

export function setTotalPlansOnServer(totalPlansOnServer: number) {
  return {
    type: schedulerActionType.setTotalPlansOnServer,
    payload: totalPlansOnServer,
  };
}
