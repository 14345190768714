import React, { memo, MouseEvent } from 'react';

interface ReprocessCancelButtonProps {
  dismountResetFunction: () => void;
  setReprocessPressed: (pressed: boolean) => void;
}

const ReprocessCancelButton: React.FC<ReprocessCancelButtonProps> = memo((props) => {
  const { dismountResetFunction, setReprocessPressed } = props;

  const handleOnClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    dismountResetFunction();
    setReprocessPressed(false);
  };

  return (
    <span
      className="k-icon k-i-close removeChart"
      style={{
        fontSize: "24px",
        marginRight: "10px",
        padding: "15px",
      }}
      onClick={handleOnClick}
    />
  );
});

export default ReprocessCancelButton;
