import React, { useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { appState } from "../../store";
import { companyType } from "../../store/company/types";
import { CompanyListItem } from "./companyListItem";
import Scrollbars from "react-custom-scrollbars";
import { customThumb } from "../manageUsers/userList";
interface CompanyListProps {
  filteredCompanyArray: any; // OBS! userType
  newCompany: boolean;
  setNewCompanyAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CompanyList: React.FC<CompanyListProps> = ({
  filteredCompanyArray,
  newCompany,
  setNewCompanyAdded,
}) => {
  const revealRefs = useRef<any[]>([]);
  revealRefs.current = [];
  const selectedCompany = useSelector(
    (state: appState) => state.company.selectedCompany
  );

  useEffect(() => {
    if (revealRefs.current.length > 0)
      revealRefs.current[0].scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
  }, [newCompany]);

  const addToRef = (elementRef: never) => {
    if (elementRef && !revealRefs.current.includes(elementRef)) {
      revealRefs.current.push(elementRef);
    }
  };

  const Rows = filteredCompanyArray.map((company: companyType) => {
    const edit = selectedCompany === company.id ? true : false;
    company.description =
      company.description === null ? "" : company.description;

    return (
      <div className="UserListButtonUser" key={company.id} ref={addToRef}>
        <CompanyListItem
          company={company}
          edit={edit}
          selectedCompany={selectedCompany}
          setNewCompanyAdded={setNewCompanyAdded}
        />
      </div>
    );
  });

  Rows.reverse();

  return (
    <Scrollbars
      thumbSize={60}
      renderThumbVertical={customThumb}
      autoHide={true}
      autoHideTimeout={1000}
      autoHideDuration={500}
    >
      <div className="userListWrapper">{Rows}</div>
    </Scrollbars>
  );
};
