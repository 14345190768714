import * as React from "react";
const TrackGeometryIcon = (props: any) => (
  <svg
    id="Rail"
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="35"
    viewBox="0 0 24 24"
    fill="var(--primary-color)"
  >
    <g
      id="Component_265_1"
      data-name="Component 265 – 1"
      transform="translate(7 5.627)"
    >
      <path
        id="Path_260"
        data-name="Path 260"
        d="M4761-619.949h6.441l-.292-1.973h-5.992Z"
        transform="translate(-4759.017 630.157)"
        color="red"
      />
      <path
        id="Path_261"
        data-name="Path 261"
        d="M4761-620.49h6.441l-.292-1.432h-5.992Z"
        transform="translate(-4759.017 626.777)"
      />
      <path
        id="Path_262"
        data-name="Path 262"
        d="M4761-620.843h5.369l-.244-1.079h-4.994Z"
        transform="translate(-4758.532 624.184)"
      />
      <path
        id="Path_263"
        data-name="Path 263"
        d="M4761.005-621.182h4.273l-.194-.74h-3.976Z"
        transform="translate(-4757.936 622.483)"
      />
      <path
        id="Path_258"
        data-name="Path 258"
        d="M4597.137-968.28c1.5,0,1.412-.771,1.412-.771l1.268-10.034a2.04,2.04,0,0,0-.035-.305.547.547,0,0,0-.11-.174c-.023-.015-.179-.091-.179-.091a.451.451,0,0,0-.1-.008c-.01.006-.129.057-.13.074s-.088.086-.1.12-2.792,9.557-2.816,9.728a4.547,4.547,0,0,0-.043.691,1.077,1.077,0,0,0,.248.574A2.681,2.681,0,0,0,4597.137-968.28Z"
        transform="translate(-4596.3 979.715)"
      />
      <path
        id="Path_259"
        data-name="Path 259"
        d="M.927,10.234a.6.6,0,0,0,.29-.16,1.811,1.811,0,0,0,.291-.24,2.276,2.276,0,0,0,.464-.557L6.161.138a.924.924,0,0,0,.015-.231.214.214,0,0,0-.107-.1c-.027,0-.212-.01-.212-.01a1.017,1.017,0,0,0-.124.028,1.65,1.65,0,0,0-.182.1c-.005.014-.13.1-.157.132S-.06,8.658-.125,8.8a3.073,3.073,0,0,0-.191.566.377.377,0,0,0,.206.37C-.029,9.773.927,10.234.927,10.234Z"
        transform="translate(2.398 4.045) rotate(-39)"
      />
    </g>
    <g
      id="Ellipse_25"
      data-name="Ellipse 25"
      transform="translate(2 2)"
      fill="none"
      stroke="#000"
      stroke-width="2"
    >
      <circle cx="10" cy="10" r="10" stroke="none" />
      {/* <circle cx="10" cy="10" r="9" fill="none" /> */}
    </g>
    <rect
      id="Rectangle_170"
      data-name="Rectangle 170"
      width="24"
      height="24"
      fill="none"
      opacity="0.2"
    />
  </svg>
);
export default TrackGeometryIcon;
