import React, { useRef } from "react";
import "./measurementList.css";

import { FormElement } from "@progress/kendo-react-form";
import { useDispatch, useSelector } from "react-redux";
import { appState } from "../../../../store";
import { updatePlanProperty } from "../../../../store/scheduler/actions";
import {
  measurementProps,
  measurementStatus,
  pipelineProps,
  planProps,
} from "../../../../store/scheduler/types";
import {
  reduxSetInformationRequest,
  reduxSetNotification,
} from "../../../../store/user/actions";
import { helpInformationPath } from "../../../../store/user/types";
import * as plansAPI from "./../../../../api/netRail/plans";
import * as pipelinesAPI from "./../../../../api/netRail/pipelines";
import MeasurementListItem from "./measurementListItem";
import { useTranslation } from "react-i18next";
import { getMeasurementStatusFromPipeline } from "../../../../store/scheduler/helpers";

interface measurementListProps {
  plan: planProps;
}

function sortByDate(
  measurementA: measurementProps,
  measurementB: measurementProps
) {
  return measurementA.createdAt < measurementB.createdAt
    ? 1
    : measurementA.createdAt > measurementB.createdAt
    ? -1
    : 0;
}

async function fetchAndUpdateMeasurements(
  planId: string,
  dispatch: any,
  t: (key: string) => string
): Promise<void> {
  try {
    const serverPlan = await plansAPI.getPlan(planId);

    const promises = serverPlan.measurements.map((measurement) =>
      pipelinesAPI.getCurrentPipelines(measurement.id)
    );

    const pipelines = await Promise.all(promises);

    const updatedMeasurements = serverPlan.measurements.map((meas, idx) => ({
      ...meas,
      pipelines: pipelines[idx] || [],
    }));

    dispatch(
      updatePlanProperty({
        measurements: updatedMeasurements,
      })
    );

    dispatch(
      reduxSetNotification({
        message: t("common.updated"),
        style: "success",
        open: true,
      } as any)
    );
  } catch (error) {
    dispatch(
      reduxSetNotification({
        message: t("common.couldNotUpdate"),
        style: "error",
        open: true,
      } as any)
    );
  }
}

const MeasurementList: React.FC<measurementListProps> = ({ plan }) => {
  const { t } = useTranslation();
  const measurements = plan.measurements.sort(sortByDate);
  const parentWidthRef = useRef<any>();
  const dispatch = useDispatch();
  const informationRequest = useSelector(
    (state: appState) => state.user.informationRequest
  );

  const Rows = measurements.map((measurement, idx) => {
    return (
      <MeasurementListItem
        key={measurement.id}
        measurement={measurement}
        measurementNumber={measurements.length - idx}
        plan={plan}
        selectedMeasurement={true}
      />
    );
  });

  const nbrDoneMeasurements = plan.measurements.filter(
    (meas) =>
      getMeasurementStatusFromPipeline(t, meas.pipelines) ===
      t("common.measurementStatus.done")
  ).length;

  const nbrFailedMeasurements = plan.measurements.filter(
    (meas) =>
      getMeasurementStatusFromPipeline(t, meas.pipelines) ===
      t("common.measurementStatus.faulty")
  ).length;

  const nbrProgressingMeasurements = plan.measurements.filter(
    (meas) =>
      getMeasurementStatusFromPipeline(t, meas.pipelines) ===
      t("common.measurementStatus.inProgress")
  ).length;

  return (
    <div className="MeasurementsForm">
      <FormElement className="MeasurementListLegend">
        <fieldset className={"k-form-fieldset"}>
          <div
            className={"k-form-legend"}
            style={{
              color: "var(--primary-color)",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                color: "var(--primary-color)",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                position: "relative",
              }}
            >
              <div title={t("measurementList.completedMeasurements") as string}  className="MeasurementCounter">
                <span
                  className="k-icon k-i-track-changes-accept-all"
                  style={{
                    marginRight: "3px",
                    fontSize: "24px",
                    // color: "var(--primary-color)",
                  }}
                />
                {nbrDoneMeasurements}
              </div>
              <div title={t("measurementList.failedMeasurement") as string} className="MeasurementCounter">
                <span
                  className="k-icon k-i-track-changes-reject-all"
                  style={{
                    marginRight: "3px",
                    fontSize: "24px",
                    // color: "var(--primary-color)",
                  }}
                />
                {nbrFailedMeasurements}
              </div>
              <div title={t("measurementList.ongoingMeasurement") as string} className="MeasurementCounter">
                <span
                  className="k-icon k-i-gears"
                  style={{
                    marginRight: "3px",
                    fontSize: "24px",
                    // color: "var(--primary-color)",
                  }}
                />
                {nbrProgressingMeasurements}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignSelf: "center",
                padding: "5px",
              }}
            >
              <span
                className="k-icon k-i-refresh RefreshButton"
                style={{
                  marginRight: "10px",
                }}
                onClick={() => {
                  fetchAndUpdateMeasurements(plan.id, dispatch, t);
                }}
              />
              <span
                className="k-icon k-i-information informationButton"
                style={{
                  marginRight: "10px",
                  padding: "12px",
                }}
                onClick={() => {
                  dispatch(
                    reduxSetInformationRequest({
                      path: helpInformationPath.FinishedMeasurements,
                      showInformation:
                        informationRequest?.path ===
                        helpInformationPath.FinishedMeasurements
                          ? !informationRequest?.showInformation
                          : true,
                    })
                  );
                }}
              />
            </div>
          </div>
        </fieldset>
      </FormElement>
      <div className="MeasurementListContainer">
        <div ref={parentWidthRef}> {Rows} </div>
      </div>
    </div>
  );
};

export default MeasurementList;
