import React, { useRef, useState } from "react";
import { FileProps, measurementProps } from "../../store/scheduler/types";
import "./handleFiles.css";
import { Input } from "@progress/kendo-react-inputs";
import { Popup } from "@progress/kendo-react-popup";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import * as filesAPI from "../../api/netRail/files";
import * as measurementsAPI from "../../api/netRail/measurements";
import { translateFileProps } from "../../helpers/genericHelpers";
import { appState } from "../../store";
import { updateMeasurementProperty } from "../../store/scheduler/actions";
import { reduxSetNotification } from "../../store/user/actions";
import { roleType } from "../../store/user/types";
import { useTranslation } from "react-i18next";

interface HandleFilesProps {
  show: boolean;
  setShow: any;
}

export const HandleFilesPopup: React.FC<HandleFilesProps> = ({
  show,
  setShow,
}) => {
  const { t } = useTranslation();
  const selectedMeasurement = useSelector(
    (state: appState) => state.scheduler.selectedMeasurement
  );
  const measurements = useSelector((state: appState) =>
    state.scheduler.plans.map((plan) => plan.measurements).flat()
  );
  const measurement = measurements.find(
    (meas) => meas.id === selectedMeasurement
  );

  const currentUser = useSelector((state: appState) => state.user.currentUser);

  const [presignedURL, setPresignedURL] = useState<string>();
  const [fileKey, setFileKey] = useState<string>();
  const [fileToUpload, setFileToUpload] = useState<any>();
  const [FileProps, setFileProps] = useState<string>("generic");

  const [fileToUploadName, setFileToUploadName] = useState<string>();

  const [loading, setLoading] = useState<boolean>(false);

  const dispatch = useDispatch();

  const deleteFile = (file: FileProps) => {
    setLoading(true);
    try {
      setTimeout(async () => {
        await filesAPI.deleteFile(file.id);

        const cleanedFiles = measurement?.files.filter(
          (measurementFile) => measurementFile.id !== file.id
        );

        dispatch(
          updateMeasurementProperty({
            files: cleanedFiles ? cleanedFiles : [],
          })
        );

        dispatch(
          reduxSetNotification({
            message: t("common.fileDeleted"),
            style: "success",
            open: true,
          })
        );

        setLoading(false);
      }, 2000);
    } catch (error) {
      dispatch(
        reduxSetNotification({
          message: t("handleFiles.couldNotDeleteFile"),
          style: "error",
          open: true,
        })
      );

      setLoading(false);
    }
  };

  const measurementFilesList = (files?: FileProps[]) => {
    let iter = 0;

    const newfiles = files;

    return (
      <div className="FileViewList">
        {newfiles?.map((file) => {
          if (currentUser?.roles.some((role) => role.type === roleType.admin)) {
            iter += 1;
            return (
              <div
                className="HandleFilesItem"
                style={{
                  backgroundColor:
                    iter % 2 !== 0 ? "rgb(231, 231, 231)" : "white",
                }}
              >
                <div
                  className="FileProps"
                  onClick={() => {
                    filesAPI.downloadFile(file);
                  }}
                >
                  {translateFileProps(file.type, t)}
                </div>
                <div
                  className="FileName"
                  onClick={() => {
                    filesAPI.downloadFile(file);
                  }}
                >
                  {file.name}
                </div>
                <div className="HandleFileButtonContainer">
                  <span
                    className="k-icon k-i-download HandleFileButton"
                    onClick={() => {
                      if (!loading) filesAPI.downloadFile(file);
                    }}
                    style={{
                      fontSize: "24px",
                      opacity: loading ? "0.2" : "1",
                    }}
                  />
                  {file.type === "generic" ? (
                    <span
                      className="k-icon k-i-delete HandleFileButton"
                      onClick={() => {
                        if (!loading) deleteFile(file);
                      }}
                      style={{
                        marginLeft: "10px",
                        fontSize: "24px",
                        opacity: loading ? "0.2" : "1",
                      }}
                    />
                  ) : null}
                </div>
              </div>
            );
          }
          if (file.type !== "data_logs")
            if (file.type !== "processed_data" || file.name.includes("0-end")) {
              iter += 1;
              return (
                <div
                  className="HandleFilesItem"
                  style={{
                    backgroundColor:
                      iter % 2 !== 0 ? "rgb(231, 231, 231)" : "white",
                  }}
                >
                  <div
                    className="FileProps"
                    onClick={() => {
                      filesAPI.downloadFile(file);
                    }}
                  >
                    {translateFileProps(file.type, t)}
                  </div>
                  <div
                    className="FileName"
                    onClick={() => {
                      filesAPI.downloadFile(file);
                    }}
                  >
                    {file.name}
                  </div>
                  <div className="HandleFileButtonContainer">
                    <span
                      className="k-icon k-i-download HandleFileButton"
                      onClick={() => {
                        if (!loading) filesAPI.downloadFile(file);
                      }}
                      style={{
                        fontSize: "24px",
                        opacity: loading ? "0.2" : "1",
                      }}
                    />
                    {file.type === "generic" ? (
                      <span
                        className="k-icon k-i-delete HandleFileButton"
                        onClick={() => {
                          if (!loading) deleteFile(file);
                        }}
                        style={{
                          marginLeft: "10px",
                          fontSize: "24px",
                          opacity: loading ? "0.2" : "1",
                        }}
                      />
                    ) : null}
                  </div>
                </div>
              );
            }
        })}
      </div>
    );
  };

  const uploadFile = (event: any) => {
    setLoading(true);
    event.preventDefault();
    event.stopPropagation();

    // upload file
    const reader = new FileReader();

    if (fileToUpload && measurement && currentUser && presignedURL) {
      reader.readAsArrayBuffer(fileToUpload);

      reader.onload = async (e) => {
        if (e.target) {
          const instance = axios.create({
            baseURL: presignedURL,
          });

          instance.interceptors.request.use(async function (config) {
            config.headers["X-Amz-meta-Name"] = fileToUpload.name;
            config.headers["X-Amz-meta-Owner_type"] = "measurement";
            config.headers["X-Amz-meta-Owner_id"] = measurement.id;
            config.headers["X-Amz-meta-Part_type"] = "complete";
            config.headers["X-Amz-meta-Part"] = "0";
            config.headers["X-Amz-meta-Type"] = FileProps;
            config.headers["X-Amz-meta-Size"] = fileToUpload.size.toString();
            config.headers["X-Amz-meta-Path"] = fileKey;

            return config;
          });

          const binaryString = reader.result;

          instance
            .put(presignedURL, binaryString)
            .then((res) => {
              try {
                setTimeout(async () => {
                  const serverMeasurement =
                    await measurementsAPI.getMeasurement(measurement.id);

                  dispatch(
                    updateMeasurementProperty({
                      files: serverMeasurement.files,
                    })
                  );

                  dispatch(
                    reduxSetNotification({
                      message: t("common.uploadedFile"),
                      style: "success",
                      open: true,
                    })
                  );
                  setFileToUploadName("");
                  setFileToUpload(undefined);

                  setLoading(false);
                }, 2000);
              } catch (error) {
                console.log(error);
                setLoading(false);
                setFileToUploadName("");
                setFileToUpload(undefined);
              }
            })
            .catch((err: any) => {
              dispatch(
                reduxSetNotification({
                  message: t("common.uploadFailed"),
                  style: "error",
                  open: true,
                })
              );
              setFileToUploadName("");
              setFileToUpload(undefined);

              console.error({ err });
              setLoading(false);
            });
        }
      };
    }
  };

  const inputRef = useRef<any>(null);

  const handleClick = () => {
    // 👇️ open file input box on click of other element
    if (inputRef.current) inputRef.current.click();
  };

  const handleFileChange = async (event: any) => {
    const fileObj = event.target.files && event.target.files[0];
    if (!fileObj) {
      return;
    }

    setFileToUpload(event.target.files[0]);
    setFileProps("generic");
    const localFile = event.target.files[0];
    if (!localFile) {
      dispatch(
        reduxSetNotification({
          message: t("common.noFileHasBeenSelected"),
          style: "error",
          open: true,
        })
      );

      return;
    }

    if (measurement) {
      try {
        const { presignedURL, fileKey } = await filesAPI.getUploadURL(
          localFile.name,
          FileProps,
          localFile.size,
          "measurement",
          measurement.id,
          "complete",
          0
        );

        setPresignedURL(presignedURL);
        setFileKey(fileKey);
      } catch (error) {
        dispatch(
          reduxSetNotification({
            message: t("common.couldNotReadFile"),
            style: "error",
            open: true,
          })
        );

        return;
      }
    }
    setFileToUploadName(fileObj.name);
    setFileToUpload(fileObj);
  };

  const refreshFiles = (measurement: measurementProps) => {
    try {
      setLoading(true);
      setTimeout(async () => {
        const serverMeasurement = await measurementsAPI.getMeasurement(
          measurement.id
        );

        dispatch(
          updateMeasurementProperty({
            files: serverMeasurement.files,
          })
        );

        setLoading(false);
      }, 2000);
    } catch (error) {
      console.log(error);
      setLoading(false);
      setFileToUploadName("");
      setFileToUpload(undefined);
    }
  };

  return (
    <div>
      <Popup
        show={show}
        style={{
          position: "absolute",
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "rgba(120, 120, 120, 0.5)",
        }}
      >
        <div className="PopupContentDownload">
          <div className="HandleFilesHeader">
            <div className="HandleFilesHeaderContainer">
              <div
                style={{
                  color: "var(--primary-color)",
                  fontWeight: "bold",
                  fontSize: "18px",
                }}
              >
                {t("handleFiles.handleFiles")}
              </div>
              {measurement ? (
                <span
                  className="k-icon k-i-reload-sm CloseWindowButton"
                  style={{ fontSize: "24px" }}
                  onClick={() => {
                    refreshFiles(measurement);
                  }}
                />
              ) : null}{" "}
            </div>
            <span
              className="k-icon k-i-close CloseWindowButton"
              style={{
                fontSize: "24px",
              }}
              onClick={() => {
                setFileToUpload(undefined);
                setFileToUploadName(undefined);
                setShow(!show);
              }}
            />
          </div>
          {measurementFilesList(measurement?.files)}
          <div className="downloadButtonsContainer">
            <input
              className="SelectFileButton"
              type="file"
              ref={inputRef}
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Input
              placeholder={t("handleFiles.chooseAFile").toString()}
              value={fileToUploadName}
              onClick={handleClick}
              className="InputDisplay"
            />{" "}
            <button
              className="UploadButton2 BUTTON"
              disabled={loading || !fileToUpload || !fileToUploadName}
              style={
                loading || !fileToUpload || !fileToUploadName
                  ? { opacity: "0.2" }
                  : {}
              }
              onClick={(e) => {
                uploadFile(e);
              }}
            >
              {t("common.upload")}
            </button>
          </div>
        </div>
      </Popup>
    </div>
  );
};
