import React, { useState, useEffect } from "react";
import "./style.scss";

import { Toolbar } from "../../components/toolbar";

import { MachineList } from "../../components/manageMachines/machineList";

// Redux Stuff
import {
  addNewMachine,
  reduxSetSelectedMachine,
} from "./../../store/machine/actions";
import { useDispatch, useSelector } from "react-redux";
import { appState } from "../../store";

import { Input } from "@progress/kendo-react-inputs";
import { roleType } from "../../store/user/types";
import { machineType } from "../../store/machine/types";
import { AdminAnnouncement } from "../../components/announcement/announcement";
import { cPhoneWidthLim } from "../../app";
import { useTranslation } from "react-i18next";

export const ManageMachines: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector((state: appState) => state.user.currentUser);

  const allMachines = useSelector(
    (state: appState) => state.machine.allMachines
  );

  const companyMachines = allMachines.filter((machine) => {
    if (
      machine.companyID === currentUser?.companyID ||
      machine.companyID === "" // If it's a new machine
    ) {
      return true;
    } else {
      return false;
    }
  });

  let machines: machineType[];

  currentUser?.roles.some((entry) => entry.name === roleType.admin)
    ? (machines = allMachines)
    : (machines = companyMachines);
  const width = useSelector((state: appState) => state.scheduler.screenWidth);
  const height = useSelector((state: appState) => state.scheduler.screenHeight);

  const [filteredMachineArray, setFilteredList] = useState(allMachines);
  const [filter, setFilter] = useState<string>("");
  const [addMachine, setAddMachine] = useState<boolean>(true);
  const [newMachine, setNewMachine] = useState(false);
  const [machineListEmpty, setMachineListEmpty] = useState<boolean>(false);

  useEffect(() => {
    dispatch(reduxSetSelectedMachine(""));
  }, [dispatch]);

  useEffect(() => {
    if (allMachines.length !== 0) {
      setMachineListEmpty(true);
      setFilteredList(
        filter !== ""
          ? machines.filter((machine) => {
              if (
                machine.name
                  .toLocaleLowerCase()
                  .includes(filter.toLocaleLowerCase())
              ) {
                return true;
              }
              return false;
            })
          : machines
      );
    }
  }, [
    allMachines,
    filter,
    setFilteredList,
    // machines
  ]);

  return (
    <div
      className="ManageUsersContainer"
      style={
        width <= cPhoneWidthLim || height <= cPhoneWidthLim
          ? {}
          : { position: "relative", left: "44px", paddingRight: "44px" }
      }
    >
      <header className="ManageUsersToolbar">
        <Toolbar>
          <h5>{t("menu.machinePageName")}</h5>
        </Toolbar>
      </header>
      <AdminAnnouncement />
      <div className="ManageUsersMain">
        <div className="manageUserList">
          <div className="centerCard">
            <div
              className="userListCardHeader"
              style={{ paddingBottom: "10px" }}
            >
              <Input
                className="autoComplete"
                label={t("manageMachines.searchmachine").toString()}
                style={{
                  width: "100%",
                }}
                value={filter}
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
              />
            </div>
            {currentUser?.roles.some((role) => role.name === roleType.admin) ? (
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  paddingTop: "10px",
                  paddingBottom: "10px",
                  justifyContent: "center",
                }}
              >
                <button
                  className="addUserHeader"
                  onClick={(e) => {
                    e.preventDefault();
                    dispatch(addNewMachine());
                    setNewMachine(!newMachine);
                    setAddMachine(false);
                  }}
                  disabled={!addMachine}
                  style={
                    !addMachine
                      ? {
                          opacity: 0.3,
                        }
                      : {}
                  }
                >
                  <img src="./icons/plus.svg" alt="" className="AddPlanImage" />
                </button>
              </div>
            ) : null}
          </div>
          <div className="userList">
            {machineListEmpty ? (
              <MachineList
                filteredMachineArray={filteredMachineArray}
                newMachine={newMachine}
                setAddMachine={setAddMachine}
              />
            ) : (
              <div className="userListWrapper">
                <h5 style={{ paddingTop: "50px" }}>
                  {t("manageMachines.noMachines")}
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
