import React from "react";

const MaxHeight: React.FC<any> = () => (
  <svg viewBox="0 0 512 512" style={{ width: "22px", height: "22px" }}><path d="M384 0H96v32h288zm0 479H240.6L320 352h-64V160h64L240 32l-80 128h64v192h-64l79.4 127H96v32h288z"></path></svg>
);

const MinHeight: React.FC<any> = () => (
  <svg viewBox="0 0 512 512" style={{ width: "22px", height: "22px" }}><path d="M240 192H96v32h288v-32zm144 96H96v32h288zM160 448h160l-80-128zm64 0h32v64h-32zm32-384V0h-32v64h-64l80 128 80-128z"></path></svg>
);

const TrashCanIcon: React.FC<any> = () => (
  <svg viewBox="0 0 512 512"><path d="M416 96h-96V64c0-17.6-14.4-32-32-32h-96c-17.6 0-32 14.4-32 32v32H64v64h32v288c0 17.6 14.4 32 32 32h224c17.6 0 32-14.4 32-32V160h32zM192 64h95.9l.1.1V96h-96c.1-.1.1-32.1 0-32m160 384H128.1l-.1-.1V160h32v256h32V160h32v256h32V160h32v256h32V160h32z"></path></svg>
);

const EditIcon: React.FC<any> = () => (
  <svg viewBox="0 0 512 512"><path d="M470.7 402.7 306 238.1c9-18.8 14-39.8 14-62.1 0-79.5-64.5-144-144-144-22.2 0-43.3 5-62.1 14l76.4 76.4c12.4 12.4 12.4 32.8 0 45.3l-22.6 22.6c-12.4 12.4-32.8 12.4-45.3 0L46 113.9c-9 18.8-14 39.9-14 62.1 0 79.5 64.5 144 144 144 22.3 0 43.4-5.1 62.2-14.1l164.7 164.7c12.3 12.5 32.7 12.5 45.1.1l22.7-22.7c12.4-12.4 12.4-32.8 0-45.3M448 425.5 425.4 448 265.8 288.5c8.3-6.7 15.9-14.3 22.6-22.6z"></path></svg>
);

const MinimizeIcon: React.FC<any> = () => (
  <svg viewBox="0 0 512 512"><path d="M416 288v64H96v-64z"></path></svg>
);

const ConfirmIcon: React.FC<any> = () => (
  <svg viewBox="0 0 512 512"><path d="M256 32C132.3 32 32 132.3 32 256s100.3 224 224 224 224-100.3 224-224S379.7 32 256 32m0 416c-106 0-192-86-192-192S150 64 256 64s192 86 192 192-86 192-192 192m-32-154-83-83-45 45 128 128 176-176-45-45z"></path></svg>);

const CancelIcon: React.FC<any> = () => (
  <svg viewBox="0 0 512 512"><path d="M384 338.7 338.7 384 256 301.3 173.3 384 128 338.7l82.7-82.7-82.7-82.7 45.3-45.3 82.7 82.7 82.7-82.7 45.3 45.3-82.7 82.7zM256 32C132.3 32 32 132.3 32 256s100.3 224 224 224 224-100.3 224-224S379.7 32 256 32m0 416c-106 0-192-86-192-192S150 64 256 64s192 86 192 192-86 192-192 192"></path></svg>);

const AddCommentIcon: React.FC<any> = () => (
  <svg viewBox="0 0 512 512">
    <path d="M448 32H64c-17.6 0-32 14.4-32 32v416l96-96h320c17.6 0 32-14.4 32-32V64c0-17.6-14.4-32-32-32m0 319.942a1 1 0 0 1-.058.058H114.745L64 402.745V64.057a1 1 0 0 1 .057-.057h383.885q.031.027.058.057z"></path>
    <path d="M256 138v160" stroke="black" stroke-width="32" stroke-linecap="round" />
    <path d="M176 215h160" stroke="black" stroke-width="32" stroke-linecap="round" />
  </svg>
)


const ExpandCaretIcon: React.FC<any> = () => (
  <svg viewBox="0 0 512 512" width="12" height="12" style={{ marginLeft: '8px' }}><path d="M352 256L160 384V128z"></path></svg>
);

const ExpandedCaretIcon: React.FC<any> = () => (
  <svg viewBox="0 0 512 512" width="12" height="12" style={{ marginLeft: '8px' }}><path d="M256 352 128 160h256z"></path></svg>
);

export {
  MaxHeight,
  MinHeight,
  TrashCanIcon,
  EditIcon,
  MinimizeIcon,
  ConfirmIcon,
  CancelIcon,
  AddCommentIcon,
  ExpandCaretIcon,
  ExpandedCaretIcon
};