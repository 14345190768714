import React from "react";
import {
  Form,
  FormRenderProps,
  FormElement,
  Field,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";

// Redux Stuff
import {
  setCompanyHasChanged,
  saveCompanyToServer,
  reduxDeleteCompany,
  reduxSetSelectedCompany,
} from "../../store/company/actions";
import { useDispatch, useSelector } from "react-redux";
import { appState } from "../../store";
import { companyType } from "../../store/company/types";
import { CustomInputUser } from "../manageUsers/userForm";
import { useTranslation } from "react-i18next";

const nameRegex = new RegExp(/^[0-9a-zA-ZåöäÅÄÖ ]{1,30}$/);

interface companyFormProps {
  setNewCompanyAdded: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CompanyForm: React.FunctionComponent<companyFormProps> = ({
  setNewCompanyAdded,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const notUndefined = (value?: string) => {
    return value && value !== "" && nameRegex.test(value)
      ? ""
      : t("common.notAValidNameValidator");
  };

  const selectedCompany = useSelector(
    (state: appState) => state.company.selectedCompany
  );
  const currentCompany = useSelector(
    (state: appState) => state.company.currentCompany
  );

  const allCompanies = useSelector(
    (state: appState) => state.company.allCompanies
  ).filter((company) => company.id !== currentCompany?.id);

  const company = useSelector((state: appState) =>
    state.company.allCompanies.find((company) => company.id === selectedCompany)
  );
  const companyHasChanged = useSelector(
    (state: appState) => state.company.companyHasChanged
  );

  const currentUser = useSelector((state: appState) => state.user.currentUser);

  const renderFormContent = (formRenderProps: FormRenderProps) => {
    const { valid } = formRenderProps;
    return (
      <FormElement className="userCardUser" style={{ width: "100%" }}>
        <div className="userCardColumnL">
          <legend className={"k-form-legend"} style={{ color: "black" }}>
            {company && company.sentToServer
              ? t("common.update") + " " + t("manageCompanies.company")
              : t("common.add") + " " + t("manageCompanies.company")}
          </legend>
          <Field
            name={"name"}
            component={CustomInputUser}
            onBlur={() => dispatch(setCompanyHasChanged(true))}
            label={t("manageCompanies.companyName")}
            validator={notUndefined}
            prop
          />
          <Field
            name={"description"}
            component={Input}
            onBlur={() => dispatch(setCompanyHasChanged(true))}
            label={t("manageCompanies.companyDescription")}
          />

          {!valid ? (
            <div style={{ marginTop: "10px", color: "red" }}>
              {t("common.requiredFields")}
            </div>
          ) : null}
        </div>
        <div className="CardColumnForm">
          {company && company.sentToServer ? (
            <button
              className="AddUserButtonForm BUTTON"
              type={"submit"}
              disabled={!valid || !companyHasChanged}
              style={
                !valid || !companyHasChanged
                  ? {
                      opacity: 0.5,
                    }
                  : {}
              }
              onClick={() => {
                if (setNewCompanyAdded) setNewCompanyAdded(true);
              }}
            >
              {t("common.update")}
            </button>
          ) : (
            <button
              className="AddUserButtonForm BUTTON"
              type={"submit"}
              disabled={!valid}
              style={
                !valid
                  ? {
                      opacity: 0.5,
                    }
                  : {}
              }
              onClick={() => {
                if (setNewCompanyAdded) setNewCompanyAdded(true);
              }}
            >
              {t("common.add")}
            </button>
          )}
        </div>
      </FormElement>
    );
  };

  const renderCompanyContent = (company?: companyType) => {
    return (
      <div className="formContainer">
        <span
          className="k-icon k-i-close removeChart"
          style={{ fontSize: "24px", marginLeft: "95%" }}
          onClick={() => {
            if (!company?.sentToServer && company)
              dispatch(reduxDeleteCompany(company.id));
            dispatch(reduxSetSelectedCompany(""));
            setNewCompanyAdded(true);
          }}
        />
        <Form
          initialValues={{
            name: company?.name,
            description: company?.description,
            type: "inspection",
          }}
          key={JSON.stringify(company)}
          onSubmit={(dataItem) => {
            const company = allCompanies.find(
              (company) => company.id === selectedCompany
            );

            if (dataItem && currentUser) {
              dispatch(
                saveCompanyToServer(
                  t,
                  dataItem.name,
                  dataItem.type,
                  dataItem?.description,
                  company?.id,
                  company?.sentToServer
                )
              );
            }
          }}
          render={(formRenderProps) => renderFormContent(formRenderProps)}
        />
      </div>
    );
  };

  return <div className="userCardUser">{renderCompanyContent(company)}</div>;
};
