import {
  announcementResponseType,
  languageType,
  userAction,
  userActionType,
  userState,
} from "./types";

const initialState: userState = {
  currentUser: undefined,
  authenticationSuccess: true,
  allCompanyUsers: [
    {
      companyID: "31166ee3-fd93-4044-8a44-ec38c5cf8bad",
      createdAt: "2021-09-22T06:46:08.590395Z",
      email: "tms19@redemptor.se",
      firstName: "admin",
      id: "e209a8e8-01ec-4415-86fc-7d6b5bb3184f",
      lastName: "",
      roles: [],
      syncedAt: "2021-09-22T06:46:08.590261Z",
      updatedAt: "2021-09-22T06:46:08.590395Z",
      sentToServer: false,
    },
  ],
  allContacts: [
    {
      companyID: "31166ee3-fd93-4044-8a44-ec38c5cf8bad",
      createdAt: "2021-09-22T06:46:08.590395Z",
      email: "tms19@redemptor.se",
      firstName: "admin",
      id: "e209a8e8-01ec-4415-86fc-7d6b5bb3184f",
      lastName: "",
      roles: [],
      syncedAt: "2021-09-22T06:46:08.590261Z",
      updatedAt: "2021-09-22T06:46:08.590395Z",
      sentToServer: false,
    },
  ],
  selectedUser: "-1",
  userHasChanged: false,
  notification: { message: "", style: "success", open: false },
  announcementResponse: announcementResponseType.show,
  messages: [],
  selectedMessage: "-1",
  informationRequest: { showInformation: false, path: undefined },
  selectedLanguage: languageType.englishGB,
  announcementsCount: 0,
};

export function userReducer(
  state: userState = initialState,
  action: userAction
): userState {
  switch (action.type) {
    case userActionType.Login:
      return {
        ...state,
        currentUser: action.payload,
      };
    case userActionType.Logout:
      return {
        ...state,
        currentUser: undefined,
      };
    case userActionType.setallCompanyUsers:
      return {
        ...state,
        allCompanyUsers: action.payload,
      };
    case userActionType.setAllContacts:
      return {
        ...state,
        allContacts: action.payload.map((user) => {
          return { ...user, sentToServer: true };
        }),
        selectedUser: "",
        userHasChanged: false,
      };

    case userActionType.deleteUser:
      return {
        ...state,
        allCompanyUsers: state.allCompanyUsers.filter(
          (user) => user.id !== action.payload
        ),
        allContacts: state.allContacts.filter(
          (user) => user.id !== action.payload
        ),
      };
    case userActionType.setSelectedUser:
      return {
        ...state,
        selectedUser: action.payload,
      };
    case userActionType.updateUserProperty:
      return {
        ...state,
        allContacts: state.allContacts.map((user) => {
          return user.id === state.selectedUser
            ? {
                ...user,
                ...action.payload,
              }
            : user;
        }),
      };
    case userActionType.updateCurrentUserProperty:
      return {
        ...state,
        currentUser: { ...state.currentUser, ...action.payload },
      };
    case userActionType.addNewReduxUser:
      return {
        ...state,
        allContacts: [...state.allContacts, { ...action.payload }],
        selectedUser: action.payload.id,
        userHasChanged: true,
      };
    case userActionType.setUserHasChanged:
      return {
        ...state,
        userHasChanged: action.payload,
      };
    case userActionType.setAuthenticationSuccess:
      return {
        ...state,
        authenticationSuccess: action.payload,
      };
    case userActionType.setNotification:
      return {
        ...state,
        notification: action.payload,
      };
    case userActionType.setAnnouncementResponse:
      return {
        ...state,
        announcementResponse: action.payload,
      };
    case userActionType.setMessages:
      return {
        ...state,
        messages: action.payload,
      };
    case userActionType.setSelectedMessage:
      return {
        ...state,
        selectedMessage: action.payload,
      };
    case userActionType.addNewMessage:
      return {
        ...state,
        messages: [action.payload, ...state.messages],
        selectedMessage: action.payload.id,
      };
    case userActionType.setInformationRequest:
      return {
        ...state,
        informationRequest: action.payload,
      };
    case userActionType.setSelectedLanguage:
      return {
        ...state,
        selectedLanguage: action.payload,
      };
    case userActionType.setAnnouncementsCount:
      return {
        ...state,
        announcementsCount: action.payload,
      };
    default:
      return state;
  }
}
