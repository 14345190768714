import React, { useState } from "react";
import {
  reduxSetMessages,
  reduxSetNotification,
  setSelectedMessage,
} from "../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import "./adminMessage.css";
import {
  Field,
  FieldRenderProps,
  FieldWrapper,
  Form,
  FormElement,
  FormRenderProps,
} from "@progress/kendo-react-form";
import { FloatingLabel, Hint, Label } from "@progress/kendo-react-labels";
import { Checkbox, Input, TextArea } from "@progress/kendo-react-inputs";
import { DatePicker } from "@progress/kendo-react-dateinputs";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { appState } from "../../store";
import * as announcementsAPI from "../../api/netRail/announcements";
import { announcementType } from "../../store/user/types";
import { useTranslation } from "react-i18next";

interface AdminMessageProps {}
const AdminMessage: React.FunctionComponent<AdminMessageProps> = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const textAreaValidator = (value: string) =>
    !value ? t("adminMessage.writeAMessage") : "";

  const titleValidator = (value: string) =>
    !value ? t("adminMessage.selectATitle") : "";

  const mustSelectOneDate = (value: string) =>
    !value ? t("adminMessage.mustSelectADate") : "";

  const allCompanies = useSelector(
    (state: appState) => state.company.allCompanies
  );
  const selectedMessage = useSelector(
    (state: appState) => state.user.selectedMessage
  );
  const allMessages = useSelector((state: appState) => state.user.messages);

  const message = allMessages.find((message) => message.id === selectedMessage);

  const [sendEmail, setSendEmail] = useState(false);

  const FormDatePicker = (fieldRenderProps: FieldRenderProps) => {
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      type,
      optional,
      max,
      value,
      ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;

    return (
      <FieldWrapper style={{ width: "49%" }}>
        <Label
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        <div className={"k-form-field-wrap"} style={{ color: "red" }}>
          <DatePicker valid={valid} value={value} id={id} {...others} />
          {showValidationMessage && validationMessage}
        </div>
      </FieldWrapper>
    );
  };

  const FormTitle = (fieldRenderProps: FieldRenderProps) => {
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      type,
      optional,
      max,
      value,
      ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hindId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";

    return (
      <FieldWrapper>
        <div className={"k-form-field-wrap"}>
          <FloatingLabel
            label={label}
            editorId={id}
            editorValue={value ? value.toString() : value}
          >
            <Input
              valid={valid}
              value={value}
              id={id}
              disabled={disabled}
              ariaDescribedBy={`${hindId} ${errorId}`}
              {...others}
            />
          </FloatingLabel>
          <Hint direction={"end"}>
            {value ? value.length : 0} / {max}
          </Hint>
          <div style={{ color: "red" }}>
            {showValidationMessage && validationMessage}
          </div>
        </div>
      </FieldWrapper>
    );
  };

  const FormCompanySelector = (fieldRenderProps: FieldRenderProps) => {
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      optional,
      value,
      onChange,
    } = fieldRenderProps;

    return (
      <FieldWrapper style={{ width: "49%" }}>
        <Label
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "100%",
            justifyContent: "space-between",
          }}
        >
          <div className={"k-form-field-wrap"} style={{ width: "100%" }}>
            <MultiSelect
              valid={valid}
              data={allCompanies.map((company) => {
                return company.name;
              })}
              onChange={onChange}
              value={value}
              autoClose={false}
              disabled={disabled}
            />
          </div>
        </div>
      </FieldWrapper>
    );
  };

  const FormMessageTypeSelector = (fieldRenderProps: FieldRenderProps) => {
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      optional,
      onChange,
      value,
    } = fieldRenderProps;

    // general urgent
    const showValidationMessage = touched && validationMessage;

    return (
      <FieldWrapper style={{ width: "49%" }}>
        <Label
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        <div className={"k-form-field-wrap"}>
          <DropDownList
            defaultItem={announcementType.general}
            valid={valid}
            data={[announcementType.urgent]}
            value={value}
            onChange={onChange}
            id={id}
            disabled={disabled}
          />
          <div style={{ color: "red" }}>
            {showValidationMessage && validationMessage}
          </div>
        </div>
      </FieldWrapper>
    );
  };

  const FormTextArea = (fieldRenderProps: FieldRenderProps) => {
    const {
      validationMessage,
      touched,
      label,
      id,
      valid,
      disabled,
      hint,
      type,
      optional,
      max,
      value,
      ...others
    } = fieldRenderProps;

    const showValidationMessage = touched && validationMessage;
    const showHint = !showValidationMessage && hint;
    const hindId = showHint ? `${id}_hint` : "";
    const errorId = showValidationMessage ? `${id}_error` : "";

    return (
      <FieldWrapper>
        <Label
          editorId={id}
          editorValid={valid}
          editorDisabled={disabled}
          optional={optional}
        >
          {label}
        </Label>
        <div className={"k-form-field-wrap"}>
          <TextArea
            valid={valid}
            id={id}
            disabled={disabled}
            rows={4}
            value={value}
            ariaDescribedBy={`${hindId} ${errorId}`}
            {...others}
          />
          <Hint direction={"end"}>
            {value ? value.length : 0} / {max}
          </Hint>
          <div style={{ color: "red" }}>
            {showValidationMessage && validationMessage}
          </div>
        </div>
      </FieldWrapper>
    );
  };

  // Convert to the states which we want the user to see.
  let initialValues;
  if (message) {
    initialValues = {
      ...message,
      expiresAt: new Date(message.expiresAt), // TODO UTC TIME!!
      validAt: new Date(message.validAt), // TODO UTC TIME!!
      companies: message.companies.map((companyID) => {
        const currElementCompany = allCompanies.find(
          (allCompaniesCompany) => allCompaniesCompany.id === companyID
        );
        return currElementCompany?.name;
      }),
    };
  } else {
    initialValues = { type: announcementType.general };
  }

  return (
    <div className="AdminMessageContainer">
      <div className="AnnouncementCardContainer">
        <Form
          initialValues={initialValues}
          onSubmit={async (dataItem: any) => {
            if (message?.sentToServer) {
              // patch message
              try {
                const payloadToPatch = {
                  ...dataItem,
                  companies: dataItem.companies.map((companyName: string) => {
                    const currCompany = allCompanies.find(
                      (allCompaniesCompany) =>
                        allCompaniesCompany.name === companyName
                    );
                    return currCompany?.id;
                  }),
                };

                const patchedMessage = await announcementsAPI.patchAnnouncement(
                  selectedMessage,
                  payloadToPatch
                );

                if (sendEmail) {
                  announcementsAPI.sendAnnouncementEmail(patchedMessage.id);
                }

                // Remove the old new message and set the one with the "true" id from the server
                dispatch(
                  reduxSetMessages(
                    allMessages
                      .filter((msg) => msg.id !== selectedMessage)
                      .concat(patchedMessage)
                  )
                );
                dispatch(setSelectedMessage("-1"));

                dispatch(
                  reduxSetNotification({
                    message: t("adminMessage.updatedMessage"),
                    style: "success",
                    open: true,
                  })
                );
              } catch (err) {
                dispatch(
                  reduxSetNotification({
                    message: t("adminMessage.coudNotUpdateMessage") + err,
                    style: "error",
                    open: true,
                  })
                );
                console.log("err", err);
              }
            } else {
              try {
                const postedMessage = await announcementsAPI.postAnnouncement({
                  ...dataItem,
                  companies: dataItem.companies.map((companyName: string) => {
                    const currCompany = allCompanies.find(
                      (allCompaniesCompany) =>
                        allCompaniesCompany.name === companyName
                    );
                    return currCompany?.id;
                  }),
                });

                // Remove the old new message and set the one with the "true" id from the server
                dispatch(
                  reduxSetMessages(
                    allMessages
                      .filter((msg) => msg.id !== selectedMessage)
                      .concat(postedMessage)
                  )
                );

                if (sendEmail) {
                  announcementsAPI.sendAnnouncementEmail(postedMessage.id);
                }

                dispatch(setSelectedMessage("-1"));

                dispatch(
                  reduxSetNotification({
                    message: t("adminMessage.sentMessage"),
                    style: "success",
                    open: true,
                  })
                );
              } catch (err) {
                dispatch(
                  reduxSetNotification({
                    message: t("adminMessage.couldNotSendMessage") + err,
                    style: "error",
                    open: true,
                  })
                );

                console.log("err", err);
              }
            }
          }}
          render={(formRenderProps: FormRenderProps) => (
            <FormElement>
              <fieldset className={"k-form-fieldset"}>
                <div className="SplitRow">
                  <Field
                    id={"validAt"}
                    name={"validAt"}
                    component={FormDatePicker}
                    label={t("adminMessage.startsBeingValid").toString()}
                    validator={mustSelectOneDate}
                  />
                  <Field
                    id={"expiresAt"}
                    name={"expiresAt"}
                    component={FormDatePicker}
                    label={t("adminMessage.startsBeingValid").toString()}
                  />
                  <span
                    className="k-icon k-i-edit removeChart"
                    style={{
                      fontSize: "24px",
                    }}
                    onClick={() => {
                      dispatch(setSelectedMessage("-1"));
                    }}
                  />
                  <span
                    className="k-icon k-i-delete removeChart"
                    style={{
                      fontSize: "24px",
                    }}
                    onClick={() => {
                      // If message in server, delete it
                      if (message?.sentToServer)
                        announcementsAPI.deleteAnnouncement(selectedMessage);

                      // Update redux
                      dispatch(
                        reduxSetMessages(
                          allMessages.filter(
                            (msg) => msg.id !== selectedMessage
                          )
                        )
                      );
                    }}
                  />
                </div>
                <div className="SplitRow">
                  <Field
                    id={"companies"}
                    name={"companies"}
                    component={FormCompanySelector}
                    label={t("adminMessage.receivingOrganisations")}
                  />
                  <Field
                    id={"type"}
                    name={"type"}
                    component={FormMessageTypeSelector}
                    label={t("adminMessage.typeOfMessage")}
                  />
                </div>

                <Field
                  id={"title"}
                  name={"title"}
                  label={t("adminMessage.subject").toString()}
                  max={40}
                  component={FormTitle}
                  validator={titleValidator}
                />
                <Field
                  id={"message"}
                  name={"message"}
                  label={t("adminMessage.message").toString()}
                  max={300}
                  component={FormTextArea}
                  validator={textAreaValidator}
                />
                <div className="k-form-buttons k-justify-content-end">
                  <Checkbox
                    onChange={() => {
                      setSendEmail(!sendEmail);
                    }}
                    label={t("adminMessage.sendEmail").toString()}
                  />
                  <button
                    className="BUTTON"
                    type={"submit"}
                    disabled={!formRenderProps.allowSubmit}
                    style={{ opacity: !formRenderProps.allowSubmit ? 0.5 : 1 }}
                  >
                    {message?.sentToServer
                      ? t("common.update")
                      : t("common.send")}
                  </button>
                </div>
              </fieldset>
            </FormElement>
          )}
        />
      </div>
    </div>
  );
};

export default AdminMessage;
