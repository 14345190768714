import React, { useEffect, useState } from "react";
import "./style.scss";
import { useDispatch, useSelector } from "react-redux";
import { appState } from "../../store";
import {
  adminMessageType,
  announcementResponseType,
  announcementType,
  userType,
} from "./../../store/user/types";
import {
  reduxSetAnnouncementResponse,
  reduxSetAnnouncementsCount,
} from "../../store/user/actions";
import { customThumb, customThumbHorizontal } from "../manageUsers/userList";
import { positionValues, Scrollbars } from "react-custom-scrollbars";
import { useLocation } from "react-router-dom";
import * as announcementsAPI from "../../api/netRail/announcements";
import { Zoom } from "@progress/kendo-react-animation";
import { count } from "console";
import { useTranslation } from "react-i18next";

interface AdminAnnouncementProps {
  user?: userType;
}

export const fetchStoredAnnouncement = () => {
  const storedAnnouncements = localStorage.getItem("storedAnnouncements");
  let storedAnnouncementsArr: string[];
  if (storedAnnouncements) {
    storedAnnouncementsArr = JSON.parse(storedAnnouncements);
    return storedAnnouncementsArr;
  } else {
    return [];
  }
};

export const AdminAnnouncement: React.FunctionComponent<
  AdminAnnouncementProps
> = () => {
  const { t } = useTranslation("translation", { keyPrefix: "announcement" });
  const [bottomReached, setBottomReached] = useState(false);
  const [announcementList, setAnnouncementList] = useState<adminMessageType[]>(
    []
  );
  const [dismissClicked, setDismissClicked] = useState<number>(0);
  const [latestAnnouncement, setLatestAnnouncement] =
    useState<adminMessageType>();
  const [reRender, setReRender] = useState(false);

  const dispatch = useDispatch();
  const location = useLocation();

  const announcementsCount = useSelector(
    (state: appState) => state.user.announcementsCount
  );

  const Today = new Date();

  const announcementResponse = useSelector((state: appState) => {
    return state.user.announcementResponse;
  });

  async function getAPIAnnouncementsLocally() {
    try {
      const announcements = await announcementsAPI.getAnnouncements();
      const storedAnnouncements = fetchStoredAnnouncement();
      const sortedAnnouncementList = announcements
        .filter((announcement) => {
          return (
            new Date(announcement.expiresAt) >= Today &&
            new Date(announcement.validAt) <= Today &&
            announcement.type === announcementType.urgent
          );
        })
        .sort((a, b) => (a.type > b.type ? 1 : b.type > a.type ? -1 : 0))
        .reverse();
      if (storedAnnouncements.length > 0) {
        const sortedAnnouncementListFiltered = sortedAnnouncementList.filter(
          (announcement) => {
            return !storedAnnouncements.includes(announcement.id);
          }
        );
        setAnnouncementList(sortedAnnouncementListFiltered);
      } else {
        setAnnouncementList(sortedAnnouncementList);
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  useEffect(() => {
    getAPIAnnouncementsLocally();
  }, []);

  useEffect(() => {
    setLatestAnnouncement(announcementList[dismissClicked]);
  }, [announcementList, dismissClicked]);

  const handleUpdate = (values: positionValues) => {
    const { scrollTop, scrollHeight, clientHeight } = values;
    const pad = 5;
    const t = (scrollTop + pad) / (scrollHeight - clientHeight);
    if (t > 1) {
      setBottomReached(true);
    } else {
      setBottomReached(false);
    }
  };

  const renderAnnouncementText = () => {
    return (
      <div className="announcementTextContainer">
        <Scrollbars
          className="scrollBarContainer"
          thumbSize={0}
          renderThumbVertical={customThumb}
          autoHide={true}
          autoHideTimeout={1000}
          autoHideDuration={500}
          onUpdate={handleUpdate}
          renderThumbHorizontal={customThumbHorizontal}
        >
          <div
            style={{
              fontWeight: "normal",
              fontSize: "14px",
              color: "black",
            }}
          >
            {
              t("numberOfAnnouncements", {
                count: dismissClicked + 1,
                countTot: announcementList.length,
              })
              //   `Meddelande ${dismissClicked + 1} av ${
              //   announcementList.length
              // }`
            }
          </div>
          <h3 style={{ color: "black" }}>{latestAnnouncement?.title}</h3>
          {latestAnnouncement?.type === announcementType.urgent ? (
            <div className="urgentMessage">
              {t("importantInformation")}
              <span
                className="k-icon k-i-warning"
                style={{
                  fontSize: "20px",
                  paddingLeft: "10px",
                }}
              />
            </div>
          ) : null}
          <br />
          <div className="messageBody" style={{ color: "black" }}>
            {latestAnnouncement?.message}
          </div>
        </Scrollbars>
      </div>
    );
  };

  const renderAnnouncementNotification = () => {
    const storedAnnouncements = fetchStoredAnnouncement();
    return (
      <div
        className={
          !bottomReached
            ? "announcement-container announcementContainerShadow"
            : "announcement-container "
        }
        style={
          location.pathname === "/dashboard"
            ? { width: "80vw" }
            : {
                width: "90vw",
              }
        }
      >
        <div className="announcementCard">
          {renderAnnouncementText()}

          <div
            className="announcementButtonContainer"
            style={{ marginBottom: "auto", marginTop: "0" }}
          >
            <span
              className="k-icon k-i-close removeChart"
              style={{ fontSize: "24px" }}
              onClick={() => {
                if (storedAnnouncements.length > 0) {
                  if (
                    latestAnnouncement &&
                    !storedAnnouncements.includes(latestAnnouncement?.id)
                  ) {
                    storedAnnouncements.push(latestAnnouncement?.id);

                    localStorage.setItem(
                      "storedAnnouncements",
                      JSON.stringify(storedAnnouncements)
                    );
                    dispatch(
                      reduxSetAnnouncementsCount(announcementsCount - 1)
                    );
                  }
                } else {
                  if (latestAnnouncement) {
                    storedAnnouncements.push(latestAnnouncement?.id);

                    localStorage.setItem(
                      "storedAnnouncements",
                      JSON.stringify(storedAnnouncements)
                    );
                    dispatch(
                      reduxSetAnnouncementsCount(announcementsCount - 1)
                    );
                  }
                }
                if (dismissClicked === announcementList.length - 1) {
                  dispatch(
                    reduxSetAnnouncementResponse(
                      announcementResponseType.dismiss
                    )
                  );
                } else {
                  setDismissClicked(dismissClicked + 1);
                  setReRender(true);
                  setTimeout(() => {
                    setReRender(false);
                  }, 200);
                }
              }}
            />
          </div>
        </div>
      </div>
    );
  };

  const childAnnouncementNotification = !reRender
    ? renderAnnouncementNotification()
    : null;
  const animationAnnouncementNotification = (
    <Zoom>{childAnnouncementNotification}</Zoom>
  );

  const renderAnnouncementAnimation = () => {
    return (
      <div className="announcement-container-wrapper">
        {animationAnnouncementNotification}
      </div>
    );
  };

  return announcementResponse === announcementResponseType.show &&
    latestAnnouncement
    ? renderAnnouncementAnimation()
    : null;
};
