import React, { useEffect, useState } from "react";
import "./style.scss";

import {
  Form,
  FormRenderProps,
  FormElement,
  Field,
} from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import {
  onLogin,
  reduxSetAllCompanyUsers,
  reduxSetNotification,
} from "./../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { appState } from "../../store";
import { useCallback } from "react";

import { Auth } from "aws-amplify";
import { Spinner } from "../../components/spinner/spinner";
import * as usersAPI from "../../api/netRail/users";
import { CustomInputUser } from "../../components/manageUsers/userForm";
import { useTranslation } from "react-i18next";
import { TFunction } from "i18next";
import { setAllReduxPlans } from "../../store/scheduler/actions";
import { reduxSetAllCompanies } from "../../store/company/actions";
import { reduxSetAllMachines } from "../../store/machine/actions";
const verificationCodeTimerMinutes = 2;

/**
 * App.tsx ska configureAmplify() finnas.
 * amplify.ts ska finnas med.
 * I denna filen typ allt ska sparas :)
 *
 * TODO::
 * Kontrollera att telefonnumret är korrekt, om någon ger fel telefonnummer, ge möjlighet att ändra?
 * Logik - get session etcetc (med app.tsx)
 *
 */

const emailRegex = new RegExp(/\S+@\S+/);

enum errorCodes {
  signInError = 1,
  getPrefferedMFAError = 2,
  verifyPhoneNumberSigninError = 3,
  completeNewPasswordError = 4,
  verifyPhoneNumberNewPWError = 5,
  verifyPNSubmitError = 6,
  setPrefferedMFAError = 7,
  confirmMFAError = 8,
  forgotPasswordError = 9,
  forgotPWSubmitError = 10,
  updatePhoneNumberError = 11,
  verifyPhoneNumberUpdatePNError = 12,
  verifyPhoneNumberResendCodeError = 13,
}

const getErrorMessage = (
  t: TFunction<"translation", undefined, "translation">,
  err: any,
  code = 999
) => {
  let errorMessage = "";
  console.error("Error with code " + code, err);
  if (err.code === "UserNotConfirmedException") {
    // The error happens if the user didn't finish the confirmation step when signing up
    // In this case you need to resend the code and confirm the user
    // About how to resend the code and confirm the user, please check the signUp part
    errorMessage = t("loginPage.userNotVerified");
  } else if (err.code === "PasswordResetRequiredException") {
    // The error happens when the password is reset in the Cognito console
    // In this case you need to call forgotPassword to reset the password
    // Please check the Forgot Password part.
    errorMessage = t("loginPage.passwordResetRequired");
  } else if (err.code === "NotAuthorizedException") {
    // The error happens when the incorrect password is provided
    errorMessage = t("loginPage.wrongUsernameOrPassword");
  } else if (err.code === "UserNotFoundException") {
    // The error happens when the supplied username/email does not exist in the Cognito user pool
    errorMessage = t("loginPage.wrongEmail");
  } else if (err.code === "CodeMismatchException") {
    errorMessage = t("loginPage.wrongAuthenticationCode");
  } else if (err.code === "NetworkError") {
    errorMessage = t("loginPage.networkError");
  } else {
    errorMessage = t("loginPage.unexpectedError") + ` ${code}`;
  }
  return errorMessage;
};

export const Login: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const newPasswordValidator = (value?: string) => {
    let error = "";
    if (value === undefined) return t("user.fillInPassword");
    const error0 = value.length < 8 ? t("user.atleast8Char") + "\n" : "";
    const error1 = !new RegExp("(?=.*[a-z])").test(value)
      ? t("user.lowerCase") + "\n"
      : "";
    const error2 = !new RegExp("(?=.*[A-Z])").test(value)
      ? t("user.upperCase") + "\n"
      : "";
    const error3 = !new RegExp("(?=.*[0-9].*)").test(value)
      ? t("user.number") + "\n"
      : "";
    const error4 = !new RegExp("(?=.*[-+_!@#$%^&*.,?])").test(value)
      ? t("user.specialCharacter") + "\n"
      : "";
    error = error0 + error1 + error2 + error3 + error4;
    return error;
  };

  const emailValidator = (value?: string) =>
    value && emailRegex.test(value) ? "" : t("common.emailValidator");

  const notEmptyValidator = (input?: string) =>
    input && input !== "" ? "" : t("common.requiredField");

  const passwordValidator = (value?: string) => {
    if (value === undefined) return t("user.fillInPassword");
    if (value.length < 8) return t("user.atleast8Char");
    return "";
  };

  const phoneNumberValidator = (value?: string) => {
    if (value === undefined) return t("common.phoneValidator");
    return !new RegExp("\\+(46)\\d{9}$").test(value) && value.length < 12
      ? t("common.phoneValidatorFormat")
      : "";
  };

  const currentUser = useSelector((state: appState) => state.user.currentUser);
  useEffect(() => {
    if (currentUser === undefined) {
      dispatch(setAllReduxPlans([]));
      dispatch(reduxSetAllCompanyUsers([]));
      dispatch(reduxSetAllCompanies([]));
      dispatch(reduxSetAllMachines([]));
    }
  }, [currentUser]);

  const history = useHistory();
  const [newPasswordRequired, setNewPasswordRequired] = useState(false);
  const [forgotPassword, setForgotPassword] = useState(false);
  const [mfaRequired, setMfaRequired] = useState(false);
  const [verifyPhoneNumberRequired, setVerifyPhoneNumberRequired] =
    useState(false);
  const [initialValue, setInitialValues] = useState<any>(undefined);
  const [cognitoUser, setCognitoUser] = useState<any | undefined>(undefined);
  const [submitNewPassword, setSubmitNewPassword] = useState(false);
  const [showSpinner, setSpinner] = useState<boolean>(false);
  const [credentials, setCredentials] = useState<
    { email: string; pw: string } | undefined
  >(undefined);
  const [timer, setTimer] = useState<NodeJS.Timeout | undefined>(undefined);
  const [isTimerDone, setIsTimerDone] = useState<boolean>(false);
  const [resetPhoneNumber, setResetPhoneNumber] = useState<boolean>(false);
  const [authError, setAuthError] = useState("");
  const [hasBeenPressed, setHasBeenPressed] = useState(false);
  const [viewNewPassword, setViewNewPassword] = useState(false);

  useEffect(() => {
    // Check if user already is logged in. If so, redirect to last page
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user !== undefined && user.attributes.phone_number_verified) {
          history.push("/scheduler");
        } else if (user !== undefined) {
          history.replace("/login");
        }
      })
      .catch((err) => void 0);
  }, []);

  useEffect(() => {
    // Check if user already is logged in. If so, redirect to last page
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (user !== undefined) {
          history.push("/scheduler");
        }
      })
      .catch((err) => void 0);
  }, []);

  const handleError = useCallback(
    (err, code = 999) => {
      const errorMessage = getErrorMessage(t, err, code);
      dispatch(
        reduxSetNotification({
          style: "error",
          message: errorMessage,
          open: true,
        })
      );
      setAuthError(errorMessage);
    },
    [dispatch]
  );

  const signInCallback = useCallback(
    (dataItem) => {
      setSpinner(true);

      Auth.signIn(dataItem.email, dataItem.password)
        .then((user) => {
          setCredentials({ email: dataItem.email, pw: dataItem.password });
          setCognitoUser(user);
          if (user.challengeName === "NEW_PASSWORD_REQUIRED") {
            setNewPasswordRequired(true);
            const userAttributes = user.challengeParam.userAttributes;
            setInitialValues({
              name: userAttributes.name,
              familyName: userAttributes.family_name,
              phoneNumber:
                userAttributes.phone_number !== ""
                  ? userAttributes.phone_number
                  : "+46",
              email: dataItem.email,
              password: dataItem.password,
            });
            dispatch(
              reduxSetNotification({
                style: "warning",
                message: t("loginPage.sessionTimeoutReminder"),
                open: true,
              })
            );

            setTimer(
              setTimeout(() => {
                dispatch(
                  reduxSetNotification({
                    style: "error",
                    message: t("loginPage.sessionTimedout"),
                    open: true,
                  })
                );
                history.push("/scheduler");
              }, 179900) // sub 3 min expiration time 179900
            );
          } else if (user.challengeName === "SMS_MFA") {
            // console.log("SMS_MFA required");
            Auth.currentAuthenticatedUser()
              .then((authenticatedUser) => {
                // If user is authenticated at this step, they have not verified their phone number:
                if (
                  authenticatedUser &&
                  authenticatedUser.attributes.phone_number_verified ===
                    undefined
                ) {
                  setCognitoUser(authenticatedUser);

                  Auth.verifyUserAttribute(authenticatedUser, "phone_number")
                    .then((user) => {
                      setVerifyPhoneNumberRequired(true);
                      setIsTimerDone(false);
                      if (timer) clearTimeout(timer);
                      setTimer(
                        setTimeout(() => {
                          setIsTimerDone(true);
                        }, verificationCodeTimerMinutes * 60000 + 2000)
                      );
                    })
                    .catch((err) => console.log("No challenge", err));
                }
              })
              .catch((error) => {
                // If user isn't authenticated then they need to use the MFA to login.
                setMfaRequired(true);
                setIsTimerDone(false);
                if (timer) clearTimeout(timer);
                setTimer(
                  setTimeout(() => {
                    setIsTimerDone(true);
                  }, verificationCodeTimerMinutes * 60000 + 2000)
                );
              });
          } else {
            dispatch(onLogin(t));
          }
        })
        .catch((err) => {
          setSpinner(false);
          handleError(err, errorCodes.signInError);
        });
    },
    [timer, handleError]
  );

  const mfaRequiredCallback = useCallback(
    async (dataItem) => {
      if (cognitoUser) {
        if (verifyPhoneNumberRequired) {
          Auth.verifyUserAttributeSubmit(
            cognitoUser,
            "phone_number",
            dataItem.mfaCode
          )
            .then((resp) => {
              setVerifyPhoneNumberRequired(false);
              Auth.setPreferredMFA(cognitoUser, "SMS")
                .then((r) => {
                  dispatch(onLogin(t));
                })
                .catch((err) => {
                  handleError(err, errorCodes.setPrefferedMFAError);
                });
            })
            .catch((err) => {
              handleError(err, errorCodes.verifyPNSubmitError);
            });
        } else {
          Auth.confirmSignIn(cognitoUser, dataItem.mfaCode, "SMS_MFA")
            .then((user) => {
              setMfaRequired(false);
              Auth.rememberDevice()
                .then((device) => void 0)
                .catch((error) => {
                  console.log("Error occurred when remembering device: ", error);
                });

              dispatch(onLogin(t));
            })
            .catch((err) => {
              handleError(err, errorCodes.confirmMFAError);
            });
        }
      }
    },
    [cognitoUser, verifyPhoneNumberRequired, handleError]
  );

  const newPasswordRequiredCallback = useCallback(
    (dataItem) => {
      if (cognitoUser) {
        const userAttributes = {
          name: dataItem.name,
          family_name: dataItem.familyName,
          phone_number: dataItem.phoneNumber,
        };
        Auth.completeNewPassword(
          cognitoUser, // the Cognito User Object
          dataItem.newPassword, // the new password
          userAttributes
        )
          .then((user) => {
            setCredentials((credentials) =>
              credentials
                ? { ...credentials, pw: dataItem.newPassword }
                : { email: dataItem.email, pw: dataItem.newPassword }
            );
            // On first login, need to sync user with the db:
            try {
              usersAPI.patchUser(user.username, {
                firstName: userAttributes.name,
                lastName: userAttributes.family_name,
                email: dataItem.email,
                phone: dataItem.phoneNumber,
              });
            } catch (reason) {
                // We just want to catch
            }

            if (user.challengeName === "SMS_MFA") {
              setMfaRequired(true);
              setIsTimerDone(false);
              if (timer) clearTimeout(timer);
              setTimer(
                setTimeout(() => {
                  setIsTimerDone(true);
                }, verificationCodeTimerMinutes * 60000 + 2000)
              );
            } else {
              Auth.verifyUserAttribute(user, "phone_number")
                .then((r) => {
                  setVerifyPhoneNumberRequired(true);
                  setIsTimerDone(false);
                  if (timer) clearTimeout(timer);
                  setTimer(
                    setTimeout(() => {
                      setIsTimerDone(true);
                    }, verificationCodeTimerMinutes * 60000 + 2000)
                  );
                })
                .catch((err) => {
                  handleError(err, errorCodes.verifyPhoneNumberNewPWError);
                });
            }
            setNewPasswordRequired(false);
          })
          .catch((err) => {
            handleError(err, errorCodes.completeNewPasswordError);
          });
      }
    },
    [cognitoUser, timer, handleError]
  );

  const handleForgotPassword = useCallback((dataItem) => {
    Auth.forgotPassword(dataItem.email)
      .then((data) => {
        setForgotPassword(false);
        setSubmitNewPassword(true);
      })
      .catch((err) => {
        handleError(err, errorCodes.forgotPasswordError);
      });
  }, []);

  const handleForgotPasswordSubmit = useCallback(
    (dataItem) => {
      Auth.forgotPasswordSubmit(
        dataItem.email,
        dataItem.code,
        dataItem.newPassword
      )
        .then((data) => {
          setSubmitNewPassword(false);
        })
        .catch((err) => {
          handleError(err, errorCodes.forgotPWSubmitError);
        });
    },
    [handleError]
  );

  const handleResetPhoneNumberCallback = useCallback(
    (dataItem) => {
      if (cognitoUser) {
        Auth.updateUserAttributes(cognitoUser, {
          phone_number: dataItem.phoneNumber,
        })
          .then((r) => {
            Auth.verifyUserAttribute(cognitoUser, "phone_number")
              .then((r) => {
                setResetPhoneNumber(false);
                setVerifyPhoneNumberRequired(true);
                setIsTimerDone(false);
                if (timer) clearTimeout(timer);
                setTimer(
                  setTimeout(() => {
                    setIsTimerDone(true);
                  }, verificationCodeTimerMinutes * 60000 + 2000)
                );
              })
              .catch((err) => {
                handleError(err, errorCodes.verifyPhoneNumberUpdatePNError);
              });
          })
          .catch((err) => {
            handleError(err, errorCodes.updatePhoneNumberError);
          });
      }
    },
    [cognitoUser, timer, handleError]
  );

  useEffect(() => {
    if (cognitoUser) setSpinner(false);
  }, [cognitoUser]);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        if (
          currentUser &&
          (history.location.pathname === "/" ||
            history.location.pathname === "/login") &&
          user.attributes.phone_number_verified !== undefined
        )
          history.replace("/scheduler");
      })
      .catch((err) => {
        console.log("Failed to push to dashboard");
      });
  }, [currentUser]);

  const renderLoginContent = (formRenderProps: FormRenderProps) => {
    return (
      <FormElement className="LoginForm">
        <Field
          name={"email"}
          component={Input}
          label={t("loginPage.email")}
          validator={emailValidator}
        />
        <Field
          name={"password"}
          component={Input}
          type="password"
          label={t("loginPage.password")}
          validator={passwordValidator}
        />
        <div style={{ color: "red" }}>
          {authError && authError.length > 0 ? authError : ""}{" "}
        </div>
        <div className="k-form-buttons">
          <button
            type={"submit"}
            className="LoginButtonSmallMarginBot BUTTON"
            disabled={!formRenderProps.allowSubmit || hasBeenPressed}
            style={
              !formRenderProps.allowSubmit || hasBeenPressed
                ? { opacity: 0.5 }
                : {}
            }
          >
            {t("loginPage.login")}
          </button>
        </div>
        <div className="k-form-buttons">
          <button
            className="LoginResendMFAButton"
            type="button"
            onClick={(e) => {
              e.preventDefault();
              setForgotPassword(true);
            }}
          >
            {t("loginPage.resetPassword")}
          </button>
        </div>
      </FormElement>
    );
  };

  const renderRmaRequiredContent = (formRenderProps: FormRenderProps) => {
    return (
      <FormElement className="LoginForm">
        <Field
          name={"mfaCode"}
          component={Input}
          label={t("loginPage.authenticationCode")}
          validator={notEmptyValidator}
        />
        <div style={{ color: "red" }}>
          {authError && authError.length > 0 ? authError : ""}{" "}
        </div>
        <Timer isTimerDone={isTimerDone} />
        <div className="LoginResendMFAContainer">
          {isTimerDone ? (
            <button
              type="button"
              className="LoginResendMFAButton"
              onClick={() => {
                if (verifyPhoneNumberRequired) {
                  Auth.verifyUserAttribute(cognitoUser, "phone_number")
                    .then((r) => {
                      setVerifyPhoneNumberRequired(true);
                      setIsTimerDone(false);
                      if (timer) clearTimeout(timer);
                      setTimer(
                        setTimeout(() => {
                          setIsTimerDone(true);
                        }, verificationCodeTimerMinutes * 60000 + 2000)
                      );
                    })
                    .catch((err) => {
                      handleError(
                        err,
                        errorCodes.verifyPhoneNumberResendCodeError
                      );
                    });
                } else {
                  signInCallback({
                    email: credentials?.email,
                    password: credentials?.pw,
                  });
                }
                setIsTimerDone(false);
                if (timer) clearTimeout(timer);
              }}
            >
              {t("loginPage.resendCode")}
            </button>
          ) : verifyPhoneNumberRequired ? (
            <button
              type="button"
              className="LoginResendMFAButton"
              onClick={() => {
                setVerifyPhoneNumberRequired(false);
                setResetPhoneNumber(true);
              }}
            >
              {t("loginPage.noAuthenticationCode")}
            </button>
          ) : null}
        </div>
        <div className="k-form-buttons">
          <button
            type={"submit"}
            className="LoginButton BUTTON"
            disabled={!formRenderProps.allowSubmit || hasBeenPressed}
            style={
              !formRenderProps.allowSubmit || hasBeenPressed
                ? { opacity: 0.5 }
                : {}
            }
          >
            {t("common.ok")}
          </button>
        </div>
      </FormElement>
    );
  };

  const renderNewPassword = (formRenderProps: FormRenderProps) => {
    const { valid, valueGetter } = formRenderProps;

    const passwordMatch =
      valueGetter("newPassword") === valueGetter("repeatPassword");

    return (
      <FormElement className="LoginForm">
        <Field
          name={"name"}
          component={CustomInputUser}
          label={t("manageUsers.firstname")}
          validator={notEmptyValidator}
        />
        <Field
          name={"familyName"}
          component={CustomInputUser}
          label={t("manageUsers.surname")}
          validator={notEmptyValidator}
        />
        <Field
          name={"phoneNumber"}
          component={CustomInputUser}
          label={t("loginPage.phoneRequired")}
          validator={phoneNumberValidator}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <Field
            name={"newPassword"}
            component={CustomInputUser}
            type={viewNewPassword ? "text" : "password"}
            label={t("user.newPassword")}
            validator={newPasswordValidator}
          />

          <span
            className="k-icon k-i-preview"
            style={
              viewNewPassword
                ? {
                    fontSize: "16px",
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                    borderRadius: "6px",
                    right: "0",
                    position: "absolute",
                    top: "26px",
                    zIndex: "1000",
                  }
                : {
                    fontSize: "16px",
                    borderRadius: "6px",
                    right: "0",
                    position: "absolute",
                    color: "var(--primary-color)",
                    top: "26px",
                    zIndex: "1000",
                  }
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setViewNewPassword(!viewNewPassword);
            }}
            onMouseLeave={() => {
              setViewNewPassword(false);
            }}
          />
        </div>
        <Field
          name={"repeatPassword"}
          component={Input}
          type={"password"}
          label={t("user.repeatPassword")}
          valid={passwordMatch}
        />
        {!passwordMatch ? (
          <div
            style={{
              color: "red",
              height: "11px",
              fontSize: "10px",
            }}
          >
            {t("user.passwordDontMatch")}
          </div>
        ) : (
          <div style={{ height: "11px" }} />
        )}
        <div style={{ color: "red" }}>
          {authError && authError.length > 0 ? authError : ""}{" "}
        </div>
        <div className="k-form-buttons">
          <button
            type={"submit"}
            className="LoginButton BUTTON"
            disabled={
              !formRenderProps.allowSubmit ||
              hasBeenPressed ||
              !valid ||
              !passwordMatch
            }
            style={
              !formRenderProps.allowSubmit ||
              hasBeenPressed ||
              !valid ||
              !passwordMatch
                ? { opacity: 0.5 }
                : {}
            }
            onClick={() => {
              if (timer && newPasswordRequired) {
                clearTimeout(timer);
              }
            }}
          >
            {newPasswordRequired ? t("common.ok") : t("loginPage.login")}
          </button>
        </div>
      </FormElement>
    );
  };

  const renderNewPhoneNumber = (formRenderProps: FormRenderProps) => {
    const { valid } = formRenderProps;
    return (
      <FormElement className="LoginForm">
        <Field
          name={"phoneNumber"}
          component={CustomInputUser}
          label={t("loginPage.phone")}
          validator={phoneNumberValidator}
        />
        <div style={{ color: "red" }}>
          {authError && authError.length > 0 ? authError : ""}{" "}
        </div>
        <div className="k-form-buttons">
          <button
            type={"submit"}
            className="LoginButton BUTTON"
            disabled={!formRenderProps.allowSubmit || hasBeenPressed || !valid}
            style={
              !formRenderProps.allowSubmit || hasBeenPressed || !valid
                ? { opacity: 0.5 }
                : {}
            }
          >
            {t("common.ok")}
          </button>
        </div>
      </FormElement>
    );
  };

  const forgotPasswordContent = (formRenderProps: FormRenderProps) => {
    return (
      <FormElement className="LoginForm">
        <p style={{ marginTop: "5px" }}>
          {t("loginPage.authenticationCodeInfo")}
        </p>
        <Field
          name={"email"}
          type={"email"}
          component={Input}
          label={t("loginPage.email")}
          validator={emailValidator}
        />
        <div style={{ color: "red" }}>
          {authError && authError.length > 0 ? authError : ""}{" "}
        </div>
        <div className="k-form-buttons">
          <button
            type={"submit"}
            className="LoginButton BUTTON"
            disabled={!formRenderProps.allowSubmit || hasBeenPressed}
            style={
              !formRenderProps.allowSubmit || hasBeenPressed
                ? { opacity: 0.5 }
                : {}
            }
          >
            {newPasswordRequired ? t("common.ok") : t("loginPage.send")}
          </button>
        </div>
      </FormElement>
    );
  };

  const forgotPasswordSubmitContent = (formRenderProps: FormRenderProps) => {
    const { valid, valueGetter } = formRenderProps;

    const passwordMatch =
      valueGetter("newPassword") === valueGetter("repeatPassword");
    return (
      <FormElement className="LoginForm">
        <Field
          name={"email"}
          component={Input}
          type={"email"}
          label={t("loginPage.email")}
          validator={emailValidator}
          disabled={true}
        />
        <Field
          name={"code"}
          component={CustomInputUser}
          label={t("loginPage.authenticationCode")}
          validator={notEmptyValidator}
        />
        <Timer isTimerDone={isTimerDone} />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "100%",
            position: "relative",
          }}
        >
          <Field
            name={"newPassword"}
            component={CustomInputUser}
            type={viewNewPassword ? "text" : "password"}
            label={t("user.newPassword")}
            validator={newPasswordValidator}
          />
          <span
            className="k-icon k-i-preview"
            style={
              viewNewPassword
                ? {
                    fontSize: "16px",
                    backgroundColor: "var(--primary-color)",
                    color: "white",
                    borderRadius: "6px",
                    right: "0",
                    position: "absolute",
                    top: "26px",
                    zIndex: "1000",
                  }
                : {
                    fontSize: "16px",
                    borderRadius: "6px",
                    right: "0",
                    position: "absolute",
                    color: "var(--primary-color)",
                    top: "26px",
                    zIndex: "1000",
                  }
            }
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              setViewNewPassword(!viewNewPassword);
            }}
            onMouseLeave={() => {
              setViewNewPassword(false);
            }}
          />
        </div>
        <Field
          name={"repeatPassword"}
          component={Input}
          type={"password"}
          label={t("user.repeatPassword")}
          valid={passwordMatch}
        />
        {!passwordMatch ? (
          <div
            style={{
              color: "red",
              height: "11px",
              fontSize: "10px",
            }}
          >
            {t("user.passwordDontMatch")}
          </div>
        ) : (
          <div style={{ height: "11px" }} />
        )}
        <div style={{ color: "red" }}>
          {authError && authError.length > 0 ? authError : ""}{" "}
        </div>
        <div className="k-form-buttons">
          <button
            type={"submit"}
            className="LoginButton BUTTON"
            disabled={
              !formRenderProps.allowSubmit ||
              hasBeenPressed ||
              !valid ||
              !passwordMatch
            }
            style={
              !formRenderProps.allowSubmit ||
              hasBeenPressed ||
              !valid ||
              !passwordMatch
                ? { opacity: 0.5 }
                : {}
            }
          >
            {t("common.update")}
          </button>
        </div>
      </FormElement>
    );
  };

  return (
    <div className="LoginContainer" style={{ height: window.innerHeight }}>
      {showSpinner ? (
        <Spinner />
      ) : (
        <div className="LoginContent">
          <h1>
            {mfaRequired || verifyPhoneNumberRequired
              ? t("loginPage.verification")
              : newPasswordRequired
              ? t("loginPage.chooseNewPassword")
              : forgotPassword || submitNewPassword
              ? t("loginPage.resetPassword")
              : resetPhoneNumber
              ? t("loginPage.newPhonenumber")
              : t("loginPage.welcome")}
          </h1>
          <Form
            initialValues={initialValue}
            key={JSON.stringify(initialValue)}
            onSubmit={(dataItem) => {
              setHasBeenPressed(true);
              setTimeout(() => {
                setHasBeenPressed(false);
              }, 1000);
              setAuthError("");

              if (dataItem) {
                if (resetPhoneNumber) {
                  // console.log("resetPhoneNumber");
                  handleResetPhoneNumberCallback(dataItem);
                } else if (mfaRequired || verifyPhoneNumberRequired) {
                  // console.log("mfa/verifyPN");
                  mfaRequiredCallback(dataItem);
                } else if (newPasswordRequired) {
                  // console.log("newPW");
                  newPasswordRequiredCallback(dataItem);
                } else if (forgotPassword) {
                  // console.log("forgotPW");
                  handleForgotPassword(dataItem);
                } else if (submitNewPassword) {
                  // console.log("forgotPWSubmit");
                  handleForgotPasswordSubmit(dataItem);
                } else {
                  // console.log("signIn");
                  signInCallback(dataItem);
                }
              }
            }}
            render={(formRenderProps) =>
              mfaRequired || verifyPhoneNumberRequired
                ? renderRmaRequiredContent(formRenderProps)
                : newPasswordRequired
                ? renderNewPassword(formRenderProps)
                : forgotPassword
                ? forgotPasswordContent(formRenderProps)
                : submitNewPassword
                ? forgotPasswordSubmitContent(formRenderProps)
                : resetPhoneNumber
                ? renderNewPhoneNumber(formRenderProps)
                : renderLoginContent(formRenderProps)
            }
          />
          <img
            className="LoginRedemptorLogo"
            src="./Rityta 1Mellan[3437].png"
            alt=""
          />
        </div>
      )}
    </div>
  );
};

const Timer = (props: any) => {
  const { t } = useTranslation();
  const { initialMinute = verificationCodeTimerMinutes, isTimerDone } = props;
  const [minutes, setMinutes] = useState(initialMinute);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const myInterval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(myInterval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(myInterval);
    };
  });

  useEffect(() => {
    if (!isTimerDone) {
      setMinutes(initialMinute);
      setSeconds(0);
    }
  }, [isTimerDone, initialMinute]);

  return (
    <div>
      {minutes === 0 && seconds === 0 ? null : (
        <p>
          {t("loginPage.codeValidFor")} {minutes}:
          {seconds < 10 ? `0${seconds}` : seconds}
        </p>
      )}
    </div>
  );
};

export default Timer;
