import { TFunction } from "i18next";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getMeasurementMetaData } from "../../api/netRail/measurements";
import { cPhoneWidthLim } from "../../app";
import { appState } from "../../store";
import {
  addLongitudinalLevelChart,
  reduxRemoveChart,
  reduxSetDataPoints,
  reduxSetDataPointsLongitudinalLevel,
  reduxSetInitialWindowPosition,
  reduxSetLongitudinalLevelToDisplay,
  reduxSetLongitudinalLevelWindow,
  reduxSetSelectedChart,
  reduxSetViewSpecialCharts,
  reduxSetWindowXPosition,
} from "../../store/plot/actions";
import {
  dataPointType,
  GroupChartDataProps,
  SignalInterval,
} from "../../store/plot/types";
import {
  reduxSetInformationRequest,
  reduxSetNotification,
} from "../../store/user/actions";
import { helpInformationPath } from "../../store/user/types";
import { fetchInitialData } from "../generic/toogleMeasurements/toogleMeasurements";
import { Spinner } from "../spinner";
import "./gpsVisualizationContainer.css";
import { VisualizationLongitudinalLevel } from "./visualizationContainerLongitudinalLevel";
import { VisualizationRailShape } from "./visualizationContainerRailShape";

export async function fetchLLData(
  selectedMeasurement: string,
  dispatch: any,
  maxWindowSize: number,
  width: number,
  signalInterval: SignalInterval,
  setsToPlot: GroupChartDataProps,
  longitudinalLevelChartID: string,
  longitudinalAngleChartID: string,
  t: TFunction<"translation", undefined, "translation">
) {
  const plotMetaData = await getMeasurementMetaData(
    "longitudinalLevel",
    selectedMeasurement
  );
  const xMin = plotMetaData.startKm * 1000 + plotMetaData.startMeter;
  const xMax = plotMetaData.endKm * 1000 + plotMetaData.endMeter;

  if (xMax - xMin <= 30) {
    dispatch(
      reduxSetLongitudinalLevelWindow(
        Number(((xMax - xMin) * 0.5).toPrecision(3))
      )
    );
  } else {
    dispatch(reduxSetLongitudinalLevelWindow(maxWindowSize));
  }

  try {
    const plotData = await fetchInitialData(
      dispatch,
      ["longitudinalLevel"],
      width,
      "longitudinalLevel",
      signalInterval,
      selectedMeasurement,
      true // reset
    );

    setsToPlot["longitudinalLevel"] = plotData;
    dispatch(reduxSetSelectedChart(longitudinalLevelChartID));
    dispatch(reduxSetLongitudinalLevelToDisplay(["longitudinalLevel"]));
    dispatch(reduxSetDataPointsLongitudinalLevel(setsToPlot));
  } catch {
    dispatch(
      reduxSetNotification({
        style: "error",
        message: t("common.couldNotFindMeasurementData"),
        open: true,
      })
    );
  }

  try {
    const plotData = await fetchInitialData(
      dispatch,
      ["longitudinalAngleHP"],
      width,
      "longitudinalAngleHP",
      signalInterval,
      selectedMeasurement,
      true // reset
    );
    setsToPlot["longitudinalAngleHP"] = plotData;
    dispatch(reduxSetSelectedChart(longitudinalAngleChartID)); // LA
    dispatch(reduxSetLongitudinalLevelToDisplay(["longitudinalAngleHP"]));
    dispatch(reduxSetDataPointsLongitudinalLevel(setsToPlot));
  } catch {
    dispatch(
      reduxSetNotification({
        style: "error",
        message: t("common.couldNotFindMeasurementData"),
        open: true,
      })
    );
  }
}

const setsToPlotInitial: GroupChartDataProps = {
  trackGauge: [] as dataPointType[],
  crossLevelBIS: [] as dataPointType[],
  crossLevel: [] as dataPointType[],
  crossLevelUnevenness: [] as dataPointType[],
  twist3m: [] as dataPointType[],
  twist6m: [] as dataPointType[],
  alignment: [] as dataPointType[],
  longitudinalLevel: [] as dataPointType[],
  alignmentLeft: [] as dataPointType[],
  longitudinalLevelLeft: [] as dataPointType[],
  alignmentRight: [] as dataPointType[],
  longitudinalLevelRight: [] as dataPointType[],
  longitudinalAngleHP: [] as dataPointType[],
};

interface CLLVisualizationProps {
  mainView: React.MutableRefObject<boolean>;
  // finishedLoading: boolean;
  // setFinishedLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const CurrentLongitudinalLevelVisualization: React.FunctionComponent<
  CLLVisualizationProps
> = ({ mainView }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [finishedLoading, setFinishedLoading] = useState(true);

  const specialChartArray = useSelector(
    (state: appState) => state.plot.specialCharts.specialChartArray
  );

  const informationRequest = useSelector(
    (state: appState) => state.user.informationRequest
  );

  const width = useSelector((state: appState) => state.scheduler.screenWidth);

  const allCharts = useSelector((state: appState) => state.plot.charts);
  // const specialChartArray = useSelector(
  //   (state: appState) => state.plot.specialCharts.specialChartArray
  // );

  const signalInterval = useSelector(
    (state: appState) => state.plot.globalSignalInterval
  );

  // const charts = useSelector((state: appState) => state.plot.charts);

  const longitudinalLevelChartID = useSelector(
    (state: appState) => state.plot.specialCharts.longitudinalLevelChartID
  );

  const longitudinalAngleChartID = useSelector(
    (state: appState) => state.plot.specialCharts.longitudinalAngleChartID
  );

  const selectedMeasurement = useSelector(
    (state: appState) => state.scheduler.selectedMeasurement
  );

  const maxWindowSize = useSelector(
    (state: appState) => state.plot.specialCharts.maxWindowSize
  );

  async function fetchLLData() {
    setFinishedLoading(false);
    const setsToPlot: GroupChartDataProps = {
      trackGauge: [] as dataPointType[],
      crossLevelBIS: [] as dataPointType[],
      crossLevel: [] as dataPointType[],
      crossLevelUnevenness: [] as dataPointType[],
      twist3m: [] as dataPointType[],
      twist6m: [] as dataPointType[],
      alignment: [] as dataPointType[],
      longitudinalLevel: [] as dataPointType[],
      alignmentLeft: [] as dataPointType[],
      longitudinalLevelLeft: [] as dataPointType[],
      alignmentRight: [] as dataPointType[],
      longitudinalLevelRight: [] as dataPointType[],
      longitudinalAngleHP: [] as dataPointType[],
    };

    dispatch(reduxSetSelectedChart(longitudinalLevelChartID)); // LL

    try {
      const plotData = await fetchInitialData(
        dispatch,
        ["longitudinalLevel"],
        width,
        "longitudinalLevel",
        signalInterval,
        selectedMeasurement,
        true // reset
      );

      setsToPlot["longitudinalLevel"] = plotData;

      dispatch(reduxSetLongitudinalLevelToDisplay(["longitudinalLevel"]));
      dispatch(reduxSetDataPointsLongitudinalLevel(setsToPlot));
    } catch {
      dispatch(
        reduxSetNotification({
          style: "error",
          message: t("common.couldNotFindMeasurementData"),
          open: true,
        })
      );
    }
    dispatch(reduxSetSelectedChart(longitudinalAngleChartID)); // LA

    dispatch(reduxSetDataPointsLongitudinalLevel(setsToPlotInitial));
    try {
      const plotData = await fetchInitialData(
        dispatch,
        ["longitudinalAngleHP"],
        width,
        "longitudinalAngleHP",
        signalInterval,
        selectedMeasurement,
        true // reset
      );
      setsToPlot["longitudinalAngleHP"] = plotData;

      dispatch(reduxSetLongitudinalLevelToDisplay(["longitudinalAngleHP"]));
      dispatch(reduxSetDataPointsLongitudinalLevel(setsToPlot));
    } catch {
      dispatch(
        reduxSetNotification({
          style: "error",
          message: t("common.couldNotFindMeasurementData"),
          open: true,
        })
      );
    }

    setFinishedLoading(true);
  }

  async function initializeLLCharts() {
    dispatch(addLongitudinalLevelChart(longitudinalLevelChartID)); // LL
    dispatch(addLongitudinalLevelChart(longitudinalAngleChartID)); // LA
    mainView.current = true;

    setFinishedLoading(false);
    await fetchLLData();

    const plotMetaData = await getMeasurementMetaData(
      "longitudinalLevel",
      selectedMeasurement
    );
    const xMin = plotMetaData.startKm * 1000 + plotMetaData.startMeter;
    const xMax = plotMetaData.endKm * 1000 + plotMetaData.endMeter;

    if (xMax - xMin <= 30) {
      dispatch(
        reduxSetLongitudinalLevelWindow(
          Number(((xMax - xMin) * 0.5).toPrecision(3))
        )
      );
    } else {
      dispatch(reduxSetLongitudinalLevelWindow(maxWindowSize));
    }
    dispatch(reduxSetInitialWindowPosition(true));
    dispatch(reduxSetWindowXPosition(70));

    const setsToPlot: GroupChartDataProps = {
      trackGauge: [] as dataPointType[],
      crossLevelBIS: [] as dataPointType[],
      crossLevel: [] as dataPointType[],
      crossLevelUnevenness: [] as dataPointType[],
      twist3m: [] as dataPointType[],
      twist6m: [] as dataPointType[],
      alignment: [] as dataPointType[],
      longitudinalLevel: [] as dataPointType[],
      alignmentLeft: [] as dataPointType[],
      longitudinalLevelLeft: [] as dataPointType[],
      alignmentRight: [] as dataPointType[],
      longitudinalLevelRight: [] as dataPointType[],
      longitudinalAngleHP: [] as dataPointType[],
    };

    const measurementCharts = allCharts.filter(
      (chart) => chart.chartID !== "999999"
    );
    for (const i in measurementCharts) {
      // Set for every chart
      const chart = measurementCharts[i];
      // To update one chart set the selected chart to the current looped one, this will be overwritten later when user do any action.
      dispatch(reduxSetSelectedChart(chart.chartID)); // Make sure that the chart we want to use is activated

      for (const i in chart.measurementToDisplay) {
        const key = chart.measurementToDisplay[i] as keyof GroupChartDataProps;

        const initialData = await fetchInitialData(
          dispatch,
          chart.measurementToDisplay,
          width,
          key,
          signalInterval,
          selectedMeasurement,
          true // reset
        );
        setsToPlot[key] = initialData;
      }
      dispatch(reduxSetDataPoints(setsToPlot));
    }
    dispatch(reduxSetViewSpecialCharts(true));

    setFinishedLoading(true);
  }

  useEffect(() => {
    initializeLLCharts();
  }, []);

  if (specialChartArray.length > 0) {
    return (
      <div
        className="DashboardMainSignalsLL"
        style={{ position: "relative" }}
        key={longitudinalLevelChartID}
      >
        <div style={{ display: "flex", flexDirection: "row" }}>
          <h5
            style={{
              paddingLeft: width >= cPhoneWidthLim ? "40px" : "10px",
              paddingTop: "20px",
            }}
          >
            {t("currentLongitudinalLevel.header")}
            <span
              style={{
                marginLeft: "10px",
                fontSize: "18px",
              }}
              className="k-icon k-i-info"
              onClick={() => {
                dispatch(
                  reduxSetInformationRequest({
                    path: helpInformationPath.currentLongitudinalLevel,
                    showInformation:
                      informationRequest?.path ===
                      helpInformationPath.currentLongitudinalLevel
                        ? !informationRequest?.showInformation
                        : true,
                  })
                );
              }}
            />
          </h5>
        </div>

        <VisualizationLongitudinalLevel
          chartID={longitudinalLevelChartID}
          mainView={mainView}
          finishedLoading={finishedLoading}
          setFinishedLoading={setFinishedLoading}
        />
        <VisualizationLongitudinalLevel
          chartID={longitudinalAngleChartID}
          mainView={mainView}
          finishedLoading={finishedLoading}
          setFinishedLoading={setFinishedLoading}
        />
        <VisualizationRailShape chartID={"999988887"} />
      </div>
    );
  } else {
    return <div />;
  }
};
