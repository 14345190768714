import i18n from "i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";

import { initReactI18next } from "react-i18next";
import { languageType } from "./store/user/types";

// Translation conventions:
// https://gist.github.com/Lemmings19/38225ba78c8cce4f68318a1e2410d325
// Helpful youtube clip for the different supported functionalites: https://www.youtube.com/watch?v=SA_9i4TtxLQ

// In general we try to define the so called "namespace" as the current page or component we are working in.
// Example with namespace="externalLinkShare":
// "externalLinkShare": {
//   "messageSent": "Meddelandet har skickats",
//   "messageNotSent": "Meddelandet kunde inte skickas",
//   "linkExpiresIn": "Länk upphör om:"
// }

// Stay Clean
// When removing code, make sure you always remove translations (and keys) as well.
// A clean i18n database is the key to fast localization cycles. Try to keep your translations as clean as your codebase.
//
// The General Namespace
// Using the "common" namespace when text-strings appear multiple times over the application. As example "delete".
// Bad:
// general.users.hobbies.surfing
// Good:
// common.save (Save)
// common.and (and)
// common.learnMore (Learn more)

// Avoid Complexity
// Although i18n supports nested keys, in our experience deeply nested keys are harder to manage.
//  We recommend to not use more than two levels (some Rails helpers might be the exception).
//  Keep your key names as flat as possible.

// Be Predictable
// If you name your keys too abstract you will have trouble knowing what it actually says.
// If you name it too close to the actual content, changing text and keeping the key name in sync will be painful.
// Once again, it’s about finding a happy medium.
// Bad:
// home.paragraph1
// home.suLbl
// Good:
// home.userWelcomeText
// home.signUpFreeTrialButtonLabel

// Convenient function:
// const {t} = useTranslation("translation", {
//   keyPrefix: "externalLinkShare",
// });
// This will initialize t at "externalLinkShare", which means we dont have to write
// externalLinkShare.XXXX before every item inside of the component. Note that
// "translation" is our namespace, and currently is the default which we don't change.

// Find Missing Keys
// Use the missing command to find missing keys throughout your app:

// $ i18n-tasks missing
// This will return a list of translations that are obviously missing.

// Find Unused Keys
// Use the unused command to find unused keys throughout your app:

// $ i18n-tasks unused
// You can use different formats, e.g. to only list the actual keys:

// $ i18n-tasks unused --format keys
// This will return a list of translations that might no longer be used in your app.

// While this is a tremendous help, i18n-tasks is not always a 100% correct and you should check the results manually before deleting keys that are actually still in use.*

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    backend: {
      loadPath: "/lang/{{lng}}/{{ns}}.json",
      addPath: "/lang/{{lng}}/{{ns}}/missing",
    },
    fallbackLng: languageType.englishGB,
    returnNull: false,
    debug: false,
    load: "currentOnly",
    interpolation: {
      escapeValue: false,
      skipOnVariables: false,
    },
  })
  .then(() => {
    // Browsers have different ways of identifying sv or en. So enforce it below:
    if (localStorage.getItem("i18nextLng")?.includes("sv")) {
      i18n.changeLanguage("sv-SE");
      localStorage.setItem("i18nextLng", "sv-SE");
    } else if (localStorage.getItem("i18nextLng")?.includes("en")) {
      i18n.changeLanguage("en-GB");
      localStorage.setItem("i18nextLng", "en-GB");
    }
  })
  .catch(() => {
    // TODO REMOVE AFTER IMPLEMENTATION:
    alert("i18next error");
  });

export default i18n;
