import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getMeasurementMetaData } from "../../api/netRail/measurements";
import { appState } from "../../store";
import {
  reduxSetDataPoints,
  reduxSetGlobalSignalInterval,
  reduxSetObjectsToDisplay,
  reduxSetSelectedChart,
  reduxSetAllMeasurementObjects,
} from "../../store/plot/actions";
import {
  dataPointType,
  GroupChartDataProps,
  objectTypes,
} from "../../store/plot/types";
import * as plotAPI from "./../../api/netRail/plot";
import "./chartList.css";
import { fetchLLData } from "./currentLongitudinalLevelVisualization";
import { computeResolution } from "./groupChart";
import { Visualization } from "./visualizationContainer";
import { AddNewChart } from "../generic/addNewChart/addNewChart";

interface ChartListProps {
  mainView: React.MutableRefObject<boolean>;
}
export const ChartList: React.FunctionComponent<ChartListProps> = ({
  mainView,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [finishedLoading, setFinishedLoading] = useState(true);

  const screenWidth = useSelector(
    (state: appState) => state.scheduler.screenWidth
  );

  const selectedMeasurement = useSelector(
    (state: appState) => state.scheduler.selectedMeasurement
  );

  const viewSpecialCharts = useSelector(
    (state: appState) => state.plot.specialCharts.viewSpecialCharts
  );

  const allCharts = useSelector((state: appState) => state.plot.charts);

  useEffect(() => {
    return () => {
      setFinishedLoading(true);
    };
  }, []);

  const changeMeasurementToPlot = async () => {
    // if (viewSpecialCharts) {
    // Update special charts
    // let setsToPlot: GroupChartDataProps = {
    //   trackGauge: [] as dataPointType[],
    //   crossLevelBIS: [] as dataPointType[],
    //   crossLevel: [] as dataPointType[],
    //   crossLevelUnevenness: [] as dataPointType[],
    //   twist3m: [] as dataPointType[],
    //   twist6m: [] as dataPointType[],
    //   alignment: [] as dataPointType[],
    //   longitudinalLevel: [] as dataPointType[],
    //   alignmentLeft: [] as dataPointType[],
    //   longitudinalLevelLeft: [] as dataPointType[],
    //   alignmentRight: [] as dataPointType[],
    //   longitudinalLevelRight: [] as dataPointType[],
    //   longitudinalAngleHP: [] as dataPointType[],
    // };

    //   await fetchLLData(
    //     selectedMeasurement,
    //     dispatch,
    //     maxWindowSize,
    //     width,
    //     signalInterval,
    //     setsToPlot,
    //     longitudinalLevelChartID,
    //     longitudinalAngleChartID,
    //     t
    //   );
    // }

    for (const i in allCharts) {
      const currChart = allCharts[i];
      dispatch(reduxSetSelectedChart(currChart.chartID));

      const metaData = await getMeasurementMetaData(
        "trackgauge",
        selectedMeasurement
      );
      const xMin = metaData.startKm * 1000 + metaData.startMeter;
      const xMax = metaData.endKm * 1000 + metaData.endMeter;
      const resolution = computeResolution(xMin, xMax, screenWidth);

      dispatch(
        reduxSetGlobalSignalInterval({
          xMinOrig: xMin,
          xMaxOrig: xMax,
          xMin,
          xMax,
          resolution,
        })
      );

      const setsToPlot: GroupChartDataProps = {
        trackGauge: [] as dataPointType[],
        crossLevelBIS: [] as dataPointType[],
        crossLevel: [] as dataPointType[],
        crossLevelUnevenness: [] as dataPointType[],
        twist3m: [] as dataPointType[],
        twist6m: [] as dataPointType[],
        alignment: [] as dataPointType[],
        longitudinalLevel: [] as dataPointType[],
        alignmentLeft: [] as dataPointType[],
        longitudinalLevelLeft: [] as dataPointType[],
        alignmentRight: [] as dataPointType[],
        longitudinalLevelRight: [] as dataPointType[],
        longitudinalAngleHP: [] as dataPointType[],
      };

      const getPoints = async (
        xMin: number,
        xMax: number,
        resolution: number
      ) => {
        for (const i in currChart.measurementToDisplay) {
          const currentProperty = currChart.measurementToDisplay[
            i
          ] as keyof GroupChartDataProps;

          let resp = await plotAPI
            .getPlotData(
              selectedMeasurement,
              xMin,
              xMax,
              resolution,
              currentProperty === "crossLevelBIS"
                ? "crossLevel"
                : currentProperty,
              currentProperty === "crossLevelBIS" ||
                currentProperty === "crossLevel"
                ? ["tick", "class", "reference", "errors", "bis", "quality"]
                : ["tick", "class", "quality"]
            )
            .catch((error) => console.log("error I getpoints", error));

          if (currentProperty === "crossLevelBIS") {
            resp = (resp as dataPointType[]).map((point) => {
              return { ...point, y: point.bis };
            });
          }

          setsToPlot[currentProperty] = resp ? resp : [];
        }

        return setsToPlot;
      };

      const plotData = await getPoints(xMin, xMax, resolution);
      mainView.current = true;

      dispatch(reduxSetDataPoints(plotData));
    }
  };

  useEffect(() => {
    changeMeasurementToPlot();
  }, [selectedMeasurement]);

  const charts = allCharts
    .filter((chart) => chart.chartID !== "999999")
    .map((chart) => {
      return (
        <div className="DashboardMainSignals" key={chart.chartID}>
          <Visualization
            chartID={chart.chartID}
            mainView={mainView}
            finishedLoading={finishedLoading}
            setFinishedLoading={setFinishedLoading}
          />
        </div>
      );
    });
  return (
    <div>
      {charts} <AddNewChart key={"adds"} mainView={mainView} />
    </div>
  );
};
