import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { cPhoneWidthLim, cPhoneWidthLimStandard } from "../../../app";
import {
  getMobileOperatingSystem,
  measurementColors,
} from "../../../helpers/genericHelpers";
import { appState } from "../../../store";
import {
  reduxSetDataPointsLongitudinalLevel,
  reduxSetLongitudinalLevelToDisplay,
  reduxSetSelectedChart,
} from "../../../store/plot/actions";
import { GroupChartDataProps } from "../../../store/plot/types";
import { measurementKeys } from "../../../store/scheduler/types";
import { reduxSetNotification } from "../../../store/user/actions";
import { checkTrafikverketError } from "../../scheduler/plan/measurementList/statusProgress";
import { ChartDimensionProps } from "../chartContainer/chartContainer";
import { fetchInitialData } from "./toogleMeasurements";
import "./toogleMeasurements.css";

interface ToogleMeasurementsLongitudinalLevelProps {
  chartID: string;
  chartDimensions: ChartDimensionProps | undefined;
}

export const ToogleMeasurementsLongitudinalLevel: React.FunctionComponent<
  ToogleMeasurementsLongitudinalLevelProps
> = ({ chartID, chartDimensions }) => {
  const { t } = useTranslation();
  const measurements = [
    "longitudinalLevel",
    "longitudinalLevelLeft",
    "longitudinalLevelRight",
  ];

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(document.documentElement.clientWidth);
    };
    if (getMobileOperatingSystem() === "iOS") {
      window.removeEventListener("resize", onResize);
      window.addEventListener("resize", onResize);
    }
    return () => {
      if (getMobileOperatingSystem() === "iOS")
        window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(document.documentElement.clientWidth);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const [width, setWindowWidth] = useState(window.innerWidth);

  const selectedChart = useSelector(
    (state: appState) => state.plot.specialCharts.specialChartArray
  ).find((chart) => chart.chartID === chartID);

  const measurementToDisplay = selectedChart?.measurementToDisplay
    ? selectedChart.measurementToDisplay
    : [];

  return (
    <div
      className="MeasurementSelectionDropDown"
      style={{
        marginBottom: width <= cPhoneWidthLim ? "15px" : "0px",
      }}
    >
      {" "}
      <ToogleMeasurementsMultiSelect
        chartID={chartID}
        measurements={measurements}
        chartDimensions={chartDimensions}
      />
    </div>
  );
};

interface ToogleMeasurementsMultiSelectProps {
  chartID: string;
  measurements: string[];
  chartDimensions: ChartDimensionProps | undefined;
}

export const ToogleMeasurementsMultiSelect: React.FunctionComponent<
  ToogleMeasurementsMultiSelectProps
> = ({ chartID, measurements, chartDimensions }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // measurementToDisplayUndefined
  const selectedChart = useSelector(
    (state: appState) => state.plot.specialCharts.specialChartArray
  ).find((chart) => chart.chartID === chartID);

  const measurementToDisplay = selectedChart?.measurementToDisplay
    ? selectedChart.measurementToDisplay
    : [];

  const selectedMeasurement = useSelector(
    (state: appState) => state.scheduler.selectedMeasurement
  );

  const setsToPlotUndefined = useSelector((state: appState) =>
    state.plot.specialCharts.specialChartArray.find((chart) => {
      if (chart.chartID === chartID) return chart.plotData;
    })
  );

  const selectedPlanID = useSelector(
    (state: appState) => state.scheduler.selectedPlan
  );

  const plan = useSelector((state: appState) => state.scheduler.plans).find(
    (plan) => plan.id === selectedPlanID
  );

  const myMeasurement = plan?.measurements.find(
    (meas) => meas.id === selectedMeasurement
  );

  const [dropDownOpened, setDropDownOpened] = useState<boolean>(false);
  const [dropDownValue, setDropDownValue] = useState<any>(null);
  const isDropDownFocused = useRef<boolean>(false);

  const setsToPlot = setsToPlotUndefined
    ? setsToPlotUndefined.plotData
    : ({
        trackGauge: [],
        crossLevel: [],
        crossLevelBIS: [],
        crossLevelUnevenness: [],
        twist3m: [],
        twist6m: [],
        alignment: [],
        longitudinalLevel: [],
        alignmentLeft: [],
        longitudinalLevelLeft: [],
        alignmentRight: [],
        longitudinalLevelRight: [],
        longitudinalAngleHP: [],
      } as GroupChartDataProps);

  const width = useSelector((state: appState) => state.scheduler.screenWidth);
  const height = useSelector((state: appState) => state.scheduler.screenHeight);

  const signalInterval = useSelector(
    (state: appState) => state.plot.globalSignalInterval
  );

  function handleDropDownClick() {
    isDropDownFocused.current = !isDropDownFocused.current;
    setDropDownOpened(isDropDownFocused.current);
  }

  useEffect(() => {
    document
      .getElementById(`dropdowner${chartID}`)
      ?.removeEventListener("click", handleDropDownClick);

    document
      .getElementById(`dropdowner${chartID}`)
      ?.addEventListener("click", handleDropDownClick);
  }, []);

  useEffect(() => {
    return () => {
      document
        .getElementById(`dropdowner${chartID}`)
        ?.removeEventListener("click", handleDropDownClick);
    };
  }, []);

  const itemRenderDropDown = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const currentMeasurement = li.props.children; // THE VALUE

    if (
      currentMeasurement.toString() === "crossLevelBIS" &&
      checkTrafikverketError(myMeasurement?.errorMessage)
    ) {
      // WE DON'T WANT TO DISPLAY BIS IF WE DON'T HAVE BIS
      return <div></div>;
    } else if (currentMeasurement.toString() === "longitudinalAngleHP") {
      return <div></div>;
    }

    setDropDownValue(null);
    const itemChildren = (
      <span
        className="MeasurementSelectorItemRender caption"
        style={{
          backgroundColor: measurementToDisplay.includes(
            currentMeasurement.toString() as keyof GroupChartDataProps
          )
            ? "var(--secondary-color)"
            : "white",
        }}
      >
        <div
          className="MeasurementSelectorLine"
          style={{
            backgroundColor:
              measurementColors[
                currentMeasurement.toString() as keyof typeof measurementColors
              ],
          }}
        />
        <div
          style={
            width >= cPhoneWidthLimStandard
              ? { fontSize: "14px" }
              : { fontSize: "12px" }
          }
        >
          {measurementKeys(currentMeasurement.toString(), t)}
        </div>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const valueRenderDropDown = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any
  ) => {
    const divElem = (
      <div className="MeasurementSelectorTagRender caption">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            rowGap: "5px",
          }}
        >
          {measurementToDisplay.map((measurementKey) => {
            return (
              <div className="dropDownValueBox">
                <div
                  className="MeasurementSelectorLine"
                  style={{
                    backgroundColor:
                      measurementColors[
                        measurementKey as keyof typeof measurementColors
                      ],
                  }}
                />
                <div
                  style={
                    width <= cPhoneWidthLimStandard ||
                    height <= cPhoneWidthLimStandard
                      ? { fontSize: "14px" }
                      : { fontSize: "16px" }
                  }
                >
                  {measurementKeys(measurementKey.toString(), t)}
                </div>

                <span
                  className="k-icon k-i-close removeChart"
                  id="ValueMeasurementClose"
                  onClick={(e) => {
                    dispatch(
                      reduxSetDataPointsLongitudinalLevel({
                        ...setsToPlot,
                        [measurementKey as keyof GroupChartDataProps]: [],
                      })
                    );

                    dispatch(
                      reduxSetLongitudinalLevelToDisplay(
                        measurementToDisplay.filter(
                          (measurementKeyFilter) =>
                            measurementKeyFilter !==
                            (measurementKey as keyof GroupChartDataProps)
                        )
                      )
                    );

                    setDropDownValue(null);
                  }}
                />
              </div>
            );
          })}
          {measurementToDisplay.length > 0 ? (
            <span
              className="k-icon k-i-close removeChart"
              style={{
                position: "absolute",
                fontSize: "20px",
                alignSelf: "center",
                right: "0",
              }}
              onClick={() => {
                measurementToDisplay.map((measurementKey) => {
                  dispatch(
                    reduxSetDataPointsLongitudinalLevel({
                      ...setsToPlot,
                      [measurementKey as keyof GroupChartDataProps]: [],
                    })
                  );

                  dispatch(reduxSetLongitudinalLevelToDisplay([]));
                  setDropDownValue(null);
                });
              }}
            />
          ) : null}
        </div>
      </div>
    );

    return React.cloneElement(element, element.props, [divElem]);
  };

  const multiSelectRefCloseMe = useRef<any>(null);

  const popupSettings = {
    className: "popupSettings",
    width: chartDimensions
      ? `${chartDimensions?.width + chartDimensions.offsetLeft + 24}px`
      : "inherent",
  };

  const dropdowner = (
    <div className="DropdownContainer">
      {measurementToDisplay.length === 0 ? (
        <span
          className="PlaceHolderDropdown"
          style={
            width >= cPhoneWidthLimStandard
              ? {
                  bottom: "0",
                  marginBottom: "10px",
                }
              : {
                  bottom: "0",
                  marginBottom: "20px",
                }
          }
        >
          {t("toggleMeasurements.trackGeometryParameters")}
        </span>
      ) : null}
      <DropDownList
        id={`dropdowner${chartID}`}
        className="DropDownListToggleMeasurement"
        data={measurements}
        popupSettings={popupSettings}
        ref={multiSelectRefCloseMe}
        value={dropDownValue}
        // defaultValue={t("toggleMeasurements.trackGeometryParameters")}
        itemRender={itemRenderDropDown}
        valueRender={valueRenderDropDown}
        filterable={false}
        opened={dropDownOpened}
        onFocus={() => dispatch(reduxSetSelectedChart(chartID))}
        onBlur={() => {
          setDropDownOpened(false);
          isDropDownFocused.current = false;
        }}
        onChange={async (dataItem) => {
          dispatch(reduxSetSelectedChart(chartID));
          // Find currently changed "measurement"
          const newMeasurementsToDisplay = dataItem.target.value;

          setDropDownValue(dataItem.target.value);

          if (
            !measurementToDisplay.includes(
              newMeasurementsToDisplay as keyof GroupChartDataProps
            )
          ) {
            const uniqueMeasurementsToDisplay = [
              ...measurementToDisplay,
              newMeasurementsToDisplay,
            ].filter(function (item, pos) {
              return (
                [...measurementToDisplay, newMeasurementsToDisplay].indexOf(
                  item
                ) === pos
              );
            });

            dispatch(
              reduxSetLongitudinalLevelToDisplay(
                uniqueMeasurementsToDisplay as (keyof GroupChartDataProps)[]
              )
            );

            try {
              const plotData = await fetchInitialData(
                dispatch,
                measurementToDisplay,
                width,
                newMeasurementsToDisplay,
                signalInterval,
                selectedMeasurement
              );

              dispatch(
                reduxSetDataPointsLongitudinalLevel({
                  ...setsToPlot,
                  [newMeasurementsToDisplay]: plotData,
                })
              );
            } catch {
              dispatch(
                reduxSetNotification({
                  style: "error",
                  message: t(
                    "toggleMeasurements.couldNotFindMeasurementDataIsMeasurementSelected"
                  ),
                  open: true,
                })
              );
            }
          } else {
            dispatch(
              reduxSetLongitudinalLevelToDisplay(
                measurementToDisplay.filter(
                  (meas) => meas !== newMeasurementsToDisplay
                )
              )
            );
            dispatch(
              reduxSetDataPointsLongitudinalLevel({
                ...setsToPlot,
                [newMeasurementsToDisplay]: [],
              })
            );
            setDropDownValue(null);
          }
        }}
      />
    </div>
  );

  return dropdowner;
};
