import {
  axisBottom,
  axisLeft,
  line,
  Line,
  select,
  selectAll,
  Selection,
} from "d3";
import { ScaleLinear, scaleLinear } from "d3-scale";
import React, { useEffect, useRef, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { appState } from "../../store";
import {
  dataPointType,
  GroupChartDataProps,
  IntegrationChartProps,
  SignalInterval,
} from "../../store/plot/types";
import { reduxSetNotification } from "../../store/user/actions";
import { ChartDimensionProps } from "../generic/chartContainer/chartContainer";
import * as plotAPI from "./../../api/netRail/plot";
import "./groupChart.css";

interface YScalesType {
  longitudinalLevelDeltaYMean: ScaleLinear<number, number> | undefined;
  longitudinalLevelDeltaYRight: ScaleLinear<number, number> | undefined;
  longitudinalLevelDeltaYLeft: ScaleLinear<number, number> | undefined;
}

const transitionDuration = 50;
const smallStrokeWidth = 2;
const largeStrokeWidth = 10;
const leftAxisWidth = 30;
const bottomAxisHeight = 40;
const numTicks = 10;
const topExtentLimit = 10;
const bottomExtentLimit = 10;
const leftExtentLimit = 50;
const rightExtentLimit = 50;
const toolTipRadius = 15;
const smallestSampleSize = 0.5;
// TODO::: Fill with metadata:
const windowingMeters = 100;
const rescalingFactor = 2;
const windowingPoints = 600;
const numFrames = 3; // The number of viewport frames the chart has. E.g. How many frames will span outside of the view window.

const resolutions = {
  x05: 0.5,
  x1: 1,
  x2: 2,
  x5: 5,
  x10: 10,
  x15: 15,
  x20: 20,
  x30: 30,
  x40: 40,
  x50: 50,
  x60: 60,
  x70: 70,
  x80: 80,
  x90: 90,
  x100: 100,
};

interface GroupChartRailShapeProps {
  chartDimensions: ChartDimensionProps | undefined;
  chartID: string;
}

export const computeResolution = (
  xMin: number,
  xMax: number,
  width: number
) => {
  const numPoints = width * 2;
  const chosenResolution =
    xMax - xMin <= numPoints * resolutions.x05
      ? resolutions.x05
      : xMax - xMin <= numPoints * resolutions.x1
      ? resolutions.x1
      : xMax - xMin <= numPoints * resolutions.x2
      ? resolutions.x2
      : xMax - xMin <= numPoints * resolutions.x5
      ? resolutions.x5
      : xMax - xMin <= numPoints * resolutions.x10
      ? resolutions.x10
      : xMax - xMin <= numPoints * resolutions.x15
      ? resolutions.x15
      : xMax - xMin <= numPoints * resolutions.x20
      ? resolutions.x20
      : xMax - xMin <= numPoints * resolutions.x30
      ? resolutions.x30
      : xMax - xMin <= numPoints * resolutions.x40
      ? resolutions.x40
      : xMax - xMin <= numPoints * resolutions.x50
      ? resolutions.x50
      : xMax - xMin <= numPoints * resolutions.x60
      ? resolutions.x60
      : xMax - xMin <= numPoints * resolutions.x70
      ? resolutions.x70
      : xMax - xMin <= numPoints * resolutions.x80
      ? resolutions.x80
      : xMax - xMin <= numPoints * resolutions.x90
      ? resolutions.x90
      : xMax - xMin <= numPoints * resolutions.x100
      ? resolutions.x100
      : resolutions.x100;

  return chosenResolution;
};

const emptySignalInterval: SignalInterval = {
  xMax: 0,
  xMin: 0,
  xMinOrig: 0,
  xMaxOrig: 0,
  resolution: 0.5,
};

export const GroupChartRailShape: React.FunctionComponent<
  GroupChartRailShapeProps
> = ({ chartDimensions, chartID }) => {
  const dispatch = useDispatch();

  const groupChartRef = useRef<SVGSVGElement>(null);
  const setsToPlot: IntegrationChartProps = {
    longitudinalLevelDeltaYMean: [] as dataPointType[],
    longitudinalLevelDeltaYRight: [] as dataPointType[],
    longitudinalLevelDeltaYLeft: [] as dataPointType[],
  };

  const [data, setData] = useState<IntegrationChartProps>(setsToPlot);

  const selectedMeasurement = useSelector(
    (state: appState) => state.scheduler.selectedMeasurement
  );

  const screenWidth = useSelector(
    (state: appState) => state.scheduler.screenWidth
  );

  const windowSize = useSelector(
    (state: appState) => state.plot.specialCharts.windowSize
  );

  const longitudinalLevelChartID = useSelector(
    (state: appState) => state.plot.specialCharts.longitudinalLevelChartID
  );

  const longitudinalLevelPlot = useSelector((state: appState) =>
    state.plot.specialCharts.specialChartArray.find(
      (chart) => chart.chartID === longitudinalLevelChartID
    )
  );

  const measurementToDisplay = longitudinalLevelPlot?.measurementToDisplay;

  const [strokeWidth, setStrokeWidth] = useState(smallStrokeWidth);
  const [scales, setScales] = useState<{
    yScale: ScaleLinear<number, number>;
    xScale: ScaleLinear<number, number>;
  }>();

  const [xAxisTick, setXAxisTick] = useState<{
    x: number[];
    tick: string[];
  }>();

  const indexX = useSelector(
    (state: appState) => state.plot.specialCharts.xAxisIndex
  );

  const [yScales, setYScales] = useState<YScalesType>({
    longitudinalLevelDeltaYMean: undefined,
    longitudinalLevelDeltaYRight: undefined,
    longitudinalLevelDeltaYLeft: undefined,
  });

  const fetchColors = (key: keyof IntegrationChartProps) => {
    if (key === "longitudinalLevelDeltaYLeft") {
      return "#516e96";
    } else if (key === "longitudinalLevelDeltaYRight") {
      return "#b85c00";
    } else if (key === "longitudinalLevelDeltaYMean") {
      return "green";
    } else {
      return "black";
    }
  };

  const fetchData = async (
    measurementsToDisplay: (keyof GroupChartDataProps)[]
  ) => {
    let measurementString: string;
    let currentIntegrationProperty: keyof IntegrationChartProps;
    for (const i in measurementsToDisplay) {
      const currentProperty = measurementsToDisplay[
        i
      ] as keyof GroupChartDataProps;

      if (currentProperty === "longitudinalLevelLeft") {
        measurementString = "longitudinalLevelDeltaYLeft";
        currentIntegrationProperty = "longitudinalLevelDeltaYLeft";
      } else if (currentProperty === "longitudinalLevelRight") {
        measurementString = "longitudinalLevelDeltaYRight";
        currentIntegrationProperty = "longitudinalLevelDeltaYRight";
      } else {
        measurementString = "longitudinalLevelDeltaYMean";
        currentIntegrationProperty = "longitudinalLevelDeltaYMean";
      }

      const dataPlot = await plotAPI
        .getIntegratedData(
          selectedMeasurement,
          Number(indexX.toPrecision(6)),
          Number((indexX + windowSize).toPrecision(6)),
          0.5,
          measurementString,
          ["tick"]
        )
        .catch((error) => {
          dispatch(
            reduxSetNotification({
              style: "error",
              message: "Kunde inte hitta mätdata",
              open: true,
            })
          );
          console.log("error I fetchdata", error);
        });

      setsToPlot[currentIntegrationProperty] =
        dataPlot && dataPlot.length > 4 ? dataPlot : [];
    }
    setData(setsToPlot);
  };

  useEffect(() => {
    if (measurementToDisplay) fetchData(measurementToDisplay);
  }, [indexX, windowSize, measurementToDisplay]);

  useEffect(() => {
    setData({
      longitudinalLevelDeltaYMean: [] as dataPointType[],
      longitudinalLevelDeltaYRight: [] as dataPointType[],
      longitudinalLevelDeltaYLeft: [] as dataPointType[],
    });
  }, [selectedMeasurement]);

  useEffect(() => {
    return () => {
      setData({
        longitudinalLevelDeltaYMean: [] as dataPointType[],
        longitudinalLevelDeltaYRight: [] as dataPointType[],
        longitudinalLevelDeltaYLeft: [] as dataPointType[],
      });
    };
  }, []);

  useEffect(() => {
    const yScalesToStore: YScalesType = {
      longitudinalLevelDeltaYMean: undefined,
      longitudinalLevelDeltaYRight: undefined,
      longitudinalLevelDeltaYLeft: undefined,
    };
    const yLimitsMax: number[] = [];
    const yLimitsMin: number[] = [];
    const xLimitsMax: number[] = [];
    const xLimitsMin: number[] = [];
    let iter = 0;
    for (const key in data) {
      if (
        chartDimensions &&
        data[key as keyof IntegrationChartProps] &&
        data[key as keyof IntegrationChartProps].length > 0
      ) {
        const xMin = data[key as keyof IntegrationChartProps].reduce(
          (pointA, pointB) => (pointA.x < pointB.x ? pointA : pointB)
        ).x;

        const xMax = data[key as keyof IntegrationChartProps].reduce(
          (pointA, pointB) => (pointA.x > pointB.x ? pointA : pointB)
        ).x;
        xLimitsMax[iter] = xMax;
        xLimitsMin[iter] = xMin;

        const newXScale = scaleLinear()
          .domain([xMin, xMax])
          .range([leftAxisWidth, chartDimensions.width]); // Make longer range for paning DETERMINES WHERE OUR SCREEN IS INITIALIZED TO!

        // TODO::: REMOVE WHEN MULTIPLE YSCALES WORKS PROPERLY

        const yMin = data[key as keyof IntegrationChartProps].reduce(
          (pointA, pointB) => (pointA.y < pointB.y ? pointA : pointB)
        ).y;
        const yMax = data[key as keyof IntegrationChartProps].reduce(
          (pointA, pointB) => (pointA.y > pointB.y ? pointA : pointB)
        ).y;

        yLimitsMax[iter] = yMax;
        yLimitsMin[iter] = yMin;
        iter += 1;

        const newYScale = scaleLinear()
          .domain([yMax * 1.1, yMin * 1.1])
          .range([0, chartDimensions.height - bottomAxisHeight]);

        setScales({ yScale: newYScale, xScale: newXScale });
        yScalesToStore[key as keyof IntegrationChartProps] = newYScale;

        let chunk;
        let i, j, temporary;
        i, j, temporary, chunk = Math.floor(
          data[key as keyof IntegrationChartProps].length / 5
        ); // if we have main view, display 5 ticks otherwise 10. This is since the scale will slide outside of the visible window.

        const storedXVector: number[] = [];
        const storedTickVector: string[] = [];
        for (
          i = 0, j = data[key as keyof IntegrationChartProps].length;
          i < j;
          i += chunk
        ) {
          temporary = data[key as keyof IntegrationChartProps].slice(
            i,
            i + chunk
          );

          if (temporary.length > 0) {
            storedXVector.push(temporary[0].x);
            storedTickVector.push(temporary[0].tick);
          }
        }
        setXAxisTick({ x: storedXVector, tick: storedTickVector });
      }
    }

    const yMax = Math.max(...yLimitsMax);
    const yMin = Math.min(...yLimitsMin);
    const xMax = Math.max(...xLimitsMax);
    const xMin = Math.min(...xLimitsMin);

    if (chartDimensions) {
      const newXScale = scaleLinear()
        .domain([xMin, xMax])
        .range([leftAxisWidth, chartDimensions.width]);

      const newYScale = scaleLinear()
        .domain([yMax * 1.1, yMin * 1.1])
        .range([0, chartDimensions.height - bottomAxisHeight]);

      setScales({ yScale: newYScale, xScale: newXScale });
    }
  }, [data, chartDimensions]);

  useEffect(() => {
    if (
      groupChartRef.current !== null &&
      chartDimensions &&
      scales &&
      xAxisTick
    ) {
      const completeChartWidth = chartDimensions.width * numFrames;
      const svg = select(groupChartRef.current);
      const svgContent: any = svg.select(`.GroupChartContentGroup${chartID}`);

      svgContent.append("g").attr("class", "yAxis caption");

      const yAxis: Selection<SVGGElement, unknown, null, undefined> =
        svg.select(".yAxisLeft");

      yAxis
        .style("transform", `translateX(${leftAxisWidth}px)`)
        .transition()
        .duration(transitionDuration)
        .call(
          axisLeft(scaleLinear().domain([-Infinity, Infinity]))
            .ticks(5)
            .tickFormat((v) => "" + v.toString())
        );

      svgContent
        .append("g")
        .attr("class", "xAxis caption")
        .attr(
          "transform",
          `translate(0,${chartDimensions.height - bottomAxisHeight}px)`
        );

      const xAxis: Selection<SVGGElement, unknown, null, undefined> =
        svgContent.select(".xAxis");

      //********* XAXIS LABELS *********

      // Writes ticks on X Axis
      let iterX = 0;
      xAxis
        .call(
          axisBottom(scales.xScale)
            .tickValues(xAxisTick.x) // Give x value of where our ticks should be.
            .tickFormat((v: any) => {
              const tickToDisplay = xAxisTick.tick[iterX];
              iterX += 1;
              return tickToDisplay;
            })
        )
        .selectAll("text")
        .style("text-anchor", "end")
        .attr("transform", "rotate(-12)");

      //*********** GRIDLINES **********
      // Find equally distant ticks
      const make_x_gridlines = () => {
        return axisBottom(scales.xScale)
          .tickValues(xAxisTick.x) // Give x value of where our ticks should be.
          .tickFormat((v: any) => {
            //TODO::: BEWARE OF USING [0]
            const tickToDisplay = xAxisTick.tick[iterX];
            iterX += 1;
            return tickToDisplay; // Instead of displaying x-value display tick value.
          });
      };

      svgContent.selectAll(".gridX").remove();
      svgContent.selectAll(".gridY").remove();

      // add the X gridlines  ((ARE OK))
      svgContent
        .append("g")
        .attr("class", "gridX")
        .attr(
          "transform",
          "translate(0," + (chartDimensions.height - bottomAxisHeight) + ")"
        )
        .attr("opacity", 0.075)
        .call(
          make_x_gridlines()
            .tickSize(-chartDimensions.height)
            .tickFormat(() => {
              return "";
            })
        );

      // add the Y gridlines
      svgContent
        .append("g")
        .attr("class", "gridY")
        .style("transform", `translateX(${-chartDimensions.width}px)`)
        .attr("opacity", 0.075)
        .call(
          axisLeft(scales.yScale)
            .ticks(5)
            .tickSize(-completeChartWidth / 1.5)
            .tickFormat(() => {
              return "";
            })
        );
      const color = fetchColors("" as keyof IntegrationChartProps);
      // Render first axes on left:
      yAxis
        .transition()
        .duration(transitionDuration)
        .style("transform", `translateX(${leftAxisWidth}px)`)
        .call(
          axisLeft(scales.yScale)
            .ticks(5)
            .tickFormat((v) => "" + Math.round(+v))
        )
        .selectAll("text")
        .style("text-anchor", "end")
        .style("color", color);
    }

    //**************************
  }, [scales, data]);

  // Plot measurements
  useEffect(() => {
    if (groupChartRef.current !== null && scales && chartDimensions) {
      const completeChartWidth =
        (chartDimensions.width - leftAxisWidth) * numFrames;

      const svg = select(groupChartRef.current);

      // Select the chart Content inside the svg object
      const svgContent: any = svg.select(`.GroupChartContentGroup${chartID}`);

      const yScaleSpecificCurveFuntion = (key: keyof YScalesType) => {
        const currYScale = yScales[key];
        if (currYScale !== undefined) {
          return line<{
            x: number | null;
            y: number | null;
          }>()
            .x((d: any) => {
              return scales.xScale(d.x);
            })
            .y((d: any) => {
              return currYScale(d.y);
            });
        }
      };

      const genericCurveFunction = line<{
        x: number | null;
        y: number | null;
      }>()
        .x((d: any) => {
          return scales.xScale(d.x);
        })
        .y((d: any) => {
          if (scales.yScale) {
            return scales.yScale(d.y);
          } else {
            return 0;
          }
        });

      // Delete Stuff to be rerendered
      select(`.mouse-line${chartID}`).remove();
      selectAll(`.mouse-per-line${chartID} circle`).remove();
      selectAll(`#tooltip${chartID}`).remove();
      svgContent.select(".brush").remove(); // Remove Brush element
      svgContent.selectAll("linearGradient").remove(); // Remove coloring
      svgContent.selectAll(".plotLine").remove(); // Remove plot lines
      svgContent.selectAll(".myCircles").remove(); // Remove plot lines

      //Define the function which plots all charts
      const plotChart = (
        key: keyof IntegrationChartProps,
        curveFunc: Line<{ x: number | null; y: number | null }>,
        currData: dataPointType[]
      ) => {
        const color = fetchColors(key);
        svgContent
          .selectAll(`.GroupChart${key}Line`)
          .attr("id", `GroupChart${key}LineID`)
          .data([currData])
          .join("path")
          .attr("stroke", (i: dataPointType) => {
            return color;
          })
          .attr("stroke-width", strokeWidth)
          .attr("fill", "none")
          .attr("d", (value: any) => {
            let curveFunction = curveFunc;
            const f = yScaleSpecificCurveFuntion(key);
            if (f) curveFunction = f;
            return curveFunction(value);
          })
          .attr("class", `GroupChart${key}Line plotLine`);
      };

      // **********************************************
      //  	             PLOT CHARTS
      // **********************************************
      for (const key in data) {
        if (
          data[key as keyof IntegrationChartProps] &&
          data[key as keyof IntegrationChartProps].length > 0
        )
          plotChart(
            key as keyof IntegrationChartProps,
            genericCurveFunction,
            data[key as keyof IntegrationChartProps]
          );
      }

      // Force acis to the bottom
      svgContent
        .selectAll(".xAxis")
        .attr(
          "transform",
          "translate(" +
            0 +
            "," +
            (chartDimensions.height - bottomAxisHeight) +
            ")"
        );
    }
  }, [scales?.xScale, scales?.yScale, data]);

  if (!chartDimensions) {
    return null;
  }

  return (
    <div id={`svgContainer${chartID}`}>
      <svg
        className={`GroupChart${chartID}`}
        ref={groupChartRef}
        style={{
          overflow: "visible",
          cursor: "pointer",
          minHeight: "250px",
        }}
      >
        <defs>
          <clipPath id={"ClipPathGroupChart"}>
            <rect
              id="GroupChartClip"
              x={leftAxisWidth + "px"}
              width={chartDimensions.width - leftAxisWidth + "px"}
              height={chartDimensions.height + "px"}
            ></rect>
          </clipPath>
          <clipPath id={"AxisClipPathGroupChart"}>
            <rect
              id="AxisClipPathGroup"
              x={leftAxisWidth + "px"}
              y={chartDimensions.height - bottomAxisHeight + "px"}
              width={chartDimensions.width - leftAxisWidth + "px"}
              height={bottomAxisHeight + "px"}
            ></rect>
          </clipPath>
        </defs>
        <g className="yAxisLeft caption">
          <text className="yAxisLeftLabel caption" />
        </g>
        <g
          className={`GroupChartContentGroup${chartID}`}
          clipPath={`url(#ClipPathGroupChart)`}
        ></g>
        <g className={`dragContainerX${chartID}`}>
          <rect
            x={leftAxisWidth + "px"}
            y={chartDimensions.height - bottomAxisHeight + 10 + "px"}
            width={chartDimensions.width - leftAxisWidth + "px"}
            height={bottomAxisHeight + "px"}
            fill="transparent"
          ></rect>
        </g>
        <g className="yAxisRight caption">
          <text className="yAxisRightLabel caption" />
        </g>
        <g className="yAxisRight2 caption">
          <text className="yAxisRigh2Label caption" />
        </g>
      </svg>
    </div>
  );
};
