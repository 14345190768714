import { DropDownList, ListItemProps } from "@progress/kendo-react-dropdowns";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getMeasurementMetaData } from "../../../api/netRail/measurements";
import { cPhoneWidthLim, cPhoneWidthLimStandard } from "../../../app";
import {
  getMobileOperatingSystem,
  measurementColors,
} from "../../../helpers/genericHelpers";
import { appState } from "../../../store";
import {
  reduxSetDataPoints,
  reduxSetGlobalSignalInterval,
  reduxSetMeasurementToDisplay,
  reduxSetSelectedChart,
} from "../../../store/plot/actions";
import {
  dataPointType,
  GroupChartDataProps,
  SignalInterval,
} from "../../../store/plot/types";
import { measurementKeys } from "../../../store/scheduler/types";
import { reduxSetNotification } from "../../../store/user/actions";
import { checkTrafikverketError } from "../../scheduler/plan/measurementList/statusProgress";
import { computeResolution } from "../../visualization/groupChart";
import { ChartDimensionProps } from "../chartContainer/chartContainer";
import * as plotAPI from "./../../../api/netRail/plot";
import "./toogleMeasurements.css";
import { FloatingLabel, Label } from "@progress/kendo-react-labels";

interface ToogleMeasurementsProps {
  chartID: string;
  chartDimensions: ChartDimensionProps | undefined;
}

export const ToogleMeasurements: React.FunctionComponent<
  ToogleMeasurementsProps
> = ({ chartID, chartDimensions }) => {
  const { t } = useTranslation();
  const measurements = [
    "trackGauge",
    "crossLevel",
    "crossLevelBIS",
    "crossLevelUnevenness",
    "twist6m",
    "twist3m",
    "longitudinalLevel",
    "longitudinalLevelLeft",
    "longitudinalLevelRight",
    "alignment",
    "alignmentLeft",
    "alignmentRight",
  ];

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(document.documentElement.clientWidth);
    };
    if (getMobileOperatingSystem() === "iOS") {
      window.removeEventListener("resize", onResize);
      window.addEventListener("resize", onResize);
    }
    return () => {
      if (getMobileOperatingSystem() === "iOS")
        window.removeEventListener("resize", onResize);
    };
  }, []);

  useEffect(() => {
    const onResize = () => {
      setWindowWidth(document.documentElement.clientWidth);
    };
    window.addEventListener("resize", onResize);
    return () => {
      window.removeEventListener("resize", onResize);
    };
  }, []);

  const selectedChart = useSelector(
    (state: appState) => state.plot.charts
  ).find((chart) => chart.chartID === chartID);

  const measurementToDisplay = selectedChart?.measurementToDisplay
    ? selectedChart.measurementToDisplay
    : [];

  const [width, setWindowWidth] = useState(window.innerWidth);

  return (
    <div
      className="MeasurementSelectionDropDown"
      style={{
        marginBottom: width <= cPhoneWidthLim ? "15px" : "0px",
      }}
    >
      <ToogleMeasurementsMultiSelect
        chartID={chartID}
        measurements={measurements}
        chartDimensions={chartDimensions}
      />
    </div>
  );
};

interface ToogleMeasurementsButtonProps {
  currentMeasurement: keyof GroupChartDataProps;
  chartID: string;
}

export async function fetchInitialData(
  dispatch: any,
  measurementToDisplay: string[],
  screnWidth: number,
  currentMeasurement: string,
  signalInterval: SignalInterval,
  measurementID: string,
  reset?: boolean
) {
  let xMin;
  let xMax;
  let chosenResolution;
  if (signalInterval.xMaxOrig === 0 || reset === true) {
    const plotMetaData = await getMeasurementMetaData(
      currentMeasurement === "crossLevelBIS"
        ? "crossLevel"
        : currentMeasurement === "longitudinalAngleHP"
        ? "longitudinalLevel"
        : currentMeasurement,
      measurementID
    );

    xMin = plotMetaData.startKm * 1000 + plotMetaData.startMeter;
    xMax = plotMetaData.endKm * 1000 + plotMetaData.endMeter;
    chosenResolution = computeResolution(xMin, xMax, screnWidth);
    dispatch(
      reduxSetGlobalSignalInterval({
        xMinOrig: xMin,
        xMaxOrig: xMax,
        xMin: xMin,
        xMax: xMax,
        resolution: chosenResolution,
      })
    );
  } else {
    xMin = signalInterval.xMin;
    xMax = signalInterval.xMax;
    chosenResolution = signalInterval.resolution;
  }

  let plotData;
  if (currentMeasurement === "crossLevelBIS") {
    // Handle bis data
    plotData = await plotAPI.getPlotData(
      measurementID,
      xMin,
      xMax,
      chosenResolution,
      "crossLevel",
      ["tick", "class", "reference", "errors", "bis", "quality"]
    );

    plotData = plotData.map((point) => {
      const bisPoint: dataPointType = {
        x: point.x,
        tick: point.tick,
        y: point.bis,
        bis: point.bis,
        errors: point.errors,
        t: point.t,
        class: point.class,
        reference: point.reference,
        quality: point.quality,
        objects: point.objects,
        notes: point.notes,
      };
      return bisPoint;
    });
  } else {
    plotData = await plotAPI.getPlotData(
      measurementID,
      xMin,
      xMax,
      chosenResolution,
      currentMeasurement,
      currentMeasurement === "crossLevel"
        ? ["tick", "class", "reference", "errors", "quality"]
        : ["tick", "class", "quality"]
    );
  }

  return plotData;
}

export const ToogleMeasurementsButton: React.FunctionComponent<
  ToogleMeasurementsButtonProps
> = ({ chartID, currentMeasurement }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // measurementToDisplayUndefined
  const selectedChart = useSelector(
    (state: appState) => state.plot.charts
  ).find((chart) => chart.chartID === chartID);

  const measurementToDisplay = selectedChart?.measurementToDisplay
    ? selectedChart.measurementToDisplay
    : [];

  const selectedMeasurement = useSelector(
    (state: appState) => state.scheduler.selectedMeasurement
  );

  const setsToPlotUndefined = useSelector((state: appState) =>
    state.plot.charts.find((chart) => {
      if (chart.chartID === chartID) return chart.plotData;
    })
  );

  const setsToPlot = setsToPlotUndefined
    ? setsToPlotUndefined.plotData
    : ({
        trackGauge: [],
        crossLevel: [],
        crossLevelBIS: [],
        crossLevelUnevenness: [],
        twist3m: [],
        twist6m: [],
        alignment: [],
        longitudinalLevel: [],
        alignmentLeft: [],
        longitudinalLevelLeft: [],
        alignmentRight: [],
        longitudinalLevelRight: [],
        longitudinalAngleHP: [],
      } as GroupChartDataProps);

  const width = useSelector((state: appState) => state.scheduler.screenWidth);

  const uniqueMeasurementsToDisplay = [
    ...measurementToDisplay,
    currentMeasurement,
  ].filter(function (item, pos) {
    return [...measurementToDisplay, currentMeasurement].indexOf(item) === pos;
  });

  const signalInterval = useSelector(
    (state: appState) => state.plot.globalSignalInterval
  );

  return (
    // OLD BUTTONS CODE::
    <div
      className="MeasurementSelectorButton caption"
      style={
        measurementToDisplay.includes(currentMeasurement)
          ? {}
          : { opacity: 0.5 }
      }
      onClick={async () => {
        // Set the selected chart before clicking action
        dispatch(reduxSetSelectedChart(chartID));

        if (!measurementToDisplay.includes(currentMeasurement)) {
          dispatch(
            reduxSetMeasurementToDisplay(
              uniqueMeasurementsToDisplay as (keyof GroupChartDataProps)[]
            )
          );
          try {
            const plotData = await fetchInitialData(
              dispatch,
              measurementToDisplay,
              width,
              currentMeasurement,
              signalInterval,
              selectedMeasurement
            );

            dispatch(
              reduxSetDataPoints({
                ...setsToPlot,
                [currentMeasurement]: plotData,
              })
            );
            // }
          } catch {
            dispatch(
              reduxSetNotification({
                style: "error",
                message: t("common.couldNotFindMeasurementData"),
                open: true,
              })
            );
          }
        } else {
          dispatch(
            reduxSetMeasurementToDisplay(
              measurementToDisplay.filter((meas) => meas !== currentMeasurement)
            )
          );
          dispatch(
            reduxSetDataPoints({
              ...setsToPlot,
              [currentMeasurement]: [],
            })
          );
        }
      }}
    >
      <div
        className="MeasurementSelectorLine"
        style={{
          backgroundColor:
            measurementColors[
              currentMeasurement as keyof typeof measurementColors
            ],
        }}
      ></div>
      <div
        style={
          width >= cPhoneWidthLimStandard
            ? { fontSize: "8px" }
            : { fontSize: "12px" }
        }
      >
        {measurementKeys(currentMeasurement, t)}
      </div>
    </div>
  );
};

interface ToogleMeasurementsMultiSelectProps {
  chartID: string;
  measurements: string[];
  chartDimensions: ChartDimensionProps | undefined;
}

export const ToogleMeasurementsMultiSelect: React.FunctionComponent<
  ToogleMeasurementsMultiSelectProps
> = ({ chartID, measurements, chartDimensions }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const selectedChart = useSelector(
    (state: appState) => state.plot.charts
  ).find((chart) => chart.chartID === chartID);

  const measurementToDisplay = selectedChart?.measurementToDisplay
    ? selectedChart.measurementToDisplay
    : [];

  const selectedMeasurement = useSelector(
    (state: appState) => state.scheduler.selectedMeasurement
  );

  const setsToPlotUndefined = useSelector((state: appState) =>
    state.plot.charts.find((chart) => {
      if (chart.chartID === chartID) return chart.plotData;
    })
  );

  const selectedPlanID = useSelector(
    (state: appState) => state.scheduler.selectedPlan
  );

  const plan = useSelector((state: appState) => state.scheduler.plans).find(
    (plan) => plan.id === selectedPlanID
  );

  const myMeasurement = plan?.measurements.find(
    (meas) => meas.id === selectedMeasurement
  );

  const [loadingData, setLoadingData] = useState<boolean>(false);
  const [dropDownValue, setDropDownValue] = useState<any>(null);
  const isDropDownFocused = useRef<boolean>(false);

  const setsToPlot = setsToPlotUndefined
    ? setsToPlotUndefined.plotData
    : ({
        trackGauge: [],
        crossLevel: [],
        crossLevelBIS: [],
        crossLevelUnevenness: [],
        twist3m: [],
        twist6m: [],
        alignment: [],
        longitudinalLevel: [],
        alignmentLeft: [],
        longitudinalLevelLeft: [],
        alignmentRight: [],
        longitudinalLevelRight: [],
        longitudinalAngleHP: [],
      } as GroupChartDataProps);

  const width = useSelector((state: appState) => state.scheduler.screenWidth);
  const height = useSelector((state: appState) => state.scheduler.screenHeight);

  const signalInterval = useSelector(
    (state: appState) => state.plot.globalSignalInterval
  );

  function handleDropDownClick() {
    isDropDownFocused.current = !isDropDownFocused.current;
  }

  useEffect(() => {
    document
      .getElementById(`dropdowner${chartID}`)
      ?.removeEventListener("click", handleDropDownClick);

    document
      .getElementById(`dropdowner${chartID}`)
      ?.addEventListener("click", handleDropDownClick);
  }, []);

  useEffect(() => {
    return () => {
      document
        .getElementById(`dropdowner${chartID}`)
        ?.removeEventListener("click", handleDropDownClick);
    };
  }, []);

  const itemRenderDropDown = (
    li: React.ReactElement<HTMLLIElement>,
    itemProps: ListItemProps
  ) => {
    const currentMeasurement = li.props.children; // THE VALUE

    if (
      currentMeasurement.toString() === "crossLevelBIS" &&
      checkTrafikverketError(myMeasurement?.errorMessage)
    ) {
      // WE DON'T WANT TO DISPLAY BIS IF WE DON'T HAVE BIS
      return <div></div>;
    } else if (currentMeasurement.toString() === "longitudinalAngleHP") {
      return <div></div>;
    }

    setDropDownValue(null);
    const itemChildren = (
      <span
        className="MeasurementSelectorItemRender caption"
        style={{
          backgroundColor: measurementToDisplay.includes(
            currentMeasurement.toString() as keyof GroupChartDataProps
          )
            ? "var(--secondary-color)"
            : "white",
        }}
      >
        <div
          className="MeasurementSelectorLine"
          style={{
            backgroundColor:
              measurementColors[
                currentMeasurement.toString() as keyof typeof measurementColors
              ],
          }}
        />
        <div
          style={
            width >= cPhoneWidthLimStandard
              ? { fontSize: "14px" }
              : { fontSize: "12px" }
          }
        >
          {measurementKeys(currentMeasurement.toString(), t)}
        </div>
      </span>
    );
    return React.cloneElement(li, li.props, itemChildren);
  };

  const valueRenderDropDown = (
    element: React.ReactElement<HTMLSpanElement>,
    value: any
  ) => {
    const divElem = (
      <div className="MeasurementSelectorTagRender caption">
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            rowGap: "5px",
          }}
        >
          {measurementToDisplay.map((measurementKey) => {
            return (
              <div className="dropDownValueBox" key={measurementKey}>
                <div
                  className="MeasurementSelectorLine"
                  style={{
                    backgroundColor:
                      measurementColors[
                        measurementKey as keyof typeof measurementColors
                      ],
                  }}
                />
                <div
                  style={
                    width <= cPhoneWidthLimStandard ||
                    height <= cPhoneWidthLimStandard
                      ? { fontSize: "14px" }
                      : { fontSize: "16px" }
                  }
                >
                  {measurementKeys(measurementKey, t)}
                </div>

                <span
                  className="k-icon k-i-close removeChart"
                  id="ValueMeasurementClose"
                  onClick={(e) => {
                    dispatch(reduxSetSelectedChart(chartID));

                    dispatch(
                      reduxSetDataPoints({
                        ...setsToPlot,
                        [measurementKey as keyof GroupChartDataProps]: [],
                      })
                    );

                    dispatch(
                      reduxSetMeasurementToDisplay(
                        measurementToDisplay.filter(
                          (measurementKeyFilter) =>
                            measurementKeyFilter !==
                            (measurementKey as keyof GroupChartDataProps)
                        )
                      )
                    );

                    setDropDownValue(null);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
    );

    return React.cloneElement(element, element.props, [divElem]);
  };

  const multiSelectRefCloseMe = useRef<any>(null);

  const popupSettings = {
    className: "popupSettings",
  };

  const dropdowner = (
    <div className="DropdownContainer">
      {measurementToDisplay.length === 0 ? (
        <span
          className="PlaceHolderDropdown"
          style={
            width >= cPhoneWidthLimStandard
              ? {
                  bottom: "0",
                  marginBottom: "10px",
                }
              : {
                  bottom: "0",
                  marginBottom: "20px",
                }
          }
        >
          {t("toggleMeasurements.trackGeometryParameters")}
        </span>
      ) : null}
      <DropDownList
        id={`dropdowner${chartID}`}
        className="DropDownListToggleMeasurement"
        data={measurements}
        popupSettings={popupSettings}
        ref={multiSelectRefCloseMe}
        value={dropDownValue}
        // defaultValue={t("toggleMeasurements.trackGeometryParameters")}
        itemRender={itemRenderDropDown}
        valueRender={valueRenderDropDown}
        filterable={false}
        disabled={loadingData}
        onFocus={() => dispatch(reduxSetSelectedChart(chartID))}
        onChange={async (dataItem) => {
          setLoadingData(true);
          dispatch(reduxSetSelectedChart(chartID));
          // Find currently changed "measurement"
          const newMeasurementsToDisplay = dataItem.target.value;

          setDropDownValue(dataItem.target.value);

          if (
            !measurementToDisplay.includes(
              newMeasurementsToDisplay as keyof GroupChartDataProps
            )
          ) {
            const uniqueMeasurementsToDisplay = [
              ...measurementToDisplay,
              newMeasurementsToDisplay,
            ].filter(function (item, pos) {
              return (
                [...measurementToDisplay, newMeasurementsToDisplay].indexOf(
                  item
                ) === pos
              );
            });

            dispatch(
              reduxSetMeasurementToDisplay(
                uniqueMeasurementsToDisplay as (keyof GroupChartDataProps)[]
              )
            );

            try {
              const plotData = await fetchInitialData(
                dispatch,
                measurementToDisplay,
                width,
                newMeasurementsToDisplay,
                signalInterval,
                selectedMeasurement
              );

              dispatch(
                reduxSetDataPoints({
                  ...setsToPlot,
                  [newMeasurementsToDisplay]: plotData,
                })
              );
            } catch {
              dispatch(
                reduxSetNotification({
                  style: "error",
                  message: t(
                    "toggleMeasurements.couldNotFindMeasurementDataIsMeasurementSelected"
                  ),
                  open: true,
                })
              );
            }
          } else {
            dispatch(
              reduxSetMeasurementToDisplay(
                measurementToDisplay.filter(
                  (meas) => meas !== newMeasurementsToDisplay
                )
              )
            );
            dispatch(
              reduxSetDataPoints({
                ...setsToPlot,
                [newMeasurementsToDisplay]: [],
              })
            );
            setDropDownValue(null);
          }
          setLoadingData(false);
        }}
      />
      {measurementToDisplay.length > 0 ? (
        <span
          className="k-icon k-i-close removeMeasurements"
          style={{ fontSize: "24px" }}
          onClick={() => {
            dispatch(reduxSetSelectedChart(chartID));

            measurementToDisplay.map((measurementKey) => {
              dispatch(
                reduxSetDataPoints({
                  ...setsToPlot,
                  [measurementKey as keyof GroupChartDataProps]: [],
                })
              );

              dispatch(reduxSetMeasurementToDisplay([]));
              setDropDownValue(null);
            });
          }}
        />
      ) : null}
    </div>
  );

  return dropdowner;
};
