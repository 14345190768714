export enum userActionType {
  Login = "LOGIN",
  Logout = "LOGOUT",
  setallCompanyUsers = "SET_ALL_OPERATORS",
  setAllContacts = "SET_ALL_CONTACTS",
  deleteUser = "DELETE_USER",
  setSelectedUser = "SET_SELECTED_USER",
  updateUserProperty = "UPDATE_USER_PROPERTY",
  setUserHasChanged = "SET_USER_HAS_CHANGED",
  addNewReduxUser = "ADD_NEW_REDUX_USER",
  setNotification = "SET_NOTIFICATION",
  setAuthenticationSuccess = "SET_AUTHENTICATION_SUCCESS",
  updateCurrentUserProperty = "UPDATE_CURRENT_USER_PROPERTY",
  setAnnouncementResponse = "SET_ANNOUNCEMENT_RESPONSE",
  setMessages = "SET_MESSAGES",
  setSelectedMessage = "SET_SELECTED_MESSAGE",
  addNewMessage = "ADD_NEW_MESSAGE",
  setInformationRequest = "SET_INFORMATION_REQUEST",
  setSelectedLanguage = "SET_SELECTED_LANGUAGE",
  setAnnouncementsCount = "SET_ANNOUNCEMENT_COUNT",
}

export interface userLoginAction {
  type: typeof userActionType.Login;
  payload: userType;
}

export interface setAuthenticationSuccess {
  type: typeof userActionType.setAuthenticationSuccess;
  payload: boolean;
}

export interface addNewReduxUser {
  type: typeof userActionType.addNewReduxUser;
  payload: userType;
}

export interface userLogoutAction {
  type: typeof userActionType.Logout;
}

export interface setUserHasChanged {
  type: typeof userActionType.setUserHasChanged;
  payload: boolean;
}

export interface setallCompanyUsers {
  type: typeof userActionType.setallCompanyUsers;
  payload: userType[];
}

export interface setAllContacts {
  type: typeof userActionType.setAllContacts;
  payload: userType[];
}

export interface deleteUser {
  type: typeof userActionType.deleteUser;
  payload: string;
}

export interface setSelectedUser {
  type: typeof userActionType.setSelectedUser;
  payload: userType["id"];
}

export interface updateUserProperty<T extends keyof userType> {
  type: typeof userActionType.updateUserProperty;
  payload: Pick<userType, T>;
}

export interface updateCurrentUserProperty<T extends keyof userType> {
  type: typeof userActionType.updateCurrentUserProperty;
  payload: Pick<userType, T>;
}

export interface setNotification {
  type: typeof userActionType.setNotification;
  payload: notificationType;
}

export interface setAnnouncementResponse {
  type: typeof userActionType.setAnnouncementResponse;
  payload: announcementResponseType;
}
export interface setNotification {
  type: typeof userActionType.setNotification;
  payload: notificationType;
}

export interface setMessages {
  type: typeof userActionType.setMessages;
  payload: adminMessageType[];
}

export interface setSelectedMessage {
  type: typeof userActionType.setSelectedMessage;
  payload: string;
}

export interface addNewMessage {
  type: typeof userActionType.addNewMessage;
  payload: adminMessageType;
}

export interface setInformationRequest {
  type: typeof userActionType.setInformationRequest;
  payload: informationRequestType;
}

export interface setSelectedLanguage {
  type: typeof userActionType.setSelectedLanguage;
  payload: languageType;
}

export interface setAnnouncementsCount {
  type: typeof userActionType.setAnnouncementsCount;
  payload: number;
}

export type userAction =
  | userLoginAction
  | userLogoutAction
  | setallCompanyUsers
  | setAllContacts
  | deleteUser
  | setSelectedUser
  | updateUserProperty<keyof userType>
  | addNewReduxUser
  | setUserHasChanged
  | setAuthenticationSuccess
  | setNotification
  | updateCurrentUserProperty<keyof userType>
  | setAnnouncementResponse
  | setMessages
  | setSelectedMessage
  | addNewMessage
  | setInformationRequest
  | setSelectedLanguage
  | setAnnouncementsCount;

export interface userState {
  currentUser?: userType;
  allCompanyUsers: userType[];
  allContacts: userType[];
  selectedUser: userType["id"];
  userHasChanged: boolean;
  authenticationSuccess: boolean;
  notification: notificationType;
  announcementResponse?: announcementResponseType;
  messages: adminMessageType[];
  selectedMessage: string;
  informationRequest?: informationRequestType;
  selectedLanguage: languageType;
  announcementsCount: number;
}

export enum languageType {
  swedish = "sv-SE",
  englishGB = "en-GB",
}

export interface userType {
  companyID: string;
  createdAt: string;
  email: string;
  firstName: string;
  id: string;
  lastName: string;
  roles: roleProps[]; //roleProps[];
  syncedAt: string;
  updatedAt: string;
  sentToServer: boolean;
  phone?: string;
}

export interface roleProps {
  companyID?: string;
  createdAt?: string;
  id?: string;
  name: string;
  type: string;
  updatedAt?: string;
}

export enum roleType {
  admin = "ADMIN",
  developer = "DEVELOPER",
  manager = "MANAGER",
  operator = "OPERATOR",
  external = "EXTERNAL",
}

export interface loginProps {
  email: string;
  password: string;
}

export interface notificationType {
  message: string;
  style: "none" | "success" | "error" | "warning" | "info" | undefined;
  open: boolean;
}

export interface adminMessageType {
  type: announcementType;
  expiresAt: string;
  validAt: string;
  title: string;
  message: string;
  companies: string[];
  id: string;
  sentToServer?: boolean;
  createdAt?: string;
  updatedAt?: string;
  creatorID?: string;
}

export enum announcementType {
  general = "general",
  urgent = "urgent",
}

export enum announcementResponseType {
  dismiss = "DISMISS",
  show = "SHOW",
}

export interface informationRequestType {
  showInformation: boolean;
  path?: string;
}

export enum helpInformationPath {
  UpdateMachineForm = "updateMachineForm",
  CreateMachineForm = "createMachineForm",
  UpdateUserForm = "updateUserForm",
  CreateUserForm = "createUserForm",
  PlanFormParameters = "planFormParameters",
  PlanFormMeasurement = "planFormMeasurement",
  PlanFormMiscellaneous = "planFormMiscellaneous",
  FinishedMeasurements = "finishedMeasurements",
  DashboardInformation = "dashboardInformation",
  updatePassword = "updatePassword",
  measurementTemperature = "measurementTemperature",
  currentLongitudinalLevel = "currentLongitudinalLevel",
  AnnouncementList = "AnnouncementList",
  SupportTicketList = "SupportTicketList",
}

export interface supportTicketMessageType{
  id: string,
  occurredAt: Date | null,
  stepsToReproduce: string,
  category: string,
  machineID: string,
  operatorID: string,
  versionSW: string,
  machineType: string,
  lastCalibratedAt: Date,
  systemCreatedOn: string,
  temperature: Number,
  positionLat: string,
  positionLong: string,
  sensorStatus: string,
  installationType: string,
  creatorID: string,
  status: string,
  comments: string[],
  subject: string,
}
