import rest from "../../rest";
import {
  measurementProps,
  requestMetaData,
  pipelineProps,
  processingState,
} from "../../store/scheduler/types";

// CHEAT SHEET, REQUESTS
// opAnd            = "$and"
// opOr             = "$or"
// opExists         = "$exists"
// opIn             = "$in"
// opNotIn          = "$nin"
// opNotEqual       = "$ne"
// opLowerThan      = "$lt"
// opLowerOrEqual   = "$lte"
// opGreaterThan    = "$gt"
// opGreaterOrEqual = "$gte"
// opRegex          = "$regex"
// opElemMatch      = "$elemMatch"

export const getPipeline = (measurementID: string, state?: string) => {
  return new Promise<measurementProps>(async (resolve, reject) => {
    // params: {
    //   sort, // NUMBER CREATED MEASUREMENTS RELATES TO THE DESIRED MEASUREMENTS, NOT TO THE AMOUNT OF PERFORMED MEASUREMENTS
    //   filter: filterQuery,
    //   limit,
    //   page,
    //   offset,
    // },

    rest
      .get("/measurements/" + measurementID + "/pipeline", {
        params: state ? { state: state } : {},
        withCredentials: true,
      })
      .then(async (response) => {
        try {
          const serverPipeline = response.data;
          resolve(serverPipeline);
        } catch (reason) {
          const err = `Failed to fetch measurement from server: ${reason}`;
          console.error(err);
          reject(err);
        }
      })
      .catch((error) => {
        const err = `Failed to fetch measurement from server: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};

export const getPipelines = (measurementID: string) => {
  return new Promise<[pipelineProps[], requestMetaData]>(
    async (resolve, reject) => {
      rest
        .get("/measurements/" + measurementID + "/pipeline", {
          withCredentials: true,
        })
        .then(async (response) => {
          try {
            const serverPipelines: pipelineProps[] = response.data.list;
            const metaData: requestMetaData = response.data.meta;

            resolve([serverPipelines, metaData]);
          } catch (reason) {
            const err = `Failed to fetch measurement from server: ${reason}`;
            console.error(err);
            reject(err);
          }
        })
        .catch((error) => {
          const err = `Failed to fetch pipeline from server: ${error}`;
          console.error(err);
          reject(err);
        });
    }
  );
};

export const getCurrentPipelines = (measurementID: string) => {
  return new Promise<pipelineProps[]>(async (resolve, reject) => {
    const currentPipelineStep = await getCurrentPipelineStep(measurementID);

    rest
      .get("/measurements/" + measurementID + "/pipeline", {
        params: {
          filter: { processNumber: currentPipelineStep.processNumber },
        },
        withCredentials: true,
      })
      .then(async (response) => {
        try {
          const serverPipeline: pipelineProps[] = response.data.list;
          resolve(serverPipeline);
        } catch (reason) {
          const err = `Failed to fetch measurement from server: ${reason}`;
          console.error(err);
          reject(err);
        }
      })
      .catch((error) => {
        const err = `Failed to fetch pipeline from server: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};

export const getLatestPipelinesWithSameParentID = (
  pipelines: pipelineProps[]
) => {
  // Cudos to chat GPT.
  const latestObjects: Record<string, pipelineProps> = {};

  pipelines.forEach((pipeline: pipelineProps) => {
    const existing = latestObjects[pipeline.state];
    if (
      !existing ||
      (new Date(pipeline.createdAt) > new Date(existing.createdAt) &&
        pipeline.parentID === existing.parentID)
    ) {
      latestObjects[pipeline.state] = pipeline;
    }
  });

  const latestObjectsArray = Object.values(latestObjects).filter(
    (pipeline: pipelineProps, index: number, self: pipelineProps[]) => {
      return (
        self.findIndex(
          (p: pipelineProps) =>
            p.state === pipeline.state && p.parentID === pipeline.parentID
        ) === index
      );
    }
  );
  return latestObjectsArray;
};

export const getCurrentPipelineStep = (measurementID: string) => {
  return new Promise<pipelineProps>(async (resolve, reject) => {
    rest
      .get("/measurements/" + measurementID + "/pipeline", {
        params: {
          sort: "-createdAt",
          limit: 1,
        },
        withCredentials: true,
      })
      .then(async (response) => {
        try {
          const serverPipelines: pipelineProps[] = response.data.list;

          if (serverPipelines.length > 0) {
            resolve(serverPipelines[0]);
          } // This should be the "newest" item
          else {
            resolve({} as pipelineProps);
          }
        } catch (reason) {
          const err = `Failed to fetch measurement from server: ${reason}`;
          console.error(err);
          reject(err);
        }
      })
      .catch((error) => {
        const err = `Failed to fetch pipeline from server: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};
