import React, { useEffect, useState } from "react";
import "./gpsVisualizationContainer.css";
import { GpsChartContainer } from "./gpsChartContainer";
import { appState } from "../../store";
import { cPhoneWidthLim } from "../../app";
import { useDispatch, useSelector } from "react-redux";
import {
  gpsPointType,
  notePointType,
  objectPointType,
  objectTypes,
  translateFromSwe,
} from "../../store/plot/types";
import {
  reduxSetAllMeasurementNotes,
  reduxSetAllMeasurementObjects,
  reduxSetGpsPoints,
} from "../../store/plot/actions";
import { computeResolution } from "./groupChart";
import { getMeasurementMetaData } from "../../api/netRail/measurements";
import * as plotAPI from "./../../api/netRail/plot";
import { Button, ButtonGroup } from "@progress/kendo-react-buttons";
import { ToogleObjects } from "../generic/toggleObjects/toggleObjects";

interface VisualizationProps {
  selectedMeasurement: string;
}

export const GpsVisualization: React.FunctionComponent<VisualizationProps> = ({
  selectedMeasurement,
}) => {
  const screenWidth = useSelector(
    (state: appState) => state.scheduler.screenWidth
  );
  const height = useSelector((state: appState) => state.scheduler.screenHeight);

  const plans = useSelector((state: appState) => state.scheduler.plans);

  const selectedPlan = useSelector(
    (state: appState) => state.scheduler.selectedPlan
  );

  const plan = plans.find((plan) => plan.id == selectedPlan);

  const dispatch = useDispatch();

  const [finishedLoadingObjects, setFinishedLoadingObjects] =
    useState<boolean>(false);

  const [gpsPoints, setGpsPoints] = useState<gpsPointType[]>([]);

  async function getGpsData() {
    try {
      const metaData = await getMeasurementMetaData(
        "trackGauge",
        selectedMeasurement
      ).catch((error) => {
        // dispatch(reduxSetGpsPoints([])); // Set to empty if there is no data available.
        setGpsPoints([]);
      });

      if (metaData) {
        const xMin = metaData.startKm * 1000 + metaData.startMeter;
        const xMax = metaData.endKm * 1000 + metaData.endMeter;
        const resolution = computeResolution(xMin, xMax, screenWidth);

        // WILL NEED A NEW FUNCTION FOR OBJECTS
        const pts = await plotAPI.getGpsData(
          selectedMeasurement,
          xMin,
          xMax,
          resolution,
          "gps",
          ["tick", "class"]
        );

        setGpsPoints(pts);

        const objects = await plotAPI.getGpsObjectData(
          selectedMeasurement,
          xMin,
          xMax,
          0.5,
          "gps",
          ["tick", "objects", "notes", "class"]
        );

        let allMeasurmentObjects = objects
          .map((obj) => {
            let object;
            if (obj.objects.length > 0 || obj.notes !== "") {
              object = {
                x: obj.x,
                gpsX: obj.gpsX,
                gpsY: obj.gpsY,
                tick: obj.tick,
                type: obj.objects, // Need to differ the object type
                value: obj.objects[0],
                note: obj.notes,
              } as objectPointType;
              return object;
            } else {
              // Dummy item to avoid bug
              return {
                x: 0,
                gpsX: 0,
                gpsY: 0,
                tick: obj.tick,
                type: [],
                value: "",
                note: "",
              } as objectPointType;
            }
          })
          .flat();

        // IF OBJECT IN DB IS IN SWEDISH, TRANSLATE IT TO ENG: (THIS IS PURE LEGACY SUPPORT)
        allMeasurmentObjects = allMeasurmentObjects.map((obj) => {
          if (!Object.values(objectTypes).includes(obj.value as objectTypes)) {
            return {
              ...obj,
              value: translateFromSwe(obj.value),
              type: obj.type.map((val) =>
                translateFromSwe(val)
              ) as objectTypes[],
            };
          } else {
            return obj;
          }
        });

        dispatch(reduxSetAllMeasurementObjects(allMeasurmentObjects));

        const existingNotes = objects.filter((obj) => obj.notes !== "");
        const allMeasurmentNotes = existingNotes
          .map((note) => {
            const object = {
              x: note.x,
              gpsX: note.gpsX,
              gpsY: note.gpsY,
              tick: note.tick,
              type:
                note.notes === objectTypes.marker || note.notes === "Markering"
                  ? objectTypes.marker
                  : objectTypes.notes,
              value:
                note.notes === objectTypes.marker
                  ? objectTypes.marker
                  : note.notes,
            } as notePointType;

            return object;
          })
          .flat();

        dispatch(reduxSetAllMeasurementNotes(allMeasurmentNotes));
      }

      setFinishedLoadingObjects(true);
    } catch (err) {
      dispatch(reduxSetAllMeasurementObjects([]));
      dispatch(reduxSetGpsPoints([]));
    }
  }

  useEffect(() => {
    // Update gps trace:
    getGpsData();
  }, [selectedMeasurement]);

  return (
    <div
      className="GpsVisualizationContainer"
      style={{
        paddingLeft: screenWidth >= cPhoneWidthLim ? "40px" : "10px",
        paddingRight: screenWidth >= cPhoneWidthLim ? "40px" : "10px",
        paddingTop: selectedMeasurement !== "-1" ? "0px" : "60px",
      }}
    >
      <GpsChartContainer
        finishedLoadingObjects={finishedLoadingObjects}
        gpsData={gpsPoints}
        selectedMeasurement={selectedMeasurement}
      />
    </div>
  );
};
