import { machineAction, machineActionType, machineState } from "./types";

const initialState: machineState = {
  currentMachine: undefined,
  allMachines: [
    {
      id: "1",
      hashKey: "asdfasdgf1",
      name: "BJM666",
      description: "",
      status: "",
      coordinates: {
        longitude: 1,
        latitude: 1,
        altitude: 1,
      },
      companyID: "6ba7b810-9dad-11d1-80b4-00c04fd420c5",
    },
  ],
  selectedMachine: "",
  machineHasChanged: false,
};

export function machineReducer(
  state: machineState = initialState,
  action: machineAction
): machineState {
  switch (action.type) {
    case machineActionType.setAllMachines:
      return {
        ...state,
        allMachines: action.payload.map((machine) => {
          return { ...machine, sentToServer: true };
        }),
        selectedMachine: "",
        machineHasChanged: false,
      };
    case machineActionType.deleteMachine:
      return {
        ...state,
        allMachines: state.allMachines.filter(
          (machine) => machine.id !== action.payload
        ),
      };
    case machineActionType.setSelectedMachine:
      return {
        ...state,
        selectedMachine: action.payload,
      };
    case machineActionType.updateMachineProps:
      return {
        ...state,
        allMachines: state.allMachines.map((machine) => {
          return machine.id === action.payload.id ? action.payload : machine;
        }),
      };
    case machineActionType.updateMachineProperty:
      return {
        ...state,
        allMachines: state.allMachines.map((machine) => {
          return machine.id === state.selectedMachine
            ? {
                ...machine,
                ...action.payload,
                sentToServer: true,
              }
            : machine;
        }),
      };
    case machineActionType.addNewReduxMachine:
      return {
        ...state,
        allMachines: [...state.allMachines, { ...action.payload }],
        selectedMachine: action.payload.id,
        machineHasChanged: true,
      };
    case machineActionType.setMachineHasChanged:
      return {
        ...state,
        machineHasChanged: action.payload,
      };
    default:
      return state;
  }
}
