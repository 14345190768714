import { planStatus, planProps } from "../store/scheduler/types";
import { v4 as uuidv4 } from "uuid";
import {
  adminMessageType,
  announcementType,
  userType,
} from "../store/user/types";
import { machineType } from "../store/machine/types";
import { companyType } from "../store/company/types";

export const emptyMeasurementsSelection = {
  alignment: false,
  longitudinal: false,
  crossLevel: false,
  trackGauge: false,
  twist: false,
  gps: false,
};

export const getDummyPlan = (): planProps => {
  return {
    id: uuidv4(),
    machineAssignees: [],
    userAssignees: [],
    creatorID: uuidv4(),
    createdAt: new Date().toString(),
    doneAt: new Date().toString(),
    classification: "H0",
    status: planStatus.inProgress,
    projectedTrackFile: undefined,
    measurements: [],
    receivers: [],
    numberMeasurements: 0,
    trackNumber: "",
    trackPart: 0,
    updatedAt: "",
    place: "here",
    pathFrom: "",
    pathTo: "",
    startAtKm: 11,
    startAtMeter: 1,
    startAt: "",
    endAtKm: 2,
    endAtMeter: 2,
    endAt: "",
    direction: "positive",
    name: "name",
    notes: "",
    scheduledAt: "",
    measurementsSelection: [true, true, true, true, true, true],
    measurementMode: "automatic",
  };
};

export const getNewUser = (creatorID: string): userType => {
  return {
    id: uuidv4(),
    email: "",
    firstName: "",
    lastName: "",
    roles: [],
    companyID: "",
    syncedAt: new Date().toString(),
    updatedAt: new Date().toString(),
    createdAt: new Date().toString(),
    sentToServer: false,
  };
};

export const getNewMessage = (): adminMessageType => {
  return {
    id: uuidv4(),
    expiresAt: new Date().toISOString(),
    validAt: new Date().toISOString(),
    title: "",
    message: "",
    companies: [],
    sentToServer: false,
    type: announcementType.general,
  };
};

export const getNewMachine = (creatorID: string): machineType => {
  return {
    id: uuidv4(),
    hashKey: "",
    name: "",
    description: "",
    status: "",
    coordinates: {
      longitude: 0,
      latitude: 0,
      altitude: 0,
    },
    companyID: "",
  };
};

export const getNewCompany = (creatorID: string): companyType => {
  return {
    id: uuidv4(),
    name: "",
    description: "",
    type: "inspector",
    sentToServer: false,
    createdAt: new Date().toString(),
    machines: [],
    updatedAt: "",
  };
};

export const getNewPlan = (
  planNumber: number,
  creatorID: string
): planProps => {
  return {
    id: uuidv4(),
    machineAssignees: [],
    userAssignees: [],
    creatorID: creatorID,
    createdAt: new Date().toString(),
    doneAt: new Date().toString(),
    classification: "H0",
    status: planStatus.awaiting,
    projectedTrackFile: undefined,
    measurements: [],
    receivers: [],
    numberMeasurements: 1,
    trackNumber: "",
    trackPart: undefined,
    updatedAt: new Date().toString(),
    place: "",
    pathFrom: "",
    pathTo: "",
    startAtKm: undefined,
    startAtMeter: undefined,
    startAt: "",
    endAtKm: undefined,
    endAtMeter: undefined,
    endAt: "",
    direction: "positive",
    name: "",
    notes: "",
    scheduledAt: new Date().toISOString(),
    measurementsSelection: [true, true, true, true, true, true],
    measurementMode: "automatic",
    sentToServer: false,
  };
};
