import React from "react";
import { useSelector } from "react-redux";
import { cPhoneWidthLim } from "../../app";
import { appState } from "../../store";
import { GroupChartContainerRailShape } from "./groupChartContainerRailShape";
import "./visualizationContainer.css";
interface VisualizationRailShapeProps {
  chartID: string;
}

export const VisualizationRailShape: React.FunctionComponent<
  VisualizationRailShapeProps
> = ({ chartID }) => {
  const screenWidth = useSelector(
    (state: appState) => state.scheduler.screenWidth
  );

  return (
    <div
      className="VisualizationContainerRS"
      style={
        {
          // paddingLeft: screenWidth >= cPhoneWidthLim ? "40px" : "10px",
          // paddingRight: screenWidth >= cPhoneWidthLim ? "40px" : "10px",
        }
      }
    >
      <GroupChartContainerRailShape chartID={chartID} />
    </div>
  );
};
