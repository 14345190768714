import React, { useEffect, useState } from "react";
import "./style.scss";

import { Toolbar } from "../../components/toolbar";

import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Input } from "@progress/kendo-react-inputs";
import { UserList } from "../../components/manageUsers/userList";

// Redux Stuff
import { addNewUser, reduxSetSelectedUser } from "./../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { appState } from "../../store";
import { roleType, userType } from "../../store/user/types";
import { AdminAnnouncement } from "../../components/announcement/announcement";
import { cPhoneWidthLim } from "../../app";
import { useTranslation } from "react-i18next";
// export const getRoleType = (role: string) => {
//   switch (role) {
//     case "Admin":
//       return roleType.admin;
//     case "Projektledare":
//       return roleType.manager;
//     // case "Kontakt":
//     //   return roleType.external;
//     default:
//       return roleType.operator;
//   }
// };

export const ManageUsers: React.FunctionComponent = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const allContacts = useSelector((state: appState) => state.user.allContacts);
  const allCompanies = useSelector(
    (state: appState) => state.company.allCompanies
  );
  const width = useSelector((state: appState) => state.scheduler.screenWidth);
  const height = useSelector((state: appState) => state.scheduler.screenHeight);

  const selectedLanguage = useSelector(
    (state: appState) => state.user.selectedLanguage
  );

  // let allContacts: userType[] = []; DEBUG
  const [userListEmpty, setUserListEmpty] = useState<boolean>(false);

  const currentUser = useSelector((state: appState) => state.user.currentUser);
  const [roleFilter, setRoleFilter] = useState(
    t("manageUsers.roleUserFilterDefault")
  );
  const [companyFilter, setCompanyFilter] = useState(
    t("manageUsers.companyUserFilterDefault")
  );
  const [filteredUserArray, setFilteredList] =
    useState<userType[]>(allContacts);
  const [filter, setFilter] = useState<string>("");
  const [newUser, setNewUser] = useState(false);
  const [newUserAdded, setNewUserAdded] = useState<boolean>(true);

  useEffect(() => {
    if (allContacts.length !== 0) {
      setUserListEmpty(true);

      setFilteredList(
        filter !== ""
          ? allContacts.filter((contact) => {
              if (
                contact.email
                  .toLocaleLowerCase()
                  .includes(filter.toLocaleLowerCase())
              ) {
                return true;
              }
              if (
                contact.firstName
                  .toLocaleLowerCase()
                  .includes(filter.toLocaleLowerCase())
              ) {
                return true;
              }
              if (
                contact.lastName
                  .toLocaleLowerCase()
                  .includes(filter.toLocaleLowerCase())
              ) {
                return true;
              }
              return false;
            })
          : allContacts
      );
    }
  }, [allContacts, filter, setFilteredList]);

  useEffect(() => {
    dispatch(reduxSetSelectedUser(""));
  }, [dispatch]);

  useEffect(() => {
    setCompanyFilter(t("manageUsers.companyUserFilterDefault"));
    setRoleFilter(t("manageUsers.roleUserFilterDefault"));
  }, [selectedLanguage]);

  return (
    <div
      className="ManageUsersContainer"
      style={
        width <= cPhoneWidthLim || height <= cPhoneWidthLim
          ? {}
          : { position: "relative", left: "44px", paddingRight: "44px" }
      }
    >
      <header className="ManageUsersToolbar">
        <Toolbar>
          <h5>{t("menu.usersPageName")}</h5>
        </Toolbar>
      </header>
      <AdminAnnouncement />
      <div className="ManageUsersMain">
        <div className="manageUserList">
          <div className="centerCard">
            <div className="userListCardHeader">
              {currentUser?.roles.some(
                (entry) => entry.name === roleType.admin
              ) ? (
                <>
                  <div className="UserListHeaderUpper">
                    <div className="UserHeader1">
                      <DropDownList
                        className="rolesAndCompanyDropDown"
                        data={[
                          t("manageUsers.operator"),
                          t("manageUsers.manager"),
                        ]}
                        label={t("manageUsers.filterRole").toString()}
                        defaultItem={t("manageUsers.roleUserFilterDefault")}
                        value={roleFilter}
                        onChange={(e) => {
                          setRoleFilter(e.target.value);
                        }}
                        style={{ zIndex: "15", width: "130px" }}
                      />
                    </div>

                    <div className="UserHeader2">
                      <DropDownList
                        className="rolesAndCompanyDropDown"
                        data={allCompanies.map((company) => company.name)}
                        label={t("manageUsers.filterCompany").toString()}
                        defaultItem={t("manageUsers.companyUserFilterDefault")}
                        value={companyFilter}
                        onChange={(e) => {
                          setCompanyFilter(e.target.value);
                        }}
                        style={{ zIndex: "15", width: "130px" }}
                      />
                    </div>
                  </div>
                  <div className="UserListHeaderLower">
                    <Input
                      label={t("manageUsers.searchUser").toString()}
                      value={filter}
                      onChange={(e) => {
                        setFilter(e.target.value);
                      }}
                      style={{ width: "90%" }}
                    />
                  </div>
                </>
              ) : (
                <div
                  className="UserListHeaderUpper2"
                  style={{ paddingBottom: "10px" }}
                >
                  <div className="UserHeader3">
                    <Input
                      label={t("manageUsers.searchNameOrEmail").toString()}
                      value={filter}
                      onChange={(e) => {
                        setFilter(e.target.value);
                      }}
                      style={{ zIndex: "15", width: "70%" }}
                    />
                  </div>

                  <div className="UserHeader4">
                    <DropDownList
                      className="rolesAndCompanyDropDown"
                      data={[
                        t("manageUsers.operator"),
                        t("manageUsers.manager"),
                      ]}
                      label={t("manageUsers.filterRole").toString()}
                      defaultItem={t("manageUsers.roleUserFilterDefault")}
                      value={roleFilter}
                      onChange={(e) => {
                        setRoleFilter(e.target.value);
                      }}
                      style={{ zIndex: "15", marginLeft: "auto" }}
                    />
                  </div>
                </div>
              )}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                width: "100%",
                paddingTop: "10px",
                paddingBottom: "10px",
                justifyContent: "center",
              }}
            >
              <button
                className="addUserHeader"
                onClick={(e) => {
                  e.preventDefault();
                  setCompanyFilter(t("manageUsers.companyUserFilterDefault"));
                  setRoleFilter(t("manageUsers.roleUserFilterDefault"));
                  setFilter("");
                  dispatch(addNewUser());
                  setNewUser(!newUser);
                  setNewUserAdded(false);
                }}
                disabled={!newUserAdded}
                style={
                  !newUserAdded
                    ? {
                        opacity: 0.3,
                      }
                    : {}
                }
              >
                <img src="./icons/plus.svg" alt="" className="AddPlanImage" />
              </button>
            </div>
          </div>

          <div className="userList">
            {userListEmpty ? (
              <UserList
                filteredUserArray={filteredUserArray}
                roleFilter={roleFilter ? roleFilter : ""}
                companyFilter={companyFilter ? companyFilter : ""}
                newUser={newUser}
                setNewUserAdded={setNewUserAdded}
              />
            ) : (
              <div className="userListWrapper">
                <h5 style={{ paddingTop: "50px" }}>
                  {t("manageUsers.createAUser")}
                </h5>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
