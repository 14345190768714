import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  cPhoneHeightLim,
  cPhoneWidthLim,
  cPhoneWidthLimStandard,
} from "../../../app";
import { appState } from "../../../store";
import {
  reduxSetObjectsToDisplay,
  reduxSetTolerancesToDisplay,
} from "../../../store/plot/actions";
import "./toogleObjects.css";
import {
  objectTypes,
  translateToSelectedLanguage,
} from "../../../store/plot/types";
import { objectColors } from "../../visualization/gpsChartContainer";

interface ToggleObjectsProps {
  selectedMeasurement: string;
}

export const ToogleObjects: React.FunctionComponent<ToggleObjectsProps> = ({
  selectedMeasurement,
}) => {
  const initialObjects = [
    objectTypes.switch,
    objectTypes.levelCrossing,
    objectTypes.marker,
    objectTypes.notes,
    objectTypes.trackBarrier,
    objectTypes.trackCross,
    objectTypes.culvert,
    objectTypes.contactPole,
  ];

  const [objectReferences, setObjectReferences] =
    useState<string[]>(initialObjects);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const width = useSelector((state: appState) => state.scheduler.screenWidth);
  const height = useSelector((state: appState) => state.scheduler.screenHeight);

  useEffect(() => {
    setObjectReferences(initialObjects);
    dispatch(reduxSetObjectsToDisplay(initialObjects));
  }, [selectedMeasurement]);

  return (
    <div
      className="ObjectButtonsContainer"
      style={{
        paddingLeft: width >= cPhoneWidthLim ? "40px" : "10px",
        paddingRight: width >= cPhoneWidthLim ? "40px" : "10px",
        paddingTop: height >= 450 ? "0px" : "60px",
      }}
    >
      {initialObjects.map((obj: string) => {
        // const typedObjectsArr = Object.keys(objectReferences);
        const typedObject = obj as objectTypes;
        return (
          <button
            key={typedObject}
            className={"ObjectSelectorButton"}
            style={{
              opacity: objectReferences.includes(typedObject) ? 1 : 0.5,
            }}
            onClick={async () => {
              if (objectReferences.includes(obj)) {
                // Filter it!
                setObjectReferences(objectReferences.filter((o) => o !== obj));
                dispatch(
                  reduxSetObjectsToDisplay(
                    objectReferences.filter((o) => o !== obj)
                  )
                );
              } else {
                // Append it!
                setObjectReferences([...objectReferences, obj]);

                dispatch(reduxSetObjectsToDisplay([...objectReferences, obj]));
              }
            }}
          >
            <div
              className="ToleranceSelectorLine"
              style={{
                backgroundColor: objectColors(typedObject),
              }}
            ></div>
            <div
              style={{
                fontSize:
                  width < cPhoneWidthLimStandard || height < cPhoneHeightLim
                    ? "10px"
                    : "14px",
                fontWeight: "bold",
                marginLeft: "5px",
                color: "black",
              }}
            >
              {translateToSelectedLanguage(t, typedObject)}
            </div>
          </button>
        );
      })}
    </div>
  );
};
