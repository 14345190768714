import React, { useState } from "react";
import { ChartDimensionProps } from "../generic/chartContainer/chartContainer";
import { ChartContainer } from "../generic/chartContainer/chartContainer";

import { Card } from "./../generic/card/card";
import "./gpsChartContainer.css";
import { appState } from "../../store";
import { useSelector } from "react-redux";
import { GpsChart } from "./gpsChart";
import { cPhoneWidthLim } from "../../app";
import { useTranslation } from "react-i18next";
import { gpsPointType, objectTypes } from "../../store/plot/types";

interface GpsChartContainerProps {
  finishedLoadingObjects: boolean;
  gpsData: gpsPointType[];
  selectedMeasurement: string;
}
export const objectColors = (object: string) => {
  return object === objectTypes.start
    ? "lightgreen"
    : object === objectTypes.end
    ? "red"
    : object === objectTypes.switch
    ? "blue"
    : object === objectTypes.culvert
    ? "yellow"
    : object === objectTypes.trackBarrier
    ? "orange"
    : object === objectTypes.contactPole
    ? "purple"
    : object === objectTypes.levelCrossing
    ? "lightblue"
    : object === objectTypes.trackCross
    ? "green"
    : object === objectTypes.notes
    ? "pink"
    : object === objectTypes.marker
    ? "red"
    : "none";
};

const CardMemo = React.memo(Card);

export const GpsChartContainer: React.FunctionComponent<
  GpsChartContainerProps
> = ({ finishedLoadingObjects, gpsData, selectedMeasurement }) => {
  const [chartDimensions, setChartDimensions] = useState<
    ChartDimensionProps | undefined
  >(undefined);
  const measurementObjects = useSelector(
    (state: appState) => state.plot.allObjects
  );
  const { t } = useTranslation();

  // let gpsData = useSelector((state: appState) => state.plot.gpsData);

  // gpsData = gpsData ? gpsData : [];

  const screenWidth = useSelector(
    (state: appState) => state.scheduler.screenWidth
  );

  return (
    // <CardMemo>
    <div className="GpsGroupChartContainer">
      <ChartContainer
        chartDimensions={chartDimensions}
        setChartDimensions={setChartDimensions}
        rerenderEvent={false}
      >
        {gpsData.length > 0 ? null : (
          <div
            style={{
              paddingLeft: screenWidth >= cPhoneWidthLim ? "40px" : "10px",
              position: "relative",
              width: "100%",
            }}
          >
            <div
              style={{
                position: "absolute",
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                paddingTop: "60px",
                pointerEvents: "none",
                zIndex: 2,
              }}
            >
              <div
                style={{
                  width: "fit-content%",
                  height: "fit-content",
                  zIndex: 2,
                  backgroundColor: "#f9f9f9",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <div
                  style={{
                    color: "rgba(0,0,0,1)",
                    fontSize: "20px",
                  }}
                >
                  {selectedMeasurement === "-1" ? null : t("gps.notAvailable")}
                </div>
              </div>
            </div>
          </div>
        )}

        <GpsChart
          gpsSignal={gpsData}
          measurementObjects={measurementObjects}
          finishedLoadingObjects={finishedLoadingObjects}
          selectedMeasurement={selectedMeasurement}
        />
      </ChartContainer>
    </div>
    // </CardMemo>
  );
};
