import {
  deleteUser,
  roleType,
  setallCompanyUsers,
  setAllContacts,
  userActionType,
  userLoginAction,
  userLogoutAction,
  setSelectedUser,
  setAuthenticationSuccess,
  notificationType,
  userType,
  roleProps,
  announcementResponseType,
  setAnnouncementResponse,
  adminMessageType,
  informationRequestType,
  setInformationRequest,
  languageType,
} from "./types";

import { reduxSetAllMachines } from "./../machine/actions";
import { machineType } from "./../machine/types";
import { Auth } from "aws-amplify";

import {
  reduxSetAllCompanies,
  reduxSetCurrentCompany,
} from "./../company/actions";
import { companyType } from "./../company/types";

import {
  setAllReduxPlans,
  setTotalPlansOnServer,
} from "./../scheduler/actions";
import { setNotification } from "./../user/types";
import { getNewUser } from "./../../development/initializer";
import rest from "../../rest";
import { appState } from "..";
import * as usersAPI from "./../../api/netRail/users";
import * as plansAPI from "./../../api/netRail/plans";
import * as machinesAPI from "./../../api/netRail/machines";
import { getLastVisitedPlanAndMeasurement } from "../../helpers/genericHelpers";
import { planProps } from "../scheduler/types";
import { TFunction } from "i18next";

// action creators
export function reduxLogin(user: userType): userLoginAction {
  return {
    type: userActionType.Login,
    payload: user,
  };
}

// action creators

export function reduxSetAllCompanyUsers(
  allCompanyUsers: userType[]
): setallCompanyUsers {
  return {
    type: userActionType.setallCompanyUsers,
    payload: allCompanyUsers,
  };
}

export function reduxSetAllUsers(allContacts: userType[]): setAllContacts {
  return {
    type: userActionType.setAllContacts,
    payload: allContacts,
  };
}

export function reduxSetSelectedUser(userID: string): setSelectedUser {
  return {
    type: userActionType.setSelectedUser,
    payload: userID,
  };
}

export function reduxSetNotification(
  notification: notificationType
): setNotification {
  return {
    type: userActionType.setNotification,
    payload: notification,
  };
}

export function reduxDeleteUser(userID: string): deleteUser {
  return {
    type: userActionType.deleteUser,
    payload: userID,
  };
}

export function reduxUpdateUserProperty<T extends keyof userType>(
  userProperty: Pick<userType, T>
) {
  return {
    type: userActionType.updateUserProperty,
    payload: userProperty,
  };
}

export function reduxUpdateCurrentUserProperty<T extends keyof userType>(
  userProperty: Pick<userType, T>
) {
  return {
    type: userActionType.updateCurrentUserProperty,
    payload: userProperty,
  };
}

export function setUserHasChanged(userHasChanged: boolean) {
  return {
    type: userActionType.setUserHasChanged,
    payload: userHasChanged,
  };
}

export function reduxLogout(): userLogoutAction {
  return { type: userActionType.Logout };
}

function addNewReduxUser(user: userType) {
  return {
    type: userActionType.addNewReduxUser,
    payload: user,
  };
}

export function reduxSetAuthenticationSuccess(
  authenticationSuccess: boolean
): setAuthenticationSuccess {
  return {
    type: userActionType.setAuthenticationSuccess,
    payload: authenticationSuccess,
  };
}

export function reduxSetSelectedLanguage(language: languageType) {
  return {
    type: userActionType.setSelectedLanguage,
    payload: language,
  };
}

export function logout() {
  return (dispatch: any) => {
    Auth.currentSession()
      .then((res) => {
        Auth.forgetDevice()
          .then((result) => {
            Auth.signOut()
              .then((response) => {
                console.log("Successfully logged out");
                dispatch(reduxLogout());
              })
              .catch((error) => {
                dispatch(reduxLogout());
                console.error("error signing out: ", error);
              });
          })
          .catch((err) => {
            console.log("unable to forget device", err);
          });
      })
      .catch((err) => {
        console.error(`Failed to fetch current session, logging out: ${err}`);
      });
  };
}

export function addNewUser() {
  return (dispatch: any, getState: () => appState) => {
    const { user } = getState();
    const userID = user.currentUser ? user.currentUser.id : "";
    const newUser = getNewUser(userID);
    dispatch(addNewReduxUser(newUser));
  };
}

export async function detachAllUserRoles(
  userRolesList: roleProps[],
  userID: string
) {
  userRolesList.map((oldRole: roleProps) => {
    rest
      .delete("/users/" + userID + "/detachRole/" + oldRole?.id)
      .then((result) => void 0 )
      .catch((error) => console.log("detachRole error", error));
    return oldRole;
  });
}

export function reduxSetMessages(messages: adminMessageType[]) {
  return { type: userActionType.setMessages, payload: messages };
}

export function setSelectedMessage(messageID: string) {
  return { type: userActionType.setSelectedMessage, payload: messageID };
}

export function addNewMessage(message: adminMessageType) {
  return { type: userActionType.addNewMessage, payload: message };
}

export function reduxSetInformationRequest(
  informationRequest: informationRequestType
): setInformationRequest {
  return {
    type: userActionType.setInformationRequest,
    payload: informationRequest,
  };
}

export function reduxSetAnnouncementsCount(announcementsCount: number) {
  return {
    type: userActionType.setAnnouncementsCount,
    payload: announcementsCount,
  };
}

export const saveUserToServer = (
  t: TFunction<"translation", undefined, "translation">,
  email: string,
  type: roleType,
  firstName: string,
  lastName: string,
  userID: string,
  companyID: string,
  sentToServer?: boolean,
  phone_number?: string
) => {
  return (dispatch: any, getState: () => appState) => {
    if (!sentToServer) {
      rest
        .post(
          "/users/register",
          {
            email: email,
            firstName: firstName,
            lastName: lastName,
            companyID: companyID,
          },
          { withCredentials: true }
        )
        .then((response) => {
          const newUser = response.data;

          rest
            .get("/roles")
            .then((roleResponse) => {
              const allRoles: roleProps[] = roleResponse.data.list;
              let roleID;
              if (type === roleType.admin) {
                roleID = allRoles.find((role) => role.name === "ADMIN")?.id;
              } else if (type === roleType.manager) {
                roleID = allRoles.find((role) => role.name === "MANAGER")?.id;
              } else if (type === roleType.operator) {
                roleID = allRoles.find((role) => role.name === "OPERATOR")?.id;
              }

              rest
                .post("/users/" + newUser.id + "/attachRole/" + roleID)
                .then((attachRoleResponse) => {
                  const newUserID = response.data.id;
                  const currentUser = getState().user.currentUser;

                  if (newUserID.length !== 0) {
                    const allCompanyUsers =
                      getState().user.allCompanyUsers.filter(
                        (operator) =>
                          operator.companyID === currentUser?.companyID
                      );

                    dispatch(
                      reduxSetAllCompanyUsers([
                        ...allCompanyUsers,
                        {
                          id: newUserID,
                          email: email,
                          firstName: firstName,
                          lastName: lastName,
                          roles: attachRoleResponse.data.list,
                          companyID: companyID,
                          sentToServer: true,
                          createdAt: response.data.createdAt,
                          syncedAt: response.data.syncedAt,
                          updatedAt: response.data.updatedAt,
                        },
                      ])
                    );
                  }

                  rest
                    .get("/users", { withCredentials: true })
                    .then((response) => {
                      const allCompanyUsers = response.data.list;

                      currentUser?.roles.some(
                        (entry: roleProps) => entry.name === roleType.admin
                      )
                        ? dispatch(reduxSetAllUsers([...allCompanyUsers]))
                        : dispatch(
                            reduxSetAllUsers(
                              [...allCompanyUsers].filter(
                                (contact: userType) =>
                                  contact.companyID === currentUser?.companyID
                              )
                            )
                          );
                    })
                    .catch((error) => {
                      console.log(error);
                      dispatch(setUserHasChanged(true));
                    });

                  dispatch(reduxSetSelectedUser(newUserID));
                  dispatch(setUserHasChanged(false));
                  dispatch(
                    reduxSetNotification({
                      message: t("store.user.userAdded"),
                      open: true,
                      style: "success",
                    })
                  );
                })
                .catch((error) => {
                  console.log("Error in attach roles", error);
                });
            })
            .catch((error) => {
              console.log("error", error);
            });
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            alert(t("store.networkError"));
          } else if (error.message === "Request failed with status code 500") {
            dispatch(
              reduxSetNotification({
                style: "error",
                message: t("store.user.usersExists"),
                open: true,
              })
            );
          } else {
            alert(t("statusProgress.unexpectedError"));
          }
        });
    } else if (sentToServer) {
      // PATCHIMG!!!!
      // 1. Fix roles:
      rest
        .get("/roles", { withCredentials: true })
        .then((roleResponse) => {
          const allRoles: roleProps[] = roleResponse.data.list;

          rest
            .get("/users/" + userID, { withCredentials: true })
            .then(async (userResponse) => {
              const userRoles = userResponse.data.roles;

              let newRole: roleProps | undefined = undefined;

              if (!userRoles.some((role: roleProps) => role.name === type)) {
                if (type === roleType.admin) {
                  newRole = allRoles.find((role) => role.name === "ADMIN");
                } else if (type === roleType.manager) {
                  newRole = allRoles.find((role) => role.name === "MANAGER");
                } else if (type === roleType.operator) {
                  newRole = allRoles.find((role) => role.name === "OPERATOR");
                }

                // Detach all roles:
                await detachAllUserRoles(userRoles, userID).catch((error) =>
                  console.log("Couldn't detach roles", error)
                );

                rest
                  .post("/users/" + userID + "/attachRole/" + newRole?.id)
                  .then((attachRoleResponse) => {
                    console.log(
                      "Successfully attached role to user",
                      attachRoleResponse
                    );

                    if (newRole)
                      dispatch(
                        reduxUpdateUserProperty({
                          roles: [newRole],
                        })
                      );
                  })
                  .catch((error) => {
                    console.log(
                      "Unable to attach role while patching: ",
                      error
                    );
                  });
              } else {
                // Just assign
              }
            })
            .catch((error) =>
              console.log("Couldn't get current user: ", error)
            );
        })
        .catch((error) => {
          console.log("error: ", error);
        });
      //2. patch other attributes
      rest
        .patch(
          "/users/" + userID,
          {
            email: email,
            firstName: firstName,
            lastName: lastName,
            phone: phone_number,
          },
          { withCredentials: true }
        )
        .then((response) => {
          dispatch(
            reduxUpdateUserProperty({
              email: email.toLowerCase(),
              firstName: firstName,
              lastName: lastName,
              companyID: companyID,
              phone: phone_number,
            })
          );

          dispatch(
            reduxSetNotification({
              style: "success",
              message: t("store.user.userUpdated"),
              open: true,
            })
          );
          dispatch(setUserHasChanged(false));
        })
        .catch((error) => {
          if (error.message === "Network Error") {
            alert(t("store.networkError"));
          } else {
            dispatch(
              reduxSetNotification({
                style: "error",
                message: t("store.user.userUpdateError"),
                open: true,
              })
            );
          }
          dispatch(setUserHasChanged(true));
        });
      dispatch(reduxSetAuthenticationSuccess(true));
    } else {
      return alert(t("store.user.passwordMatchError"));
    }
  };
};

export const removeUser = (
  t: TFunction<"translation", undefined, "translation">,
  user: userType
) => {
  return (dispatch: any) => {
    // Check roles, remove them first if they exist.

    rest
      .delete("/users/" + user.id, { withCredentials: true })
      .then((response) => {
        dispatch(reduxDeleteUser(user.id));
        dispatch(
          reduxSetNotification({
            style: "success",
            message: t("store.user.userDeleted"),
            open: true,
          })
        );
      })
      .catch(() => {
        console.error("Failed to delete user with userID ", user.id);
        dispatch(
          reduxSetNotification({
            style: "error",
            message: t("store.user.userDeleteError") + " " + user.email,
            open: true,
          })
        );
      });
  };
};

export function populateCurrentSession(
  t: TFunction<"translation", undefined, "translation">,
  email?: string
) {
  return (dispatch: any, getState: () => appState) => {
    Auth.currentSession()
      .then(async (session) => {
        const userIDToken = session.getIdToken().payload.sub;
        const currentUser = await usersAPI.getUser(userIDToken);

        if (
          !currentUser.roles.some(
            (role) =>
              role.name === "ADMIN" ||
              role.name === "MANAGER" ||
              role.name === "DEVELOPER"
          )
        ) {
          dispatch(
            reduxSetNotification({
              message: t("store.authorizationBlock"),
              style: "error",
              open: true,
            })
          );
          throw Error("");
        }
        // Set users
        try {
          const users = await usersAPI.getUsers();
          const companyUsers = users.filter(
            (user) => user.companyID === currentUser?.companyID
          );
          dispatch(reduxLogin(currentUser));
          dispatch(reduxSetAuthenticationSuccess(true));

          // All company users
          dispatch(reduxSetAllCompanyUsers(companyUsers));

          // all user
          dispatch(reduxSetAllUsers(users));
        } catch (reason) {
          console.error("Failed to fetch users: " + reason);
        }

        // Set machines
        try {
          const machines: machineType[] = await machinesAPI.getMachines();
          dispatch(reduxSetAllMachines(machines));
        } catch (reason) {
          console.error("failed to set machines: " + reason);
        }

        // Set companies
        try {
          rest
            .get("/companies", { withCredentials: true })
            .then((companyResponse) => {
              const allFetchedCompanies: companyType[] =
                companyResponse.data.list.map((element: companyType) => {
                  return { ...element, sentToServer: true };
                });
              const currentCompany = allFetchedCompanies.find(
                (company) => company.id === currentUser.companyID
              );
              dispatch(reduxSetAllCompanies(allFetchedCompanies));
              if (currentCompany)
                dispatch(reduxSetCurrentCompany(currentCompany));
            })
            .catch((error) => console.log(error));
        } catch (reason) {
          console.error("Failed to set companies: " + reason);
        }

        // Set plans
        try {
          // Handle if user have a pre-selected plan:
          const lastVisited = getLastVisitedPlanAndMeasurement();

          let limit = 25;
          let plan: planProps | undefined = undefined;
          if (lastVisited?.lastPlan) {
            limit = 24;

            plan = await plansAPI.getPlan(lastVisited?.lastPlan);
          }

          const [plans, metaData] = await plansAPI.getPlans(
            undefined,
            undefined,
            undefined,
            undefined,
            undefined,
            limit,
            1,
            0
          );

          // If we have a pre-selected plan attach it to the gotten plans
          dispatch(
            setAllReduxPlans(plan !== undefined ? [...plans, plan] : plans)
          );
          dispatch(setTotalPlansOnServer(plans.length));
        } catch (reason) {
          console.error("Failed to fetch plans: " + reason);
        }
      })
      .catch((error) => console.error("currentSession error: ", error));
  };
}

export function onLogin(t: TFunction<"translation", undefined, "translation">) {
  return (dispatch: any, getState: () => appState) => {
    //reset all plans
    dispatch(populateCurrentSession(t));
  };
}

export function changeUserSettings(
  userID: string,
  email?: string,
  currentPassword?: string,
  newPassword?: string,
  firstName?: string,
  lastName?: string
) {
  return (dispatch: any, getState: () => appState) => {
    rest
      .post(
        "/users/login",
        { email: email, password: currentPassword },
        { withCredentials: true }
      )
      .then((response) =>
        rest
          .patch(
            "/users/" + userID,
            {
              email: email,
              password: newPassword,
              firstName: firstName,
              lastName: lastName,
            },
            { withCredentials: true }
          )
          .catch((error) => {
            if (error.message === "Network Error") {
              console.error(error);
              alert("Kontrollera din nätverksanslutning.");
            } else {
              alert("Användare med email " + email + "  finns redan.");
              console.error("Failed to add user with email ", email);
            }
          })
      )
      .catch((error) => {
        if (error.message === "Network Error") {
          console.log(error);
          alert("Kontrollera din nätverksanslutning.");
        } else {
          dispatch(reduxSetAuthenticationSuccess(false));
        }
      });
  };
}

export function reduxSetAnnouncementResponse(
  announcementResponse: announcementResponseType
): setAnnouncementResponse {
  return {
    type: userActionType.setAnnouncementResponse,
    payload: announcementResponse,
  };
}
