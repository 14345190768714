import React from "react";
import "./card.css";

interface CardProps {
  children?: React.ReactNode;
}

export const Card: React.FunctionComponent<CardProps> = ({ children }) => {
  return <div className="CardContainer">{children}</div>;
};
