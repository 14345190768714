import { ChartType, objectTypes, PlotAction, plotActionType } from "./types";
import { Loop, LoopReducer } from "redux-loop";
import { scaleLinear } from "d3";
import { v4 as uuidv4 } from "uuid";

const initialChartID = uuidv4();

const initialState: ChartType = {
  charts: [
    {
      chartID: "999999",
      finishedLoading: true,
      plotData: {
        trackGauge: [],
        crossLevel: [],
        crossLevelBIS: [],
        crossLevelUnevenness: [],
        alignment: [],
        longitudinalLevel: [],
        alignmentRight: [],
        longitudinalLevelRight: [],
        alignmentLeft: [],
        longitudinalLevelLeft: [],
        twist3m: [],
        twist6m: [],
        longitudinalAngleHP: [],
      },
      plotMetaData: {
        trackGauge: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        crossLevel: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        crossLevelBIS: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        crossLevelUnevenness: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        longitudinalLevel: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        alignment: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        longitudinalLevelLeft: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        alignmentLeft: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        longitudinalLevelRight: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        alignmentRight: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        twist6m: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        twist3m: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
      },
      measurementToDisplay: [], //["trackGauge"],
      tolerancesToDisplay: {
        plan: true,
        uh1: true,
        uh2: true,
        krit: true,
        stopp: true,
      },
      signalInterval: {
        xMinOrig: 0,
        xMaxOrig: 1,
        xMin: 0,
        xMax: 1,
        resolution: 1,
      },
    },

    {
      chartID: initialChartID,
      finishedLoading: true,
      plotData: {
        trackGauge: [],
        crossLevel: [],
        crossLevelBIS: [],
        crossLevelUnevenness: [],
        alignment: [],
        longitudinalLevel: [],
        alignmentRight: [],
        longitudinalLevelRight: [],
        alignmentLeft: [],
        longitudinalLevelLeft: [],
        twist3m: [],
        twist6m: [],
        longitudinalAngleHP: [],
      },
      plotMetaData: {
        trackGauge: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        crossLevel: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        crossLevelBIS: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        crossLevelUnevenness: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        longitudinalLevel: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        alignment: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        longitudinalLevelLeft: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        alignmentLeft: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        longitudinalLevelRight: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        alignmentRight: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        twist6m: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
        twist3m: {
          endKm: 0,
          endMeter: 0,
          endTick: "0",
          startKm: 0,
          startMeter: 0,
          startTick: "0",
          totalKrit: 0,
          totalPlan: 0,
          totalStop: 0,
          totalUh1: 0,
          totalUh2: 0,
        },
      },
      measurementToDisplay: [], //["trackGauge"],
      tolerancesToDisplay: {
        plan: true,
        uh1: true,
        uh2: true,
        krit: true,
        stopp: true,
      },
      signalInterval: {
        xMinOrig: 0,
        xMaxOrig: 1,
        xMax: 1,
        xMin: 0,
        resolution: 0,
      },
    },
  ],

  specialCharts: {
    specialChartArray: [],
    xAxisIndex: 0,
    longitudinalLevelChartID: "LLplt1",
    longitudinalAngleChartID: "LAplt1",
    windowSize: 30,
    initialWindowPositon: true,
    windowXPosition: 70,
    maxWindowSize: 30,
    viewSpecialCharts: false,
  },
  globalSignalInterval: {
    xMinOrig: 0,
    xMaxOrig: 0,
    xMax: 0,
    xMin: 0,
    resolution: 0,
  },
  globalXScale: {
    mainXScale: scaleLinear().domain([0, 1]).range([0, 1]),
    xScaleTicks: { x: [0, 1], tick: ["0", "1"] },
  },
  gpsData: undefined,
  selectedChart: initialChartID,
  allObjects: [],
  allNotes: [],
  objectsToDisplay: [
    objectTypes.switch,
    objectTypes.levelCrossing,
    objectTypes.marker,
    objectTypes.notes,
    objectTypes.trackBarrier,
    objectTypes.trackCross,
    objectTypes.culvert,
    objectTypes.contactPole,
  ],
  accelerationArea: [],
};

export const plotReducer: LoopReducer<ChartType, PlotAction> = (
  state: ChartType = initialState,
  action: PlotAction
): ChartType | Loop<ChartType, PlotAction> => {
  switch (action.type) {
    case plotActionType.setDataPointsAnyChart:
      return {
        ...state,
        charts: state.charts.map((chart) => {
          return chart.chartID === action.payload.chartID
            ? { ...chart, plotData: action.payload.data }
            : chart;
        }),
      };
    case plotActionType.setDataPoints:
      return {
        ...state,
        charts: state.charts.map((chart) => {
          return chart.chartID === state.selectedChart
            ? { ...chart, plotData: action.payload }
            : chart;
        }),
      };
    case plotActionType.setDataPointsLongitudinalLevel:
      return {
        ...state,
        specialCharts: {
          ...state.specialCharts,
          specialChartArray: state.specialCharts.specialChartArray.map(
            (chart) => {
              return chart.chartID === state.selectedChart
                ? { ...chart, plotData: action.payload }
                : chart;
            }
          ),
        },
      };
    case plotActionType.setGpsPoints:
      return {
        ...state,
        gpsData: action.payload,
      };
    case plotActionType.setMeasurementToDisplay:
      return {
        ...state,
        charts: state.charts.map((chart) => {
          return chart.chartID === state.selectedChart
            ? { ...chart, measurementToDisplay: action.payload }
            : chart;
        }),
      };
    case plotActionType.setLongitudinalLevelToDisplay:
      return {
        ...state,
        specialCharts: {
          ...state.specialCharts,
          specialChartArray: state.specialCharts.specialChartArray.map(
            (chart) => {
              return chart.chartID === state.selectedChart
                ? { ...chart, measurementToDisplay: action.payload }
                : chart;
            }
          ),
        },
      };
    case plotActionType.setTolerancesToDisplay:
      if (
        state.selectedChart === state.specialCharts.longitudinalLevelChartID
      ) {
        return {
          ...state,
          specialCharts: {
            ...state.specialCharts,
            specialChartArray: state.specialCharts.specialChartArray.map(
              (chart) => {
                return chart.chartID === state.selectedChart
                  ? { ...chart, tolerancesToDisplay: action.payload }
                  : chart;
              }
            ),
          },
        };
      } else {
        return {
          ...state,
          charts: state.charts.map((chart) => {
            return chart.chartID === state.selectedChart
              ? { ...chart, tolerancesToDisplay: action.payload }
              : chart;
          }),
        };
      }
    case plotActionType.setAllCharts:
      return {
        ...state,
        charts: action.payload.map((chart) => {
          return chart;
        }),
      };
    case plotActionType.setSignalInterval:
      return {
        ...state,
        charts: state.charts.map((chart) => {
          return chart.chartID === state.selectedChart
            ? { ...chart, signalInterval: action.payload }
            : chart;
        }),
      };
    case plotActionType.setGlobalSignalInterval:
      return {
        ...state,
        globalSignalInterval: action.payload,
      };

    case plotActionType.setSelectedChart:
      return {
        ...state,
        selectedChart: action.payload,
      };
    case plotActionType.addNewChart:
      return {
        ...state,
        charts: [...state.charts, action.payload],
      };
    case plotActionType.addLongitudinalLevelChart:
      return {
        ...state,
        specialCharts: {
          ...state.specialCharts,
          specialChartArray: [
            ...state.specialCharts.specialChartArray,
            action.payload,
          ],
        },
      };
    case plotActionType.removeChart:
      return {
        ...state,
        charts: state.charts.filter(
          (chart) => chart.chartID !== action.payload
        ),

        specialCharts: {
          ...state.specialCharts,
          specialChartArray: state.specialCharts.specialChartArray.filter(
            (chart) => chart.chartID !== action.payload
          ),
        },
      };
    case plotActionType.setPlotMetaData:
      return {
        ...state,
        charts: state.charts.map((chart) => {
          return chart.chartID === state.selectedChart
            ? { ...chart, plotMetaData: action.payload }
            : chart;
        }),
      };
    case plotActionType.setMainXScale:
      return {
        ...state,
        globalXScale: { ...state.globalXScale, mainXScale: action.payload },
      };
    case plotActionType.setMainXScaleTicks:
      return {
        ...state,
        globalXScale: { ...state.globalXScale, xScaleTicks: action.payload },
      };
    case plotActionType.setFinishedLoading:
      return {
        ...state,
        charts: state.charts.map((chart) => {
          return chart.chartID === action.payload.chartID
            ? { ...chart, finishedLoading: action.payload.finishedLoading }
            : chart;
        }),
      };
    case plotActionType.setXAxisIndex:
      return {
        ...state,
        specialCharts: { ...state.specialCharts, xAxisIndex: action.payload },
      };
    case plotActionType.setLongitudinalLevelWindow:
      return {
        ...state,
        specialCharts: { ...state.specialCharts, windowSize: action.payload },
      };
    case plotActionType.setInitialWindowPosition:
      return {
        ...state,
        specialCharts: {
          ...state.specialCharts,
          initialWindowPositon: action.payload,
        },
      };
    case plotActionType.setWindowXPosition:
      return {
        ...state,
        specialCharts: {
          ...state.specialCharts,
          windowXPosition: action.payload,
        },
      };

    case plotActionType.setAllMeasurementObjects:
      return {
        ...state,
        allObjects: action.payload,
      };

    case plotActionType.setAllMeasurementNotes:
      return {
        ...state,
        allNotes: action.payload,
      };
    case plotActionType.setViewSpecialCharts:
      return {
        ...state,
        specialCharts: {
          ...state.specialCharts,
          viewSpecialCharts: action.payload,
        },
      };
    case plotActionType.setObjectsToDisplay:
      return {
        ...state,
        objectsToDisplay: action.payload,
      };
    case plotActionType.setAccelerationArea:
      return {
        ...state,
        accelerationArea: action.payload,
      };
    default:
      return state;
  }
};
