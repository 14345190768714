import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getMeasurementMetaData } from "../../api/netRail/measurements";
import { appState } from "../../store";
import {
  reduxRemoveChart,
  reduxSetDataPoints,
  reduxSetDataPointsLongitudinalLevel,
  reduxSetInitialWindowPosition,
  reduxSetLongitudinalLevelWindow,
  reduxSetMainXScale,
  reduxSetSelectedChart,
  reduxSetWindowXPosition,
} from "../../store/plot/actions";
import { dataPointType, GroupChartDataProps } from "../../store/plot/types";
import {
  ChartContainer,
  ChartDimensionProps,
} from "../generic/chartContainer/chartContainer";
import {
  fetchInitialData,
  ToogleMeasurements,
} from "../generic/toogleMeasurements/toogleMeasurements";
import { ToogleTolerances } from "../generic/toogleTolerances/toggleTolerances";
import { Card } from "./../generic/card/card";
import { GroupChart, leftAxisWidth } from "./groupChart";
import "./groupChartContainer.css";
import { scaleLinear } from "d3";

interface GroupChartContainerProps {
  chartID: string;
  mainView: React.MutableRefObject<boolean>;
  finishedLoading: boolean;
  setFinishedLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

export const emptyDataSet: GroupChartDataProps = {
  trackGauge: [] as dataPointType[],
  crossLevelBIS: [] as dataPointType[],
  crossLevel: [] as dataPointType[],
  crossLevelUnevenness: [] as dataPointType[],
  twist3m: [] as dataPointType[],
  twist6m: [] as dataPointType[],
  alignment: [] as dataPointType[],
  longitudinalLevel: [] as dataPointType[],
  alignmentLeft: [] as dataPointType[],
  longitudinalLevelLeft: [] as dataPointType[],
  alignmentRight: [] as dataPointType[],
  longitudinalLevelRight: [] as dataPointType[],
  longitudinalAngleHP: [] as dataPointType[],
};

export const GroupChartContainer: React.FunctionComponent<
  GroupChartContainerProps
> = ({ chartID, mainView, finishedLoading, setFinishedLoading }) => {
  const [chartDimensions, setChartDimensions] = useState<
    ChartDimensionProps | undefined
  >(undefined);

  const height = useSelector((state: appState) => state.scheduler.screenHeight);
  const dispatch = useDispatch();

  const triggerRerender = height >= 450 ? true : false;

  const screenWidth = useSelector(
    (state: appState) => state.scheduler.screenWidth
  );

  const signalInterval = useSelector(
    (state: appState) => state.plot.globalSignalInterval
  );

  const selectedMeasurement = useSelector(
    (state: appState) => state.scheduler.selectedMeasurement
  );

  const allCharts = useSelector((state: appState) => state.plot.charts);

  const allChartsSpecial = useSelector(
    (state: appState) => state.plot.specialCharts.specialChartArray
  );

  const graphCharts = allCharts.filter((chart) => chart.chartID !== "999999");
  const allChartsArray = graphCharts;

  const xScale = useSelector(
    (state: appState) => state.plot.globalXScale.mainXScale
  );

  return (
    <Card>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          maxHeight: "40px",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
          overflow: "visible",
        }}
      >
        <ToogleTolerances chartID={chartID} />
        <div style={{ display: "flex", flexDirection: "row" }}>
          <span
            className="k-icon k-i-home removeChart"
            style={{ fontSize: "24px", marginRight: "20px" }}
            onClick={async () => {
              if (finishedLoading) {
                setFinishedLoading(false);

                const setsToPlot: GroupChartDataProps = {
                  trackGauge: [] as dataPointType[],
                  crossLevelBIS: [] as dataPointType[],
                  crossLevel: [] as dataPointType[],
                  crossLevelUnevenness: [] as dataPointType[],
                  twist3m: [] as dataPointType[],
                  twist6m: [] as dataPointType[],
                  alignment: [] as dataPointType[],
                  longitudinalLevel: [] as dataPointType[],
                  alignmentLeft: [] as dataPointType[],
                  longitudinalLevelLeft: [] as dataPointType[],
                  alignmentRight: [] as dataPointType[],
                  longitudinalLevelRight: [] as dataPointType[],
                  longitudinalAngleHP: [] as dataPointType[],
                };

                for (const i in allChartsArray) {
                  // Set for every chart
                  const chart = allChartsArray[i];
                  // To update one chart set the selected chart to the current looped one, this will be overwritten later when user do any action.
                  dispatch(reduxSetSelectedChart(chart.chartID)); // Make sure that the chart we want to use is activated
                  for (const i in chart.measurementToDisplay) {
                    const key = chart.measurementToDisplay[
                      i
                    ] as keyof GroupChartDataProps;

                    const initialData = await fetchInitialData(
                      dispatch,
                      chart.measurementToDisplay,
                      screenWidth,
                      key,
                      signalInterval,
                      selectedMeasurement,
                      true // reset
                    );
                    setsToPlot[key] = initialData;
                  }

                  dispatch(reduxSetDataPoints(setsToPlot));
                }

                setTimeout(() => {
                  if (chartDimensions) {
                    const xScale = scaleLinear()
                      .domain([
                        signalInterval.xMinOrig,
                        signalInterval.xMaxOrig,
                      ])
                      .range([leftAxisWidth, chartDimensions.width]);
                    dispatch(reduxSetMainXScale(xScale));
                  }

                  mainView.current = true;

                  setFinishedLoading(true);
                }, 100);
              }
            }}
          />
          <span
            className="k-icon k-i-close removeChart"
            style={{ fontSize: "24px" }}
            onClick={() => {
              dispatch(reduxRemoveChart(chartID));
            }}
          />
        </div>
      </div>
      <div className="GroupChartContainer">
        <ChartContainer
          chartDimensions={chartDimensions}
          setChartDimensions={setChartDimensions}
          rerenderEvent={triggerRerender}
        >
          <GroupChart
            chartDimensions={chartDimensions}
            chartID={chartID}
            mainView={mainView}
            finishedLoading={finishedLoading}
            setFinishedLoading={setFinishedLoading}
          />
        </ChartContainer>
      </div>
      <ToogleMeasurements chartID={chartID} chartDimensions={chartDimensions} />
    </Card>
  );
};
