import React, { useState } from "react";
import "./style.scss";
import { t } from "i18next";
import { patchSupportTicket } from "../../api/netRail/support";
import { supportTicketMessageType } from "../../store/user/types";
import { deleteSupportTicket } from "../../api/netRail/support";
import { TabStrip, TabStripTab } from '@progress/kendo-react-layout';
import { MaxHeight, MinHeight } from "./supportHelpers";
import { Toolbar, SupportField, TicketStatus, CommentSection } from "./supportComponents";

interface AdminSupportTicketProps {
  supportTicket: supportTicketMessageType;
  listView?: boolean;
  removeSupportTicket: (id: string) => void;
  updateSupportTicket: (supportTicket: supportTicketMessageType) => void;
}

export const SupportCard: React.FunctionComponent<AdminSupportTicketProps> = ({ supportTicket, listView, removeSupportTicket, updateSupportTicket }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedTab, setSelectedTab] = useState<number>(0);
  const [menuHeight, setMenuHeight] = useState("500px");

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const handleDeleteTicket = async () => {
    try {
      await deleteSupportTicket(supportTicket.id);
      removeSupportTicket(supportTicket.id);
    } catch (err) {
      console.error("Error deleting ticket", err);
    }
  };

  const handleChangeStatus = async (updatedTicket: any) => {
    try {
        const { id, status, comments } = updatedTicket;
        const updatedTicketResponse = await patchSupportTicket(id, { status, comments });
        updateSupportTicket(updatedTicketResponse);
    } catch (err) {
        console.error("Error updating status", err);
    }
};

  const formatOccurredAt = (occurredAt: any) => {
    const occurredDate: any = new Date(occurredAt);
    const now: any = new Date();
    const msPerMinute = 60 * 1000;
    const msPerHour = msPerMinute * 60;
    const msPerDay = msPerHour * 24;

    const elapsed = now - occurredDate;

    const hoursAgo = t("support.hoursAgo");
    if (elapsed < msPerDay) {
      const hours = Math.round(elapsed / msPerHour);
      return `${hours} ${hoursAgo}`;
    } else if (now.getFullYear() === occurredDate.getFullYear()) {
      return occurredDate.toLocaleDateString("en-US", { month: "short", day: "2-digit" });
    } else {
      return occurredDate.toLocaleDateString("en-US", { month: "short", day: "2-digit", year: "2-digit" });
    }
  };

  const renderCollapsedView = () => (
    <div className="announcementCardTextContainer" style={{ height: "auto", cursor: "pointer", display: "flex", flexDirection: "column" }} onClick={toggleExpand}>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "baseline", marginBottom: "10px" }}>
        <div style={{ display: "flex", width: "100%", justifyContent: "space-between" }}>
          <span style={{ display: "flex", fontWeight: "700", width: "100%" }}>
            <div style={{
              flexGrow: 1,
              marginRight: "10px",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis"
            }}>
              {supportTicket.subject}
            </div>
            {formatOccurredAt(supportTicket.occurredAt)}
          </span>
        </div>
      </div>
      <TicketStatus status={supportTicket.status} supportTicket={supportTicket} handleChangeStatus={handleChangeStatus} />
    </div>
  );

  const renderCollapsedListView = () => (
    <div className="announcementCardTextContainer" style={{ height: "auto", cursor: "pointer" }} onClick={toggleExpand}>
      <table style={{ width: "100%", tableLayout: "fixed" }}>
        <tbody>
          <tr>
            <td style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              {supportTicket.subject}
            </td>
            <td></td>
            <td></td>
            <td><TicketStatus status={supportTicket.status} supportTicket={supportTicket} handleChangeStatus={handleChangeStatus} /></td>
            <td>{formatOccurredAt(supportTicket.occurredAt)}</td>
          </tr>
        </tbody>
      </table>
    </div>
  );

  const renderSizeAdjustButton = () => (
    <button
      className="sizeAdjustButton toolbarButton"
      onClick={() => setMenuHeight(menuHeight === "500px" ? "auto" : "500px")}
    >
      {menuHeight === "500px" ? <MaxHeight /> : <MinHeight />}
    </button>
  );

  const renderExpandedView = () => (
    <div className="announcementCardTextContainer" style={listView ? { height: menuHeight, minHeight: "300px", border: "solid 3px var(--primary-color)", borderRadius: "12px" } : { height: menuHeight, minHeight: "300px" }}>
      <Toolbar
        collapse={toggleExpand}
        deleteTicket={handleDeleteTicket}
        sizeAdjustButton={renderSizeAdjustButton}
      />
      <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between", alignItems: "baseline" }}>
        <span style={{ display: "flex", fontWeight: "700", width: "100%" }}>
          <div style={{
            flexGrow: 1,
            marginRight: "10px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis"
          }}>
            {supportTicket.stepsToReproduce.substring(0, 30) + (supportTicket.stepsToReproduce.length > 30 ? "...  " : "  ")}
          </div>
          {formatOccurredAt(supportTicket.occurredAt)}
        </span>
      </div>
      <TabStrip selected={selectedTab} onSelect={(e) => setSelectedTab(e.selected)} className="myCustomTabStrip">
        <TabStripTab title={t("support.timeline")}>
          <CommentSection supportTicket={supportTicket} updateSupportTicket={updateSupportTicket} />
        </TabStripTab>
        <TabStripTab title={t("support.details")}>
          <div className="messageBodyCard" style={{ color: "black" }}>
            <SupportField label="Steps to Reproduce" value={supportTicket.stepsToReproduce} />
            <SupportField label="Category" value={supportTicket.category} />
            <SupportField label="Creator ID" value={supportTicket.creatorID} />
            <SupportField label="Installation Type" value={supportTicket.installationType} />
            <SupportField label="Last Calibrated At" value={new Date(supportTicket.lastCalibratedAt).toLocaleString()} />
            <SupportField label="Machine ID" value={supportTicket.machineID} />
            <SupportField label="Machine Type" value={supportTicket.machineType} />
            <SupportField label="Operator ID" value={supportTicket.operatorID} />
            <SupportField label="Position Latitude" value={supportTicket.positionLat} />
            <SupportField label="Position Longitude" value={supportTicket.positionLong} />
            <SupportField label="Sensor Status" value={supportTicket.sensorStatus} />
            <SupportField label="System Created On" value={supportTicket.systemCreatedOn} />
            <SupportField label="Temperature" value={supportTicket.temperature} />
            <SupportField label="Version SW" value={supportTicket.versionSW} />
          </div>
        </TabStripTab>
      </TabStrip>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
        <TicketStatus status={supportTicket.status} supportTicket={supportTicket} handleChangeStatus={handleChangeStatus} />
        <span style={{ fontWeight: "bold", alignSelf: "center" }}>ID: {supportTicket.id}</span>
      </div>
    </div>
  );

  return (
    <div className={listView ? "announcementCardListContainer" : "announcementCard-container"}>
      <div className="announcementCard">
        {isExpanded ? renderExpandedView() : listView ? renderCollapsedListView() : renderCollapsedView()}
      </div>
    </div>
  );
};