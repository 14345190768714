import React, { useEffect, useRef } from "react";
// Redux Stuff
import { useSelector } from "react-redux";
import { appState } from "../../store";
import { machineType } from "../../store/machine/types";
import { MachineListItem } from "./machineListItem";
import { customThumb } from "../manageUsers/userList";
import Scrollbars from "react-custom-scrollbars";

interface MachineListProps {
  filteredMachineArray: any; // OBS! userType
  newMachine: boolean;
  setAddMachine: React.Dispatch<React.SetStateAction<boolean>>;
}

export const MachineList: React.FC<MachineListProps> = ({
  filteredMachineArray,
  newMachine,
  setAddMachine,
}) => {
  const selectedMachine = useSelector(
    (state: appState) => state.machine.selectedMachine
  );

  const revealRefs = useRef<any[]>([]);
  revealRefs.current = [];

  useEffect(() => {
    if (revealRefs.current.length > 0)
      revealRefs.current[0].scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
  }, [newMachine]);

  const addToRef = (elementRef: never) => {
    if (elementRef && !revealRefs.current.includes(elementRef)) {
      revealRefs.current.push(elementRef);
    }
  };

  const Rows = filteredMachineArray.map((machine: machineType) => {
    const edit = selectedMachine === machine.id ? true : false;

    return (
      <div className="UserListButtonUser" key={machine.id} ref={addToRef}>
        <MachineListItem
          machine={machine}
          edit={edit}
          selectedMachine={selectedMachine}
          setAddMachine={setAddMachine}
        />
      </div>
    );
  });

  Rows.reverse();

  return (
    <Scrollbars
      thumbSize={60}
      renderThumbVertical={customThumb}
      autoHide={true}
      autoHideTimeout={1000}
      autoHideDuration={500}
    >
      <div className="userListWrapper">{Rows}</div>
    </Scrollbars>
  );
};
