import { machineType } from "./../../store/machine/types";

import rest from "./../../rest";

export const getMachines = () => {
  return new Promise<machineType[]>(async (resolve, reject) => {
    rest
      .get("/machines")
      .then(async (response) => {
        try {
          const serverMachines: machineType[] = response.data.list;

          const machines: machineType[] = serverMachines.map(
            (serverMachine) => {
              const machine: machineType = {
                id: serverMachine.id,
                name: serverMachine.name,
                description: serverMachine.description,
                status: serverMachine.status,
                coordinates: serverMachine.coordinates,
                companyID: serverMachine.companyID,
                secretKey: serverMachine.secretKey
                  ? serverMachine.secretKey
                  : "",
                clientID: serverMachine.clientID ? serverMachine.clientID : "",
                sentToServer: true,
              };
              return machine;
            }
          );

          resolve(machines); //
        } catch (reason) {
          const err = `Failed to fetch machines from server: ${reason}`;
          console.error(err);
          reject(err);
        }
      })
      .catch((error) => {
        const err = `Failed to fetch machines from server: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};

export const getMachine = (machineID: string) => {
  return new Promise<machineType>(async (resolve, reject) => {
    rest
      .get("/machines/" + machineID, { withCredentials: true })
      .then(async (response) => {
        try {
          const machine: machineType = response.data;

          resolve(machine);
        } catch (reason) {
          const err = `Failed to fetch users from server: ${reason}`;
          console.error(err);
          reject(err);
        }
      })
      .catch((error) => {
        const err = `Failed to fetch users from server: ${error}`;
        console.error(err);
        reject(err);
      });
  });
};
