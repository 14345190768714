import {
  axisBottom,
  axisLeft,
  bisectLeft,
  brushX,
  drag,
  event,
  line,
  Line,
  nest,
  select,
  selectAll,
  Selection,
  zoom,
  zoomIdentity,
  zoomTransform,
} from "d3";
import { ScaleLinear, scaleLinear } from "d3-scale";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";

import { useDispatch, useSelector } from "react-redux";
import { getMeasurementMetaData } from "../../api/netRail/measurements";
import {
  color,
  ColorType,
  getColorOffsets,
  getYLimXLim,
  measurementColors,
  SpeedClassToleranceType,
  SpeedClassType,
  tolerances,
  TolerancesType,
} from "../../helpers/genericHelpers";
import { appState } from "../../store";
import {
  reduxSetDataPoints,
  reduxSetDataPointsLongitudinalLevel,
  reduxSetGlobalSignalInterval,
  reduxSetInitialWindowPosition,
  reduxSetLongitudinalLevelWindow,
  reduxSetMainXScale,
  reduxSetMainXScaleTicks,
  reduxSetSelectedChart,
  reduxSetWindowXPosition,
  reduxSetXAxisIndex,
} from "../../store/plot/actions";
import {
  dataPointType,
  GroupChartDataProps,
  objectPointType,
  objectTypes,
  plotStateLongitudinalLevel,
  SelectedTolerancesType,
  translateToSelectedLanguage,
} from "../../store/plot/types";
import {
  measurementKeys,
  measurementMetaData,
} from "../../store/scheduler/types";
import { ChartDimensionProps } from "../generic/chartContainer/chartContainer";
import { fetchInitialData } from "../generic/toogleMeasurements/toogleMeasurements";
import { emptyTolerances } from "../generic/toogleTolerances/toggleTolerances";
import * as plotAPI from "./../../api/netRail/plot";
import { objectColors } from "./gpsChartContainer";
import "./groupChart.css";

const transitionDuration = 50;
const smallStrokeWidth = 2;
const leftAxisWidth = 30;
const bottomAxisHeight = 40;
const numTicks = 10;
const smallestSampleSize = 0.5;
// TODO::: Fill with metadata:
const numFrames = 3; // The number of viewport frames the chart has. E.g. How many frames will span outside of the view window.

const resolutions = {
  x05: 0.5,
  x1: 1,
  x2: 2,
  x5: 5,
  x10: 10,
  x15: 15,
  x20: 20,
  x30: 30,
  x40: 40,
  x50: 50,
  x60: 60,
  x70: 70,
  x80: 80,
  x90: 90,
  x100: 100,
};

const linePadding = 2;

interface GroupChartLongitudinalLevelProps {
  chartDimensions: ChartDimensionProps | undefined;
  data: GroupChartDataProps;
  chartID: string;
  mainView: React.MutableRefObject<boolean>;
  finishedLoading: boolean;
  setFinishedLoading: React.Dispatch<React.SetStateAction<boolean>>;
}

interface ChunkType {
  x1: number;
  x2: number;
  speedClass: keyof SpeedClassType;
}

interface YScalesType {
  trackGauge: ScaleLinear<number, number> | undefined;
  crossLevel: ScaleLinear<number, number> | undefined;
  crossLevelBIS: ScaleLinear<number, number> | undefined;
  crossLevelUnevenness: ScaleLinear<number, number> | undefined;
  twist3m: ScaleLinear<number, number> | undefined;
  twist6m: ScaleLinear<number, number> | undefined;
  alignment: ScaleLinear<number, number> | undefined;
  longitudinalLevel: ScaleLinear<number, number> | undefined;
  alignmentLeft: ScaleLinear<number, number> | undefined;
  longitudinalLevelLeft: ScaleLinear<number, number> | undefined;
  alignmentRight: ScaleLinear<number, number> | undefined;
  longitudinalLevelRight: ScaleLinear<number, number> | undefined;
  longitudinalAngleHP: ScaleLinear<number, number> | undefined;
}

export const computeResolution = (
  xMin: number,
  xMax: number,
  width: number
) => {
  const numPoints = width * 2;
  const chosenResolution =
    xMax - xMin <= numPoints * resolutions.x05
      ? resolutions.x05
      : xMax - xMin <= numPoints * resolutions.x1
      ? resolutions.x1
      : xMax - xMin <= numPoints * resolutions.x2
      ? resolutions.x2
      : xMax - xMin <= numPoints * resolutions.x5
      ? resolutions.x5
      : xMax - xMin <= numPoints * resolutions.x10
      ? resolutions.x10
      : xMax - xMin <= numPoints * resolutions.x15
      ? resolutions.x15
      : xMax - xMin <= numPoints * resolutions.x20
      ? resolutions.x20
      : xMax - xMin <= numPoints * resolutions.x30
      ? resolutions.x30
      : xMax - xMin <= numPoints * resolutions.x40
      ? resolutions.x40
      : xMax - xMin <= numPoints * resolutions.x50
      ? resolutions.x50
      : xMax - xMin <= numPoints * resolutions.x60
      ? resolutions.x60
      : xMax - xMin <= numPoints * resolutions.x70
      ? resolutions.x70
      : xMax - xMin <= numPoints * resolutions.x80
      ? resolutions.x80
      : xMax - xMin <= numPoints * resolutions.x90
      ? resolutions.x90
      : xMax - xMin <= numPoints * resolutions.x100
      ? resolutions.x100
      : resolutions.x100;

  return chosenResolution;
};

export const GroupChartLongitudinalLevel: React.FunctionComponent<
  GroupChartLongitudinalLevelProps
> = ({
  chartDimensions,
  data,
  chartID,
  mainView,
  finishedLoading,
  setFinishedLoading,
}) => {
  useEffect(() => {
    setFinishedLoading(true);
    return () => {
      setFinishedLoading(true);
    };
  }, []);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const groupChartRef = useRef<SVGSVGElement>(null);

  const [strokeWidth, setStrokeWidth] = useState(smallStrokeWidth);

  const IDX = useRef<any>();
  const noneEmptySets = useRef<any>();
  const firstDisplayedSignal = useRef<any>();
  const [chunks, setChunks] = useState<ChunkType[]>();

  const initialWindowPosition = useSelector(
    (state: appState) => state.plot.specialCharts.initialWindowPositon
  );

  const windowXPosition = useSelector(
    (state: appState) => state.plot.specialCharts.windowXPosition
  );

  const selectedChart = useSelector((state: appState) =>
    state.plot.specialCharts.specialChartArray.find(
      (chart) => chart.chartID === chartID
    )
  );

  const maxWindowSize = useSelector(
    (state: appState) => state.plot.specialCharts.maxWindowSize
  );

  const maxWindowSizeDivider = maxWindowSize / 5;

  const selectedTolerances = selectedChart?.tolerancesToDisplay
    ? selectedChart?.tolerancesToDisplay
    : emptyTolerances;

  const measurementsToDisplay = selectedChart?.measurementToDisplay
    ? selectedChart?.measurementToDisplay
    : [];

  const longitudinalLevelChartID = useSelector(
    (state: appState) => state.plot.specialCharts.longitudinalLevelChartID
  );
  const longitudinalAngleChartID = useSelector(
    (state: appState) => state.plot.specialCharts.longitudinalAngleChartID
  );

  const allChartsSpecial = useSelector(
    (state: appState) => state.plot.specialCharts.specialChartArray
  );

  const allCharts = useSelector((state: appState) => state.plot.charts);

  const windowSize = useSelector(
    (state: appState) => state.plot.specialCharts.windowSize
  );

  const signalInterval = useSelector(
    (state: appState) => state.plot.globalSignalInterval
  );

  const selectedMeasurement = useSelector(
    (state: appState) => state.scheduler.selectedMeasurement
  );

  const xScale = useSelector(
    (state: appState) => state.plot.globalXScale.mainXScale
  );
  const xScaleTicks = useSelector(
    (state: appState) => state.plot.globalXScale.xScaleTicks
  );

  const accelerationArea = useSelector(
    (state: appState) => state.plot.accelerationArea
  );

  const [scales, setYScale] = useState<{
    yScale: ScaleLinear<number, number>;
  }>();

  const [yScales, setYScales] = useState<YScalesType>({
    trackGauge: undefined,
    crossLevel: undefined,
    crossLevelBIS: undefined,
    crossLevelUnevenness: undefined,
    twist6m: undefined,
    twist3m: undefined,
    alignment: undefined,
    longitudinalLevel: undefined,
    alignmentLeft: undefined,
    longitudinalLevelLeft: undefined,
    alignmentRight: undefined,
    longitudinalLevelRight: undefined,
    longitudinalAngleHP: undefined,
  });

  const setsToPlot: GroupChartDataProps = {
    trackGauge: [] as dataPointType[],
    crossLevelBIS: [] as dataPointType[],
    crossLevel: [] as dataPointType[],
    crossLevelUnevenness: [] as dataPointType[],
    twist3m: [] as dataPointType[],
    twist6m: [] as dataPointType[],
    alignment: [] as dataPointType[],
    longitudinalLevel: [] as dataPointType[],
    alignmentLeft: [] as dataPointType[],
    longitudinalLevelLeft: [] as dataPointType[],
    alignmentRight: [] as dataPointType[],
    longitudinalLevelRight: [] as dataPointType[],
    longitudinalAngleHP: [] as dataPointType[],
  };

  const screenWidth = useSelector(
    (state: appState) => state.scheduler.screenWidth
  );

  const railObjects = useSelector((state: appState) => state.plot.allObjects);

  const objectsToDisplay = useSelector(
    (state: appState) => state.plot.objectsToDisplay
  );

  const getPoints = async (
    xMin: number,
    xMax: number,
    resolution: number,
    measurementsToDisplay: (keyof GroupChartDataProps)[],
    metaData: measurementMetaData
  ) => {
    setFinishedLoading(false);
    for (const i in measurementsToDisplay) {
      const currentProperty = measurementsToDisplay[
        i
      ] as keyof GroupChartDataProps;
      // If requested data are above the possible data set, set it to the original data limit

      let resp = await plotAPI
        .getPlotData(
          selectedMeasurement,
          xMin,
          xMax,
          resolution,
          currentProperty === "crossLevelBIS" ? "crossLevel" : currentProperty,
          currentProperty === "crossLevelBIS" ||
            currentProperty === "crossLevel"
            ? ["tick", "class", "reference", "errors", "bis", "quality"]
            : ["tick", "class", "quality"]
        )
        .catch((error) => console.log("error I getpoints", error));

      if (currentProperty === "crossLevelBIS") {
        resp = (resp as dataPointType[]).map((point) => {
          return { ...point, y: point.bis };
        });
      }

      setsToPlot[currentProperty] = resp ? resp : [];
    }
    setFinishedLoading(true);
    return setsToPlot;
  };

  const updatePanningDataPoints = async (xMin: number, xMax: number) => {
    try {
      setFinishedLoading(false);
      const chosenResolution = computeResolution(xMin, xMax, screenWidth);

      const measurementAllCharts = allCharts.filter(
        (chart) => chart.chartID !== "999999"
      );

      const allChartsArray = [measurementAllCharts, allChartsSpecial];

      const setsToPlot: GroupChartDataProps = {
        trackGauge: [] as dataPointType[],
        crossLevelBIS: [] as dataPointType[],
        crossLevel: [] as dataPointType[],
        crossLevelUnevenness: [] as dataPointType[],
        twist3m: [] as dataPointType[],
        twist6m: [] as dataPointType[],
        alignment: [] as dataPointType[],
        longitudinalLevel: [] as dataPointType[],
        alignmentLeft: [] as dataPointType[],
        longitudinalLevelLeft: [] as dataPointType[],
        alignmentRight: [] as dataPointType[],
        longitudinalLevelRight: [] as dataPointType[],
        longitudinalAngleHP: [] as dataPointType[],
      };
      for (const i in allChartsArray) {
        // Set for every chart
        const charts = allChartsArray[i];
        for (const i in charts) {
          const chart = charts[i];
          // To update one chart set the selected chart to the current looped one, this will be overwritten later when user do any action.
          dispatch(reduxSetSelectedChart(chart.chartID));

          let newData;

          // paning left
          if (xMin <= signalInterval.xMin) {
            newData = await getPoints(
              xMin,
              signalInterval.xMin,
              chosenResolution,
              chart.measurementToDisplay,
              chart.plotMetaData
            );

            for (const key in newData) {
              const signalToAppend = newData[key as keyof GroupChartDataProps];
              let signalToKeep;

              if (signalToAppend.length !== 0) {
                // Implies that we are not yet at an edge!
                signalToKeep = data[key as keyof GroupChartDataProps].filter(
                  (point) =>
                    point.x >= signalToAppend[signalToAppend.length - 1].x
                );

                setsToPlot[key as keyof GroupChartDataProps] =
                  signalToAppend.concat(signalToKeep);
              } else {
                // Implies that we are at an edge!
                const validData = data[key as keyof GroupChartDataProps].filter(
                  (point) => point.x > xMin
                );

                setsToPlot[key as keyof GroupChartDataProps] = data[
                  key as keyof GroupChartDataProps
                ].slice(
                  0,
                  data[key as keyof GroupChartDataProps].length -
                    signalToAppend.length -
                    1
                );
              }
            }
          } else if (signalInterval.xMax <= xMax) {
            //Panning right
            newData = await getPoints(
              signalInterval.xMax,
              xMax,
              chosenResolution,
              chart.measurementToDisplay,
              chart.plotMetaData
            );

            for (const key in newData) {
              const signalToAppend = newData[key as keyof GroupChartDataProps];

              let signalToKeep;
              if (signalToAppend.length !== 0) {
                // Implies that we are not yet at an edge!
                // handle if we reach the edge.
                signalToKeep = data[key as keyof GroupChartDataProps].filter(
                  (point) => point.x <= signalToAppend[0].x
                );
                setsToPlot[key as keyof GroupChartDataProps] =
                  signalToKeep.concat(signalToAppend);
              } else {
                // Implies that we are at an edge!
                const validData = data[key as keyof GroupChartDataProps].filter(
                  (point) => point.x > xMin
                );

                setsToPlot[key as keyof GroupChartDataProps] = data[
                  key as keyof GroupChartDataProps
                ].slice(
                  data[key as keyof GroupChartDataProps].length -
                    validData.length -
                    1,
                  data[key as keyof GroupChartDataProps].length
                );
              }
            }
          }

          // MUST BE REMOVED IN getPoints!
          dispatch(
            reduxSetGlobalSignalInterval({
              xMinOrig: signalInterval.xMinOrig,
              xMaxOrig: signalInterval.xMaxOrig,
              xMin,
              xMax,
              resolution: chosenResolution,
            })
          );
          if (
            chart.chartID === longitudinalLevelChartID ||
            chart.chartID === longitudinalAngleChartID
          ) {
            dispatch(reduxSetDataPointsLongitudinalLevel(setsToPlot));
          } else {
            dispatch(reduxSetDataPoints(setsToPlot));
          }
        }
      }
      setFinishedLoading(true);
    } catch (error) {
      setFinishedLoading(true);
      alert("kunde inte hitta data!");
    }
  };

  const updateDataPoints = async (xMin: number, xMax: number) => {
    try {
      setFinishedLoading(false);
      const chosenResolution = computeResolution(xMin, xMax, screenWidth);

      const measurementAllCharts = allCharts.filter(
        (chart) => chart.chartID !== "999999"
      );
      const allChartsArray = [allChartsSpecial, measurementAllCharts];

      for (const i in allChartsArray) {
        // Set for every chart
        const charts = allChartsArray[i];
        for (const i in charts) {
          const chart = charts[i];
          // To update one chart set the selected chart to the current looped one, this will be overwritten later when user do any action.
          dispatch(reduxSetSelectedChart(chart.chartID));
          const newData = await getPoints(
            xMin,
            xMax,
            chosenResolution,
            chart.measurementToDisplay,
            chart.plotMetaData
          );

          dispatch(
            reduxSetGlobalSignalInterval({
              xMinOrig: signalInterval.xMinOrig,
              xMaxOrig: signalInterval.xMaxOrig,
              xMin,
              xMax,
              resolution: chosenResolution,
            })
          );

          const concatenatePlotData: GroupChartDataProps = {
            trackGauge: [] as dataPointType[],
            crossLevelBIS: [] as dataPointType[],
            crossLevel: [] as dataPointType[],
            crossLevelUnevenness: [] as dataPointType[],
            twist3m: [] as dataPointType[],
            twist6m: [] as dataPointType[],
            alignment: [] as dataPointType[],
            longitudinalLevel: [] as dataPointType[],
            alignmentLeft: [] as dataPointType[],
            longitudinalLevelLeft: [] as dataPointType[],
            alignmentRight: [] as dataPointType[],
            longitudinalLevelRight: [] as dataPointType[],
            longitudinalAngleHP: [] as dataPointType[],
          };

          for (const i in measurementsToDisplay) {
            const currentProperty = measurementsToDisplay[
              i
            ] as keyof GroupChartDataProps;
            const currSet = data[currentProperty].filter(
              (point) => point.x >= xMin
            );
            const lastPart = newData[currentProperty].filter(
              (point) => point.x >= currSet[currSet.length - 1].x
            );

            if (xMax >= currSet[currSet.length - 1].x) {
              concatenatePlotData[currentProperty] = currSet.concat(lastPart);
            }
          }

          if (
            chart.chartID === longitudinalLevelChartID ||
            chart.chartID === longitudinalAngleChartID
          ) {
            dispatch(
              reduxSetDataPointsLongitudinalLevel(
                newData as GroupChartDataProps
              )
            );
          } else {
            dispatch(reduxSetDataPoints(newData as GroupChartDataProps));
          }
        }
      }
      setFinishedLoading(true);
    } catch {
      setFinishedLoading(true);
      alert("kunde inte hitta data!");
    }
  };

  function getNewDataLimits(midpoint: number, xMin: number, xMax: number) {
    const additionalData = xMax - xMin;
    return {
      xMin: midpoint - additionalData / 3,
      xMax: midpoint + (additionalData * 2) / 3,
    };
  }
  const restGroup = [
    "longitudinalLevel",
    "longitudinalLevelLeft",
    "longitudinalLevelRight",
    "longitudinalAngleHP",
  ];

  useEffect(() => {
    if (chartDimensions && measurementsToDisplay) {
      const oneViewPortPx = chartDimensions.width - leftAxisWidth;
      const completeChartWidth =
        (chartDimensions.width - leftAxisWidth) * numFrames;

      let xScale;

      if (mainView.current === true) {
        xScale = scaleLinear()
          .domain([signalInterval.xMin, signalInterval.xMax])
          .range([leftAxisWidth, chartDimensions.width]); // Make longer range for paning DETERMINES WHERE OUR SCREEN IS INITIALIZED TO!
      } else {
        xScale = scaleLinear()
          .domain([signalInterval.xMin, signalInterval.xMax])
          .range([
            -completeChartWidth / 3 + leftAxisWidth,
            (completeChartWidth * 2) / 3 + leftAxisWidth,
          ]); // Make longer range for paning DETERMINES WHERE OUR SCREEN IS INITIALIZED TO!
      }

      // TODO::: REMOVE WHEN MULTIPLE YSCALES WORKS PROPERLY
      const yScale = scaleLinear()
        .domain([0, 1])
        .range([0, chartDimensions.height - bottomAxisHeight]);

      dispatch(reduxSetMainXScale(xScale));
      setYScale({ yScale });

      // Get yscale for each data set:
      const yScalesToStore: YScalesType = {
        trackGauge: undefined,
        crossLevel: undefined,
        crossLevelBIS: undefined,
        crossLevelUnevenness: undefined,
        twist6m: undefined,
        twist3m: undefined,
        alignment: undefined,
        longitudinalLevel: undefined,
        alignmentLeft: undefined,
        longitudinalLevelLeft: undefined,
        alignmentRight: undefined,
        longitudinalLevelRight: undefined,
        longitudinalAngleHP: undefined,
      };

      let propertyYMin = Infinity,
        propertyYMax = -Infinity;

      propertyYMin =
        propertyYMin < Infinity ? propertyYMin : Infinity;
      propertyYMax =
        propertyYMax > -Infinity ? propertyYMax : -Infinity;

      // YScale of the other signals
      let propertyYMinSave = Infinity;
      let propertyYMaxSave = -Infinity;

      for (const index in restGroup) {
        const property = restGroup[index] as keyof GroupChartDataProps;
        if (
          measurementsToDisplay.includes(property) &&
          data[property].length > 0
        ) {
          const [propertyYMin, propertyYMax] = getYLimXLim(
            data,
            property,
            selectedTolerances
          );

          propertyYMinSave =
            propertyYMin < propertyYMinSave ? propertyYMin : propertyYMinSave;
          propertyYMaxSave =
            propertyYMax > propertyYMaxSave ? propertyYMax : propertyYMaxSave;
        }
      }

      for (const index in restGroup) {
        if (data[restGroup[index] as keyof GroupChartDataProps].length > 0) {
          yScalesToStore[restGroup[index] as keyof GroupChartDataProps] =
            scaleLinear()
              .domain([propertyYMaxSave * 1.1, propertyYMinSave * 1.1])
              .range([0, chartDimensions.height - bottomAxisHeight]);
        }
      }
      setYScales(yScalesToStore);
    }

    if (selectedChart && measurementsToDisplay[0]) {
      // Split data into ~equal parts, take first value, this will be our tick.
      const plotData = selectedChart.plotData;
      let chunk;
      let i, j, temporary;
      i, j, temporary, chunk = Math.floor(
        plotData[measurementsToDisplay[0]].length /
          (mainView.current ? 5 : 10)
      ); // if we have main view, display 5 ticks otherwise 10. This is since the scale will slide outside of the visible window.

      const storedXVector: number[] = [];
      const storedTickVector: string[] = [];
      for (
        i = 0, j = plotData[measurementsToDisplay[0]].length;
        i < j;
        i += chunk
      ) {
        temporary = plotData[measurementsToDisplay[0]].slice(i, i + chunk);

        if (temporary.length > 0) {
          storedXVector.push(temporary[0].x);
          storedTickVector.push(temporary[0].tick);
        }
      }

      dispatch(
        reduxSetMainXScaleTicks({ x: storedXVector, tick: storedTickVector })
      );
    }
  }, [data, chartDimensions, selectedTolerances]);

  useEffect(() => {
    if (groupChartRef.current !== null && chartDimensions && scales) {
      const completeChartWidth = chartDimensions.width * numFrames;
      // Linear mapping from data values to window pixels
      const yScale = scales.yScale;

      const svg = select(groupChartRef.current);
      const svgContent: any = svg.select(
        `.GroupChartContentGroupPlt${chartID}`
      );

      const generateYScales = () => {
        const yAxisLeft: Selection<SVGGElement, unknown, null, undefined> =
          svg.select(".yAxisLeftPlt");

        if (
          // Check if any value from one of the groups exists in measurements to display
          restGroup.some((r) =>
            measurementsToDisplay.includes(r as keyof GroupChartDataProps)
          )
        ) {

          const filteredArray = measurementsToDisplay.filter((value) =>
            restGroup.includes(value)
          );
          const prop: keyof YScalesType = filteredArray[0];

          const yScaleCurr = yScales ? yScales[prop] : undefined;
          if (yScaleCurr) {
            // Render first axes on left:
            yAxisLeft
              .transition()
              .duration(transitionDuration)
              .style("transform", `translateX(${leftAxisWidth}px)`)
              .call(
                axisLeft(yScaleCurr)
                  .ticks(5)
                  .tickFormat((v) => {
                    return "" + v.toString();
                  })
              )
              .selectAll("text")
              .style("text-anchor", "end")
              .style("color", "black");
          }
        }
      };

      generateYScales();

      svgContent
        .append("g")
        .attr("class", "xAxis caption")
        .attr(
          "transform",
          `translate(0,${chartDimensions.height - bottomAxisHeight}px)`
        );

      const xAxis: Selection<SVGGElement, unknown, null, undefined> =
        svgContent.select(".xAxis");

      //********* XAXIS LABELS *********
      //*********** GRIDLINES **********
      // Find equally distant ticks
      if (measurementsToDisplay[0]) {
        let iter = 0;

        xAxis
          .call(
            axisBottom(xScale)
              .tickValues(xScaleTicks.x) // Give x value of where our ticks should be.
              .tickFormat((v: any) => {
                const tickToDisplay = xScaleTicks.tick[iter];
                iter += 1;
                return tickToDisplay; // Instead of displaying x-value display tick value.
              })
          )
          .selectAll("text")
          .style("text-anchor", "end")
          .attr("transform", "rotate(-12)");

        const make_x_gridlines = () => {
          return axisBottom(xScale)
            .tickValues(xScaleTicks.x) // Give x value of where our ticks should be.
            .tickFormat((v: any) => {
              //TODO::: BEWARE OF USING [0]
              const tickToDisplay = xScaleTicks.tick[iter];
              iter += 1;
              return tickToDisplay; // Instead of displaying x-value display tick value.
            });
        };

        // gridlines in y axis function
        const make_y_gridlines = () => {
          if (measurementsToDisplay[0]) {
            const scale =
              yScales[measurementsToDisplay[0] as keyof YScalesType];
            return axisLeft(scale ? scale : yScale).ticks(5);
          } else {
            return axisLeft(yScale).ticks(5);
          }
        };

        svgContent.selectAll(".gridX").remove();
        svgContent.selectAll(".gridY").remove();

        // add the X gridlines  ((ARE OK))
        svgContent
          .append("g")
          .attr("class", "gridX")
          .attr(
            "transform",
            "translate(0," + (chartDimensions.height - bottomAxisHeight) + ")"
          )
          .attr("opacity", 0.075)
          .call(
            make_x_gridlines()
              .tickSize(-chartDimensions.height)
              .tickFormat(() => {
                return "";
              })
          );

        // add the Y gridlines
        svgContent
          .append("g")
          .attr("class", "gridY")
          .style("transform", `translateX(${-chartDimensions.width}px)`)
          .attr("opacity", 0.075)
          .call(
            make_y_gridlines()
              .tickSize(-completeChartWidth)
              .tickFormat(() => {
                return "";
              })
          );
      }
      //**************************
    }
  }, [
    scales,
    yScales,
    xScaleTicks, // translate an eventually new xAxis
  ]);

  useEffect(() => {
    if (groupChartRef.current !== null && scales && chartDimensions) {
      const svg = select(groupChartRef.current);

      // Delete Stuff to be rerendered
      select(`.mouse-line${chartID}`).remove();
      selectAll(`.mouse-per-line${chartID} circle`).remove();
      selectAll(`#tooltip${chartID}`).remove();

      // *****************************************************
      //              TOOLTIP w intersecting line
      // *****************************************************
      // 1.  First gather data in suitable format.
      // 2. Initialize tooltip
      // 3. Identify platform. We want different behaviour for PC and phones.
      // 4. Move line on dragging and find the most closeby data point and display it in tooltip.
      // 5. Move tooltip with mouse

      const resNested = nest()
        .key((d: any) => {
          return d;
        })
        .entries(
          Object.keys(data).map((signal) => {
            let gatheredData;
            for (let j = 0; j < allChartsSpecial.length; j++) {
              const currentChart = allChartsSpecial[j];
              const signalData =
                currentChart.plotData[signal as keyof GroupChartDataProps];

              if (signalData.length > 0) {
                gatheredData = signalData;
              }
            }

            return {
              signal: signal,
              data: gatheredData ? gatheredData : [],
            };
          })
        );

      const windowWidthMax =
        xScale(xScale.invert(leftAxisWidth) + maxWindowSize) - leftAxisWidth;

      const tooltip: any = select(`#svgContainerPlt${chartID}`)
        .append("div")
        .attr("id", `tooltip${chartID}`)
        .style("position", "absolute")
        .style("background-color", "#D3D3D3") //"rgb(43, 43, 43)") // )
        .style("opacity", 0.85)
        .style("padding", 6)
        .style("pointer-events", "none");

      select(`.mouse-line-dragable${chartID}`).remove();
      select(`.mouse-line-dragable-overlay${chartID}`).remove();
      select(`.mouse-line-dragable-line${chartID}`).remove();
      select(`.mouse-line-dragable-line-overlay${chartID}`).remove();

      const dragableMouseG = svg
        .append("rect") // create vertical line to follow mouse
        .attr("class", `mouse-line-dragable${chartID}`)
        .style("stroke", "var(--primary-color)")
        .attr("height", chartDimensions.height - bottomAxisHeight)
        .attr(
          "width",
          !mainView.current
            ? xScale(xScale.invert(leftAxisWidth) + windowSize) - leftAxisWidth
            : xScale(signalInterval.xMinOrig + windowSize) - leftAxisWidth
        )
        .attr(
          "x",
          !mainView.current
            ? initialWindowPosition
              ? leftAxisWidth + 40
              : windowXPosition
            : initialWindowPosition
            ? xScale(signalInterval.xMinOrig) + 40
            : windowXPosition
        )
        .attr("y", function (d: any) {
          return 0;
        })
        .attr("fill", "rgb(178, 178, 178)")
        .style("opacity", "0.6");

      dragableMouseG
        .selectAll(`.mouse-per-line-dragable${chartID}`)
        .data(resNested)
        .enter()
        .append("g")
        .attr("class", `mouse-per-line-dragable${chartID}`);

      const tooltipDragBehavior: any = drag();
      let windowClickedAt: any;

      tooltipDragBehavior
        .on("start", function () {
          windowClickedAt =
            (event.x - windowXPosition) /
            (xScale(xScale.invert(leftAxisWidth) + windowSize) - leftAxisWidth);
        })
        .on("drag", function (d: any) {
          let mouseX =
            event.x -
            windowClickedAt *
              (xScale(xScale.invert(leftAxisWidth) + windowSize) -
                leftAxisWidth);

          if (mouseX <= leftAxisWidth) {
            mouseX = leftAxisWidth;
          } else if (
            xScale(xScale.invert(mouseX) + windowSize) >= chartDimensions.width
          ) {
            mouseX = xScale(xScale.invert(chartDimensions.width) - windowSize);
          }

          let mouseY = event.y + bottomAxisHeight;

          if (mouseY > chartDimensions.height) {
            mouseY = chartDimensions.height;
          } else if (mouseY < bottomAxisHeight) {
            mouseY = bottomAxisHeight;
          }

          selectAll(`.mouse-per-line-dragable${chartID}`).attr(
            "transform",
            function (d: any, i) {
              noneEmptySets.current = d.values
                .map((set: any) => {
                  if (set.data.length > 0) {
                    return { signal: set.signal, data: set.data };
                  }
                })
                .filter((set: any) => set !== undefined);

              const xDist = xScale.invert(mouseX);
              const idx2: any = [];
              noneEmptySets.current.map((set: any) => {
                const arr = set.data.map((point: any) => point.x);
                idx2[set.signal] = bisectLeft(arr, xDist);
              });

              // Get the first signal in measurements to display, since this is the one we want to use as x-axis of the measurement
              firstDisplayedSignal.current = noneEmptySets.current.find(
                (signal: any) =>
                  measurementsToDisplay.some(
                    (measToDisplay: string) => measToDisplay === signal.signal
                  )
              );

              IDX.current = idx2;
              if (firstDisplayedSignal.current !== undefined) {
                const idx = bisectLeft(
                  firstDisplayedSignal.current.data.map(
                    (point: any) => point.x
                  ),
                  xDist
                );
                const allGraphCharts = allChartsSpecial;
                for (const i in allGraphCharts) {
                  // Set for every chart
                  const chart = allGraphCharts[i];
                  select(`.mouse-line-dragable${chart.chartID}`).attr(
                    "x",
                    mouseX
                  );
                  select(`.mouse-line-dragable-line${chart.chartID}`)
                    .attr(
                      "x1",
                      mouseX +
                        xScale(xScale.invert(leftAxisWidth) + windowSize) -
                        leftAxisWidth
                    )
                    .attr("y1", 0)
                    .attr(
                      "x2",
                      mouseX +
                        xScale(xScale.invert(leftAxisWidth) + windowSize) -
                        leftAxisWidth
                    )
                    .attr("y2", chartDimensions.height - bottomAxisHeight);

                  select(`.mouse-line-dragable-line-overlay${chart.chartID}`)
                    .attr(
                      "x1",
                      mouseX +
                        xScale(xScale.invert(leftAxisWidth) + windowSize) -
                        leftAxisWidth
                    )
                    .attr("y1", 0)
                    .attr(
                      "x2",
                      mouseX +
                        xScale(xScale.invert(leftAxisWidth) + windowSize) -
                        leftAxisWidth
                    )
                    .attr("y2", chartDimensions.height - bottomAxisHeight);

                  // Transparent overlay
                  select(`.mouse-line-dragable-overlay${chart.chartID}`).attr(
                    "x",
                    mouseX
                  );

                  if (chart.chartID === longitudinalLevelChartID)
                    updateTooltipContent(resNested, mouseX, mouseY, chart);
                }

                if (noneEmptySets.current[0].data[idx]) {
                  return null;
                } else {
                  return null;
                }
              } else {
                return null;
              }
            }
          );
        })
        .on("end", function (f: any) {
          let mouseX =
            event.x -
            windowClickedAt *
              (xScale(xScale.invert(leftAxisWidth) + windowSize) -
                leftAxisWidth);

          if (mouseX <= leftAxisWidth) {
            mouseX = leftAxisWidth;
          } else if (
            xScale(xScale.invert(mouseX) + windowSize) >= chartDimensions.width
          ) {
            mouseX = xScale(xScale.invert(chartDimensions.width) - windowSize);
          }

          dispatch(reduxSetInitialWindowPosition(false));

          dispatch(reduxSetWindowXPosition(mouseX));

          const xDist = xScale.invert(mouseX);

          dispatch(reduxSetXAxisIndex(xDist));
        });

      const tooltipWindowDragBehavior: any = drag();
      tooltipWindowDragBehavior
        .on("start", () => void 0)
        .on("drag", function (d: any) {
          let mouseX = event.x;
          if (
            mouseX - windowXPosition >= windowWidthMax &&
            windowXPosition + windowWidthMax < chartDimensions.width
          ) {
            mouseX = windowXPosition + windowWidthMax;
          } else if (
            mouseX - windowXPosition <
            windowWidthMax / maxWindowSizeDivider
          ) {
            mouseX = windowXPosition + windowWidthMax / maxWindowSizeDivider;
          } else if (
            mouseX > chartDimensions.width &&
            windowXPosition + windowWidthMax >= chartDimensions.width
          ) {
            mouseX = chartDimensions.width;
          }

          selectAll(`.mouse-per-line-dragable-line${chartID}`).attr(
            "transform",
            function (d: any, i) {
              noneEmptySets.current = d.values
                .map((set: any) => {
                  if (set.data.length > 0) {
                    return { signal: set.signal, data: set.data };
                  }
                })
                .filter((set: any) => set !== undefined);

              const xDist = xScale.invert(mouseX);
              const idx2: any = [];
              noneEmptySets.current.map((set: any) => {
                const arr = set.data.map((point: any) => point.x);
                idx2[set.signal] = bisectLeft(arr, xDist);
              });

              // Get the first signal in measurements to display, since this is the one we want to use as x-axis of the measurement
              firstDisplayedSignal.current = noneEmptySets.current.find(
                (signal: any) =>
                  measurementsToDisplay.some(
                    (measToDisplay: string) => measToDisplay === signal.signal
                  )
              );

              IDX.current = idx2;
              if (firstDisplayedSignal.current !== undefined) {
                const idx = bisectLeft(
                  firstDisplayedSignal.current.data.map(
                    (point: any) => point.x
                  ),
                  xDist
                );
                const allGraphCharts = allChartsSpecial;
                for (const i in allGraphCharts) {
                  // Set for every chart
                  const chart = allGraphCharts[i];
                  select(`.mouse-line-dragable-line${chart.chartID}`)
                    .attr("x1", mouseX)
                    .attr("y1", 0)
                    .attr("x2", mouseX)
                    .attr("y2", chartDimensions.height - bottomAxisHeight);
                  select(`.mouse-line-dragable-line-overlay${chart.chartID}`)
                    .attr("x1", mouseX)
                    .attr("y1", 0)
                    .attr("x2", mouseX)
                    .attr("y2", chartDimensions.height - bottomAxisHeight);
                  select(`.mouse-line-dragable${chart.chartID}`).attr(
                    "width",

                    mouseX - windowXPosition
                  );
                  // Transparent overlay
                }

                if (noneEmptySets.current[0].data[idx]) {
                  return null;
                } else {
                  return null;
                }
              } else {
                return null;
              }
            }
          );
        })
        .on("end", function (f: any) {
          let mouseX = event.x;

          if (
            mouseX - windowXPosition >= windowWidthMax &&
            windowXPosition + windowWidthMax < chartDimensions.width
          ) {
            mouseX = windowXPosition + windowWidthMax;
          } else if (
            mouseX - windowXPosition <
            windowWidthMax / maxWindowSizeDivider
          ) {
            mouseX = windowXPosition + windowWidthMax / maxWindowSizeDivider;
          } else if (
            mouseX > chartDimensions.width &&
            windowXPosition + windowWidthMax >= chartDimensions.width
          ) {
            mouseX = chartDimensions.width;
          }

          const newWindowSize =
            xScale.invert(mouseX - windowXPosition + leftAxisWidth) -
            xScale.invert(leftAxisWidth);

          dispatch(
            reduxSetLongitudinalLevelWindow(
              Number(newWindowSize.toPrecision(3))
            )
          );
        });

      if (windowWidthMax / 3 < 20) {
        const dragableMouseOverlay = svg

          .append("rect") // create vertical line to follow mouse
          .attr("class", `mouse-line-dragable-overlay${chartID}`)

          .attr("height", chartDimensions.height - bottomAxisHeight)
          .attr("width", windowWidthMax < 25 ? 25 : windowWidthMax)
          .attr(
            "x",
            !mainView.current
              ? initialWindowPosition
                ? leftAxisWidth + 40
                : windowXPosition
              : initialWindowPosition
              ? xScale(signalInterval.xMinOrig) + 40
              : windowXPosition
          )
          .attr("y", function (d: any) {
            return 0;
          })
          .attr("fill", "transparent");

        dragableMouseOverlay.call(tooltipDragBehavior);
      } else {
        const dragableMouseLine = svg
          .append("line") // create vertical line to follow window right side
          .attr("class", `mouse-line-dragable-line${chartID}`)
          .attr("stroke", "rgb(110, 110, 110)")
          .style("stroke-width", 5)
          .attr(
            "x1",
            initialWindowPosition
              ? xScale(xScale.invert(leftAxisWidth) + windowSize) + 40
              : windowXPosition +
                  xScale(xScale.invert(leftAxisWidth) + windowSize) -
                  leftAxisWidth
          )
          .attr(
            "x2",
            initialWindowPosition
              ? xScale(xScale.invert(leftAxisWidth) + windowSize) + 40
              : windowXPosition +
                  xScale(xScale.invert(leftAxisWidth) + windowSize) -
                  leftAxisWidth
          )
          .attr("y1", 0)
          .attr("y2", chartDimensions.height - bottomAxisHeight);

        dragableMouseLine
          .selectAll(`.mouse-per-line-dragable-line${chartID}`)
          .data(resNested)
          .enter()
          .append("g")
          .attr("class", `mouse-per-line-dragable-line${chartID}`);

        const dragableMouseLineOverlay = svg
          .append("line")
          .attr("class", `mouse-line-dragable-line-overlay${chartID}`)
          .style("stroke", "transparent")
          .style("stroke-width", 15)
          .attr(
            "x1",
            initialWindowPosition
              ? xScale(xScale.invert(leftAxisWidth) + windowSize) + 40
              : windowXPosition +
                  xScale(xScale.invert(leftAxisWidth) + windowSize) -
                  leftAxisWidth
          )
          .attr(
            "x2",
            initialWindowPosition
              ? xScale(xScale.invert(leftAxisWidth) + windowSize) + 40
              : windowXPosition +
                  xScale(xScale.invert(leftAxisWidth) + windowSize) -
                  leftAxisWidth
          )
          .attr("y1", 0)
          .attr("y2", chartDimensions.height - bottomAxisHeight);

        dragableMouseG.call(tooltipDragBehavior);
        dragableMouseLineOverlay.call(tooltipWindowDragBehavior);
      }

      // Used for all operating systems
      const updateTooltipContent = (
        resNested: any,
        mouseX: number,
        mouseY: number,
        currentChart: plotStateLongitudinalLevel
      ) => {
        let htmlString = "";

        if (
          firstDisplayedSignal.current &&
          firstDisplayedSignal.current.data.length > 0
        ) {
          const initialIndex = IDX.current[firstDisplayedSignal.current.signal];

          if (firstDisplayedSignal.current.data[initialIndex] !== undefined) {
            htmlString += `<div >
          <div style="font-size:12px;float: left"> km+m: </div><div style="font-size:12px;float: right">${firstDisplayedSignal.current.data[initialIndex].tick}
      </div></div>`;
          } else {
            htmlString += `<div>
        <div style="font-size:12px;float: left"> km+m: </div><div style="font-size:12px;float: right">${
          firstDisplayedSignal.current.data[
            firstDisplayedSignal.current.data.length - 1
          ].tick
        }
    </div></div>`;
          }

          currentChart.measurementToDisplay.map((toDisplay) => {
            const currSet = noneEmptySets.current.find(
              (set: any) => set.signal === toDisplay
            );

            if (currSet) {
              const currIDX = IDX.current[currSet.signal];

              let point;
              if (currSet.data[currIDX]) {
                point = currSet.data[currIDX];
              } else {
                point = currSet.data[currSet.data.length - 1];
              }

              htmlString += `<div>
              <div style="font-size:12px;color:${
                measurementColors[
                  currSet.signal as keyof typeof measurementColors
                ]
              };float: left"> ${measurementKeys(
                currSet.signal.toString(),
                t
              )}: </div><div style="font-size:12px;float: right">${
                point.y === undefined ? "-" : point.y
              }mm  </div></div>`;
            }
          });
          if (firstDisplayedSignal.current.data[initialIndex]) {
            htmlString += `<div style="width:100%;">
          <div style="font-size:12px;float: left;width:100%;"> ${firstDisplayedSignal.current.data[initialIndex].class} </div></div>`;
          }
          const currentObjectsToDisplay: objectPointType[] = railObjects.filter(
            (object) => {
              return (
                object.type.some((type) => objectsToDisplay.includes(type)) ||
                object.note !== ""
              );
            }
          );

          if (
            currentObjectsToDisplay.length > 0 &&
            firstDisplayedSignal.current.data[initialIndex]
          ) {
            const objectsArray = currentObjectsToDisplay?.filter(
              (point) =>
                point?.tick ===
                firstDisplayedSignal.current?.data[initialIndex].tick
            );

            htmlString += `<div style="width:100%;">
          <div style="font-size:12px;float: left;width:100%;word-break:break-word;"> ${translateToSelectedLanguage(
            t,
            objectsArray
              .filter((obj: objectPointType) =>
                obj.type.some((type) => objectsToDisplay.includes(type))
              )
              .map((obj: objectPointType) => obj.value)
              .toString()
          )}</div></div>`;

            objectsArray.map((obj: objectPointType) => {
              if (
                (obj.note === objectTypes.marker || obj.note === "Markering") &&
                objectsToDisplay.includes(objectTypes.marker)
              ) {
                htmlString += `<div style="width:100%;">
              <div style="font-size:12px;float: left;width:100%;max-width:150px;word-break:break-word"> ${t(
                "gps.marker"
              )}</div></div>`;
              } else if (
                obj.note !== "" &&
                !(
                  obj.note === objectTypes.marker || obj.note === "Markering"
                ) &&
                objectsToDisplay.includes(objectTypes.notes)
              ) {
                htmlString += `<div style="margin-bottom:5px;width:100%;">
            <div style="font-size:12px;float: left;width:100%;max-width:150px;word-break:break-word"> ${obj.note}</div></div>`;
              }
            });
          }
        }

        selectAll(`#tooltip${currentChart.chartID}`)
          .html(htmlString)
          .style("display", "block")
          .style("position", "absolute")
          .style(
            "left",
            mouseX >= chartDimensions.width - 100
              ? mouseX - 60 + "px"
              : mouseX + 60 + "px"
          )
          .style("top", 120 + "px") //- 65
          .style("visibility", "visible")
          .style("border", "solid")
          .style("border-width", "1px")
          .style("border-radius", "5px");
      };

      // **********************************************
      //  	  ^^^ TOOLTIP w intersecting line ^^^
      // **********************************************
    }
  }, [
    xScale,
    windowSize,
    windowXPosition,
    initialWindowPosition,
    objectsToDisplay,
  ]);

  // Plot measurements
  useEffect(() => {
    if (groupChartRef.current !== null && scales && xScale && chartDimensions) {
      const completeChartWidth =
        (chartDimensions.width - leftAxisWidth) * numFrames;

      const svg = select(groupChartRef.current);

      // Select the chart Content inside the svg object
      const svgContent: any = svg.select(
        `.GroupChartContentGroupPlt${chartID}`
      );
      const yScale = scales.yScale;

      const genericCurveFunction = line<{
        x: number | null;
        y: number | null;
      }>()
        .x((d: any) => {
          return xScale(d.x);
        })
        .y((d: any) => {
          return yScale(d.y);
        });

      const yScaleSpecificCurveFuntion = (key: keyof YScalesType) => {
        const currYScale = yScales[key];
        if (currYScale !== undefined) {
          return line<{
            x: number | null;
            y: number | null;
          }>()
            .x((d: any) => {
              return xScale(d.x);
            })
            .y((d: any) => {
              return currYScale(d.y);
            });
        }
      };

      // Delete Stuff to be rerendered
      select(`.mouse-line${chartID}`).remove();

      selectAll(`.mouse-per-line${chartID} circle`).remove();
      selectAll(`#tooltip${chartID}`).remove();
      svgContent.select(".brush").remove(); // Remove Brush element
      svgContent.selectAll("linearGradient").remove(); // Remove coloring
      svgContent.selectAll(".plotLinePlt").remove(); // Remove plot lines
      svgContent.selectAll(".railObjectsPlt").remove(); // Remove plot lines
      svgContent.selectAll(".identify-step").remove();
      svgContent.selectAll(".identify-acceleration").remove();

      const getMaskingChunks = (data: dataPointType[]) => {
        if (data.length > 0) {
          const chunksToMask: any[] = [];
          let storedQuality = data[0].quality;
          let startIdx = 0;
          let endIdx = 0;

          for (let j = 0; j < data.length; j++) {
            const currSample = data[j];

            if (currSample && storedQuality !== currSample.quality) {
              endIdx = j - 1;

              if (startIdx == 0) {
                // Handle initial sample
                chunksToMask.push({
                  x1: data[startIdx > 1 ? startIdx - 1 : 0].x,
                  x2: data[endIdx === startIdx ? endIdx + 1 : endIdx].x, // Get the point in between two points
                  x1Tick: data[startIdx].tick,
                  x2Tick: data[endIdx].tick,
                  quality: storedQuality,
                });
              } else {
                chunksToMask.push({
                  x1: storedQuality
                    ? data[startIdx > 1 ? startIdx : 0].x
                    : data[startIdx > 1 ? startIdx - 1 : 0].x,
                  x2: storedQuality ? data[endIdx].x : data[endIdx + 1].x, // If the bad data 0nly is in one point then just take the next sample.
                  x1Tick: data[startIdx > 1 ? startIdx - 1 : 0].tick,
                  x2Tick: data[endIdx === startIdx ? endIdx + 1 : endIdx].tick,
                  quality: storedQuality,
                });
              }

              storedQuality = currSample.quality;
              startIdx = j;
            }
          }

          // This one is fairly sensitive!
          if (data.length > 0 && data[startIdx] && data[data.length - 1])
            chunksToMask.push({
              // Handle last iteration
              x1: storedQuality
                ? data[startIdx].x
                : data[startIdx > 0 ? startIdx - 1 : startIdx].x,
              x2: data[data.length - 1].x,
              x1Tick: data[startIdx].tick,
              x2Tick: data[data.length - 1].tick,
              quality: storedQuality,
            });

          return chunksToMask;
        } else {
          return [];
        }
      };

      //Define the function which plots all charts
      const plotChart = (
        key: keyof GroupChartDataProps,
        curveFunc: Line<{ x: number | null; y: number | null }>,
        currData: dataPointType[]
      ) => {
        // 1. this section only needs to run ones, keep out of for loop.
        const speedClass = currData.map((point) => point.class);

        let storedSpeedClass = speedClass[0] as keyof SpeedClassType;
        const chunks: ChunkType[] = [];
        let iterSaved = 0;

        // find x-values of tolerances
        speedClass.map((speedClassItem, iter) => {
          if (speedClassItem != storedSpeedClass) {
            chunks.push({
              x1: currData[iterSaved].x,
              x2: currData[iter].x,
              speedClass: storedSpeedClass,
            });
            iterSaved = iter;
            storedSpeedClass = speedClassItem as keyof SpeedClassType;
          }
          if (iter === speedClass.length - 1) {
            chunks.push({
              x1: currData[iterSaved].x,
              x2: currData[iter].x,
              speedClass: storedSpeedClass,
            });
            iterSaved = iter;
            storedSpeedClass = speedClassItem as keyof SpeedClassType;
          }
        });

        if (chunks.length === 0) {
          chunks.push({
            x1: xScale.domain()[0],
            x2: xScale.domain()[1],
            speedClass: storedSpeedClass,
          });
        }

        setChunks(chunks);

        // This section only needs to run ones, keep out of for loop.
        if (currData && chartDimensions) {
          // dataArray has values. First connect them with a line

          // Plot each measurement:
          if (key === "longitudinalAngleHP") {
            const threshold = 0.003;
            const stepStartXPosition: number[] = [];
            const stepStopXPosition: number[] = [];
            for (let k = 1; k < currData.length - 1; k++) {
              const currDataSample = currData[k];

              if (Math.abs(currDataSample.y) >= threshold) {
                const prevDataSample = currData[k - 1];
                const nextDataSample = currData[k + 1];

                if (
                  Math.abs(prevDataSample.y) <= threshold &&
                  Math.abs(nextDataSample.y) >= threshold
                ) {
                  stepStartXPosition.push(xScale(currDataSample.x));
                }
                if (
                  Math.abs(prevDataSample.y) >= threshold &&
                  Math.abs(nextDataSample.y) <= threshold
                ) {
                  stepStopXPosition.push(xScale(currDataSample.x));
                }
              }
            }

            for (let k = 0; k < stepStartXPosition.length; k++) {
              svgContent
                .append("rect")
                .attr("class", "identify-step")
                .style("fill", "orange")
                .style("opacity", "0.3")
                .style("pointer-events", "none")
                .attr("height", chartDimensions.height - bottomAxisHeight)
                .attr(
                  "width",
                  (stepStopXPosition[k] < chartDimensions.width
                    ? stepStopXPosition[k]
                    : chartDimensions.width) -
                    (stepStartXPosition[k] > leftAxisWidth
                      ? stepStartXPosition[k]
                      : leftAxisWidth)
                )
                .attr(
                  "x",
                  stepStartXPosition[k] > leftAxisWidth
                    ? stepStartXPosition[k]
                    : leftAxisWidth
                );
            }

            svgContent
              .selectAll(`.GroupChart${key}LinelongitudinalAngleHP`)
              .attr("id", `GroupChart${key}LinelongitudinalAngleHPID`)
              .data([currData])
              .join("path")
              .attr("stroke", "#4ed0a9")
              .attr("stroke-width", strokeWidth)
              .attr("fill", "none") // get color
              .attr("d", (value: any) => {
                let curveFunction = curveFunc;
                const f = yScaleSpecificCurveFuntion(key);
                if (f) curveFunction = f;
                return curveFunction(value);
              })
              .attr(
                "class",
                `GroupChart${key}LinelongitudinalAngleHP plotLinePlt`
              );
          } else {
            const stopAccelerationArray: number[] = accelerationArea.filter(
              (point) => point < 0
            );

            const startAccelerationArray: number[] = accelerationArea.filter(
              (point) => point > 0
            );

            for (const k in startAccelerationArray) {
              svgContent
                .append("rect")
                .attr("class", "identify-acceleration")
                .style("fill", "black")
                .style("opacity", "0.1")
                .style("pointer-events", "none")
                .attr("height", chartDimensions.height - bottomAxisHeight)
                .attr(
                  "width",
                  (xScale(Math.abs(stopAccelerationArray[k])) <
                  chartDimensions.width
                    ? xScale(Math.abs(stopAccelerationArray[k]))
                    : chartDimensions.width) -
                    (xScale(startAccelerationArray[k]) > leftAxisWidth
                      ? xScale(startAccelerationArray[k])
                      : leftAxisWidth)
                )
                .attr(
                  "x",
                  xScale(startAccelerationArray[k]) > leftAxisWidth
                    ? xScale(startAccelerationArray[k])
                    : leftAxisWidth
                );
            }

            let chunkIter = 0;
            chunks.map((chunk) => {
              if (chunk.speedClass) {
                // CREATE THE SPEEDCLASS SPECIFIC LINEAR GRADIENT
                svgContent
                  .append("linearGradient")
                  .attr("id", `gradient${key}${chunkIter}Plt`)
                  .attr("gradientUnits", "userSpaceOnUse")
                  .attr("x1", 0)
                  .attr("x2", 0)
                  .attr("y1", 0)
                  .attr("y2", chartDimensions.height)
                  .selectAll("stop")
                  .data(
                    getColorOffsets(
                      yScales[key] ? yScales[key] : yScale,
                      tolerances[key],
                      chunk.speedClass,
                      chartDimensions
                    )
                  )
                  .join("stop")
                  .attr("offset", (d: any) => {
                    if (d.offset && Math.abs(d.offset) != Infinity)
                      return d.offset;
                  })
                  .attr("stop-color", (d: any) => d.color);
              }

              // DRAW THE GRAPH
              const speedClassData = currData.filter(
                (point) => chunk.x1 <= point.x && point.x <= chunk.x2
              );

              const chunksToMask = getMaskingChunks(speedClassData);

              for (let j = 0; j < chunksToMask.length; j++) {
                const maskingChunk = chunksToMask[j];

                if (maskingChunk.quality === false) {
                  svgContent
                    .selectAll(`.GroupChartPlt${key}Line${chunkIter}${j}`)
                    .attr("id", `GroupChartPlt${key}Line${chunkIter}ID`)
                    .data([
                      speedClassData.filter(
                        (point) =>
                          maskingChunk.x1 <= point.x &&
                          point.x <= maskingChunk.x2
                      ),
                    ])
                    .join("path")
                    .attr("stroke", (i: dataPointType) => {
                      return measurementsToDisplay.length > 1
                        ? measurementColors[key]
                        : `url(#gradient${key}${chunkIter}Plt)`;
                    })
                    .attr("stroke-width", strokeWidth)
                    .attr("fill", "none")
                    .style("opacity", "0.2")
                    .attr("d", (value: any) => {
                      let curveFunction = curveFunc;
                      const f = yScaleSpecificCurveFuntion(key);
                      if (f) curveFunction = f;
                      return curveFunction(value);
                    })
                    .attr(
                      "class",
                      `GroupChartPlt${key}Line${chunkIter} plotLinePlt`
                    );
                } else {
                  svgContent
                    .selectAll(`.GroupChartPlt${key}Line${chunkIter}${j}`)
                    .attr("id", `GroupChartPlt${key}Line${chunkIter}ID`)
                    .data([
                      speedClassData.filter(
                        (point) =>
                          maskingChunk.x1 <= point.x &&
                          point.x <= maskingChunk.x2
                      ),
                    ])
                    .join("path")
                    .attr("stroke", (i: dataPointType) => {
                      return measurementsToDisplay.length > 1
                        ? measurementColors[key]
                        : `url(#gradient${key}${chunkIter}Plt)`;
                    })
                    .attr("stroke-width", strokeWidth)
                    .attr("fill", "none")
                    .attr("d", (value: any) => {
                      let curveFunction = curveFunc;
                      const f = yScaleSpecificCurveFuntion(key);
                      if (f) curveFunction = f;
                      return curveFunction(value);
                    })
                    .attr(
                      "class",
                      `GroupChartPlt${key}Line${chunkIter} plotLinePlt`
                    );
                }
              }

              chunkIter += 1;
            });
          }

          const currentObjectsToDisplay: objectPointType[] = railObjects.filter(
            (object) => {
              return (
                object.type.some((type) => objectsToDisplay.includes(type)) ||
                object.note !== ""
              );
            }
          );

          // Draw objects:
          if (chartID === longitudinalLevelChartID) {
            for (let i = 0; i < currentObjectsToDisplay.length; i++) {
              const objectPoint = currentObjectsToDisplay[i];
              for (let j = 0; j < objectPoint.type.length; j++) {
                const objectPointType = {
                  ...objectPoint,
                  type: objectPoint.type[j],
                };
                if (objectsToDisplay.includes(objectPointType.type))
                  // Draw objects:
                  svgContent
                    .selectAll("railObjectsPlt")
                    .data([objectPoint])
                    .enter()
                    .append("circle")
                    .attr("stroke", "none")
                    .attr("cx", function (d: any, idx: any) {
                      return xScale(d.x);
                    })
                    .attr("cy", function (d: any, idx: any) {
                      const currYScale = yScales[key];
                      if (currYScale) {
                        // GET THE CLOSEST Y-VALUE!
                        const arr = currData.map((point: any) => point.x);
                        idx = bisectLeft(arr, d.x);

                        if (currData[idx]) return currYScale(currData[idx].y);
                      }
                    })
                    .attr("fill", function (d: any, idx: any) {
                      const railObject = currentObjectsToDisplay[idx].value;
                      if (railObject !== undefined) {
                        return objectColors(railObject);
                      }
                    })
                    // Gives circles a black border
                    .attr("stroke", function (d: any, idx: any) {
                      const railObject = currentObjectsToDisplay[idx].value;
                      if (railObject !== undefined) {
                        return "black";
                      } else {
                        return "none";
                      }
                    })
                    .attr("r", 3)
                    .attr("class", `railObjectsPlt`);
              }
              // Draw objects:
              if (objectPoint.note !== "") {
                svgContent
                  .selectAll("railNotes")
                  .data([objectPoint])
                  .enter()
                  .append("circle")
                  .attr("stroke", "none")
                  .attr("cx", function (d: any, idx: any) {
                    return xScale(d.x);
                  })
                  .attr("cy", function (d: any, idx: any) {
                    const currYScale = yScales[key];
                    if (currYScale) {
                      // GET THE CLOSEST Y-VALUE!

                      const arr = currData.map((point: any) => point.x);
                      idx = bisectLeft(arr, d.x);

                      if (currData[idx]) {
                        return currYScale(currData[idx].y);
                      }
                    }
                  })
                  .attr("fill", function (d: any, idx: any) {
                    const railNote = d.note;
                    if (railNote !== undefined && railNote !== "") {
                      if (
                        (railNote === objectTypes.marker ||
                          railNote === "Markering") &&
                        objectsToDisplay.includes(objectTypes.marker)
                      ) {
                        return "#FF0000";
                      } else if (
                        railNote !== objectTypes.marker &&
                        railNote !== "Markering" &&
                        objectsToDisplay.includes(objectTypes.notes)
                      ) {
                        return "pink";
                      } else {
                        return "none";
                      }
                    } else {
                      return "none";
                    }
                  })
                  // Gives circles a black border
                  .attr("stroke", function (d: any, idx: any) {
                    const railNote = d.note;
                    if (railNote !== undefined && railNote !== "") {
                      if (
                        (railNote === objectTypes.marker ||
                          railNote === "Markering") &&
                        objectsToDisplay.includes(objectTypes.marker)
                      ) {
                        return "black";
                      } else if (
                        railNote !== objectTypes.marker &&
                        railNote !== "Markering" &&
                        objectsToDisplay.includes(objectTypes.notes)
                      ) {
                        return "black";
                      } else {
                        return "none";
                      }
                    } else {
                      return "none";
                    }
                  })
                  .attr("r", 3)
                  .attr("class", `railObjectsPlt`);
              }
            }
          }
          // *********************************************
          // *********************************************
        }
      };

      const allGraphCharts = allChartsSpecial;
      const allChartsFiltered = allCharts.filter(
        (chart) => chart.chartID !== "999999"
      );

      const zoomBehavior: any = zoom()
        .scaleExtent([1, 1]) // Only allow translations
        .on("start", function () {
          dispatch(reduxSetSelectedChart(chartID)); // Make sure that the chart we want to use is activated!

          if (!mainView.current) {
            // Guard against dragging on mainview.
            dragContainer.call(zoomBehavior.transform, zoomIdentity);
          }
        })
        .on("zoom", function () {
          // WE NEED TO TRANSLATE EVERY CHART ALONG X-AXIS
          const valueToTranslate = zoomTransform(this).x;

          if (!mainView.current && valueToTranslate !== 0) {
            for (const i in allGraphCharts) {
              // Set for every chart
              const chart = allGraphCharts[i];

              const svg = selectAll(`.GroupChartPlt${chart.chartID}`);

              const svgContentToTranslate: any = svg.select(
                `.GroupChartContentGroupPlt${chart.chartID}`
              );

              svgContentToTranslate
                .selectAll(".plotLinePlt")
                .attr("transform", "translate(" + valueToTranslate + ",0)");

              svgContentToTranslate
                .selectAll(".identify-step")
                .attr("transform", "translate(" + valueToTranslate + ",0)");

              svgContentToTranslate
                .selectAll(".identify-acceleration")
                .attr("transform", "translate(" + valueToTranslate + ",0)");

              svgContentToTranslate
                .selectAll(".railObjectsPlt")
                .attr("transform", "translate(" + valueToTranslate + ",0)");

              svgContentToTranslate
                .selectAll(".allErrorLinesPlt")
                .attr("transform", "translate(" + valueToTranslate + ",0)");

              svgContentToTranslate
                .selectAll(".xAxis")
                .attr(
                  "transform",
                  "translate(" +
                    valueToTranslate +
                    "," +
                    (chartDimensions.height - bottomAxisHeight) +
                    ")"
                );

              svgContentToTranslate
                .selectAll(".gridX")
                .attr(
                  "transform",
                  "translate(" +
                    valueToTranslate +
                    "," +
                    (chartDimensions.height - bottomAxisHeight) +
                    ")"
                );

              svgContentToTranslate
                .selectAll(".gridY")
                .attr(
                  "transform",
                  "translate(" + valueToTranslate + "," + 0 + ")"
                );
            }
            for (const i in allChartsFiltered) {
              // Set for every chart
              const chart = allChartsFiltered[i];

              const svg = selectAll(`.GroupChartPlt${chart.chartID}`);

              const svgContentToTranslate: any = svg.select(
                `.GroupChartContentGroupPlt${chart.chartID}`
              );

              svgContentToTranslate
                .selectAll(".plotLine")
                .attr("transform", "translate(" + valueToTranslate + ",0)");

              svgContentToTranslate
                .selectAll(".railObjects")
                .attr("transform", "translate(" + valueToTranslate + ",0)");

              svgContentToTranslate
                .selectAll(".identify-acceleration")
                .attr("transform", "translate(" + valueToTranslate + ",0)");

              svgContentToTranslate
                .selectAll(".allErrorLines")
                .attr("transform", "translate(" + valueToTranslate + ",0)");

              svgContentToTranslate
                .selectAll(".xAxis")
                .attr(
                  "transform",
                  "translate(" +
                    valueToTranslate +
                    "," +
                    (chartDimensions.height - bottomAxisHeight) +
                    ")"
                );

              svgContentToTranslate
                .selectAll(".gridX")
                .attr(
                  "transform",
                  "translate(" +
                    valueToTranslate +
                    "," +
                    (chartDimensions.height - bottomAxisHeight) +
                    ")"
                );

              svgContentToTranslate
                .selectAll(".gridY")
                .attr(
                  "transform",
                  "translate(" + valueToTranslate + "," + 0 + ")"
                );
            }
          }
        })
        .on("end", async function () {
          const valueToTranslate = zoomTransform(this).x;

          if (!mainView.current && valueToTranslate !== 0 && finishedLoading) {
            const draggedPixles = -zoomTransform(this).x + leftAxisWidth;
            const draggedMeters = xScale.invert(draggedPixles);
            mainView.current = false;
            const newLimits = getNewDataLimits(
              draggedMeters,
              signalInterval.xMin,
              signalInterval.xMax
            );
            setFinishedLoading(false);

            await updatePanningDataPoints(newLimits.xMin, newLimits.xMax);
            setFinishedLoading(true);
          }
        });

      const dragContainer = svg.selectAll(`.dragContainerXPlt${chartID}`);

      dragContainer.call(zoomBehavior).transition().duration(2000);

      // TOOLTIP POSISITON HEREREER

      // **********************************************
      //  	             PLOT CHARTS
      // **********************************************

      for (const property in data) {
        const currentProperty = property as keyof GroupChartDataProps;

        if (measurementsToDisplay.includes(currentProperty)) {
          plotChart(
            currentProperty,
            genericCurveFunction,
            data[currentProperty]
          );
        }
      }

      // **********************************************
      //  	       ^^^^ PLOT CHARTS ^^^^
      // **********************************************

      // **********************************************
      //  	    Reset all charts on dbl click
      // **********************************************

      const allChartsArray = allChartsSpecial;

      svgContent.on("dblclick", async () => {
        dispatch(reduxSetSelectedChart(chartID)); // Make sure that the chart we want to use is activated

        const setsToPlot: GroupChartDataProps = {
          trackGauge: [] as dataPointType[],
          crossLevelBIS: [] as dataPointType[],
          crossLevel: [] as dataPointType[],
          crossLevelUnevenness: [] as dataPointType[],
          twist3m: [] as dataPointType[],
          twist6m: [] as dataPointType[],
          alignment: [] as dataPointType[],
          longitudinalLevel: [] as dataPointType[],
          alignmentLeft: [] as dataPointType[],
          longitudinalLevelLeft: [] as dataPointType[],
          alignmentRight: [] as dataPointType[],
          longitudinalLevelRight: [] as dataPointType[],
          longitudinalAngleHP: [] as dataPointType[],
        };

        setFinishedLoading(false);
        for (const i in allChartsArray) {
          // Set for every chart
          const chart = allChartsArray[i];

          // To update one chart set the selected chart to the current looped one, this will be overwritten later when user do any action.
          dispatch(reduxSetSelectedChart(chart.chartID)); // Make sure that the chart we want to use is activated

          for (const i in chart.measurementToDisplay) {
            const key = chart.measurementToDisplay[
              i
            ] as keyof GroupChartDataProps;

            const initialData = await fetchInitialData(
              dispatch,
              chart.measurementToDisplay,
              screenWidth,
              key,
              signalInterval,
              selectedMeasurement,
              true // reset
            );
            setsToPlot[key] = initialData;
          }

          if (
            chart.chartID === longitudinalLevelChartID ||
            chart.chartID === longitudinalAngleChartID
          ) {
            dispatch(reduxSetDataPointsLongitudinalLevel(setsToPlot));
          } else {
            dispatch(reduxSetDataPoints(setsToPlot));
          }
        }

        const plotMetaData = await getMeasurementMetaData(
          "longitudinalLevel",
          selectedMeasurement
        );

        const xMin = plotMetaData.startKm * 1000 + plotMetaData.startMeter;
        const xMax = plotMetaData.endKm * 1000 + plotMetaData.endMeter;

        if (xMax - xMin <= 30) {
          dispatch(
            reduxSetLongitudinalLevelWindow(
              Number(((xMax - xMin) * 0.5).toPrecision(3))
            )
          );
        } else {
          dispatch(reduxSetLongitudinalLevelWindow(maxWindowSize));
        }

        // dispatch(reduxSetWindowXPosition(70));
        // dispatch(reduxSetInitialWindowPosition(true));
        const xScale = scaleLinear()
          .domain([signalInterval.xMinOrig, signalInterval.xMaxOrig])
          .range([leftAxisWidth, chartDimensions.width]); // Make longer range for paning DETERMINES WHERE OUR SCREEN IS INITIALIZED TO!

        mainView.current = true;
        dispatch(reduxSetMainXScale(xScale));

        setFinishedLoading(true);
      });

      // **********************************************
      //  	^^^^ Reset all charts on dbl click ^^^^
      // **********************************************

      // **********************************************
      //              BRUSHING
      // **********************************************
      const updateChart = async () => {
        const extent = event.selection;

        // If no selection, back to initial coordinate. Otherwise, update X axis domain
        if (!extent) {
          if (!idleTimeout) return (idleTimeout = setTimeout(idled, 350)); // This allows to wait a little bit
          dispatch(reduxSetMainXScale(xScale));
          setYScale({ yScale });
        } else {
          let xMin;
          let xMax;
          if (
            Math.abs(xScale.invert(extent[0]) - xScale.invert(extent[1])) <
            numTicks * smallestSampleSize
          ) {
            xMin = xScale.invert(extent[0]);
            xMax = xScale.invert(extent[0]) + 2.5;
          } else {
            xMin = xScale.invert(extent[0]); // Last pixel
            xMax = xScale.invert(extent[1]);
          }

          // XMIN AND XMAX ARE REQUESTING THE CORRECT DATA POINTS

          if (xMax - xMin >= 5) {
            if (xMax - xMin < windowSize) {
              const newWindowSize =
                xMax - xMin < 6 ? xMax - xMin - 0.5 : xMax - xMin - 1;
              dispatch(
                reduxSetLongitudinalLevelWindow(
                  Number(newWindowSize.toPrecision(3))
                )
              );
            }
            // if(xMax - xMin < windowSize)
            // There is a error between our domain and our range. We zoom To xMin, xMax, However, we should get additional data to fit the scrollable width
            // We need to request a larger data-interval so that we can fill the scrollable width.
            // Let's do this by adding the diff to xmax, this should mean that we append more data to the right.
            // THIS DOES NOT HANDLE EDGE CASES
            const newLimits = getNewDataLimits(
              xMin + (xMax - xMin) / 2,
              xMin,
              xMax
            );

            const oneViewWidth = -completeChartWidth / 3 + leftAxisWidth; //px

            const diffToDataEnd = xScale(
              newLimits.xMin + (signalInterval.xMinOrig - newLimits.xMin)
            ); //px

            const rangeBegining = oneViewWidth - diffToDataEnd;

            let newXScale;
            if (signalInterval.xMinOrig - newLimits.xMin > 0) {
              newXScale = xScale
                .domain([signalInterval.xMinOrig, newLimits.xMax])
                .range([
                  rangeBegining + leftAxisWidth,
                  (completeChartWidth * 2) / 3 + leftAxisWidth,
                ]);
            } else {
              newXScale = xScale
                .domain([xMin - (xMax - xMin), xMax + (xMax - xMin)])
                .range([
                  -completeChartWidth / 3 + leftAxisWidth,
                  (completeChartWidth * 2) / 3 + leftAxisWidth,
                ]);
            }

            dispatch(reduxSetMainXScale(newXScale));
            setYScale({ yScale });
            setFinishedLoading(false);
            await updateDataPoints(xMin - (xMax - xMin), xMax + (xMax - xMin));
            setFinishedLoading(true);
          }

          svgContent.select(".brush").call(xBrush.move, null); // This remove the grey brush area as soon as the selection has been done
        }
      };

      const xBrush = brushX() // Add the brush feature using the d3.brush function
        .extent([
          [leftAxisWidth, 0],
          [chartDimensions.width, chartDimensions.height - bottomAxisHeight],
        ])
        .on("start", () => {
          dispatch(reduxSetSelectedChart(chartID));
          mainView.current = false; // Make sure that the chart we want to use is activated
        }) // initialise the brush area: start at 0,0 and finishes at width,height: it means I select the whole graph area
        .on("end", () => {
          updateChart();

          if (!(event.selection === null)) {
            dispatch(reduxSetWindowXPosition(70));

            dispatch(reduxSetInitialWindowPosition(true));
          }
        });

      let idleTimeout: any;
      const idled = () => {
        idleTimeout = null;
      };

      svgContent.append("g").attr("class", "brush").call(xBrush);

      // **********************************************
      //              ^^^^ BRUSHING ^^^^
      // **********************************************

      // Force acis to the bottom
      svgContent
        .selectAll(".xAxis")
        .attr(
          "transform",
          "translate(" +
            0 +
            "," +
            (chartDimensions.height - bottomAxisHeight) +
            ")"
        );
    }
  }, [xScale, objectsToDisplay]);

  useEffect(() => {
    if (groupChartRef.current !== null && scales && chartDimensions) {
      const svg = select(groupChartRef.current);
      // Select the chart Content inside the svg object
      const svgContent = svg.select(`.GroupChartContentGroupPlt${chartID}`);
      const yScale = scales.yScale;

      svgContent.selectAll(".allErrorLinesPlt").remove(); // Remove error lines
      const genericCurveFunction = line<{
        x: number | null;
        y: number | null;
      }>()
        .x((d: any) => {
          return xScale(d.x);
        })
        .y((d: any) => {
          return yScale(d.y);
        });

      const yScaleSpecificCurveFuntion = (key: keyof YScalesType) => {
        const currYScale = yScales[key];

        if (currYScale !== undefined) {
          return line<{
            x: number | null;
            y: number | null;
          }>()
            .x((d: any) => {
              return xScale(d.x);
            })
            .y((d: any) => {
              return currYScale(d.y);
            });
        } else {
          return undefined;
        }
      };

      const renderErrorLines = (
        svgContent: any,
        key: string,
        tolerancesToPlot: string[],
        curveFunc: Line<{ x: number | null; y: number | null }>
      ) => {
        // iterate over sets.

        const chunkIter = 0;

        chunks?.map((chunk) => {
          tolerancesToPlot.map((tolerance) => {
            const currTolerance = tolerance as keyof SpeedClassToleranceType;
            const toleranceColor = color[tolerance as keyof ColorType];
            const currKey = key as keyof TolerancesType;

            let toleranceLineLow;
            let toleranceLineUp;

            if (chunk.speedClass) {
              const chunkData = data[currKey].filter(
                (point) => chunk.x1 <= point.x && point.x <= chunk.x2
              );

              toleranceLineLow = chunkData.map((point) => {
                return {
                  x: point.x,
                  y: tolerances[currKey][chunk.speedClass][currTolerance].low,
                };
              });

              toleranceLineUp = chunkData.map((point) => {
                return {
                  x: point.x,
                  y: tolerances[currKey][chunk.speedClass][currTolerance].up,
                };
              });
            }
            if (
              chunk.speedClass &&
              tolerances[currKey][chunk.speedClass][currTolerance].low !==
                -Infinity
            ) {
              svgContent
                .selectAll(
                  `.GroupChartPlt${key}Lower${tolerance}${chunk.speedClass}ErrorLine${chunkIter}`
                )
                .attr(
                  "id",
                  `GroupChartPlt${key}Lower${tolerance}${chunk.speedClass}ErrorLine${chunkIter}ID`
                )
                .data([toleranceLineLow])
                .join("path")
                .style("stroke-dasharray", "3, 3")
                .attr("stroke", toleranceColor)
                .attr("opacity", 0.5)
                .attr("stroke-width", smallStrokeWidth)
                .attr("fill", "none")
                .style("pointer-events", "none") // Shouldn't be able to hit lines
                .transition()
                .duration(transitionDuration)
                .attr("d", (value: any) => {
                  let curveFunction = curveFunc;
                  const f = yScaleSpecificCurveFuntion(
                    key as keyof YScalesType
                  );
                  if (f) curveFunction = f;
                  return curveFunction(value);
                })
                .attr(
                  "class",
                  `GroupChartPlt${key}Lower${tolerance}${chunk.speedClass}ErrorLine${chunkIter} allErrorLinesPlt`
                );
            }

            if (
              chunk.speedClass &&
              tolerances[currKey][chunk.speedClass][currTolerance].up !==
                Infinity
            ) {
              svgContent
                .selectAll(
                  `.GroupChartPlt${key}Upper${tolerance}${chunk.speedClass}ErrorLine${chunkIter}`
                )
                .attr(
                  "id",
                  `GroupChartPlt${key}Upper${tolerance}${chunk.speedClass}ErrorLine${chunkIter}ID`
                )
                .data([toleranceLineUp])
                .join("path")
                .style("stroke-dasharray", "3, 3")
                .attr("stroke", toleranceColor)
                .attr("opacity", 0.5)
                .attr("stroke-width", smallStrokeWidth)
                .attr("fill", "none")
                .style("pointer-events", "none") // Shouldn't be able to hit lines
                .transition()
                .duration(transitionDuration)
                .attr("d", (value: any) => {
                  let curveFunction = curveFunc;
                  const f = yScaleSpecificCurveFuntion(
                    key as keyof YScalesType
                  );
                  if (f) curveFunction = f;
                  return curveFunction(value);
                })
                .attr(
                  "class",
                  `GroupChartPlt${key}Upper${tolerance}${chunk.speedClass}ErrorLine${chunkIter} allErrorLinesPlt`
                );
            }
          });
        });
      };

      // Define the function which plots all charts
      const plotChart = (
        key: keyof GroupChartDataProps,
        curveFunc: Line<{ x: number | null; y: number | null }>
      ) => {
        const dataArray = data[key] ? data[key] : undefined;

        if (
          dataArray !== undefined &&
          chartDimensions &&
          key !== "longitudinalAngleHP"
        ) {
          renderErrorLines(
            svgContent,
            key,
            Object.keys(selectedTolerances).filter((fieldName) => {
              const keyName = fieldName as keyof SelectedTolerancesType;
              return selectedTolerances[keyName];
            }),
            curveFunc
          );
        }
      };

      for (const property in data) {
        const currentProperty = property as keyof GroupChartDataProps;
        if (
          measurementsToDisplay.includes(currentProperty) &&
          measurementsToDisplay.length < 2
        ) {
          plotChart(currentProperty, genericCurveFunction);
        }
      }
    }
  }, [chunks]);

  if (!chartDimensions) {
    return null;
  }

  return (
    <div id={`svgContainerPlt${chartID}`}>
      <svg
        className={`GroupChartPlt${chartID}`}
        ref={groupChartRef}
        style={{
          overflow: "visible",
          cursor: "pointer",
          minHeight: "250px",
        }}
      >
        <defs>
          <clipPath id={"ClipPathGroupChartPlt"}>
            <rect
              id="GroupChartClipPlt"
              x={leftAxisWidth + "px"}
              width={chartDimensions.width - leftAxisWidth + "px"}
              height={chartDimensions.height + "px"}
            ></rect>
          </clipPath>
          <clipPath id={"AxisClipPathGroupChartPlt"}>
            <rect
              id="AxisClipPathGroupPlt"
              x={leftAxisWidth + "px"}
              y={chartDimensions.height - bottomAxisHeight + "px"}
              width={chartDimensions.width - leftAxisWidth + "px"}
              height={bottomAxisHeight + "px"}
            ></rect>
          </clipPath>
        </defs>
        <g className="yAxisLeftPlt caption">
          <text className="yAxisLeftPltLabel caption" />
        </g>
        <g
          className={`GroupChartContentGroupPlt${chartID}`}
          clipPath={`url(#ClipPathGroupChartPlt)`}
        ></g>
        <g className={`dragContainerXPlt${chartID}`}>
          <rect
            x={leftAxisWidth + "px"}
            y={chartDimensions.height - bottomAxisHeight + 10 + "px"}
            width={chartDimensions.width - leftAxisWidth + "px"}
            height={bottomAxisHeight + "px"}
            fill="transparent"
          ></rect>
        </g>
        <g className="yAxisRightPlt caption">
          <text className="yAxisRightPltLabel caption" />
        </g>
        <g className="yAxisRight2Plt caption">
          <text className="yAxisRight2PltLabel caption" />
        </g>
      </svg>
    </div>
  );
};
