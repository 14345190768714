import proj4 from "proj4";
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  GoogleMap,
  GoogleMapProps,
  InfoWindow,
  Marker,
  Polyline,
  withGoogleMap,
  withScriptjs,
} from "react-google-maps";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { cPhoneHeightLim, cPhoneWidthLim, developmentMode } from "../../app";
import {
  convertToHmiStatus,
  fromTickToMeters,
  removeItemAll,
} from "../../helpers/genericHelpers";
import { appState } from "../../store";
import { reduxSetObjectsToDisplay } from "../../store/plot/actions";
import {
  gpsPointType,
  objectPointType,
  objectTypes,
  translateObject,
  translateToSelectedLanguage,
} from "../../store/plot/types";
import { Spinner } from "../spinner/spinner";
import mapStyle from "./../../theme/mapStyle";
import { objectColors } from "./gpsChartContainer";
import "./groupChart.css";
import MarkerClusterer from "react-google-maps/lib/components/addons/MarkerClusterer";
import { start } from "repl";

export interface GpsChartDataProps {
  trackGauge: { x: number; y: number }[];
}

interface GpsChartProps {
  gpsSignal: gpsPointType[] | undefined;
  measurementObjects: objectPointType[];
  finishedLoadingObjects: boolean;
  selectedMeasurement: string;
}

interface MarkerType {
  lng: number;
  lat: number;
  type: objectTypes;
  color: string;
  tick: string;
  value?: string;
  description?: string;
}

interface ClusterInfo {
  lng: number;
  lat: number;
  // number: {
  // Start and end are outside of the clusterer
  [objectTypes.switch]: number;
  [objectTypes.culvert]: number;
  [objectTypes.trackBarrier]: number;
  [objectTypes.levelCrossing]: number;
  [objectTypes.notes]: number;
  [objectTypes.marker]: number;
  [objectTypes.trackCross]: number;
  [objectTypes.contactPole]: number;
  // };
  info?: string;
}

export const convertToWGS84 = (x: number, y: number) => {
  proj4.defs([
    [
      "WGS84",
      "+title=WGS 84 (long/lat) +proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees",
    ],
    [
      "SWEREF99TM",
      "+proj=utm +zone=33 +ellps=GRS80 +towgs84=0,0,0,0,0,0,0 +units=m +no_defs",
    ],
  ]);

  const source = "SWEREF99TM";
  const target = "WGS84";

  const result = proj4(source, target, [x, y]);

  return [result[0], result[1]];
};

const getGPSTraceOfSwitches = (markerPoints: objectPointType[]) => {
  // Identify intersections of data points
  const chunks: any = [];
  const testSwitches = markerPoints.filter((point) => {
    return point.type.some((type) => type === objectTypes.switch);
  });

  let iterSaved = 0;
  testSwitches.map((aSwitch, iter) => {
    const currentTick = fromTickToMeters(testSwitches[iter].tick);

    let previousTick;
    if (iter === 0) {
      previousTick = currentTick;
    } // handle initial index
    else {
      previousTick = fromTickToMeters(testSwitches[iter - 1].tick);
    }

    if (currentTick - previousTick > 0.5) {
      // New switch identified
      chunks.push({
        x1: testSwitches[iterSaved].x,
        x2: testSwitches[iter - 1].x,
      });
      iterSaved = iter;
    }

    if (iter === testSwitches.length - 1) {
      chunks.push({
        x1: testSwitches[iterSaved].x,
        x2: testSwitches[iter].x,
      });
      iterSaved = iter;
    }
  });

  // Create the trace:
  const switchTraceChunks: gpsPointType[][] = [];
  for (let i = 0; i < chunks.length; i++) {
    const theTrace = markerPoints
      ?.filter(
        (signalValue) =>
          signalValue.x >= chunks[i].x1 && signalValue.x <= chunks[i].x2
      )
      .map((pt) => {
        const gpsPt = {
          x: pt.x,
          gpsX: pt.gpsX,
          gpsY: pt.gpsY,
          t: 1,
          class: "H0 - dummy",
          tick: pt.tick,
          errors: "",
          reference: 0,
          objects: ["switch"],
          notes: pt.note,
        } as gpsPointType;
        return gpsPt;
      });

    if (theTrace) switchTraceChunks.push(theTrace);
  }

  return switchTraceChunks;
};

const restructureLongObjects = (
  allMarkers: MarkerType[],
  switchTraceChunks: gpsPointType[][]
) => {
  let switchMarkers = [] as MarkerType[];

  if (switchTraceChunks !== undefined && switchTraceChunks.length > 0)
    switchMarkers = switchTraceChunks.map((traceChunk) => {
      if (traceChunk.length > 0) {
        const [lng, lat] = convertToWGS84(
          traceChunk[0].gpsY,
          traceChunk[0].gpsX
        );

        return {
          lat: lat,
          lng: lng,
          type: objectTypes.switch,
          tick: `${traceChunk[0].tick} - ${
            traceChunk[traceChunk.length - 1].tick
          }`,
          color: objectColors(objectTypes.switch),
        } as MarkerType;
      } else {
        return {
          lat: 0,
          lng: 0,
          type: "undefined" as any,
          tick: `0+0,0m`,
          color: objectColors(objectTypes.switch),
        } as MarkerType;
      }
    });

  return allMarkers
    .filter((marker) => marker.type !== objectTypes.switch) // Remove original switches
    .concat(switchMarkers); // Add new switch marker
};

const getStartAndEndMarkers = (gpsSignal: gpsPointType[]) => {
  // Generate start marker position
  const [lngStart, latStart] = convertToWGS84(
    gpsSignal[0].gpsY,
    gpsSignal[0].gpsX
  );

  const startPoint = {
    tick: gpsSignal[0].tick,
    lng: lngStart,
    lat: latStart,
  };

  const [lngEnd, latEnd] = convertToWGS84(
    gpsSignal[gpsSignal.length - 1].gpsY,
    gpsSignal[gpsSignal.length - 1].gpsX
  );

  const endPoint = {
    tick: gpsSignal[gpsSignal.length - 1].tick,
    lng: lngEnd,
    lat: latEnd,
  };
  return [startPoint, endPoint];
};

export const GpsChart: React.FunctionComponent<GpsChartProps> = ({
  gpsSignal,
  measurementObjects,
  finishedLoadingObjects,
  selectedMeasurement,
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const objectList: string[] = [
    objectTypes.switch,
    objectTypes.levelCrossing,
    objectTypes.marker,
    objectTypes.notes,
    objectTypes.trackBarrier,
    objectTypes.trackCross,
    objectTypes.culvert,
    objectTypes.contactPole,
  ];

  const mapRef = useRef<GoogleMap>(null);

  let defaultCenter = { lat: 57.70887, lng: 11.97456 };

  const switchTraceChunks = getGPSTraceOfSwitches(measurementObjects);
  // Google maps component
  const Map: React.FC<GoogleMapProps> = (props) => {
    // Generate Gps trace
    const createGpsPath = (gpsSignal: gpsPointType[]) => {
      const gpsVec = gpsSignal.map((point) => {
        const [lng, lat] = convertToWGS84(point.gpsY, point.gpsX);

        return { lat, lng };
      });
      return gpsVec;
    };

    let path: { lat: number; lng: number }[] = [];

    if (gpsSignal && gpsSignal.length > 0) {
      path = createGpsPath(gpsSignal);
    }

    const options = {
      strokeColor: "#782441",
      strokeOpacity: 0.8,
      strokeWeight: 4,
      fillColor: "#782441",
      fillOpacity: 0.35,
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: 30000,
      zIndex: 1,
    };

    const optionsSwitchPath = {
      strokeColor: "#141adb",
      strokeWeight: 4,
      fillColor: "#141adb",
      clickable: false,
      draggable: false,
      editable: false,
      visible: true,
      radius: 30000,
      zIndex: 2,
    };

    useEffect(() => {
      // Initial pan to boundaries of GPS signal
      const bounds = new google.maps.LatLngBounds();
      if (gpsSignal && gpsSignal?.length > 0) {
        for (let i = 0; i < gpsSignal.length; i++) {
          const [lng, lat] = convertToWGS84(
            gpsSignal[i].gpsY,
            gpsSignal[i].gpsX
          );
          bounds.extend({ lat: lat, lng: lng });
        }
      } else {
        bounds.extend({
          lat: defaultCenter.lat + 0.05,
          lng: defaultCenter.lng + 0.05,
        });
        bounds.extend({
          lat: defaultCenter.lat - 0.05,
          lng: defaultCenter.lng - 0.05,
        });
      }

      if (bounds && mapRef.current) mapRef.current.fitBounds(bounds);
    }, [mapRef.current]);

    interface ObjectReferencesType {
      switch: React.MutableRefObject<boolean>;
      culvert: React.MutableRefObject<boolean>;
      trackBarrier: React.MutableRefObject<boolean>;
      levelCrossing: React.MutableRefObject<boolean>;
      notes: React.MutableRefObject<boolean>;
      marker: React.MutableRefObject<boolean>;
      trackCross: React.MutableRefObject<boolean>;
      contactPole: React.MutableRefObject<boolean>;
    }

    const objectReferences: ObjectReferencesType = {
      switch: useRef(false),
      culvert: useRef(false),
      trackBarrier: useRef(false),
      levelCrossing: useRef(false),
      trackCross: useRef(false),
      contactPole: useRef(false),
      notes: useRef(false),
      marker: useRef(false),
    };

    const [selectedMarker, setSelectedMarker] = useState<
      number | string | null
    >(null);

    let allMarkers = measurementObjects
      .map((point) => {
        const [lng, lat] = convertToWGS84(point.gpsY, point.gpsX);
        return point.type
          .map((theType: string) => {
            return {
              lat: lat,
              lng: lng,
              type: theType,
              color: objectColors(theType),
              tick: point.tick,
            } as MarkerType;
          })
          .flat();
      })
      .flat();

    allMarkers = restructureLongObjects(allMarkers, switchTraceChunks);
    // Add notes to all markers
    const allNotes = useSelector((state: appState) => state.plot.allNotes);

    allMarkers = allMarkers
      .concat(
        allNotes.map((point) => {
          const [lng, lat] = convertToWGS84(point.gpsY, point.gpsX);

          return {
            lat: lat,
            lng: lng,
            type: point.type,
            color: objectColors(point.type),
            tick: point.tick,
            value: point.value,
          } as MarkerType;
        })
      )
      .flat();

    if (allMarkers) {
      let startPoint: any = {};
      let endPoint: any = {};

      if (gpsSignal && gpsSignal?.length > 0) {
        [startPoint, endPoint] = getStartAndEndMarkers(gpsSignal);

        defaultCenter = { lat: startPoint.lat, lng: startPoint.lat };
      }
    }

    // useEffect(()=>{

    // }, [objectsToDisplay])

    const objectsToDisplay = useSelector(
      (state: appState) => state.plot.objectsToDisplay
    );

    const markers = allMarkers.filter((marker) => {
      return objectsToDisplay.includes(marker.type);
    });

    const screenWidth = useSelector((state: appState) => state).scheduler
      .screenWidth;

    const screenHeight = useSelector((state: appState) => state).scheduler
      .screenHeight;

    let startPoint: any = {};
    let endPoint: any = {};

    if (gpsSignal && gpsSignal?.length > 0) {
      [startPoint, endPoint] = getStartAndEndMarkers(gpsSignal);
    }

    const startMarker = {
      lat: startPoint.lat,
      lng: startPoint.lng,
      color: objectColors("Start"),
      type: "Start",
      tick: startPoint.tick,
    } as MarkerType;

    const endMarker = {
      lat: endPoint.lat,
      lng: endPoint.lng,
      type: "End",
      color: objectColors("End"),
      tick: endPoint.tick,
    } as MarkerType;

    const [markerCluster, setMarkerCluster] = useState<ClusterInfo>();

    return (
      <GoogleMap
        onZoomChanged={() => setMarkerCluster(undefined)}
        options={{
          maxZoom: 17, //17,
          minZoom: 3, //3,
          styles: mapStyle as any,
          streetViewControl: false,
          fullscreenControl: false,
        }}
        defaultCenter={defaultCenter}
        ref={mapRef}
      >
        <Marker
          key={"marker" + "Start"}
          position={new google.maps.LatLng(startMarker.lat, startMarker.lng)}
          icon={{
            url: "./icons/location-dot-solid-" + startMarker.color + ".svg", //"icons/map-pin-icon-" + marker.color + ".svg",
          }}
          onClick={() => {
            setSelectedMarker("start");
          }}
        >
          {selectedMarker === "start" ? (
            <InfoWindow
              position={
                new google.maps.LatLng(startMarker.lat, startMarker.lng)
              }
              onCloseClick={() => {
                setSelectedMarker(null);
              }}
            >
              <div>
                <h6>
                  {startMarker.type === objectTypes.notes
                    ? startMarker.value
                    : startMarker.type === objectTypes.marker
                    ? t("gps.marker")
                    : translateToSelectedLanguage(t, startMarker.type)}
                </h6>
                <div> {startMarker.tick}</div>
                <div>
                  {" "}
                  lat:{" "}
                  {Math.round(startMarker.lat * Math.pow(10, 6)) /
                    Math.pow(10, 6)}
                </div>
                <div>
                  {" "}
                  lng:{" "}
                  {Math.round(startMarker.lng * Math.pow(10, 6)) /
                    Math.pow(10, 6)}
                </div>
              </div>
            </InfoWindow>
          ) : null}
        </Marker>

        {markers
          .filter((mark) => mark.type !== "End" && mark.type !== "Start")
          .map((marker, i) => {
            return (
              <Marker
                key={"marker" + i}
                position={new google.maps.LatLng(marker.lat, marker.lng)}
                icon={
                  marker.type === objectTypes.marker
                    ? {
                        path: "M427.049,119.97L143.15,26.579V20c0-11.046-8.954-20-20-20s-20,8.954-20,20v380.914c-34.067,4.941-63.183,21.913-80.762,41.345c-2.267,2.506-2.845,6.113-1.474,9.202c1.371,3.089,4.434,5.077,7.813,5.077h188.846c3.379,0,6.44-1.991,7.811-5.079c1.371-3.088,0.795-6.695-1.472-9.2c-17.579-19.428-46.699-36.396-80.763-41.341V239.087l283.898-93.391c5.555-1.827,9.31-7.015,9.31-12.863C436.358,126.985,432.604,121.798,427.049,119.97z",
                        fillColor: "#FF0000",
                        fillOpacity: 1,
                        strokeWeight: 1,
                        strokeColor: "#000000",
                        scale: 0.06,
                        anchor: new google.maps.Point(
                          150, // width
                          450 // height
                        ),
                      }
                    : {
                        url:
                          "./icons/location-dot-solid-" + marker.color + ".svg",
                      }
                }
                onClick={() => {
                  setSelectedMarker(i);
                }}
              >
                {selectedMarker === i ? (
                  <InfoWindow
                    position={new google.maps.LatLng(marker.lat, marker.lng)}
                    onCloseClick={() => {
                      setSelectedMarker(null);
                    }}
                  >
                    <div>
                      <h6>
                        {" "}
                        {marker.type === objectTypes.notes
                          ? marker.value
                          : marker.type === objectTypes.marker
                          ? t("gps.marker")
                          : translateToSelectedLanguage(t, marker.type)}
                      </h6>
                      <div> {marker.tick}</div>
                      <div>
                        {" "}
                        lat:{" "}
                        {Math.round(marker.lat * Math.pow(10, 6)) /
                          Math.pow(10, 6)}
                      </div>
                      <div>
                        {" "}
                        lng:{" "}
                        {Math.round(marker.lng * Math.pow(10, 6)) /
                          Math.pow(10, 6)}
                      </div>
                    </div>
                  </InfoWindow>
                ) : null}
              </Marker>
            );
          })}
        <Marker
          key={"marker" + "End"}
          position={new google.maps.LatLng(endMarker.lat, endMarker.lng)}
          icon={{
            url: "./icons/location-dot-solid-" + endMarker.color + ".svg", //"icons/map-pin-icon-" + marker.color + ".svg",
          }}
          onClick={() => {
            setSelectedMarker("end");
          }}
        >
          {selectedMarker === "end" ? (
            <InfoWindow
              position={new google.maps.LatLng(endMarker.lat, endMarker.lng)}
              onCloseClick={() => {
                setSelectedMarker(null);
              }}
            >
              <div>
                <h6>
                  {endMarker.type === objectTypes.notes
                    ? endMarker.value
                    : endMarker.type === objectTypes.marker
                    ? t("gps.marker")
                    : translateToSelectedLanguage(t, endMarker.type)}
                </h6>
                <div> {endMarker.tick}</div>
                <div>
                  {" "}
                  lat:{" "}
                  {Math.round(endMarker.lat * Math.pow(10, 6)) /
                    Math.pow(10, 6)}
                </div>
                <div>
                  {" "}
                  lng:{" "}
                  {Math.round(endMarker.lng * Math.pow(10, 6)) /
                    Math.pow(10, 6)}
                </div>
              </div>
            </InfoWindow>
          ) : null}
        </Marker>

        <Polyline path={path} options={options} />
        {!objectReferences.switch.current
          ? switchTraceChunks.map((chunk) => {
              if (chunk !== undefined) {
                const switchPath = chunk.map((point) => {
                  const [lng, lat] = convertToWGS84(point.gpsY, point.gpsX);
                  return { lng, lat };
                });
                return (
                  <Polyline path={switchPath} options={optionsSwitchPath} />
                );
              }
            })
          : null}
      </GoogleMap>
    );
  };

  const MapWrapped = withScriptjs(
    withGoogleMap((props) => {
      return Map(props);
    })
  );

  return (
    <MapWrapped
      googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.9&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
      loadingElement={
        <div
          style={{
            height: `80vh`,
            width: "calc(100% + 30px)",
            maxHeight: "400px",
            borderRadius: "10px",
          }}
        />
      } // Add additional 30px for the non-existing y-axes
      containerElement={
        <div
          style={{
            height: `80vh`,
            width: "calc(100% + 30px)",
            maxHeight: "400px",
            borderRadius: "10px",
          }}
        />
      } // Add additional 30px for the non-existing y-axes
      mapElement={
        <div
          style={{
            height: `80vh`,
            width: "calc(100% + 30px)",
            maxHeight: "400px",
            borderRadius: "10px",
          }}
        />
      } // Add additional 30px for the non-existing y-axes
    />
  );
};
