import { ScaleLinear } from "d3";
import {
  getLongitudinalLevelChart,
  getNewChart,
} from "../../helpers/genericHelpers";
import { measurementMetaData } from "../scheduler/types";
import {
  plotActionType,
  GroupChartDataProps,
  SelectedTolerancesType,
  setDataPoints,
  setMeasurementToDisplay,
  setTolerancesToDisplay,
  setSignalInterval,
  setAllCharts,
  plotState,
  setSelectedChart,
  setPlotMetaData,
  gpsPointType,
  setGpsPoints,
  setDisplayProjectedTrack,
  setDataPointsAnyChart,
  setGlobalSignalInterval,
  setMainXScale,
  setMainXScaleTicks,
  setFinishedLoading,
  setXAxisIndex,
  plotStateLongitudinalLevel,
  setDataPointsLongitudinalLevel,
  setLongitudinalLevelToDisplay,
  setLongitudinalLevelWindow,
  setWindowXPosition,
  setInitialWindowPosition,
  setViewSpecialCharts,
  setAllMeasurementObjects,
  objectPointType,
  setObjectsToDisplay,
  setAccelerationArea,
  notePointType,
  setAllMeasurementNotes,
} from "./types";

export function reduxSetDataPoints(
  dataSets: GroupChartDataProps
): setDataPoints {
  return {
    type: plotActionType.setDataPoints,
    payload: dataSets,
  };
}

export function reduxSetDataPointsLongitudinalLevel(
  dataSets: GroupChartDataProps
): setDataPointsLongitudinalLevel {
  return {
    type: plotActionType.setDataPointsLongitudinalLevel,
    payload: dataSets,
  };
}

export function reduxSetDataPointsAnyChart(
  chartID: string,
  data: GroupChartDataProps
): setDataPointsAnyChart {
  return {
    type: plotActionType.setDataPointsAnyChart,
    payload: { chartID: chartID, data: data },
  };
}

export function reduxSetGpsPoints(gpsSet: gpsPointType[]): setGpsPoints {
  return {
    type: plotActionType.setGpsPoints,
    payload: gpsSet,
  };
}

export function reduxSetMeasurementToDisplay(
  measurementToDisplay: (keyof GroupChartDataProps)[]
): setMeasurementToDisplay {
  return {
    type: plotActionType.setMeasurementToDisplay,
    payload: measurementToDisplay,
  };
}

export function reduxSetLongitudinalLevelToDisplay(
  measurementToDisplay: (keyof GroupChartDataProps)[]
): setLongitudinalLevelToDisplay {
  return {
    type: plotActionType.setLongitudinalLevelToDisplay,
    payload: measurementToDisplay,
  };
}

export function reduxSetDisplayProjectedTrack(
  displayProjectedTrack: boolean
): setDisplayProjectedTrack {
  return {
    type: plotActionType.setDisplayProjectedTrack,
    payload: displayProjectedTrack,
  };
}

export function reduxSetTolerancesToDisplay(
  tolerancesToDisplay: SelectedTolerancesType
): setTolerancesToDisplay {
  return {
    type: plotActionType.setTolerancesToDisplay,
    payload: tolerancesToDisplay,
  };
}

export function reduxSetCharts(charts: plotState[]): setAllCharts {
  return {
    type: plotActionType.setAllCharts,
    payload: charts,
  };
}

export function reduxSetSelectedChart(chartID: string): setSelectedChart {
  return {
    type: plotActionType.setSelectedChart,
    payload: chartID,
  };
}

export function addNewChart(chartID: string) {
  return (dispatch: any) => {
    const chart = getNewChart(chartID);
    dispatch(reduxAddNewChart(chart));
  };
}

export function reduxAddNewChart(chart: plotState) {
  return {
    type: plotActionType.addNewChart,
    payload: chart,
  };
}

export function addLongitudinalLevelChart(chartID: string) {
  return (dispatch: any) => {
    const chart = getLongitudinalLevelChart(chartID);
    dispatch(reduxAddLongitudinalLevelChart(chart));
  };
}

export function reduxAddLongitudinalLevelChart(
  chart: plotStateLongitudinalLevel
) {
  return {
    type: plotActionType.addLongitudinalLevelChart,
    payload: chart,
  };
}

export function reduxRemoveChart(chartID: string) {
  return {
    type: plotActionType.removeChart,
    payload: chartID,
  };
}

export function reduxSetSignalInterval(signalInterval: {
  xMinOrig: number;
  xMaxOrig: number;
  xMin: number;
  xMax: number;
  resolution: number;
}): setSignalInterval {
  return {
    type: plotActionType.setSignalInterval,
    payload: signalInterval,
  };
}

export function reduxSetGlobalSignalInterval(signalInterval: {
  xMinOrig: number;
  xMaxOrig: number;
  xMin: number;
  xMax: number;
  resolution: number;
}): setGlobalSignalInterval {
  return {
    type: plotActionType.setGlobalSignalInterval,
    payload: signalInterval,
  };
}

export function reduxSetPlotMetaData(
  metaData: measurementMetaData
): setPlotMetaData {
  return {
    type: plotActionType.setPlotMetaData,
    payload: metaData,
  };
}

export function reduxSetMainXScale(
  xScale: ScaleLinear<number, number>
): setMainXScale {
  return {
    type: plotActionType.setMainXScale,
    payload: xScale,
  };
}

export function reduxSetMainXScaleTicks(payload: {
  x: number[];
  tick: string[];
}): setMainXScaleTicks {
  return {
    type: plotActionType.setMainXScaleTicks,
    payload: payload,
  };
}

export function reduxSetFinishedLoading(payload: {
  finishedLoading: boolean;
  chartID: string;
}): setFinishedLoading {
  return {
    type: plotActionType.setFinishedLoading,
    payload: payload,
  };
}

export function reduxSetXAxisIndex(payload: number): setXAxisIndex {
  return {
    type: plotActionType.setXAxisIndex,
    payload: payload,
  };
}

export function reduxSetLongitudinalLevelWindow(
  payload: number
): setLongitudinalLevelWindow {
  return {
    type: plotActionType.setLongitudinalLevelWindow,
    payload: payload,
  };
}

export function reduxSetInitialWindowPosition(
  payload: boolean
): setInitialWindowPosition {
  return {
    type: plotActionType.setInitialWindowPosition,
    payload: payload,
  };
}

export function reduxSetWindowXPosition(payload: number): setWindowXPosition {
  return {
    type: plotActionType.setWindowXPosition,
    payload: payload,
  };
}

export function reduxSetViewSpecialCharts(
  payload: boolean
): setViewSpecialCharts {
  return {
    type: plotActionType.setViewSpecialCharts,
    payload: payload,
  };
}

export function reduxSetAllMeasurementObjects(
  payload: objectPointType[]
): setAllMeasurementObjects {
  return {
    type: plotActionType.setAllMeasurementObjects,
    payload: payload,
  };
}

export function reduxSetAllMeasurementNotes(
  payload: notePointType[]
): setAllMeasurementNotes {
  return {
    type: plotActionType.setAllMeasurementNotes,
    payload: payload,
  };
}

export function reduxSetObjectsToDisplay(
  ObjectsToDisplay: string[]
): setObjectsToDisplay {
  return {
    type: plotActionType.setObjectsToDisplay,
    payload: ObjectsToDisplay,
  };
}

export function reduxSetAccelerationArea(
  accelerationArea: number[]
): setAccelerationArea {
  return {
    type: plotActionType.setAccelerationArea,
    payload: accelerationArea,
  };
}
