import React from "react";
import "./style.scss";
import { useErrorBoundary } from "react-error-boundary";
import { useTranslation } from "react-i18next";

export const ErrorFallback: React.FunctionComponent = () => {
  const { resetBoundary } = useErrorBoundary();
  const { t } = useTranslation();

  return (
    <div className="ErrorViewMain" role="alert">
      {/* <img className="ErrorImageRedemptorLogo" src={"./redemptorLogo.png"} /> */}
      <h2>{t("fallback.header")}</h2>
      <p>{t("fallback.recover")}</p>
      <button className="BUTTON ReloadButton" onClick={resetBoundary}>
        {t("fallback.refresh")}
        <span className="k-icon k-i-refresh RefreshButton" />
      </button>
    </div>
  );
};
