import React, { useState, useEffect } from "react";
import "./style.scss";

import {
  Form,
  FormRenderProps,
  FormElement,
  Field,
} from "@progress/kendo-react-form";
import {
  setUserHasChanged,
  reduxSetNotification,
  reduxSetSelectedUser,
  reduxSetInformationRequest,
} from "../../store/user/actions";
import { Input } from "@progress/kendo-react-inputs";
import { useDispatch, useSelector } from "react-redux";
import { helpInformationPath, userType } from "../../store/user/types";
import { appState } from "../../store";
import { Auth } from "aws-amplify";
import { CustomInputUser } from "../manageUsers/userForm";
import { useTranslation } from "react-i18next";

interface UpdateUserFormProps {
  user: userType;
  allowEdit: boolean;
  setAllowEdit: React.Dispatch<React.SetStateAction<boolean>>;
}

export const UpdatePasswordForm: React.FunctionComponent<
  UpdateUserFormProps
> = ({ user, allowEdit, setAllowEdit }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const notEmptyValidator = (value?: string) =>
    value && value.length > 0 ? "" : t("common.cannotBeEmptyValidator");

  const passwordValidator = (value?: string) => {
    let error = "";
    if (value === undefined) return t("user.fillInPassword");
    const error0 = value.length < 8 ? t("user.atleast8Char") + "\n" : "";
    const error1 = !new RegExp("(?=.*[a-z])").test(value)
      ? t("user.lowerCase") + "\n"
      : "";
    const error2 = !new RegExp("(?=.*[A-Z])").test(value)
      ? t("user.upperCase") + "\n"
      : "";
    const error3 = !new RegExp("(?=.*[0-9].*)").test(value)
      ? t("user.number") + "\n"
      : "";
    const error4 = !new RegExp("(?=.*[-+_!@#$%^&*.,?])").test(value)
      ? t("user.specialCharacter") + "\n"
      : "";
    error = error0 + error1 + error2 + error3 + error4;
    return error;
  };

  useEffect(() => {
    dispatch(setUserHasChanged(false));
    return () => {
      dispatch(setUserHasChanged(false));
    };
  }, []);

  useEffect(() => {
    if (user.id) dispatch(reduxSetSelectedUser(user?.id));
    dispatch(setUserHasChanged(false));
    return () => {
      dispatch(setUserHasChanged(false));
    };
  }, []);

  const authenticationSuccess = useSelector(
    (state: appState) => state.user.authenticationSuccess
  );

  const [viewCurrentPassword, setViewCurrentPassword] = useState(false);
  const [viewNewPassword, setViewNewPassword] = useState(false);
  const [newPassword, setNewPassword] = useState("");

  const informationRequest = useSelector(
    (state: appState) => state.user.informationRequest
  );

  const renderUpdatePasswordFormContent = (
    formRenderProps: FormRenderProps
  ) => {
    const { valid, valueGetter } = formRenderProps;

    const passwordMatch =
      valueGetter("newPassword") === valueGetter("confirmPassword");
    setNewPassword(valueGetter("newPassword"));

    return (
      <FormElement className="userCardUser" style={{ width: "100%" }}>
        <div className="userCardColumnL">
          <div>
            <div className="userCardRow">
              <legend
                className={"k-form-legend"}
                style={{
                  color: "black",
                  marginTop: "30px",
                  marginBottom: "0px",
                }}
              >
                {t("user.authorizeChanges")}
                <span
                  className="k-icon k-i-info informationButton"
                  style={{ marginLeft: "10px" }}
                  onClick={() => {
                    dispatch(
                      reduxSetInformationRequest({
                        path: helpInformationPath.updatePassword,
                        showInformation:
                          informationRequest?.path ===
                          helpInformationPath.updatePassword
                            ? !informationRequest?.showInformation
                            : true,
                      })
                    );
                  }}
                />
              </legend>
              <span
                className="k-icon k-i-change-manually editUser "
                style={{
                  fontSize: "24px",
                  marginRight: "auto",
                  marginTop: "auto",
                  backgroundColor: "var(--primary-color)",
                  color: "white",
                  borderRadius: "6px",
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setAllowEdit(!allowEdit);
                }}
              />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                position: "relative",
              }}
            >
              <Field
                name={"currentPassword"}
                type={viewCurrentPassword ? "text" : "password"}
                component={CustomInputUser}
                label={t("user.currentPassword")}
                validator={notEmptyValidator} // minimum number letters
              />
              <span
                className="k-icon k-i-preview "
                style={
                  viewCurrentPassword
                    ? {
                        fontSize: "16px",
                        backgroundColor: "var(--primary-color)",
                        color: "white",
                        borderRadius: "6px",
                        right: "0",
                        position: "absolute",
                      }
                    : {
                        fontSize: "16px",
                        borderRadius: "6px",
                        right: "0",
                        position: "absolute",
                        color: "var(--primary-color)",
                      }
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setViewCurrentPassword(!viewCurrentPassword);
                }}
                onMouseLeave={() => {
                  setViewCurrentPassword(false);
                }}
              />
            </div>
            <div style={{ color: "red" }}>
              {authenticationSuccess ? "" : t("manageUsers.wrongPassword")}
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                width: "100%",
                position: "relative",
              }}
            >
              <span
                className="k-icon k-i-preview"
                style={
                  viewNewPassword
                    ? {
                        fontSize: "16px",
                        backgroundColor: "var(--primary-color)",
                        color: "white",
                        borderRadius: "6px",
                        right: "0",
                        position: "absolute",
                        top: "26px",
                        zIndex: "1000",
                      }
                    : {
                        fontSize: "16px",
                        borderRadius: "6px",
                        right: "0",
                        position: "absolute",
                        color: "var(--primary-color)",
                        top: "26px",
                        zIndex: "1000",
                      }
                }
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  setViewNewPassword(!viewNewPassword);
                }}
                onMouseLeave={() => {
                  setViewNewPassword(false);
                }}
              />
              <Field
                name={"newPassword"}
                type={viewNewPassword ? "text" : "password"}
                component={CustomInputUser}
                label={t("user.newPassword")}
                validator={passwordValidator} // minimum number letters
              />
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <Field
                name={"confirmPassword"}
                type="password"
                component={Input}
                label={t("user.repeatPassword")}
                valid={passwordMatch}
              />
              {!passwordMatch ? (
                <div
                  style={{
                    color: "red",
                    height: "11px",
                    fontSize: "10px",
                  }}
                >
                  {t("user.passwordDontMatch")}
                </div>
              ) : (
                <div style={{ height: "11px" }} />
              )}
            </div>
          </div>
          <div className="AddUserButtonFormContainer">
            <button
              className="AddUserButtonForm BUTTON"
              type={"submit"}
              disabled={
                !formRenderProps.allowSubmit || !valid || !passwordMatch
              }
              style={
                !formRenderProps.allowSubmit || !valid || !passwordMatch
                  ? {
                      opacity: 0.5,
                    }
                  : {}
              }
            >
              {t("common.update")}
            </button>
          </div>
        </div>
      </FormElement>
    );
  };

  const openedContent = () => {
    return (
      <Form
        onSubmit={(dataItem) => {
          Auth.currentAuthenticatedUser()
            .then((user) => {
              Auth.changePassword(user, dataItem.currentPassword, newPassword)
                .then((response) => {
                  dispatch(
                    reduxSetNotification({
                      style: "success",
                      message: t("user.passwordUpdated"),
                      open: true,
                    })
                  );
                })
                .catch((error) => {
                  dispatch(
                    reduxSetNotification({
                      style: "error",
                      message:
                        t("user.passwordCouldntUpdate") + " " + error.message,
                      open: true,
                    })
                  );
                });
            })
            .catch((err) => {
              console.log(err);
              dispatch(
                reduxSetNotification({
                  style: "error",
                  message: t("user.passwordCouldntUpdate") + " " + err.message,
                  open: true,
                })
              );
            });
          setAllowEdit(!allowEdit);
        }}
        render={(formRenderProps) =>
          renderUpdatePasswordFormContent(formRenderProps)
        }
      />
    );
  };

  return <div> {openedContent()} </div>;
};
