import React, { useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { cPhoneWidthLim } from "../../app";

import { appState } from "../../store";
import "./chartTabs.css";
import { tabProps } from "../../pages/dashboard/page";
import SvgAnalysisGraph from "../generic/componentIcons/AnalysisGraph";

interface ChartTabsProps {
  tabs: tabProps[];
}

export const ChartTabs: React.FunctionComponent<ChartTabsProps> = ({
  tabs,
}) => {
  const [selectedComponent, setSelectedComponent] = useState(tabs[0].title);

  const width = useSelector((state: appState) => state.scheduler.screenWidth);
  let fontSize = 18;
  if (width < 720) {
    fontSize = 14;
  }

  return (
    <div className="charTabsMainContainer">
      <div className="tabButtonsContainer">
        {tabs.map((tab, idx) => (
          <button
            className={
              selectedComponent === tab.title
                ? "tabButtonSelected"
                : "tabButtonNotSelected"
            }
            style={{
              borderLeft:
                idx !== 0 && selectedComponent !== tab.title
                  ? "solid 1px #d9d9d9"
                  : "none",
              borderRight:
                idx !== tabs.length - 1 && selectedComponent !== tab.title
                  ? "solid 1px #d9d9d9"
                  : "none",
              borderRadius: idx !== 0 ? "0 5px 0 0" : "5px 0 0 0",
            }}
            onClick={() => {
              setSelectedComponent(tab.title);
            }}
          >
            {tab.iconToTheLeft === true ? (
              <div className="tabContentContainer" style={{ fontSize }}>
                <div>{tab.icon}</div>
                <div>{tab.title}</div>
              </div>
            ) : (
              <div className="tabContentContainer" style={{ fontSize }}>
                <div>{tab.title}</div>
                <div>{tab.icon}</div>
              </div>
            )}
          </button>
        ))}
      </div>
      <div
        style={{
          marginLeft: width >= cPhoneWidthLim ? "30px" : "10px",
          marginRight: width >= cPhoneWidthLim ? "30px" : "10px",
        }}
        className="charTabsSecondaryContainer"
      >
        {
          tabs.find((tab: tabProps) => tab.title === selectedComponent)
            ?.component
        }
      </div>
    </div>
  );
};
