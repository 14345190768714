import React, { useEffect } from "react";
import { Auth } from "aws-amplify";
import { useHistory } from "react-router-dom";
import { onLogin } from "../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { appState } from "../../store";
import { useTranslation } from "react-i18next";

export const LandingPage: React.FunctionComponent = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const currentUser = useSelector((state: appState) => state.user.currentUser);

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // If user isn't logged in, then log them in.
        if (currentUser === undefined) {
          dispatch(onLogin(t));
        }

        // We are now logged in, push to scheduler
        if (
          (history.location.pathname === "/" ||
            history.location.pathname === "/login") &&
          user.attributes.phone_number_verified !== undefined
        ) {
          history.replace("/scheduler");
        }
      })
      .catch((err) => {
        history.replace("/login");
        console.log("not authenticated landing", err);
      });
  }, []);

  return <div></div>;
};
