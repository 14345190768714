import { userReducer } from "./user";
import { applyMiddleware, compose, createStore } from "redux";
import {
  reduxReconnectingSocket,
  reduxReconnectingSocketReducer,
  ReduxReconnectingSocketState,
} from "redux-reconnecting-socket";
import thunk from "redux-thunk";
import { schedulerReducer } from "./scheduler";
import { combineReducers, install, StoreCreator } from "redux-loop";
import { machineReducer } from "./machine";
import { plotReducer } from "./plot";
import { userState } from "./user/types";
import { machineState } from "./machine/types";
import { ChartType } from "./plot/types";
import { companyReducer } from "./company";
import { companyState } from "./company/types";
import { schedulerState } from "./scheduler/types";

const enhancedCreateStore = createStore as StoreCreator;

const rootReducer = combineReducers({
  machine: machineReducer,
  user: userReducer,
  scheduler: schedulerReducer,
  company: companyReducer,
  plot: plotReducer,
  connection: reduxReconnectingSocketReducer,
} as any);

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const enhancer = composeEnhancers(
  applyMiddleware(reduxReconnectingSocket(), thunk),
  install()
);
const store = enhancedCreateStore(rootReducer, undefined, enhancer);

export type appState = {
  machine: machineState;
  user: userState;
  scheduler: schedulerState;
  company: companyState;
  plot: ChartType;
  connection: ReduxReconnectingSocketState;
};

export default store;
