import React, { memo, MouseEvent } from 'react';

interface ReprocessAcceptButtonProps {
  startAtKmValid: boolean;
  startAtMeterValid: boolean;
  endAtKmValid: boolean;
  endAtMeterValid: boolean;
  trackNumberValid: boolean;
  invalidEndPoint: boolean;
  setShowReprocessPopup: (show: boolean) => void;
}

const ReprocessAcceptButton: React.FC<ReprocessAcceptButtonProps> = memo((props) => {
  const { startAtKmValid, startAtMeterValid, endAtKmValid, endAtMeterValid, trackNumberValid, invalidEndPoint, setShowReprocessPopup } = props;

  const handleOnClick = (e: MouseEvent<HTMLSpanElement>) => {
    e.preventDefault();
    e.stopPropagation();
    setShowReprocessPopup(true);
  };

  const isButtonEnabled = startAtKmValid && startAtMeterValid && endAtKmValid && endAtMeterValid && trackNumberValid && !invalidEndPoint;

  return (
    <span
      className="k-icon k-i-check removeChart"
      style={{
        fontSize: "24px",
        marginRight: "10px",
        padding: "15px",
        pointerEvents: isButtonEnabled ? "all" : "none",
        opacity: isButtonEnabled ? 1 : 0.5,
      }}
      onClick={handleOnClick}
    />
  );
});

export default ReprocessAcceptButton;
