import React, { useEffect, useState } from "react";
import { Redirect, Route, useHistory } from "react-router-dom";
import { Auth } from "aws-amplify";
import { appState } from "./store";
import { useDispatch, useSelector } from "react-redux";
import {
  setSelectedMeasurement,
  setSelectedPlan,
} from "./store/scheduler/actions";
import { logout, onLogin } from "./store/user/actions";
import { Menu } from "./components/menu";
import {
  getLastVisitedPlanAndMeasurement,
  setLastVisitedPlanAndMeasurement,
} from "./helpers/genericHelpers";
import { useTranslation } from "react-i18next";
import { fetchStoredAnnouncement } from "./components/announcement/announcement";
import * as announcementsAPI from "./api/netRail/announcements";

interface ProtectedRouteProps {
  component: any;
  exact: any;
  path: any;
}

export const ProtectedRoute: React.FunctionComponent<ProtectedRouteProps> = ({
  component: Component,
  ...rest
}) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [userIsLoggedIn, setUserHasLoggedIn] = useState(false);
  const currentUser = useSelector((state: appState) => state.user.currentUser);

  const Today = new Date();

  // Handle if user have a refreshtoken.
  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then((user) => {
        // If user refreshes without an valid phone number, force logout so that they can redo the process.
        if (user.attributes.phone_number_verified === undefined) {
          dispatch(logout());
        }

        // If user isn't logged in, then log them in.
        if (currentUser === undefined) {
          dispatch(onLogin(t));

          const lastVisitedPage = getLastVisitedPlanAndMeasurement();

          if (currentUser) {
            if (lastVisitedPage?.lastPlan) {
              dispatch(setSelectedPlan(lastVisitedPage?.lastPlan));
            }
            if (lastVisitedPage?.lastMeasurement) {
              dispatch(
                setSelectedMeasurement(lastVisitedPage?.lastMeasurement)
              );
            }
          }
        }
      })
      .catch((err) => {
        history.replace("/login");
        console.log("not authenticated protected", err);
      });
  }, []);

  const allPlans = useSelector((state: appState) => state.scheduler.plans);

  async function getAPIAnnouncementsLocally() {
    try {
      const announcements = await announcementsAPI.getAnnouncements();
      const storedAnnouncements = fetchStoredAnnouncement();
      if (storedAnnouncements.length > 0) {
        // Filter to find still active announcement and discard the rest from stored
        const filteredStoredAnnouncementList = announcements.filter(
          (announcement) => {
            return (
              storedAnnouncements.includes(announcement.id) &&
              new Date(announcement.expiresAt) > Today
            );
          }
        );

        const filteredStoredAnnouncementIDList =
          filteredStoredAnnouncementList.map((announcement) => {
            return announcement.id;
          });

        localStorage.setItem(
          "storedAnnouncements",
          JSON.stringify(filteredStoredAnnouncementIDList)
        );
      }
    } catch (err) {
      console.log("err", err);
    }
  }

  useEffect(() => {
    if (currentUser !== undefined) {
      setUserHasLoggedIn(true);
      getAPIAnnouncementsLocally();
      // Find last measurement and plan ID from session and set them as selected.
      const lastVisitedResources = getLastVisitedPlanAndMeasurement();

      if (currentUser) {
        if (
          lastVisitedResources?.lastPlan &&
          allPlans.some((plan) => plan.id === lastVisitedResources?.lastPlan)
        ) {
          dispatch(setSelectedPlan(lastVisitedResources?.lastPlan));
        } else if (lastVisitedResources?.lastPlan && allPlans.length > 0) {
          // history.push("/scheduler");

          setLastVisitedPlanAndMeasurement(undefined, undefined);
        }

        if (lastVisitedResources?.lastMeasurement) {
          dispatch(
            setSelectedMeasurement(lastVisitedResources?.lastMeasurement)
          );
        }
      }
    }
  }, [currentUser, allPlans.length]);

  return (
    <>
      {currentUser ? <Menu /> : null}
      <Route
        {...rest}
        // exact
        path={rest.path}
        render={(props) => {
          if (userIsLoggedIn) {
            if (
              // Make sure that only admins can access these pages
              (rest.path.includes("announcements") ||
                rest.path.includes("manageCompanies") ||
                rest.path.includes("support")) &&
              currentUser?.roles.some((role) => role.name !== "ADMIN")
            ) {
              return (
                <Redirect
                  to={{
                    pathname: "/scheduler",
                    state: {
                      from: props.location,
                    },
                  }}
                />
              );
            }
            return <Component exact path={rest.path} {...props} />;
          }
        }}
      />
    </>
  );
};
